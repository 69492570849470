import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../reducers/currentUser";
import ClientDiscountList from "../../components/discount/ClientDiscountList";
import GroupDiscountList from "../../components/discount/GroupDiscountList";
import TradenameDiscountList from "../../components/discount/TradenameDiscountList";
import ProductDiscountList from "../../components/discount/ProductDiscountList";
import PreSeasonDiscountList from "../../components/discount/PreSeasonDiscountList";
import { Typography, Tabs, Tab } from "@material-ui/core";

const TabContainer = (props) => {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
};

const DiscountLists = () => {
  const [tabOpened, setTabOpened] = useState(0);
  const currentUser = useSelector(getCurrentUser);

  // if (!currentUser.get('discountAble')) {
  //   return null;
  // }

  const handleChange = (event, newValue) => {
    setTabOpened(newValue);
  };

  return (
    <>
      <Typography variant="subtitle1" paragraph>
        Vous pouvez cliquer sur les cellules de "date de fin", de "remise" et de
        "franco" pour en changer la valeur.
      </Typography>
      <Tabs
        value={tabOpened}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
      >
        <Tab label="Magasins" />
        <Tab label="Groupements" />
        <Tab label="Enseignes" />
        <Tab label="Produits" />
        <Tab label="Présaisons" />
      </Tabs>
      {(currentUser.get("discountAble") ||
        currentUser.get("role") === "commercial") &&
        tabOpened === 0 && (
          <TabContainer>
            <ClientDiscountList
              editable
              addable={currentUser.get("discountAble")}
              readOnly={!currentUser.get("discountAble")}
            />
          </TabContainer>
        )}
      {currentUser.get("discountAble") && (
        <>
          {tabOpened === 1 && (
            <TabContainer>
              <GroupDiscountList editable addable />
            </TabContainer>
          )}
          {tabOpened === 2 && (
            <TabContainer>
              <TradenameDiscountList editable addable />
            </TabContainer>
          )}
          {tabOpened === 3 && (
            <TabContainer>
              <ProductDiscountList editable addable />
            </TabContainer>
          )}
          {tabOpened === 4 && (
            <TabContainer>
              <PreSeasonDiscountList editable addable />
            </TabContainer>
          )}
        </>
      )}
    </>
  );
};

export default DiscountLists;
