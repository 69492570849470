import React, { useRef, useLayoutEffect, useState } from "react";
import classNames from "classnames";
import DataGridView from "../reactgrid/DataGridView";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { getProducts, getShippings } from "../../reducers/order";
import { getCurrentUser } from "../../reducers/currentUser";
import ProductIcons from "../../components/product/ProductsIcons";
import { getArticleData, generateAvailabilityText } from "../../utils2";
import { useSelector } from "react-redux";
import { Typography, Tooltip } from "@material-ui/core";
import CountCell from "./CountCell";
import moment from "moment";
import { filterSuppliesDataFromDate } from "../../utils/filterSuppliesDataFromDate";
import { getArticlesMinStock } from "../../utils/generateSuppliesData/getArticlesMinStock";

const lineHeight = 32;
const ARKTCODARTColWidth = 85;
const orderedQuantityColWidth = 85;
const netTotalPriceWidth = 90;
const netUnitPriceWidth = 90;
const availabilityColWidth = 55;
const quantityColWidth = 110;
const amountColWidth = 150;
const shippingColsWidth =
  availabilityColWidth + quantityColWidth + amountColWidth;

const useStyles = makeStyles(() => ({
  dataGridViewContainer: {
    overflow: "auto",
  },
  cellLine: {
    display: "flex",
    height: lineHeight,
    lineHeight: lineHeight + "px",
    flexDirection: "column",
    justifyContent: "center",
  },
  alignCenter: {
    alignItems: "center",
  },
  alignRight: {
    alignItems: "flex-end",
  },
  textWithSecondLine: {
    display: "flex",
    lineHeight: "18px",
  },
  secondLine: {
    display: "flex",
    lineHeight: "12px",
    color: "#777",
  },
  textWithSecondLineForArticles: {
    display: "flex",
    justifyContent: "flex-end",
    lineHeight: "22px",
  },
  secondLineForArticles: {
    display: "flex",
    justifyContent: "flex-end",
    lineHeight: "8px",
    color: "#777",
  },
  marginForCellWithArticle: {
    display: "flex",
    flexDirection: "column",
    margin: "5px 0",
  },
  flexDirectionRow: {
    display: "flex",
    flexDirection: "row",
  },
  image: {
    margin: "0 4px 0 0",
  },
}));

const SelectedProductsList = ({ activeStep, height }) => {
  /******** variables and functions declaration ********/
  const currentUser = useSelector(getCurrentUser);
  const userRole =
    currentUser !== undefined ? currentUser.get("role") : undefined;

  const shippings = useSelector(getShippings);
  // second argument should be a function comparing old productsOrder and new productsOrder. Since the only thing changed by SelectedProductsList is productsOrder, we know that every change made will change productsOrder. No need to compare, but if needed we would use a deep compare like isEqual from lodash, because shallow compare wouldn't trigger rerender here.
  const productsOrder = useSelector(getProducts, false);

  const theme = useTheme();
  const classes = useStyles({ theme });

  const [ARCTLIB01Width, setARCTLIB01Width] = useState(250);
  const firstColumns = [
    { name: "orderedQuantity", title: "Quantité", type: "string" },
    { name: "netTotalPrice", title: "Montant total net HT", type: "string" },
    { name: "netUnitPrice", title: "Prix unitaire net HT", type: "string" },
    { name: "ARKTCODART", title: "Référence", type: "string" },
    { name: "ARCTLIB01", title: "Modèle", type: "string" },
  ];
  const firstColumnExtensions = [
    {
      columnName: "orderedQuantity",
      width: orderedQuantityColWidth,
      wordWrapEnabled: true,
    },
    {
      columnName: "netTotalPrice",
      width: netTotalPriceWidth,
      wordWrapEnabled: true,
    },
    {
      columnName: "netUnitPrice",
      width: netUnitPriceWidth,
      wordWrapEnabled: true,
    },
    { columnName: "ARKTCODART", width: ARKTCODARTColWidth, align: "left" },
    { columnName: "ARCTLIB01", width: ARCTLIB01Width },
  ];
  let columns = [];
  let columnExtensions = [];
  let columnBands = [];
  let products = [];
  const fixedLeftColumns = [
    "orderedQuantity",
    "netTotalPrice",
    "netUnitPrice",
    "ARKTCODART",
    "ARCTLIB01",
  ];

  // setting remaining space to ARCTLIB01 column
  const dataGridViewContainer = useRef();
  useLayoutEffect(() => {
    if (dataGridViewContainer.current) {
      const dataGridViewContainerWidth =
        dataGridViewContainer.current.clientWidth;
      const spaceLeftForARCTLIB01ColWidth =
        dataGridViewContainerWidth -
        ARKTCODARTColWidth -
        orderedQuantityColWidth -
        netTotalPriceWidth -
        netUnitPriceWidth -
        shippingColsWidth * shippings.length;
      if (spaceLeftForARCTLIB01ColWidth > ARCTLIB01Width) {
        setARCTLIB01Width(spaceLeftForARCTLIB01ColWidth);
      }
    }
  }, [ARCTLIB01Width, shippings.length]);

  const generateHeaders = () => {
    const extraColumns = [];
    const extraColumnExtensions = [];

    // setting shippings headers
    shippings.forEach((shipping, index) => {
      const availabilityColName = "S_" + index + "_availability";
      const quantityColName = "S_" + index + "_quantity";
      const amountColName = "S_" + index + "_amount";

      // columns & columnExtensions
      if (activeStep === 1) {
        extraColumns.push({
          name: availabilityColName,
          title: "Dispo",
          type: "string",
        });
        extraColumnExtensions.push({
          columnName: availabilityColName,
          width: availabilityColWidth,
        });
      }
      extraColumns.push({
        name: quantityColName,
        title: "Quantité",
        type: "string",
      });
      extraColumnExtensions.push({
        columnName: quantityColName,
        width: quantityColWidth,
      });

      extraColumns.push({
        name: amountColName,
        title: "Montant HT",
        type: "string",
      });
      extraColumnExtensions.push({
        columnName: amountColName,
        width: amountColWidth,
      });

      const children = [];
      if (activeStep === 1) {
        children.push({ columnName: "S_" + index + "_availability" });
      }
      children.push({ columnName: "S_" + index + "_quantity" });
      children.push({ columnName: "S_" + index + "_amount" });

      // columnBands
      columnBands.push({
        title:
          parseInt(index + 1) +
          ": " +
          shipping.client.name +
          " (" +
          moment(shipping.deliveryDate).format("DD/MM/YYYY") +
          ")",
        children,
      });
    });
    columns = firstColumns.concat(extraColumns);
    columnExtensions = firstColumnExtensions.concat(extraColumnExtensions);
    //setColumns( firstColumns.concat(extraColumns) );
    //setColumnExtensions( firstColumnExtensions.concat(extraColumnExtensions) );
    //setColumnBands(columnBands);
  };

  const generateCells = () => {
    /********* internal function *********/

    const getAvailableSupply = (
      article,
      productHasArticle,
      suppliesPerDate,
      productWebSecurityStock
    ) => {
      const articlesMinStock = getArticlesMinStock(suppliesPerDate);
      if (article === "total") {
        let quantityToReturn = 0;
        suppliesPerDate.forEach((articleSupply) => {
          const minStock = articlesMinStock[articleSupply.ARKTCOMART] || 0;
          if (productHasArticle) {
            const webSecurityStock = articleSupply.webSecurityStock || 0;
            quantityToReturn +=
              minStock - webSecurityStock > 0 ? minStock - webSecurityStock : 0;
          } else {
            const webSecurityStock = productWebSecurityStock || 0;
            quantityToReturn +=
              minStock - webSecurityStock > 0 ? minStock - webSecurityStock : 0;
          }
        });
        return quantityToReturn;
      } else {
        const minStock = articlesMinStock[article.ARKTCOMART] || 0;
        const webSecurityStock = productWebSecurityStock || 0;
        return minStock - webSecurityStock > 0
          ? minStock - webSecurityStock
          : 0;
      }
    };

    const getAppliedDiscountsText = (product, shippingIndex) => {
      const shippingId = shippings[shippingIndex].id;
      //console.log('- product: ', product);
      let stringReturned = [];
      if (
        product.appliedDiscountsDetails !== undefined &&
        product.appliedDiscountsDetails[shippingId] !== undefined
      ) {
        stringReturned.push(
          <div key={product.ARKTCODART + "_" + shippingId}>
            remise(s) appliquée(s) :
          </div>
        );
        Object.entries(
          product.appliedDiscountsDetails[shippingId].discounts
        ).forEach(([shippingRef, discounts]) => {
          if (parseInt(shippingRef) === shippingId) {
            //console.log("discounts: ", discounts);
            const discountNames = {
              clientDiscount: "client",
              preSeasonDiscount: "de présaison",
              noConditionDiscount: "sans condition",
              productQuantityDiscount: "quantitative produit",
              minAmountDiscount: "avec minimum d'achat",
              productQuantityAndMinAmountDiscount:
                "quantitative avec minimum d'achat",
            };
            Object.entries(discounts).forEach(
              ([discountType, discountDetails]) => {
                const key =
                  product.ARKTCODART + "_" + shippingId + "_" + discountType;
                if (discountType === "clientDiscount") {
                  const clientConcerned =
                    discountDetails.origin === "group" ? " (groupe)" : ""; // only used for clientDiscount
                  if (discounts.hasOwnProperty("preSeasonDiscount")) {
                    // if there's a preSeasonDiscount we have to add percentage to clientDiscount (e.g. 15+10=25)
                    stringReturned.push(
                      <div key={key}>
                        - remise client{clientConcerned} + présaison:{" "}
                        {discountDetails.percentageDiscount +
                          discounts.preSeasonDiscount.percentageDiscount}{" "}
                        %
                      </div>
                    );
                  } else {
                    stringReturned.push(
                      <div key={key}>
                        - remise {discountNames[discountType]}
                        {clientConcerned}: {discountDetails.percentageDiscount}{" "}
                        %
                      </div>
                    );
                  }
                  // all other discounts but preSeason because it'salready treated above
                } else if (discountType !== "preSeasonDiscount") {
                  stringReturned.push(
                    <div key={key}>
                      - remise {discountNames[discountType]}:{" "}
                      {discountDetails.percentageDiscount} %
                    </div>
                  );
                }
              }
            );
          }
        });
      }

      return <>{stringReturned}</>;
    };

    const getOrderData = (dataName, product, ARKTCOMART, shippingIndex) => {
      // in this function, article.shippings[shippingIndex] !== undefined is there because when you add a shipping rendering happens before new shipping was added to articles. It is rendered again when this is done.
      let orderData = 0;
      if (ARKTCOMART === "total") {
        if (shippingIndex === "all") {
          orderData = product.quantities[dataName]; // total product quantity
        } else {
          product.quantities.articles.forEach((article) => {
            if (article.shippings[shippingIndex] !== undefined) {
              orderData += article.shippings[shippingIndex][dataName]; // shipping's product quantity
            }
          });
        }
      } else {
        product.quantities.articles.forEach((article) => {
          if (
            article.ARKTCOMART === ARKTCOMART &&
            article.shippings[shippingIndex] !== undefined
          ) {
            orderData = article.shippings[shippingIndex][dataName]; // article quantity
          }
        });
      }
      return orderData;
    };

    /******* END internal function *******/

    // setting cells contents
    // on activeStep 2 (order confirmation) we only display products with quantities
    const productsToDisplay = [];
    productsOrder.forEach((product) => {
      if (!(activeStep === 2 && product.quantities.quantity === 0)) {
        productsToDisplay.push(product);
      }
    });

    products = productsToDisplay.map((product) => {
      const row = {};
      const { suppliesPerDate, quantities } = product;
      const articles = quantities.articles;
      const productHasArticle =
        (articles.length === 1 && articles[0].ARKTCOMART !== "") ||
        (articles.length > 0 &&
          (articles[0].ARKTCOMART !== "" ||
            (articles[1] !== undefined && articles[1].ARKTCOMART !== "")));
      let articlesLength = 0;
      // removing lines with quantity to 0
      if (activeStep === 2 && productHasArticle) {
        articles.forEach((article) => {
          article.quantity && articlesLength++;
        });
      } else {
        articlesLength = productHasArticle ? articles.length : 0;
      }
      const cellHeight =
        articlesLength > 0 ? (articlesLength + 1) * lineHeight : lineHeight; // +1 because of the main line
      const styleForSingleLine = {
        display: "flex",
        flexDirection: "column",
        height: cellHeight + "px",
        lineHeight: lineHeight + "px",
      };
      const styleForSingleLineRightAligned = { ...styleForSingleLine };
      styleForSingleLineRightAligned.alignItems = "flex-end";
      const totalQuantity = getOrderData("quantity", product, "total", "all");
      const discountedTotalPrice = getOrderData(
        "amount",
        product,
        "total",
        "all"
      );

      // ordered quantity
      row.orderedQuantity = (
        <div style={styleForSingleLineRightAligned}>{totalQuantity}</div>
      );

      // netTotalPrice
      row.netTotalPrice = (
        <div style={styleForSingleLineRightAligned}>
          {new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
          }).format(discountedTotalPrice)}
        </div>
      );

      // netUnitPrice
      const netUnitPrice =
        totalQuantity > 0 ? discountedTotalPrice / totalQuantity : 0;
      row.netUnitPrice = (
        <div style={styleForSingleLineRightAligned}>
          {new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
          }).format(netUnitPrice)}
        </div>
      );

      // ARKTCODART
      row.ARKTCODART = (
        <div style={styleForSingleLine}>{product.ARKTCODART}</div>
      );

      // model
      row.ARCTLIB01 = [];
      const hasImage = product.imageUrl !== undefined;
      if (!productHasArticle) {
        row.ARCTLIB01 = (
          <div className={classes.flexDirectionRow}>
            <ProductIcons
              key={product.ARKTCODART}
              productId={product.ARKTCODART}
              showCard
              showChart={userRole !== "client" && userRole !== "commercial"}
              chartWithNegativeValues={userRole !== "client"}
              size="small"
            />
            {hasImage && (
              <img
                className={classes.image}
                src={product.imageUrl}
                width="28"
                height="28"
                alt=""
              />
            )}
            <div style={styleForSingleLine}>
              <div className={classes.textWithSecondLine}>
                {product.ARCTLIB01},{" "}
                {generateAvailabilityText(productHasArticle, product)}
              </div>
              <Typography variant="caption" className={classes.secondLine}>
                prix catalogue:{" "}
                {new Intl.NumberFormat("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                }).format(product.productObj.ARCNTARIF1)}
                , prix public conseillé TTC:{" "}
                {new Intl.NumberFormat("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                }).format(product.productObj.ARCNTARIF9)}
              </Typography>
            </div>
          </div>
        );

        // if product has articles
      } else {
        row.ARCTLIB01.push(
          <div className={classes.flexDirectionRow} key={-1}>
            <ProductIcons
              key={product.ARKTCODART}
              productId={product.ARKTCODART}
              showCard
              showChart={userRole !== "client" && userRole !== "commercial"}
              chartWithNegativeValues={userRole !== "client"}
              size="small"
            />
            {hasImage && (
              <img
                className={classes.image}
                src={product.imageUrl}
                width="28"
                height="28"
                alt=""
              />
            )}
            <div className={classes.cellLine}>
              <div className={classes.textWithSecondLine}>
                {product.ARCTLIB01}
              </div>
              <Typography variant="caption" className={classes.secondLine}>
                pc = prix catalogue, pp = prix public conseillé TTC
              </Typography>
            </div>
          </div>
        );

        // article lines
        articles.forEach((article, index) => {
          if (!(activeStep === 2 && article.quantity === 0)) {
            const currentARKTCOMART = article.ARKTCOMART;
            const currentARCTLIB02 = getArticleData(
              article,
              product,
              "ARCTLIB02"
            );
            const articleText =
              currentARKTCOMART === currentARCTLIB02 ||
              currentARCTLIB02 === undefined
                ? currentARKTCOMART
                : currentARCTLIB02 + " (" + currentARKTCOMART + ")";
            // getting pc (prix catalogue - ARCNTARIF1) & pp (prix puclic conseillé - ARCNTARIF9) for display:
            const ARCNTARIF1 = getArticleData(article, product, "ARCNTARIF1");
            const ARCNTARIF9 = getArticleData(article, product, "ARCNTARIF9");
            // console.log('--------------------');
            // console.log('product: ', product);
            // console.log('article: ', article);

            row.ARCTLIB01.push(
              <div
                className={classNames(classes.cellLine, classes.alignRight)}
                key={index}
              >
                <div className={classes.textWithSecondLineForArticles}>
                  {currentARKTCOMART === "" ? "générique" : articleText}{" "}
                  {generateAvailabilityText(
                    productHasArticle,
                    product,
                    currentARKTCOMART
                  )}
                </div>
                <Typography
                  variant="caption"
                  className={classes.secondLineForArticles}
                >
                  pc*:{" "}
                  {new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }).format(ARCNTARIF1)}
                  , pp*:{" "}
                  {new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }).format(ARCNTARIF9)}
                </Typography>
              </div>
            );
          }
        });
      }

      // adding margin
      if (productHasArticle) {
        row.ARCTLIB01 = (
          <div className={classes.marginForCellWithArticle}>
            {row.ARCTLIB01}
          </div>
        );
      }

      // ----------------- shippings ----------------- //

      // initializing...
      shippings.forEach((shipping, shippingIndex) => {
        const availabilityColName = "S_" + shippingIndex + "_availability";
        const quantityColName = "S_" + shippingIndex + "_quantity";
        const amountColName = "S_" + shippingIndex + "_amount";
        row[availabilityColName] = [];
        row[quantityColName] = [];
        row[amountColName] = [];
      });

      // ... and filling
      shippings.forEach((shipping, shippingIndex) => {
        const availabilityColName = "S_" + shippingIndex + "_availability";
        const quantityColName = "S_" + shippingIndex + "_quantity";
        const amountColName = "S_" + shippingIndex + "_amount";
        const countCellHOCName = "S_" + shippingIndex + "_countCell";

        // main line
        const availableSupplyForShipping = getAvailableSupply(
          "total",
          productHasArticle,
          filterSuppliesDataFromDate(
            suppliesPerDate,
            moment(shipping.deliveryDate).subtract(3, "days").toDate()
          ),
          product.webSecurityStock
        );
        row[availabilityColName].push(
          <div
            className={classNames(classes.cellLine, classes.alignRight)}
            key={availabilityColName}
          >
            <Typography variant="body2">
              {!productHasArticle ? availableSupplyForShipping : " "}
            </Typography>
          </div>
        );

        const orderedQuantity = getOrderData(
          "quantity",
          product,
          "total",
          shippingIndex
        );

        row[quantityColName].push(
          <div
            className={classNames(classes.cellLine, classes.alignCenter)}
            key={availabilityColName}
          >
            {(productHasArticle || activeStep === 2) && (
              <Typography variant="body2">{orderedQuantity}</Typography>
            )}
            {!productHasArticle && activeStep === 1 && (
              <CountCell
                key={countCellHOCName + "_ _" + orderedQuantity}
                product={product}
                ARKTCOMART={""}
                shippingIndex={shippingIndex}
                orderedQuantity={orderedQuantity}
                availableQuantity={availableSupplyForShipping}
                supplyPerDate={
                  suppliesPerDate.filter(
                    (supplyPerDate) => supplyPerDate.ARKTCOMART === ""
                  )[0].supplies
                }
              />
            )}
          </div>
        );
        // displaying tooltip with calculation detail
        const shippingProductAmount = getOrderData(
          "amount",
          product,
          "total",
          shippingIndex
        );
        let rawShippingProductAmount = 0;

        //----------- getting rawShippingProductAmount -----------//
        product.quantities.articles.forEach((article) => {
          const ARCNTARIF1 = getArticleData(article, product, "ARCNTARIF1");
          let quantityOrdered = 0;
          // working with correct shipping to get quantityOrdered
          article.shippings.forEach((shipping) => {
            if (shipping.id === shippings[shippingIndex].id) {
              quantityOrdered = shipping.quantity;
            }
          });
          rawShippingProductAmount += quantityOrdered * ARCNTARIF1;
        });

        const shippingProductDiscount =
          (rawShippingProductAmount - shippingProductAmount) /
          rawShippingProductAmount;
        const shippingProductDiscountDisplayed = !!shippingProductAmount
          ? " (" +
            new Intl.NumberFormat("fr-FR", {
              style: "percent",
              maximumFractionDigits: 1,
            }).format(shippingProductDiscount) +
            ")"
          : "";
        row[amountColName].push(
          <div
            className={classNames(classes.cellLine, classes.alignRight)}
            key={amountColName}
          >
            <Tooltip title={getAppliedDiscountsText(product, shippingIndex)}>
              <Typography variant="body2">
                {new Intl.NumberFormat("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                }).format(shippingProductAmount)}
                {shippingProductDiscountDisplayed}
              </Typography>
            </Tooltip>
          </div>
        );

        // article lines
        if (productHasArticle) {
          quantities.articles.forEach((article) => {
            const ARKTCOMART = article.ARKTCOMART;
            const availableQuantity = getAvailableSupply(
              article,
              false,
              filterSuppliesDataFromDate(
                suppliesPerDate,
                moment(shipping.deliveryDate).subtract(3, "days").toDate()
              )
            );
            const articleOrderedQuantity = getOrderData(
              "quantity",
              product,
              ARKTCOMART,
              shippingIndex
            );

            if (activeStep === 1) {
              row[availabilityColName].push(
                <div
                  className={classNames(classes.cellLine, classes.alignRight)}
                  key={availabilityColName + "_" + ARKTCOMART}
                >
                  <Typography variant="body2">{availableQuantity}</Typography>
                </div>
              );
              row[quantityColName].push(
                <CountCell
                  key={
                    countCellHOCName +
                    "_" +
                    ARKTCOMART +
                    "_" +
                    articleOrderedQuantity
                  }
                  product={product}
                  ARKTCOMART={ARKTCOMART}
                  shippingIndex={shippingIndex}
                  orderedQuantity={articleOrderedQuantity}
                  availableQuantity={availableQuantity}
                  supplyPerDate={
                    suppliesPerDate.filter(
                      (supplyPerDate) => supplyPerDate.ARKTCOMART === ARKTCOMART
                    )[0].supplies
                  }
                />
              );
              row[amountColName].push(
                <div
                  className={classNames(classes.cellLine, classes.alignRight)}
                  key={amountColName + "_" + ARKTCOMART}
                />
              );
            } else {
              // displaying only articles with quantity ordered
              if (article.quantity > 0) {
                row[quantityColName].push(
                  <Typography
                    variant="body2"
                    key={
                      countCellHOCName +
                      "_" +
                      ARKTCOMART +
                      "_" +
                      articleOrderedQuantity
                    }
                    className={classNames(
                      classes.cellLine,
                      classes.alignCenter
                    )}
                  >
                    {articleOrderedQuantity}
                  </Typography>
                );
                row[amountColName].push(
                  <div
                    className={classNames(classes.cellLine, classes.alignRight)}
                    key={amountColName + "_" + ARKTCOMART}
                  >
                    <Typography variant="body2">
                      {new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      }).format(
                        getOrderData(
                          "amount",
                          product,
                          ARKTCOMART,
                          shippingIndex
                        )
                      )}
                    </Typography>
                  </div>
                );
              }
            }
          });
        }
      });
      return row;
    });
  };

  /****** END variables and functions declaration ******/

  generateHeaders();
  generateCells();

  return (
    <div
      ref={dataGridViewContainer}
      className={classes.dataGridViewContainer}
      style={{ height: activeStep === 1 ? height + 64 : height - 114 }}
    >
      {" "}
      {/* ref={(dataGridViewContainer) => this.dataGridViewContainer = dataGridViewContainer}*/}{" "}
      {/* for height calculation */}
      {false && (
        <div>
          <pre>{JSON.stringify(this.state, null, 2)}</pre>
        </div>
      )}
      <DataGridView
        columnHeaders={columns}
        rowsValues={products}
        isVirtualized={true}
        height={height}
        withPagination={false}
        withFilter={false}
        columnExtensions={columnExtensions}
        withFixedColumns={true}
        withSorting={false}
        fixedLeftColumns={fixedLeftColumns}
        columnBands={columnBands}
        hasActions={false}
      />
    </div>
  );
};

export default SelectedProductsList;
