import React, { useState, useEffect, createRef } from "react";
import useDebounce from "../../../hooks";
import Page from "../Page";
import Parse from "parse";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { getLists } from "../../../reducers/lists";
import { getCurrentUser } from "../../../reducers/currentUser";
import {
  makeStyles,
  useTheme,
  Paper,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Tooltip,
  Toolbar,
  IconButton,
  AppBar,
  Button,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import PageviewIcon from "@material-ui/icons/Pageview";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import EmailIcon from "@material-ui/icons/Email";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ControlPointDuplicateIcon from "@material-ui/icons/ControlPointDuplicate";
import GetApp from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";
import DataGridView from "../../../components/reactgrid/DataGridView";
import OrderDetail from "../../../components/order/OrderDetail";
import OrderEdit from "../../../components/order/OrderEdit";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import {
  setNumberColumns,
  translateColumn,
  filtersQuery,
  createForcedDiscountData,
  deleteOrderFromOnUnshippedOrders,
  isClientBlocked,
  getClientDiscounts,
  buildContactsOptions,
} from "../../../utils2";
import {
  setClient,
  setProductsToPreselect,
  setObjectOfContactOptions,
} from "../../../actions/main";
import ClockedOrderDialog from "../../../components/order/ClockedOrderDialog";
import DialogForForcedDiscounts from "../../../components/order/DialogForForcedDiscounts";
import { Form, FormSpy } from "react-final-form";
import AutoSave from "../../../components/form/AutoSave";
import AutoComplete from "../../../components/form/AutoComplete";
import OrderImport from "./../../../components/order/Import";
import PdfOrder from "./../../../components/order/PdfOrder";

const useStyles = makeStyles(() => ({
  tableTitleWrapper: {
    display: "flex",
    alignItems: "center",
    minHeight: "64px",
    padding: "0 8px 0 24px",
  },
  tableContainer: {
    position: "relative",
    "& col:nth-child(5)": {
      // Magasin livré
      width: "25% !important",
    },
  },
  toolTip: {
    maxWidth: "230px",
  },
  circularProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  toolbar: {
    backgroundColor: "#46494c", //'#02cfa3'
  },
  grid: {
    display: "flex",
  },
  textContainer: {
    lineHeight: "64px",
  },
  fieldContainer: {
    marginLeft: "2rem",
    width: 400,
  },
  button: {
    marginLeft: "1rem",
  },
}));

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const pageSizes = [15, 25, 50, 100, 150];

const History = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const currentUser = useSelector(getCurrentUser);
  const userRole =
    currentUser !== undefined ? currentUser.get("role") : undefined;
  let columns = [
    { name: "status", title: "Statut" },
    { name: "ECCJCRE", title: "Date de commande", type: "date" },
    { name: "ECKTNUMERO", title: "N° com" },
    {
      name: "earliestDeliveryDate",
      title: "Date de livraison prévue",
      type: "date",
    },
    { name: "ECCTNOMLIV", title: "Magasin livré" },
    { name: "ECCTCODE", title: "Code Magasin" },
    { name: "ECCTDOMACT", title: "Type de commande" },
    { name: "rep1", title: "Représentant" },
    { name: "rawAmount", title: "Montant HT (€)", type: "numeric" },
    { name: "discount", title: "Remise (€)", type: "numeric" },
    { name: "amount", title: "Montant remisé HT (€)", type: "numeric" },
    { name: "ECCTREFCDE", title: "Réf. client" },
  ];
  let columnsForProductOrders = [...columns];
  // adding column
  columnsForProductOrders.splice(8, 0, {
    name: "productsOrdered",
    title: "Nbre Prod. Com.",
    type: "numeric",
  });
  if (userRole === "commercial") {
    columns.splice(7, 1);
    columnsForProductOrders.splice(7, 1);
  } else if (userRole === "client") {
    columnsForProductOrders = [...columns];
  }
  const columnExtensions = [
    { columnName: "status", width: 85 },
    { columnName: "ECCJCRE", width: 95, align: "right" },
    { columnName: "ECCTREFCDE", width: 150, align: "right" },
    { columnName: "ECKTNUMERO", width: 70, align: "right" },
    { columnName: "earliestDeliveryDate", width: 95, align: "right" },
    { columnName: "ECCTNOMLIV", width: 200, wordWrapEnabled: true }, // width is not taken into account, weird...
    { columnName: "ECCTCODE", width: 95 },
    { columnName: "ECCTDOMACT", width: 95 },
    {
      columnName: "productsOrdered",
      width: 95,
      align: "right",
      filteringEnabled: false,
    },
    { columnName: "rep1", width: 140 },
    { columnName: "rawAmount", width: 85, align: "right" },
    { columnName: "discount", width: 85, align: "right" },
    { columnName: "amount", width: 85, align: "right" },
    { columnName: "ECCTREFCDE", width: 140 },
  ];
  const listsOptions = useSelector(getLists);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarText, setSnackBarText] = useState("");
  const [alertSnackBarOpen, setAlertSnackBarOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [clockedOrderDialogOpen, setClockedOrderDialogOpen] = useState(false);
  const [questionForValidationDialogOpen, setQuestionForValidationDialogOpen] =
    useState(false);
  const [
    questionForValidationRowIndexValidationButtonToReenable,
    setQuestionForValidationRowIndexValidationButtonToReenable,
  ] = useState(0);
  const [
    questionForValidationValidateButtonDisabled,
    setQuestionForValidationValidateButtonDisabled,
  ] = useState(false);
  const [
    originalClientDiscountForValidationDialog,
    setOriginalClientDiscountForValidationDialog,
  ] = useState(false);
  const [
    appliedClientDiscountForValidationDialog,
    setAppliedClientDiscountForValidationDialog,
  ] = useState(false);
  const [
    questionForValidationDialogAction,
    setQuestionForValidationDialogAction,
  ] = useState(false);
  const [
    questionForDiscountValidationDialogOpen,
    setQuestionForDiscountValidationDialogOpen,
  ] = useState(false);
  const [disableSendConfirmationButton, setDisableSendConfirmationButton] =
    useState(false);
  const [pdfDialogOpen, setPdfDialogOpen] = useState(false);
  const [pdfOrderNumber, setPdfOrderNumber] = useState(false);
  const [dialogOperation, setDialogOperation] = useState("");
  const [columnsHeaders, setColumnsHeaders] = useState(columns);

  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogYesBtnText, setDialogYesBtnText] = useState("");
  const [dialogNoBtnText, setDialogNoBtnText] = useState("");
  const [dialogVoidBtnText, setDialogVoidBtnText] = useState(null);
  const [orderNumberForDialog, setOrderNumberForDialog] = useState("");
  const [columnsTranslations, setColumnsTranslations] = useState([
    {
      columnName: "status",
      values: [
        ["confirmed", "confirmée"],
        ["validated", "validée"],
        ["closed", "expédiée"],
        ["imported", "importée"],
      ],
    },
  ]);
  const [rows, setRows] = useState(null);
  const [filters, setFilters] = useState([]);
  const debouncedFilters = useDebounce(filters, 300);
  const [sorting, setSorting] = useState([
    { columnName: "ECCJCRE", direction: "desc" },
    { columnName: "ECKTNUMERO", direction: "desc" },
  ]);
  const [totalCount, setTotalCount] = useState(100);
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [productsOptions, setProductsOptions] = useState([]);
  const [numberColumns, setStateNumberColumns] = useState([]);
  const [forcedDiscountData, setForcedDiscountData] = useState(undefined);
  const [productIdForOrders, setProductIdForOrders] = useState("ALL");
  const [incrementalForOrderChanged, setIncrementalForOrderChanged] =
    useState(0);

  let excelFileName = "test";
  let _export = createRef();
  const dispatch = useDispatch();

  /********************************************************/
  /********************** Product form ********************/
  /********************************************************/
  const buildProductOptions = () => {
    const productsOptions = listsOptions.find(
      (listOptions) => listOptions.listName === "products",
    );
    const options = productsOptions.options;
    if (options[0].value !== "ALL") {
      options.unshift({ value: "ALL", text: "Tous les produits" });
    }
    setProductsOptions(options);
  };

  const updateProductIdForOrders = (values) => {
    setLoading(true);
    if (values.product === null) {
      setProductIdForOrders("ALL");
    } else if (values.product.value !== undefined) {
      setProductIdForOrders(values.product.value);
    } else if (values.product !== undefined) {
      setProductIdForOrders(values.product);
    }
  };

  /********************************************************/
  /************ content info for paging panel**************/
  /********************************************************/
  const contentInfo = ({ from, to, count }) => {
    return `${from} ${from < to ? ` à ${to}` : ""} sur ${count} commande(s)`;
  };

  /********************************************************/
  /*********************** Table data *********************/
  /********************************************************/
  const changeFilters = (filters) => {
    setLoading(true);
    setFilters(filters);
  };
  const changeSorting = (sorting) => {
    setLoading(true);
    setSorting(sorting);
  };
  const changeCurrentPage = (currentPage) => {
    setLoading(true);
    setCurrentPage(currentPage);
  };
  const changePageSize = (pageSize) => setPageSize(pageSize);

  // getting data from mongo
  const loadData = async () => {
    if (columnsTranslations.length < 2) {
      return null;
    }
    // filtering by productId
    const arrayOfOrdersId = [];
    if (productIdForOrders !== "ALL") {
      const product = await new Parse.Query("Products")
        .equalTo("ARKTCODART", productIdForOrders)
        .first();
      // console.log('product: ', product);
      Object.entries(product.get("supplies")).forEach(([articleRef, data]) => {
        data.onUnshippedOrders.orders.forEach((order) => {
          if (!arrayOfOrdersId.includes(order.orderNumber)) {
            arrayOfOrdersId.push(order.orderNumber);
          }
        });
      });
      // console.log('arrayOfOrdersId: ', arrayOfOrdersId);
    }

    const query = new Parse.Query("Orders")
      .select(
        "ECKTNUMERO",
        "ECCTREFCDE",
        "ECCJCRE",
        "ECCTNOMLIV",
        "ECCTCODE",
        "ECCTUTIMOD",
        "ECCTDOMACT",
        "rawAmount",
        "amount",
        "discount",
        "status",
        "lines",
        "rep1",
        "clientPermanentDiscountApplied",
        "clientPermanentDiscountNeedsValidation",
        "earliestDeliveryDate",
        "contact",
        "orderConfirmationSent",
        "manuallyEditedByClient",
      )
      .equalTo("ECCTTYPE2", "S");

    if (productIdForOrders !== "ALL") {
      query.containedIn("ECKTNUMERO", arrayOfOrdersId);
    }
    if (userRole === "commercial") {
      query.equalTo("rep1", currentUser.get("commercial"));
    }
    if (userRole === "client") {
      query.equalTo("ECCTCODE", currentUser.get("client"));
    }
    if (userRole === "group") {
      const listOptions = listsOptions.find(
        (listOptions) =>
          listOptions.listName === "z_gp_" + currentUser.get("group"),
      ); // getting group's clientsCode
      const groupClientsCode = listOptions.options.map((listOption) =>
        listOption.value.toString(),
      ); // creating array of clients code
      query.containedIn("ECCTCODE", groupClientsCode);
    }
    filtersQuery(query, columnsTranslations, { columns, filters, sorting });

    const totalCount = await query.count();

    // skipping according to currentPage and pageSize
    query.skip(pageSize * currentPage).limit(pageSize);

    const orders = await query.find();
    const rows = [];

    // formatting data
    orders.forEach((product) => {
      const data = product.attributes;
      const order = {};
      order.ECKTNUMERO = data.ECKTNUMERO;
      order.ECCTREFCDE = data.ECCTREFCDE;
      order.ECCJCRE = moment(data.ECCJCRE).format("DD/MM/YYYY");
      order.earliestDeliveryDate = moment(data.earliestDeliveryDate).format(
        "DD/MM/YYYY",
      ); // (data.lines[0] !== undefined) ? moment(data.lines[0].LCCJDELARR).format('DD/MM/YYYY') : '?????';
      order.ECCTNOMLIV = data.ECCTNOMLIV;
      order.rawAmount = data.rawAmount;
      order.amount = data.amount;
      order.discount = data.discount;
      order.ECCTUTIMOD = data.ECCTUTIMOD;
      order.ECCTCODE = data.ECCTCODE;
      order.clientPermanentDiscountNeedsValidation =
        data.clientPermanentDiscountNeedsValidation !== undefined
          ? data.clientPermanentDiscountNeedsValidation
          : false;
      order.clientPermanentDiscountApplied =
        data.clientPermanentDiscountApplied;
      order.ECCTDOMACT = translateColumn(
        data.ECCTDOMACT,
        columnsTranslations.find(
          (columnTranslation) => columnTranslation.columnName === "ECCTDOMACT",
        ),
      );
      order.rep1 =
        data.rep1 !== "0"
          ? translateColumn(
              data.rep1,
              columnsTranslations.find(
                (columnTranslation) => columnTranslation.columnName === "rep1",
              ),
            )
          : "";
      order.status = translateColumn(
        data.status,
        columnsTranslations.find(
          (columnTranslation) => columnTranslation.columnName === "status",
        ),
      );
      order.contact = data.contact;
      order.orderConfirmationSent = data.orderConfirmationSent;
      order.manuallyEditedByClient = data.manuallyEditedByClient;
      order.validationButtonDisabledForValidationInProgress = false;
      if (productIdForOrders !== "ALL") {
        const reducer = (accumulator, currentValue) =>
          accumulator + currentValue;
        const linesConcerned = data.lines.filter(
          (line) => productIdForOrders === line.LCCTCODART,
        );
        order.productsOrdered = linesConcerned
          .map((linesConcerned) => linesConcerned.LCCNQTECDE)
          .reduce(reducer);
      }
      rows.push(order);
    });
    setColumnsHeaders(
      productIdForOrders === "ALL" ? columns : columnsForProductOrders,
    );
    setRows(rows);
    setTotalCount(totalCount);
    setLoading(false);
  };

  const listToColumnTranslations = (arrayOfListNameAndColumnName) => {
    const newColumnTranslations = [];
    arrayOfListNameAndColumnName.forEach((listNameAndColumnName) => {
      let listOfOptions = [];
      // getting list
      listsOptions.forEach((list) => {
        if (list.listName === listNameAndColumnName.listName) {
          listOfOptions = list.options;
        }
      });
      newColumnTranslations.push({
        columnName: listNameAndColumnName.columnName,
        values: listOfOptions.map((option) => [option.value, option.text]),
      });
    });
    setColumnsTranslations([...columnsTranslations, ...newColumnTranslations]);
  };

  const validateOrder = async (orderNumber) => {
    setQuestionForValidationValidateButtonDisabled(true);
    const query = await new Parse.Query("Orders")
      .equalTo("ECKTNUMERO", orderNumber)
      .first();
    const clientIsBlocked = await isClientBlocked(query.get("ECCTCODE"));
    if (!clientIsBlocked) {
      const validator =
        currentUser.get("firstname") + " " + currentUser.get("lastname");
      query.set("status", "validated");
      query.set("ECCTUTIMOD", validator);

      try {
        await query.save();
        let ordersSaved = { ...query.attributes };
        delete ordersSaved.createdAt;
        delete ordersSaved.updatedAt;
        delete ordersSaved.orderRef;
        delete ordersSaved.rep1;
        delete ordersSaved.orderAuthor;
        delete ordersSaved.contact;

        await Parse.Cloud.run("saveOrderToPmi", { ordersSaved: [ordersSaved] });
        setIncrementalForOrderChanged(incrementalForOrderChanged + 1); // triggers loadData and refresh page
        await handleQuestionForValidationDialogClose("validated");
        setQuestionForValidationValidateButtonDisabled(false);
      } catch (error) {
        console.log(error.message);
      }

      // this will initiate a check to see if there's a forced clientDiscount and then ask if we should apply
      const forcedDiscountData = await createForcedDiscountData(orderNumber);
      setForcedDiscountData(forcedDiscountData);
    } else {
      // client is blocked
      setAlertSnackBarOpen(true);
      setQuestionForValidationDialogOpen(false);
    }
  };

  const deleteOrder = async (orderNumber) => {
    await deleteOrderFromOnUnshippedOrders(orderNumber);
    const query = await new Parse.Query("Orders")
      .equalTo("ECKTNUMERO", orderNumber)
      .first();
    try {
      await query.destroy();
      setIncrementalForOrderChanged(incrementalForOrderChanged + 1); // triggers loadData and refresh page
      handleQuestionForValidationDialogClose("deleted");
    } catch (error) {
      console.log(error.message);
    }
  };

  const duplicateOrder = async (orderNumber) => {
    const orderQuery = await new Parse.Query("Orders")
      .equalTo("ECKTNUMERO", orderNumber)
      .first();

    // setting client
    const dbObj = await new Parse.Query("Clients")
      .equalTo("CLKTCODE", orderQuery.get("ECCTCODE"))
      .first();

    const client = {
      dbObj: dbObj.attributes,
      type: "client",
    };

    await dispatch(setClient(client));
    await dispatch(
      setObjectOfContactOptions(await buildContactsOptions(client)),
    );
    // getting client discounts

    // setting productsToPreselect
    const productsToPreselect = [];
    orderQuery.get("lines").forEach((line) => {
      productsToPreselect.push(line.LCCTCODART);
    });
    await dispatch(setProductsToPreselect(productsToPreselect));

    callSetClockedOrderDialogOpen(true);
  };

  const acceptOrderClientDiscount = async (orderNumber) => {
    const orderQuery = await new Parse.Query("Orders")
      .equalTo("ECKTNUMERO", orderNumber)
      .first();
    orderQuery.set("clientPermanentDiscountNeedsValidation", false);
    try {
      await orderQuery.save();
      handleQuestionForDiscountValidationDialogClose("rateAccepted");
    } catch (error) {
      console.log(error.message);
      handleQuestionForDiscountValidationDialogClose("error");
    }
  };

  const refuseOrderClientDiscount = async (orderNumber) => {
    const orderQuery = await new Parse.Query("Orders")
      .equalTo("ECKTNUMERO", orderNumber)
      .first();
    orderQuery.set("clientPermanentDiscountNeedsValidation", false);
    try {
      await orderQuery.save();
      handleQuestionForDiscountValidationDialogClose("rateRefused");
    } catch (error) {
      console.log(error.message);
      handleQuestionForDiscountValidationDialogClose("error");
    }

    await handleClickOpen({ ECKTNUMERO: orderNumber }, "editOrder");
  };

  /********************************************************/
  /*********************** Dialogs ************************/
  /********************************************************/

  const callSetClockedOrderDialogOpen = (boolean) => {
    setClockedOrderDialogOpen(boolean);
    if (!boolean) {
      loadData(); // when we close it could mean we have created a new order. In that case we have to reload
    }
  };

  // when dialog is called
  const handleClickOpen = async (data, operation) => {
    const dialogYesBtnText = operation === "showOrder" ? "" : "modifier"; // as operation there are just 'showOrder' or 'editOrder'
    setDialogYesBtnText(dialogYesBtnText);
    setOrderNumberForDialog(data.ECKTNUMERO);
    setDialogTitle("Commande n° " + data.ECKTNUMERO);
    setDialogOpen(true);
    setDialogNoBtnText("fermer");
    setDialogVoidBtnText(null);
    setDialogOperation(operation);
    // setUserData(data);
  };

  // when closing dialog box
  const handleModalClose = async (action) => {
    if (action === "orderUpdated") {
      handleSnackBarShow("Commande mise à jour");
    }
    await loadData();
    setDialogOpen(false);
  };

  // when dialog for question for validation is called
  const handleQuestionForDiscountValidationDialogOpen = async (data) => {
    setDialogTitle(
      "Validation de la remise client pour la commande n° " + data.ECKTNUMERO,
    );
    const clientDiscounts = await getClientDiscounts(data.ECCTCODE);
    const clientPermanentDiscount = clientDiscounts.find(
      (clientDiscount) => clientDiscount.type === "clientPermanent",
    );
    setOriginalClientDiscountForValidationDialog(
      clientPermanentDiscount !== undefined
        ? clientPermanentDiscount.percentageDiscount
        : undefined,
    );
    setAppliedClientDiscountForValidationDialog(
      data.clientPermanentDiscountApplied,
    );
    setDialogYesBtnText("valider");
    setDialogNoBtnText("refuser");
    setDialogVoidBtnText("annuler");
    setOrderNumberForDialog(data.ECKTNUMERO);
    setQuestionForDiscountValidationDialogOpen(true);
  };
  // when closing question for validation dialog box
  const handleQuestionForDiscountValidationDialogClose = (action) => {
    setQuestionForDiscountValidationDialogOpen(false);
    if (action === "rateAccepted") {
      handleSnackBarShow("Taux de remise client accepté.");
    } else if (action === "rateRefused") {
      handleSnackBarShow("Taux de remise client refusé.");
    } else if (action === "error") {
      handleSnackBarShow("Erreur dans l'opération");
    }
  };

  // when dialog for question for validation is called
  const handleQuestionForValidationDialogOpen = async (data, operation) => {
    // console.log('data: ', data);
    // console.log('rows: ', rows);
    // disabling button
    const rowIndexToUpdate = rows.findIndex(
      (row) => row.ECKTNUMERO === data.ECKTNUMERO,
    );
    rows[rowIndexToUpdate].validationButtonDisabledForValidationInProgress =
      true;
    setRows(rows);
    setQuestionForValidationRowIndexValidationButtonToReenable(
      rowIndexToUpdate,
    );

    // as operation there are just 'validateOrder' or 'deleteOrder'
    const dialogTitle =
      operation === "validateOrder"
        ? "Validation de la commande n° " + data.ECKTNUMERO
        : "Suppression de la commande n° " + data.ECKTNUMERO;
    setDialogTitle(dialogTitle);
    const dialogYesBtnText =
      operation === "validateOrder" ? "valider" : "supprimer";
    setDialogYesBtnText(dialogYesBtnText);
    setOrderNumberForDialog(data.ECKTNUMERO);
    setQuestionForValidationDialogAction(operation);
    setDialogNoBtnText("annuler");
    setQuestionForValidationDialogOpen(true);
  };

  // when closing question for validation dialog box
  const handleQuestionForValidationDialogClose = (action) => {
    // reenabling validation button
    if (
      rows[questionForValidationRowIndexValidationButtonToReenable]
        .validationButtonDisabledForValidationInProgress
    ) {
      rows[
        questionForValidationRowIndexValidationButtonToReenable
      ].validationButtonDisabledForValidationInProgress = false;
      setRows(rows);
    }

    setQuestionForValidationDialogOpen(false);
    if (action === "validated") {
      handleSnackBarShow("commande validée");
    } else if (action === "deleted") {
      handleSnackBarShow("commande supprimée");
    }
  };

  const handleModalForForcedDiscountsClose = (action) => {
    if (action === "changeDiscount") {
      handleSnackBarShow("Remise(s) client mise(s) à jour");
    } else if (action === "saveError") {
      handleSnackBarShow("Erreur lors de la sauvegarde");
    }
    setForcedDiscountData(undefined);
  };

  const handlePdfDialogOpen = async (orderNumber) => {
    setPdfOrderNumber(orderNumber);
    setPdfDialogOpen(true);
  };

  /********************************************************/
  /************************ SnackBar **********************/
  /********************************************************/

  const handleSnackBarShow = (textValue) => {
    setSnackBarOpen(true);
    setSnackBarText(textValue);
  };

  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  //_export();

  const exportOrder = async (orderNumber) => {
    excelFileName = "commande_" + orderNumber;
    let linesToExport = [];
    const orderQuery = await new Parse.Query("Orders")
      .equalTo("ECKTNUMERO", orderNumber)
      .first();

    const lines = orderQuery.get("lines");

    if (lines !== undefined) {
      linesToExport = lines.map((line) => {
        return {
          LCCTCODART: line.LCCTCODART,
          LCCTLIB01: line.LCCTLIB01,
          LCCTCOMART: line.LCCTCOMART !== undefined ? line.LCCTCOMART : "",
          LCCJDELARR: moment(line.LCCJDELARR).format("DD/MM/YYYY"),
          LCCNQTECDE: line.LCCNQTECDE,
          discount:
            Math.round((1 - line.LCCNPUNET / line.LCCNPUBRUT) * 100) / 100,
          LCCNPUBRUT: line.LCCNPUBRUT,
          LCCNPUNET: line.LCCNPUNET,
          LCCNVALCDE: line.LCCNVALCDE,
          LCCNQTEEXP: line.LCCNQTEEXP,
        };
      });
      _export.current.save(linesToExport);
    } else {
      console.log("order has no lines !");
    }
  };

  const sendOrdersConfirmation = async () => {
    setDisableSendConfirmationButton(true);
    const ordersConfirmationSent = await Parse.Cloud.run(
      "sendOrdersConfirmationGroupedByContact",
      {},
    );
    await setDisableSendConfirmationButton(false);
    if (!!ordersConfirmationSent.emailsSent) {
      handleSnackBarShow(
        ordersConfirmationSent.emailsSent +
          " confirmations envoyées (concernant " +
          ordersConfirmationSent.ordersTreated +
          " commandes)",
      );
    } else {
      handleSnackBarShow(" il n'y a aucune confirmation à envoyer");
    }
  };

  const sendOrderConfirmation = async (row) => {
    const params = {
      orderNumber: row.ECKTNUMERO,
    };
    const orderConfirmationSent = await Parse.Cloud.run("sendOrderPdf", params);
    if (orderConfirmationSent) {
      handleSnackBarShow("confirmation envoyée");
    } else {
      handleSnackBarShow("erreur lors de l'envoi");
    }
  };

  const displayIcon = (action, row) => {
    if (row === undefined) {
      return false;
    } else {
      if (action === "deleteOrder") {
        return row.status === "confirmée" || row.status === "importée";
      } else if (action === "editOrder") {
        return row.status === "confirmée" || row.status === "importée"; // && userRole !== 'client'
      } else if (action === "validateOrder") {
        return (
          (userRole === "master" || userRole === "superuser") &&
          row.status === "confirmée" &&
          !row.validationButtonDisabledForValidationInProgress &&
          !row.clientPermanentDiscountNeedsValidation &&
          row.contact !== undefined
        );
      } else if (action === "validateOrderDisabledForContactMissing") {
        return (
          (userRole === "master" || userRole === "superuser") &&
          row.status === "confirmée" &&
          !row.clientPermanentDiscountNeedsValidation &&
          row.contact === undefined
        );
      } else if (action === "validateOrderDisabledForValidationInProgress") {
        return (
          (userRole === "master" || userRole === "superuser") &&
          row.status === "confirmée" &&
          row.validationButtonDisabledForValidationInProgress
        );
      } else if (action === "sendConfirmation") {
        return (
          (userRole === "master" || userRole === "superuser") &&
          (row.status === "validée" || row.status === "expédiée") &&
          row.orderConfirmationSent === false
        );
      } else if (action === "reSendConfirmation") {
        return (
          (userRole === "master" || userRole === "superuser") &&
          (row.status === "validée" || row.status === "expédiée") &&
          row.orderConfirmationSent === true
        );
      } else if (action === "duplicateOrder") {
        return (
          currentUser.get("orderAble") &&
          row.ECCTCODE !== "999995" &&
          row.ECCTCODE !== "999992"
        ); // you can"t copy a 'vente directe"
      } else if (action === "validateClientDiscount") {
        return (
          row.clientPermanentDiscountNeedsValidation &&
          (userRole === "master" || userRole === "superuser") &&
          row.status !== "validée"
        );
      } else if (action === "showPdfOrder") {
        return row.ECCTCODE !== "999995" && row.ECCTCODE !== "999992";
      } else {
        return true;
      }
    }
  };

  const getEditIcon = (rowData) => {
    return userRole !== "client" && rowData.row.manuallyEditedByClient ? (
      <Tooltip
        title="Le client a modifié la commande manuellement, veuillez vérifier les remises appliquées avant validation"
        placement="top"
        classes={{ tooltip: classes.toolTip }}
      >
        <EditIcon color="secondary" />
      </Tooltip>
    ) : (
      <Tooltip
        title="Modifier la commande"
        placement="top"
        classes={{ tooltip: classes.toolTip }}
      >
        <EditIcon />
      </Tooltip>
    );
  };
  const actions = [
    {
      icon: (
        <Tooltip
          title="Voir la commande"
          placement="top"
          classes={{ tooltip: classes.toolTip }}
        >
          <PageviewIcon />
        </Tooltip>
      ),
      action: (rowData) => handleClickOpen(rowData.row, "showOrder"),
      displayIcon: (rowData) => displayIcon("showOrder", rowData.row),
    },
    {
      icon: (
        <Tooltip
          title="Voir le pdf de la commande"
          placement="top"
          classes={{ tooltip: classes.toolTip }}
        >
          <PictureAsPdfIcon />
        </Tooltip>
      ),
      action: (rowData) => handlePdfDialogOpen(rowData.row.ECKTNUMERO),
      displayIcon: (rowData) => displayIcon("showPdfOrder", rowData.row),
    },
    {
      icon: (
        <Tooltip
          title="Créer une nouvelle commande reprenant les produits de cette commande"
          placement="top"
          classes={{ tooltip: classes.toolTip }}
        >
          <ControlPointDuplicateIcon />
        </Tooltip>
      ),
      action: (rowData) => duplicateOrder(rowData.row.ECKTNUMERO),
      displayIcon: (rowData) => displayIcon("duplicateOrder", rowData.row),
    },
    {
      icon: (
        <Tooltip
          title="Exporter sous Excel"
          placement="top"
          classes={{ tooltip: classes.toolTip }}
        >
          <GetApp />
        </Tooltip>
      ),
      action: (rowData) => exportOrder(rowData.row.ECKTNUMERO),
      displayIcon: (rowData) => displayIcon("exportOrder", rowData.row),
    },
    {
      icon: (
        <Tooltip
          title="Cette commande nécessite une validation concernant la remise appliquée"
          placement="top"
          classes={{ tooltip: classes.toolTip }}
        >
          <WarningIcon color="error" />
        </Tooltip>
      ),
      action: (rowData) =>
        handleQuestionForDiscountValidationDialogOpen(rowData.row),
      displayIcon: (rowData) =>
        displayIcon("validateClientDiscount", rowData.row),
    },
    {
      icon: (
        <Tooltip
          title="Envoyer la confirmation de commande"
          placement="top"
          classes={{ tooltip: classes.toolTip }}
        >
          <EmailIcon />
        </Tooltip>
      ),
      action: (rowData) => sendOrderConfirmation(rowData.row),
      displayIcon: (rowData) => displayIcon("sendConfirmation", rowData.row),
    },
    {
      icon: (
        <Tooltip
          title="Réenvoyer la confirmation de commande"
          placement="top"
          classes={{ tooltip: classes.toolTip }}
        >
          <EmailIcon />
        </Tooltip>
      ),
      action: (rowData) => sendOrderConfirmation(rowData.row),
      displayIcon: (rowData) => displayIcon("reSendConfirmation", rowData.row),
    },
    {
      icon: (
        <Tooltip
          title="Valider la commande"
          placement="top"
          classes={{ tooltip: classes.toolTip }}
        >
          <DoneIcon />
        </Tooltip>
      ),
      action: (rowData) =>
        handleQuestionForValidationDialogOpen(rowData.row, "validateOrder"),
      displayIcon: (rowData) => displayIcon("validateOrder", rowData.row),
    },
    {
      icon: (
        <Tooltip
          title="Une commande doit avoir un contact pour pouvoir être validée"
          placement="top"
          classes={{ tooltip: classes.toolTip }}
        >
          <DoneIcon color="disabled" />
        </Tooltip>
      ),
      action: () => console.log("action disabled: contact missing"),
      displayIcon: (rowData) =>
        displayIcon("validateOrderDisabledForContactMissing", rowData.row),
    },
    {
      icon: (
        <Tooltip
          title="Validation de la commande en cours"
          placement="top"
          classes={{ tooltip: classes.toolTip }}
        >
          <DoneIcon color="disabled" />
        </Tooltip>
      ),
      action: () => console.log("action disabled: validation in progress"),
      displayIcon: (rowData) =>
        displayIcon(
          "validateOrderDisabledForValidationInProgress",
          rowData.row,
        ),
    },
    {
      getIcon: (rowData) => getEditIcon(rowData),
      disabledIcon: <EditIcon />,
      action: (rowData) => handleClickOpen(rowData.row, "editOrder"),
      displayIcon: (rowData) => displayIcon("editOrder", rowData.row),
    },
    {
      icon: (
        <Tooltip
          title="Supprimer la commande"
          placement="top"
          classes={{ tooltip: classes.toolTip }}
        >
          <DeleteIcon />
        </Tooltip>
      ),
      disabledIcon: <DeleteIcon />,
      action: (rowData) =>
        handleQuestionForValidationDialogOpen(rowData.row, "deleteOrder"),
      displayIcon: (rowData) => displayIcon("deleteOrder", rowData.row),
    },
  ];

  useEffect(() => {
    setNumberColumns(columnsForProductOrders, setStateNumberColumns);
    listToColumnTranslations([
      { listName: "commercials", columnName: "rep1" },
      { listName: "orderTypes", columnName: "ECCTDOMACT" },
    ]);
    buildProductOptions(); // eslint-disable-next-line
  }, []);

  // when page or sorting or productId change or orderChanged (validated, deleted...)
  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, [
    currentPage,
    sorting,
    pageSize,
    productIdForOrders,
    columnsTranslations,
    questionForDiscountValidationDialogOpen,
    incrementalForOrderChanged,
  ]);

  // when filters change
  useEffect(() => {
    if (debouncedFilters) {
      loadData();
    }
    // eslint-disable-next-line
  }, [debouncedFilters]);

  if (rows === null) {
    return null;
  }

  return (
    <Page
      toolBarTitle="Historique des commandes"
      pageTitle="Historique des commandes"
    >
      <Form
        onSubmit={updateProductIdForOrders}
        initialValues={{ product: productsOptions[0] }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form id="productPickerForm" onSubmit={handleSubmit}>
            <FormSpy
              subscription={{ values: true }}
              component={AutoSave}
              onFormChange={updateProductIdForOrders}
            />

            <Grid container spacing={0}>
              <Grid item xs={8} className={classes.grid}>
                <div className={classes.textContainer}>Filtre par produit</div>
                <div className={classes.fieldContainer}>
                  <AutoComplete
                    name="product"
                    label="Produit"
                    options={productsOptions}
                  />
                </div>
              </Grid>
            </Grid>
          </form>
        )}
      />

      {currentUser.get("role") !== "client" && (
        <OrderImport
          onLoadData={loadData}
          onSnackBarShow={handleSnackBarShow}
        />
      )}

      <Paper className={classes.tableContainer}>
        <DataGridView
          title="Liste des commandes"
          rowsValues={rows}
          columnHeaders={columnsHeaders}
          currentPage={currentPage}
          onCurrentPageChange={changeCurrentPage}
          pageSize={pageSize}
          pageSizes={pageSizes}
          onPageSizeChange={changePageSize}
          filters={filters}
          onFiltersChange={changeFilters}
          sorting={sorting}
          onSortingChange={changeSorting}
          totalCount={totalCount}
          loading={loading}
          numberColumns={numberColumns}
          rowsPerPage="Nb de commandes par page:"
          contentInfoSupplier={contentInfo}
          columnExtensions={columnExtensions}
          withPagination={true}
          hasActions={true}
          actions={actions}
          actionWidth={300}
          withCustomAddButton
          noDataText="pas de commande"
        >
          {(currentUser.get("role") === "master" ||
            currentUser.get("role") === "superuser") && (
            <Button
              variant="contained"
              color="primary"
              component="span"
              className={classes.button}
              onClick={() => sendOrdersConfirmation()}
              disabled={disableSendConfirmationButton}
            >
              Envoyer les confirmations de commande
            </Button>
          )}
          {/* this is for <OrderImport/> */}
          {currentUser.get("role") !== "client" && (
            <label htmlFor="importButton">
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.button}
              >
                Importer
              </Button>
            </label>
          )}
        </DataGridView>
      </Paper>

      <ExcelExport fileName={excelFileName} ref={_export}>
        <ExcelExportColumn
          field="LCCTCODART"
          title="Code article"
          width={140}
        />
        <ExcelExportColumn
          field="LCCTLIB01"
          title="Libellé article"
          width={350}
        />
        <ExcelExportColumn
          field="LCCTCOMART"
          title="Code complémentaire"
          width={100}
        />
        <ExcelExportColumn
          field="LCCJDELARR"
          title="Date de livraison prévue"
          width={100}
        />
        <ExcelExportColumn
          field="LCCNQTECDE"
          title="Quantité commandée"
          width={100}
        />
        <ExcelExportColumn
          field="discount"
          title="Quantité commandée"
          width={100}
        />
        <ExcelExportColumn
          field="LCCNPUBRUT"
          title="Prix unitaire brut"
          width={100}
        />
        <ExcelExportColumn
          field="LCCNPUNET"
          title="Prix unitaire net"
          width={100}
        />
        <ExcelExportColumn
          field="LCCNVALCDE"
          title="Montant total net HT (€)"
          width={100}
        />
        <ExcelExportColumn
          field="LCCNQTEEXP"
          title="Quantité expédiée"
          width={100}
        />
      </ExcelExport>

      <Dialog
        fullScreen
        open={dialogOpen}
        onClose={() => handleModalClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              color="inherit"
              onClick={() => handleModalClose()}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
               Commande n°{orderNumberForDialog}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          {dialogOperation === "showOrder" && (
            <OrderDetail orderNumber={orderNumberForDialog} />
          )}
          {dialogOperation === "editOrder" && (
            <OrderEdit
              orderNumber={orderNumberForDialog}
              onClose={() => handleModalClose}
              onValidateOrder={validateOrder}
            />
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={questionForDiscountValidationDialogOpen}
        onClose={handleQuestionForDiscountValidationDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Pour cette commande, le commercial a défini un taux de remise client
            permanente ({appliedClientDiscountForValidationDialog} %) 
            {originalClientDiscountForValidationDialog === undefined && (
              <span>
                alors qu'il n'y a pas de remise client permanente définie dans
                le système.
              </span>
            )}
            {originalClientDiscountForValidationDialog !== undefined && (
              <span>
                différent de celui défini dans le système (
                {originalClientDiscountForValidationDialog} %).
              </span>
            )}
            <br />
            Souhaitez-vous valider ce taux ?
            <br />
            <br />
            <Typography variant="caption">
              En cas de refus vous serez amené sur la page d'édition de la
              commande pour appliquer le taux souhaité.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => acceptOrderClientDiscount(orderNumberForDialog)}
            color="primary"
          >
            {dialogYesBtnText}
          </Button>
          <Button
            onClick={() => refuseOrderClientDiscount(orderNumberForDialog)}
            color="primary"
          >
            {dialogNoBtnText}
          </Button>
          <Button
            onClick={() => handleQuestionForDiscountValidationDialogClose()}
            color="primary"
            autoFocus
          >
            {dialogVoidBtnText}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={questionForValidationDialogOpen}
        onClose={handleQuestionForValidationDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {questionForValidationDialogAction === "validateOrder" && (
              <>
                En validant la commande, vous envoyez celle-ci dans PMI. Cette
                dernière pourra alors uniquement être modifiée sous PMI.
                <br />
                Souhaitez-vous continuer ?
              </>
            )}
            {questionForValidationDialogAction === "deleteOrder" && (
              <>Voulez-vous vraiment supprimer la commande ?</>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {questionForValidationDialogAction === "validateOrder" && (
            <Button
              onClick={() => validateOrder(orderNumberForDialog)}
              color="primary"
              disabled={questionForValidationValidateButtonDisabled}
            >
              {dialogYesBtnText}
            </Button>
          )}
          {questionForValidationDialogAction === "deleteOrder" && (
            <Button
              onClick={() => deleteOrder(orderNumberForDialog)}
              color="primary"
            >
              {dialogYesBtnText}
            </Button>
          )}
          <Button
            onClick={() => handleQuestionForValidationDialogClose()}
            color="primary"
            autoFocus
          >
            {dialogNoBtnText}
          </Button>
        </DialogActions>
      </Dialog>

      <ClockedOrderDialog
        dialogOpen={clockedOrderDialogOpen}
        setDialogOpen={setClockedOrderDialogOpen}
      />

      {forcedDiscountData !== undefined &&
        forcedDiscountData.forcedDiscountExists && (
          <DialogForForcedDiscounts
            forcedDiscountData={forcedDiscountData}
            onDialogClose={handleModalForForcedDiscountsClose}
          />
        )}

      <Dialog
        fullScreen
        open={pdfDialogOpen}
        onClose={() => setPdfDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <PdfOrder
          orderNumber={pdfOrderNumber}
          onDialogClose={setPdfDialogOpen}
        />
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
        open={snackBarOpen}
        onClose={handleSnackBarClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackBarText}</span>}
      />

      <Snackbar
        open={alertSnackBarOpen}
        autoHideDuration={10000}
        onClose={() => {
          setAlertSnackBarOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setAlertSnackBarOpen(false);
          }}
          severity="warning"
        >
          Suite à un blocage client La commande ne peut pas être validée.
          Veuillez contacter le responsable financier pour plus d'information.
        </Alert>
      </Snackbar>
    </Page>
  );
};

export default History;
