import React, { useState, useEffect } from "react";
import moment from "moment";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import { makeStyles, Button, Grid, InputAdornment } from "@material-ui/core";
import Parse from "parse";

const useStyles = makeStyles(() => ({
  gridContainer: {
    width: "100%",
  },
  fieldContainer: {
    display: "flex",
    flex: 1,
    height: "48px",
    margin: "8px 32px 8px 32px",
    justifyContent: "center",
  },
  buttonWrapper: {
    display: "flex",
    flex: 1,
    margin: "1rem 0 0.5rem",
    justifyContent: "center",
  },
}));

const ClientDiscountQuickEditForm = (props) => {
  const classes = useStyles();
  const { id, onDiscountUpdate } = props;
  const [discountData, setDiscountData] = useState(null);

  const loadClientDiscount = async () => {
    const discount = await new Parse.Query("Discounts").get(id);
    let discountValues = Object.assign({}, discount.attributes);
    Object.entries(discount.attributes).forEach(([key, value]) => {
      // dates
      if (key === "endDate") {
        discountValues[key] = moment(value).format("YYYY-MM-DD");
      }
    });
    setDiscountData(discountValues);
  };

  const onSubmit = async (values) => {
    const discount = await new Parse.Query("Discounts").get(id);
    await Promise.all(
      Object.entries(values).map(async ([key, value]) => {
        if (key === "endDate") {
          const date = moment.utc(value).toDate();
          discount.set(key, date);
        } else {
          discount.set(key, value);
        }
      }),
    );

    try {
      await discount.save();
      onDiscountUpdate();
    } catch (error) {
      console.log(error.message);
    }
  };

  /**********************************************************/
  /*********************** validators ***********************/
  /**********************************************************/

  const required = (value) => (value ? undefined : "Champs obligatoire");

  useEffect(() => {
    loadClientDiscount();
    // eslint-disable-next-line
  }, []);

  if (discountData === null) {
    return null;
  }
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={discountData}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form id="clientInfoForm" onSubmit={handleSubmit}>
          <Grid container spacing={0} className={classes.gridContainer}>
            <Grid item xs={12}>
              <div className={classes.fieldContainer}>
                <Field
                  name="percentageDiscount"
                  component={TextField}
                  label="Pourcentage de remise"
                  type="number"
                  fullWidth
                  validate={required}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </div>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={12}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="francoMinAmount"
                    component={TextField}
                    label="Franco à partir de"
                    type="number"
                    fullWidth
                    validate={required}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={12}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="endDate"
                    component={TextField}
                    type="date"
                    label="Date de fin"
                    fullWidth
                    validate={required}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.buttonWrapper}>
                <Button
                  type="submit"
                  disabled={submitting || pristine}
                  variant="contained"
                  color="primary"
                >
                  modifier
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default ClientDiscountQuickEditForm;
