import React from "react";
import Page from "./Page";
import DiscountLists from "../discount/DiscountLists";

const Discounts = () => {
  return (
    <Page toolBarTitle="Gestion des remises" pageTitle="Gestion des remises">
      <DiscountLists />
    </Page>
  );
};

export default Discounts;
