"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getArticlesMinStock = void 0;
function getArticlesMinStock(supplies) {
    return supplies.reduce(function (minStocks, supply) {
        var _a;
        var articleId = supply.ARKTCOMART;
        var currentMin = minStocks[articleId] || Infinity;
        var newMin = Math.min.apply(Math, __spreadArrays([currentMin], supply.supplies.map(function (s) { return s.quantity; })));
        return __assign(__assign({}, minStocks), (_a = {}, _a[articleId] = newMin, _a));
    }, {});
}
exports.getArticlesMinStock = getArticlesMinStock;
