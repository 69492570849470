import React, { useState } from "react";
import Page from "../Page";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../reducers/currentUser";
import { makeStyles, Typography } from "@material-ui/core";
import ClientPickerForm from "../../form/ClientPickerForm";
import ClockedOrderDialog from "../../../components/order/ClockedOrderDialog";

const useStyles = makeStyles(() => ({
  subtitle1Wrapper: {
    padding: "12px 0 24px",
  },
}));

const ClockedOrder = () => {
  const currentUser = useSelector(getCurrentUser);
  const userRole =
    currentUser !== undefined ? currentUser.get("role") : undefined;
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  if (
    (userRole === "master" || userRole === "superuser") &&
    !currentUser.get("orderAble")
  ) {
    return null;
  }

  return (
    <Page toolBarTitle="Commande cadencée" pageTitle="Commande cadencée">
      {userRole !== "client" && (
        <>
          <div className={classes.subtitle1Wrapper}>
            <Typography variant="subtitle1" paragraph>
              Passez une commande cadencée pour le compte d'un client depuis
              cette page.
            </Typography>
          </div>
          <Typography variant="body2" paragraph>
            Pour quel compte souhaitez-vous passer une commande cadencée ?
          </Typography>
        </>
      )}
      {userRole === "client" && (
        <div className={classes.subtitle1Wrapper}>
          <Typography variant="subtitle1" paragraph>
            Cliquez sur le bouton ci-dessous pour passez une commande cadencée.
          </Typography>
        </div>
      )}

      <ClientPickerForm
        onFormSubmit={() => {
          setDialogOpen(true);
        }}
      />

      <ClockedOrderDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
      />
    </Page>
  );
};

export default ClockedOrder;

// const styles = theme => ({
//   subtitle1Wrapper: {
//     padding: '12px 0 24px'
//   }
// });
// const currentUser = Parse.User.current();
// const userRole = currentUser !== null ? currentUser.get('role') : undefined;
//
// class ClockedOrder extends PureComponent {
//   constructor(props) {
//     super(props);
//     this.state = {
//       dialogOpen: false
//     };
//   }
//
//   startOrder = () => {
//     this.setState({
//       dialogOpen: true
//     })
//   };
//
//   setDialogOpen = (dialogOpen) => {
//     this.setState({ dialogOpen: dialogOpen });
//   };
//
//   render() {
//     const { dialogOpen } = this.state;
//     const { classes } = this.props;
//
//     if (!currentUser.get('resupplyAble') && currentUser.get('role') !== 'client') {
//       return null;
//     }
//
//     return (
//       <Page toolBarTitle='Commande cadencée' pageTitle='Commande cadencée'>
//         {userRole !== 'client' &&
//           <>
//           <div className={classes.subtitle1Wrapper}>
//             <Typography variant="subtitle1" paragraph>
//               Passez une commande cadencée pour le compte d'un client depuis cette page.
//             </Typography>
//           </div>
//           <Typography variant="body2" paragraph>
//             Pour quel compte souhaitez-vous passer une commande cadencée ?
//           </Typography>
//           </>
//           }
//         {userRole === 'client' &&
//         <div className={classes.subtitle1Wrapper}>
//           <Typography variant="subtitle1" paragraph>
//             Cliquez sur le bouton ci-dessous pour passez une commande cadencée.
//           </Typography>
//         </div>
//         }
//
//         <ClientPickerForm onFormSubmit={this.startOrder}/>
//
//         <ClockedOrderDialog
//           dialogOpen={dialogOpen}
//           setDialogOpen={this.setDialogOpen}
//         />
//
//       </Page>
//     );
//   }
// }
//
//
// export default connect(state => ({
//   productsOrder: getProducts(state)
// }), {
// }) (withStyles(styles)(ClockedOrder));
