import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getProducts, getShippings } from "../../reducers/order";
import { makeStyles, Typography, Divider } from "@material-ui/core";
import ShippingChip from "./ShippingChip";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
  tableWrapper: {
    overflow: "overlay",
    padding: "2px 12px",
    maxHeight: "225px",
  },
  lineWrapper: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 12px",
  },
  firstCol: {
    display: "flex",
    width: "50%",
  },
  secCol: {
    display: "flex",
    width: "25%",
    justifyContent: "flex-end",
  },
  thirdCol: {
    display: "flex",
    width: "25%",
    justifyContent: "flex-end",
  },
  productCount: {
    paddingLeft: "0.5rem",
  },
  text: {
    lineHeight: "2rem",
  },
  bold: {
    fontWeight: "500",
  },
}));

const AmountByShipping = () => {
  const classes = useStyles();
  const productsOrder = useSelector(getProducts);
  const shippings = useSelector(getShippings);
  const [amountByShipping, setAmountByShipping] = useState({});
  const [totalRawAmount, setTotalRawAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const updateAmountByShipping = () => {
    let newAmountByShipping = {};
    let newTotalAmount = 0;
    let newTotalRawAmount = 0;

    if (productsOrder !== undefined) {
      productsOrder.forEach((product) => {
        const quantity = product.quantities.quantity;
        const rawPrice = product.productObj.ARCNTARIF1;
        newTotalRawAmount += quantity * rawPrice;
        newTotalAmount += product.quantities.amount;
        if (quantity) {
          product.quantities.articles.forEach((article) => {
            if (article.quantity) {
              article.shippings.forEach((shipping) => {
                if (shipping.quantity) {
                  if (newAmountByShipping[shipping.id] === undefined) {
                    newAmountByShipping[shipping.id] = {
                      rawAmount: 0,
                      amount: 0,
                      quantity: 0,
                    };
                  }
                  newAmountByShipping[shipping.id].rawAmount +=
                    shipping.quantity * rawPrice;
                  newAmountByShipping[shipping.id].amount += shipping.amount;
                  newAmountByShipping[shipping.id].quantity +=
                    shipping.quantity;
                }
              });
            }
          });
        }
      });
    }
    setAmountByShipping(newAmountByShipping);
    setTotalRawAmount(newTotalRawAmount);
    setTotalAmount(newTotalAmount);
  };

  useEffect(() => {
    updateAmountByShipping();
    // eslint-disable-next-line
  }, [productsOrder, shippings]);

  /*
  console.log("---------------");
  console.log("productsOrder: ", productsOrder);
  console.log("shippings: ", shippings);
  console.log("amountByShipping: ", amountByShipping);
*/
  if (productsOrder === undefined || shippings === undefined) {
    return null;
  }

  return (
    <div className={classes.tableWrapper}>
      <div className={classes.lineWrapper}>
        <Typography
          variant="body2"
          className={classNames(classes.text, classes.firstCol, classes.bold)}
        >
          Cadence
        </Typography>
        <Typography
          variant="body2"
          className={classNames(classes.text, classes.secCol, classes.bold)}
        >
          Total brut
        </Typography>
        <Typography
          variant="body2"
          className={classNames(classes.text, classes.thirdCol, classes.bold)}
        >
          Total net
        </Typography>
      </div>
      {shippings.map((shipping, index) => {
        // console.log('product: ', product);
        return (
          <div key={index} className={classes.lineWrapper}>
            <div className={classes.firstCol}>
              <ShippingChip number={index + 1} size="small" />
              <Typography
                variant="body2"
                className={classNames(classes.text, classes.productCount)}
              >
                {amountByShipping[shipping.id] !== undefined
                  ? amountByShipping[shipping.id].quantity + " article(s)"
                  : "0 article(s)"}
              </Typography>
            </div>
            <Typography
              variant="body2"
              className={classNames(classes.text, classes.secCol)}
            >
              {amountByShipping[shipping.id] !== undefined
                ? new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }).format(amountByShipping[shipping.id].rawAmount)
                : "0,00 €"}
            </Typography>
            <Typography
              variant="body2"
              className={classNames(classes.text, classes.thirdCol)}
            >
              {amountByShipping[shipping.id] !== undefined
                ? new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }).format(amountByShipping[shipping.id].amount)
                : "0,00 €"}
            </Typography>
          </div>
        );
      })}
      <Divider />
      <div className={classes.lineWrapper}>
        <Typography
          variant="body2"
          className={classNames(classes.text, classes.firstCol)}
        >
          Total
        </Typography>
        <Typography
          variant="body2"
          className={classNames(classes.text, classes.secCol)}
        >
          {new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
          }).format(totalRawAmount)}
        </Typography>
        <Typography
          variant="body2"
          className={classNames(classes.text, classes.thirdCol)}
        >
          {new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
          }).format(totalAmount)}
        </Typography>
      </div>
    </div>
  );
};

export default AmountByShipping;
