import React, { useState, useEffect } from "react";
import Page from "../Page";
import Parse from "parse";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../reducers/currentUser";
import {
  makeStyles,
  Paper,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Snackbar,
  AppBar,
  Toolbar,
  IconButton,
  Grid,
} from "@material-ui/core";
import {
  FilteringState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
} from "@devexpress/dx-react-grid";
import {
  Grid as Grid2,
  Table,
  TableHeaderRow,
  TableFilterRow,
} from "@devexpress/dx-react-grid-material-ui";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import ActionsColumn from "../../../components/reactgrid/ActionsCol";
import HandleTmForm from "../../form/HandleTmForm";

const useStyles = makeStyles(() => ({
  tableTopWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "",
  },
  tableTitleWrapper: {
    display: "flex",
    alignItems: "center",
    minHeight: "64px",
    padding: "0 8px 0 24px",
  },
  subtitle1Wrapper: {
    padding: "12px 0 24px",
  },
  toolbar: {
    backgroundColor: "#46494c", //'#02cfa3'
  },
}));

const COLUMNS = [
  { name: "CLCTGROUPE", title: "Code" },
  { name: "name", title: "Nom" },
];

const Tradenames = () => {
  const classes = useStyles();
  const currentUser = useSelector(getCurrentUser);
  const userRole =
    currentUser !== undefined ? currentUser.get("role") : undefined;
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarText, setSnackBarText] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogText, setDialogText] = useState("");
  const [tms, setTms] = useState([]);
  const [tmCode, setTmCode] = useState("");

  // getting tradenames from mongo
  const getTms = async () => {
    const query = await new Parse.Query("Tradenames")
      .select("CLCTGROUPE", "name")
      .find();
    const tms = query.map((tradename) => tradename.attributes);
    // const tms = [];
    // query.forEach(tradename => {
    //   tms.push(tradename.attributes);
    // });
    setTms(tms);
  };

  // when dialog is called
  const handleClickOpen = (tradename) => {
    const tmCode = tradename.CLCTGROUPE;
    const tmName = tradename.name !== undefined ? tradename.name : "sans nom";
    setDialogOpen(true);
    setTmCode(tmCode);
    setDialogTitle(
      "Modification de l'enseigne " + tmName + " (" + tmCode + ")",
    );
    setDialogText("");
  };

  // when closing dialog box
  const handleModalClose = async (action) => {
    if (action === "validate") {
      handleSnackBarShow("enseigne mise à jour");
    }
    // we always reload tradenames on modal close
    await getTms();
    setDialogOpen(false);
  };

  /********************************************************/
  /************************ SnackBar **********************/
  /********************************************************/
  const handleSnackBarShow = (textValue) => {
    setSnackBarOpen(true);
    setSnackBarText(textValue);
  };
  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  /********************************************************/
  /************************ Actions ***********************/
  /********************************************************/
  const displayIcon = (action, row) => {
    if (row === undefined) {
      return false;
    } else {
      if (action === "editTradeName") {
        return userRole !== "commercial";
      } else if (action === "viewTradeName") {
        return userRole === "commercial";
      } else {
        return true;
      }
    }
  };

  const actions = [
    {
      icon: <EditIcon />,
      action: (rowData) => handleClickOpen(rowData.row),
      displayIcon: (rowData) => displayIcon("editTradeName", rowData.row),
    },
    {
      icon: <InfoIcon />,
      action: (rowData) => handleClickOpen(rowData.row),
      displayIcon: (rowData) => displayIcon("viewTradeName", rowData.row),
    },
  ];

  // when inline command is done
  useEffect(() => {
    getTms(); // eslint-disable-next-line
  }, []);

  if (!tms || (!currentUser.get("clientAble") && userRole !== "commercial")) {
    return null;
  }

  return (
    <Page
      toolBarTitle="Gestion des enseignes"
      pageTitle="Gestion des enseignes"
    >
      <div className={classes.subtitle1Wrapper}>
        <Typography variant="subtitle1" paragraph>
          Editez les champs des enseignes depuis cette page.
          <br />
          Les enseignes peuvent être supprimées ou ajoutées uniquement depuis
          PMI.
        </Typography>
      </div>

      <Paper>
        <Grid2 rows={tms} columns={COLUMNS}>
          <div className={classes.tableTopWrapper}>
            <Typography variant="h6" className={classes.tableTitleWrapper}>
              Enseignes
            </Typography>
          </div>

          <FilteringState defaultFilters={[]} />
          <IntegratedFiltering />
          <SortingState
            defaultSorting={[{ columnName: "CLCTGROUPE", direction: "asc" }]}
          />
          <IntegratedSorting />
          <Table />
          <TableHeaderRow
            showSortingControls
            messages={{ sortingHint: "Trier" }}
          />
          <TableFilterRow messages={{ filterPlaceholder: "Filtrer" }} />
          <ActionsColumn actions={actions} />
        </Grid2>
      </Paper>

      <Dialog
        fullScreen
        open={dialogOpen}
        onClose={() => handleModalClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              color="inherit"
              onClick={() => handleModalClose()}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              {dialogTitle}
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogText}
          </DialogContentText>
          <Grid container spacing={0} className={classes.gridContainer}>
            <Grid item xs={4}>
              <HandleTmForm
                tmCode={tmCode}
                onHandleModalClose={() => handleModalClose("validate")}
                readOnly={userRole !== "master" && userRole !== "superuser"}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleModalClose("cancel")}
            color="primary"
            autoFocus
          >
            Fermer
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
        open={snackBarOpen}
        onClose={handleSnackBarClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackBarText}</span>}
      />
    </Page>
  );
};

export default Tradenames;
