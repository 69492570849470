import React, { PureComponent } from "react";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import { withStyles, Button, Grid, InputAdornment } from "@material-ui/core";
import Parse from "parse";

const styles = (theme) => ({
  formContainer: {
    marginTop: "84px",
  },
  gridContainer: {
    width: "50%",
  },
  textField: {
    width: "100%",
  },
  selectField: {
    width: "100%",
  },
  fieldContainer: {
    display: "flex",
    flex: 1,
    height: "48px",
  },
  buttonWrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    margin: "2rem 1rem 1rem 0",
  },
  errorMessage: {
    textAlign: "center",
    color: "red",
  },
  switchText: {
    lineHeight: "48px",
  },
});

class HandleTmForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tmData: {},
    };
  }
  componentDidMount() {
    this.loadTm();
  }

  loadTm = async () => {
    const { tmCode } = this.props;
    const tm = await new Parse.Query("Tradenames")
      .equalTo("CLCTGROUPE", tmCode)
      .first();
    const tmData = tm.attributes;

    this.setState({
      tmData,
    });
  };

  onSubmit = async (values) => {
    const { readOnly } = this.props;
    if (!!readOnly) {
      return null;
    }
    const { tmCode, onHandleModalClose } = this.props;
    const tm = await new Parse.Query("Tradenames")
      .equalTo("CLCTGROUPE", tmCode)
      .first();
    Object.entries(values).forEach(([key, value]) => {
      const valueToSave =
        key === "RFAPercentage" || key === "RFAFix" ? parseInt(value) : value;
      tm.set(key, valueToSave);
    });
    await tm.save();
    onHandleModalClose("validate");
  };

  render() {
    const { tmData } = this.state;
    const { classes, readOnly } = this.props;

    return (
      <div className={classes.formContainer}>
        <Form
          onSubmit={this.onSubmit}
          initialValues={tmData}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form id="groupForm" onSubmit={handleSubmit}>
              <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    <Field
                      name="name"
                      component={TextField}
                      placeholder="Nom de l'enseigne"
                      label="Nom de l'enseigne"
                      className={classes.textField}
                      disabled={readOnly}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    <Field
                      name="RFAPercentage"
                      component={TextField}
                      label="RFA (%)"
                      placeholder="RFA (%)"
                      type="number"
                      fullWidth
                      disabled={readOnly}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    <Field
                      name="RFAFix"
                      component={TextField}
                      label="RFA (fixe)"
                      placeholder="RFA (fixe)"
                      type="number"
                      fullWidth
                      disabled={readOnly}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">€</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              {!readOnly && (
                <Grid container spacing={0} className={classes.gridContainer}>
                  <Grid item xs={12}>
                    <div className={classes.buttonWrapper}>
                      <Button
                        type="submit"
                        disabled={submitting || pristine}
                        variant="contained"
                        color="primary"
                      >
                        enregistrer
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              )}
            </form>
          )}
        />
      </div>
    );
  }
}

export default withStyles(styles)(HandleTmForm);
