import React from "react";
import { IconButton } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { updateProductOrderDiscountAndAmount } from "../../utils2";
import { useSelector, useDispatch } from "react-redux";
import { getProducts, getShippings } from "../../reducers/order";
import { setProducts } from "../../actions/main";
import CountCellForm from "../../containers/form/CountCellForm";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { getCurrentUser } from "../../reducers/currentUser";
import { generateSuppliesData } from "../../utils/generateSuppliesData/generateSuppliesData";

const lineHeight = 32;
const useStyles = makeStyles((theme) => ({
  cellLine: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: lineHeight,
    lineHeight: lineHeight + "px",
  },
  count: {
    display: "flex",
    justifyContent: "center",
    minWidth: "28px",
  },
}));

const CountCell = (props) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const currentUser = useSelector(getCurrentUser);
  const userRole =
    currentUser !== undefined ? currentUser.get("role") : undefined;
  const {
    product,
    ARKTCOMART,
    shippingIndex,
    orderedQuantity,
    availableQuantity,
    supplyPerDate,
  } = props;
  const productsOrder = useSelector(getProducts);
  const shippings = useSelector(getShippings);
  const dispatch = useDispatch();

  const changeQuantity = async (
    product,
    ARKTCOMART,
    shippingIndex,
    newQuantity
  ) => {
    // here we do 3 things in productOrder.
    // A: incrementing/decrementing quantity ordered
    // B: decrementing/incrementing supplies
    // C: recalculating discounts and prices
    const newProductsOrder = [...productsOrder];

    newProductsOrder.forEach((productOrder) => {
      if (productOrder.ARKTCODART === product.ARKTCODART) {
        // A: incrementing/decrementing quantity ordered
        productOrder.quantities.articles.forEach((article) => {
          if (article.ARKTCOMART === ARKTCOMART) {
            const delta =
              newQuantity - article.shippings[shippingIndex].quantity;
            productOrder.quantities.quantity += delta;
            article.quantity += delta;
            article.shippings[shippingIndex].quantity += delta;
          }
        });

        // B: decrementing/incrementing supplies
        const params = {
          supplies: product.productObj.supplies,
          resupplies: product.productObj.resupplies,
          quantities: productOrder.quantities,
          removeNegativeQuantities:
            userRole !== "master" && userRole !== "admin", // We remove negative quantities for commercial, clients & groups
        };
        productOrder.suppliesPerDate = generateSuppliesData(params);
      }

      // C: recalculating discounts and prices
      updateProductOrderDiscountAndAmount(newProductsOrder, shippings);
    });
    dispatch(setProducts(newProductsOrder));
  };

  return (
    <div
      className={classes.cellLine}
      key={product + "_" + ARKTCOMART + "_" + shippingIndex}
    >
      <IconButton
        color="default"
        disabled={!orderedQuantity}
        onClick={() =>
          changeQuantity(
            product,
            ARKTCOMART,
            shippingIndex,
            orderedQuantity - 1
          )
        }
        aria-label="Close"
      >
        <RemoveCircleIcon />
      </IconButton>
      <div className={classes.count}>
        <CountCellForm
          count={orderedQuantity}
          onUpdateQuantity={(newQuantity) =>
            changeQuantity(product, ARKTCOMART, shippingIndex, newQuantity)
          }
          availableQuantity={availableQuantity}
          supplyPerDate={supplyPerDate}
        />
      </div>
      <IconButton
        color="default"
        onClick={() =>
          changeQuantity(
            product,
            ARKTCOMART,
            shippingIndex,
            orderedQuantity + 1
          )
        }
        aria-label="Close"
      >
        <AddCircleIcon />
      </IconButton>
    </div>
  );
};

export default CountCell;
