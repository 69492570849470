import React, { PureComponent } from "react";
import moment from "moment";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import {
  withStyles,
  Button,
  Grid,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import {
  FilteringState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
} from "@devexpress/dx-react-grid";
import {
  Grid as Grid2,
  Table,
  TableHeaderRow,
  TableFilterRow,
} from "@devexpress/dx-react-grid-material-ui";
import Parse from "parse";

const styles = () => ({
  formContainer: {
    marginTop: "84px",
  },
  gridContainer: {
    width: "50%",
  },
  textField: {
    width: "100%",
  },
  selectField: {
    width: "100%",
  },
  fieldContainer: {
    display: "flex",
    flex: 1,
    height: "48px",
  },
  buttonWrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    margin: "2rem 1rem 1rem 0",
  },
  errorMessage: {
    textAlign: "center",
    color: "red",
  },
  switchText: {
    lineHeight: "48px",
  },
});
const columns = [
  { name: "field", title: "Champs" },
  { name: "value", title: "Valeur" },
];

class HandleGroupForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      groupData: {},
      values: [],
      pmiData: [],
    };
  }
  componentDidMount() {
    this.loadGroup();
  }

  loadGroup = async () => {
    const { groupCode } = this.props;
    const group = await new Parse.Query("Groups")
      .equalTo("CLKTCODE", groupCode)
      .first();
    const groupData = group.attributes;
    const allPmiData = [
      { field: "Nom", value: groupData.CLCTNOM },
      { field: "CLCTABRE", value: groupData.CLCTABRE },
      { field: "Code postal", value: groupData.CLCTCP },
      { field: "Ville", value: groupData.CLCTVILLE },
      { field: "Pays", value: groupData.CLCTPAYS },
      { field: "Code pays", value: groupData.CLCTCPAYS },
      {
        field: "CLCJCRE",
        value: moment(groupData.CLCJCRE).format("DD/MM/YYYY"),
      },
      {
        field: "CLCJMOU",
        value: moment(groupData.CLCJCRE).format("DD/MM/YYYY"),
      },
      { field: "Livraison - Nom", value: groupData.CLCTNOMLIV },
      { field: "Livraison - Rue (1)", value: groupData.CLCTRUE1LI },
      { field: "Livraison - Rue (2)", value: groupData.CLCTRUE1LI },
      { field: "Livraison - Code postal", value: groupData.CLCTCPLIV },
      { field: "Livraison - Ville", value: groupData.CLCTVILLIV },
      { field: "Livraison - Pays", value: groupData.CLCTPAYSLI },
      { field: "Livraison - Code pays", value: groupData.CLCTCPAYLI },
      { field: "Livraison - Transporteur", value: groupData.CLCTTRANPO },
    ];

    const pmiData = [];
    allPmiData.forEach((data) => {
      if (data.value !== "" && data.value !== undefined && data.value !== ".")
        pmiData.push(data);
    });

    this.setState({
      groupData,
      pmiData,
    });
  };

  onSubmit = async (values) => {
    const { readOnly } = this.props;
    if (!!readOnly) {
      return null;
    }
    const { groupCode, onHandleModalClose } = this.props;
    const group = await new Parse.Query("Groups")
      .equalTo("CLKTCODE", groupCode)
      .first();
    Object.entries(values).forEach(([key, value]) => {
      const valueToSave =
        key === "RFAPercentage" || key === "RFAFix" ? parseInt(value) : value;
      group.set(key, valueToSave);
    });
    await group.save();
    onHandleModalClose("validate");
  };

  render() {
    const { groupData, pmiData } = this.state;
    const { classes, readOnly } = this.props;

    return (
      <div className={classes.formContainer}>
        <Typography variant="h6" className={classes.tableTitleWrapper}>
          Valeurs éditables
        </Typography>
        <Form
          onSubmit={this.onSubmit}
          initialValues={groupData}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form id="groupForm" onSubmit={handleSubmit}>
              <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    <Field
                      name="RFAPercentage"
                      component={TextField}
                      label="RFA (%)"
                      placeholder="RFA (%)"
                      type="number"
                      fullWidth
                      disabled={readOnly}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    <Field
                      name="RFAFix"
                      component={TextField}
                      label="RFA (fixe)"
                      placeholder="RFA (fixe)"
                      type="number"
                      fullWidth
                      disabled={readOnly}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">€</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </Grid>
              </Grid>

              {!readOnly && (
                <Grid container spacing={0} className={classes.gridContainer}>
                  <Grid item xs={12}>
                    <div className={classes.buttonWrapper}>
                      <Button
                        type="submit"
                        disabled={submitting || pristine}
                        variant="contained"
                        color="primary"
                      >
                        enregistrer
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              )}
            </form>
          )}
        />
        <Grid2 rows={pmiData} columns={columns}>
          <div className={classes.tableTopWrapper}>
            <Typography variant="h6" className={classes.tableTitleWrapper}>
              Valeurs présentes dans PMI
            </Typography>
          </div>

          <FilteringState defaultFilters={[]} />
          <IntegratedFiltering />
          <SortingState />
          <IntegratedSorting />
          <Table />
          <TableHeaderRow
            showSortingControls
            messages={{ sortingHint: "Trier" }}
          />
          <TableFilterRow messages={{ filterPlaceholder: "Filtrer" }} />
        </Grid2>
      </div>
    );
  }
}

export default withStyles(styles)(HandleGroupForm);
