import React, { PureComponent } from "react";
import Parse from "parse";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import { withStyles, Grid, IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

const styles = (theme) => ({
  textField: {
    width: 400,
  },
  fieldContainer: {
    display: "flex",
    flex: 1,
  },
  iconButton: {
    margin: "8px 0 0 8px",
  },
});

class LocalProductionTimeForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ARKTCODART: this.props.ARKTCODART,
      ARKTCOMART: this.props.ARKTCOMART,
    };
  }

  onSubmit = async (values) => {
    const { ARKTCODART, ARKTCOMART } = this.state;
    const { onHandleModalClose, onSubmitUpdate } = this.props;

    const localProductionTime =
      values.days !== undefined &&
      values.quantity !== undefined &&
      values.days !== null &&
      values.quantity !== null
        ? {
            days: parseInt(values.days),
            quantity: parseInt(values.quantity),
          }
        : undefined;
    const newArticle =
      values.days !== undefined &&
      values.quantity !== undefined &&
      values.days !== null &&
      values.quantity !== null
        ? {
            ARKTCOMART,
            quantity: 0,
            scheduledResupplies: [],
            localProductionTime,
          }
        : {
            ARKTCOMART,
            quantity: 0,
            scheduledResupplies: [],
          };

    const query = await new Parse.Query("Products")
      .equalTo("ARKTCODART", ARKTCODART)
      .first();

    let resupplies = query.get("resupplies");
    if (resupplies !== undefined) {
      let articleIsAlreadyInResupplies = false;
      resupplies.forEach((article) => {
        if (article.ARKTCOMART === ARKTCOMART) {
          articleIsAlreadyInResupplies = true;
          if (
            values.days !== undefined &&
            values.quantity !== undefined &&
            values.days !== null &&
            values.quantity !== null
          ) {
            article.localProductionTime = localProductionTime;
          } else {
            delete article.localProductionTime;
          }
        }
      });
      // all articles are not always in resupplies
      if (!articleIsAlreadyInResupplies) {
        resupplies.push(newArticle);
      }
    } else {
      // resupplies doesn't exist, let's build it
      resupplies = [newArticle];
    }
    query.set("resupplies", resupplies);

    try {
      await query.save();
    } catch (error) {
      console.log(error.message);
    }
    onHandleModalClose("localProductionTimeUpdated");
    onSubmitUpdate(
      "localProductionTime",
      ARKTCODART,
      ARKTCOMART,
      localProductionTime,
    );
  };
  resetForm = () => {};

  render() {
    const { classes, localProductionTime } = this.props;

    /**********************************************************/
    /*********************** validators ***********************/
    /**********************************************************/

    const allValuesOrNoneRequired = (value, allValues) =>
      (!allValues.quantity && allValues.days) ||
      (allValues.quantity && !allValues.days)
        ? "Il faut soit préciser tous les champs soit aucun"
        : undefined;

    return (
      <Form
        onSubmit={this.onSubmit}
        initialValues={localProductionTime}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
          reset,
        }) => (
          <form id="editForm" onSubmit={handleSubmit}>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={4}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="quantity"
                    label="Quantité"
                    placeholder="Quantité"
                    type="number"
                    component={TextField}
                    className={classes.textField}
                    validate={allValuesOrNoneRequired}
                  />
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="days"
                    label="Nombre de jours"
                    placeholder="Nombre de jours"
                    type="number"
                    component={TextField}
                    className={classes.textField}
                    validate={allValuesOrNoneRequired}
                  />
                </div>
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  aria-label="Delete"
                  onClick={() => {
                    form.change("quantity", undefined);
                    form.change("days", undefined);
                  }}
                  className={classes.iconButton}
                >
                  <ClearIcon />
                </IconButton>
              </Grid>
            </Grid>
          </form>
        )}
      />
    );
  }
}
export default withStyles(styles)(LocalProductionTimeForm);
