import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import { withStyles, CssBaseline, Paper, Typography } from "@material-ui/core";
import LoginForm from "../../containers/form/LoginForm";
import FirstLoginForm from "../../containers/form/FirstLoginForm";
import ResetLoginForm from "../../containers/form/ResetLoginForm";

const styles = () => ({
  root: {
    display: "flex",
  },
  loginFormWrapper: {
    width: "400px",
    padding: "2rem",
    position: "fixed",
    top: "50%",
    left: "50%",
    /* bring your own prefixes */
    transform: "translate(-50%, -50%)",
  },
  linkToFormWrapper: {
    margin: "0 0 1rem",
    display: "flex",
    flex: 1,
  },
  linkToForm: {
    position: "relative",
    margin: "0 1.5rem 0 0",
    cursor: "pointer",
    "&:after": {
      position: "absolute",
      content: '""',
      width: "100%",
      height: "2px",
      left: "0",
      bottom: "-10px",
      backgroundColor: "rgba(222, 222, 222, 0.7)",
      transition: "all 0.3s ease",
    },
    "&:hover:after": {
      backgroundColor: "#0092FE",
    },
  },
  linkToFormSelected: {
    fontWeight: 700,
    "&:after": {
      backgroundColor: "#0092FE",
    },
  },
});

class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeForm: "login",
    };
  }

  showForm = (formName) => {
    this.setState({ activeForm: formName });
  };

  render() {
    const { activeForm } = this.state;
    const { classes, location } = this.props;
    const isPasswordReset = location.query.reset;
    let classNameForLogin =
      activeForm === "login"
        ? classNames(classes.linkToForm, classes.linkToFormSelected)
        : classes.linkToForm;
    let classNameForFirstCon =
      activeForm === "firstCo"
        ? classNames(classes.linkToForm, classes.linkToFormSelected)
        : classes.linkToForm;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <title>Login Gtech</title>
          <link rel="canonical" href="https://www.gtech-bo.com/" />
        </Helmet>
        <div className={classes.root}>
          <CssBaseline />
          <Paper className={classes.loginFormWrapper}>
            <div className={classes.linkToFormWrapper}>
              <Typography
                variant="body2"
                className={classNameForLogin}
                onClick={() => this.showForm("login")}
              >
                Login
              </Typography>
              <Typography
                variant="body2"
                className={classNameForFirstCon}
                onClick={() => this.showForm("firstCo")}
              >
                1ère connexion ?
              </Typography>
            </div>
            {activeForm === "login" && (
              <LoginForm
                isPasswordReset={isPasswordReset}
                onForgottenPassword={() => this.showForm("lostPass")}
              />
            )}
            {activeForm === "firstCo" && (
              <FirstLoginForm
                onForgottenPassword={() => this.showForm("lostPass")}
              />
            )}
            {activeForm === "lostPass" && (
              <ResetLoginForm onFirstLogin={() => this.showForm("firstCo")} />
            )}
          </Paper>
        </div>
      </>
    );
  }
}
export default withStyles(styles)(Login);
