import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Page from "../Page";
import Parse from "parse";
import useDebounce from "../../../hooks";
import {
  makeStyles,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Snackbar,
  Toolbar,
  IconButton,
  AppBar,
} from "@material-ui/core";
import { getLists } from "../../../reducers/lists";
import { getCurrentUser } from "../../../reducers/currentUser";
import EditIcon from "@material-ui/icons/Edit";
import BlockIcon from "@material-ui/icons/Block";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import InfoIcon from "@material-ui/icons/Info";
import DataGridView from "../../../components/reactgrid/DataGridView";
import {
  setNumberColumns,
  filtersQuery,
  listsToColumnsTranslations,
  translateColumn,
} from "../../../utils2";
import ProductDetail from "../../../components/product/ProductDetail";
import ProductIcons from "../../../components/product/ProductsIcons";
import { Form, Field } from "react-final-form";
import Switch from "../../../components/form/Switch";
import { generateSuppliesData } from "../../../utils/generateSuppliesData/generateSuppliesData";
import { fixStockJ0 } from "./_utils/fixStockJ0";

const useStyles = makeStyles(() => ({
  tableTitleWrapper: {
    display: "flex",
    alignItems: "center",
    minHeight: "64px",
    padding: "0 8px 0 24px",
  },
  subtitle1Wrapper: {
    padding: "12px 0 24px",
  },
  toolbar: {
    backgroundColor: "#46494c", //'#02cfa3'
  },
  fieldContainer: {
    display: "flex",
  },
  switchText: {
    lineHeight: "38px",
  },
}));

const numberRedNegativeColumns = ["quantity"];
const pageSizes = [15, 25, 50, 100, 150];

const Products = () => {
  const classes = useStyles();
  const currentUser = useSelector(getCurrentUser);
  const userRole =
    currentUser !== undefined ? currentUser.get("role") : undefined;
  let columns = [
    { name: "images", title: " ", type: "string" },
    { name: "buttons", title: " " },
    { name: "ARKTCODART", title: "Référence", type: "string" },
    { name: "ARCTLIB01", title: "Modèle", type: "string" },
    { name: "quantity", title: "Dispo à J0", type: "numeric" },
    { name: "catalog", title: "Catalogue" },
    { name: "ARCNTARIF9", title: "Prix public TTC (€)", type: "numeric" },
    { name: "ARCNTARIF1", title: "Prix catalogue (€)", type: "numeric" },
    { name: "ARCNTARIF6", title: "Prix de revient (€)", type: "numeric" },
    { name: "margin", title: "Marge (%)", type: "numeric" },
    { name: "hasSpecificDiscount", title: "Avec Remise", type: "boolean" },
  ];
  if (userRole === "commercial") {
    columns.splice(8, 3);
  }
  const columnExtensions = [
    { columnName: "images", width: 80, filteringEnabled: false },
    { columnName: "buttons", width: 100, filteringEnabled: false },
    { columnName: "ARKTCODART", width: 120 },
    { columnName: "ARCTLIB01", width: 300 },
    { columnName: "quantity", width: 100 },
    { columnName: "ARCNTARIF9", width: 100 },
    { columnName: "ARCNTARIF1", width: 100 },
    { columnName: "ARCNTARIF6", width: 100 },
    { columnName: "margin", width: 100 },
    { columnName: "hasSpecificDiscount", width: 80 },
  ];
  const [stateProducts, setStateProducts] = useState([]);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarText, setSnackBarText] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogForQuestionOpen, setDialogForQuestionOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState(null);
  const [dialogText, setDialogText] = useState(null);
  const [dialogYesBtnText, setDialogYesBtnText] = useState(null);
  const [dialogNoBtnText, setDialogNoBtnText] = useState(null);
  const [productCodeForDialog, setProductCodeForDialog] = useState(null);
  const [filters, setFilters] = useState([]);
  const debouncedFilters = useDebounce(filters, 300);
  const [sorting, setSorting] = useState([
    { columnName: "ARKTCODART", direction: "asc" },
  ]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showDeprecated, setShowDeprecated] = useState(false);
  const [numberColumns, setStateNumberColumns] = useState([]);
  const lists = useSelector(getLists);
  const listsNameToTranslate = ["catalog", "brand", "productType"];
  const columnsTranslations = [];
  listsToColumnsTranslations(listsNameToTranslate, lists, columnsTranslations);

  /********************************************************/
  /************ content info for paging panel**************/
  /********************************************************/
  const contentInfo = ({ from, to, count }) => {
    return `${from} ${from < to ? ` à ${to}` : ""} sur ${count} produit(s)`;
  };

  /********************************************************/
  /*********************** Navigation *********************/
  /********************************************************/
  const changeFilters = (filters) => {
    setLoading(true);
    setFilters(filters);
  };

  const changeSorting = (sorting) => {
    setLoading(true);
    setSorting(sorting);
  };

  const changeCurrentPage = (currentPage) => {
    setLoading(true);
    setCurrentPage(currentPage);
  };

  const changePageSize = (pageSize) => setPageSize(pageSize);

  /********************************************************/
  /*********************** Table data *********************/
  /********************************************************/
  const setProductsToState = async () => {
    const query = new Parse.Query("Products").select(
      "ARKTCODART",
      "ARCTLIB01",
      "images",
      "isOnline",
      "isDeprecated",
      "articles",
      "supplies",
      "resupplies",
      "catalog",
      "ARCNTARIF1",
      "ARCNTARIF6",
      "ARCNTARIF9",
      "margin",
      "hasSpecificDiscount",
      "webSecurityStock"
    );
    if (!showDeprecated) {
      query.notEqualTo("isDeprecated", true);
    }
    filtersQuery(query, columnsTranslations, { columns, filters, sorting });
    const totalCount = await query.count();

    // skipping according to currentPage and pageSize
    query.skip(pageSize * currentPage);
    query.limit(pageSize);

    const rawProducts = await query.find();

    const products = rawProducts.map((product) => {
      const row = {};
      columns.forEach((column) => {
        let value = product.get(column.name);
        // formatting data from db
        if (column.name === "images" && value !== undefined) {
          value.forEach((image) => {
            if (image.articleCode === "generic") {
              // sometimes we can get image value but with no image. Maybe in a case where we put an image and delete it...
              value =
                image.miniature !== undefined ? (
                  <img
                    src={image.miniature.secure_url}
                    width="28"
                    height="28"
                    alt=""
                  />
                ) : (
                  <span />
                );
            }
          });
        }
        row[column.name] = value;
      });
      row.quantity =
        fixStockJ0(
          {
            articles: product.get("articles"),
            webSecurityStock: product.get("webSecurityStock"),
          },
          generateSuppliesData({
            supplies: product.get("supplies"),
            resupplies: product.get("resupplies"),
          })
        ) || 0;
      row.isOnline = product.get("isOnline");
      row.hasSpecificDiscount = product.get("hasSpecificDiscount")
        ? "oui"
        : "non";
      row.isDeprecated = product.get("isDeprecated");
      // in next line userRole should never be client, but this is to be consistent with others <ProductIcons> calls
      row.buttons = [
        <ProductIcons
          key={product.get("ARKTCODART")}
          productId={product.get("ARKTCODART")}
          showCard
          showChart={userRole !== "client" && userRole !== "commercial"}
          chartWithNegativeValues
        />,
      ];

      // translating from columnsTranslations
      listsNameToTranslate.forEach((listName) => {
        row[listName] = translateColumn(
          product.get(listName),
          columnsTranslations.find(
            (columnTranslation) => columnTranslation.columnName === listName
          )
        );
      });
      return row;
    });

    setStateProducts(products);
    setTotalCount(totalCount);
    setLoading(false);
  };

  /********************************************************/
  /********************* Inline command *******************/
  /********************************************************/

  const switchProductDeprecatedProp = async (productCode) => {
    const query = await new Parse.Query("Products")
      .equalTo("ARKTCODART", productCode)
      .first();
    query.set("isDeprecated", !query.get("isDeprecated"));
    try {
      await query.save();
      handleModalClose();
      handleSnackBarShow(productCode + " est maintenant désactivé");
    } catch (error) {
      console.log(error.message);
    }
  };

  const switchProductOnlineProp = async (productCode) => {
    const query = await new Parse.Query("Products")
      .equalTo("ARKTCODART", productCode)
      .first();
    const onlineStatus = query.get("isOnline");
    query.set("isOnline", !onlineStatus);
    try {
      await query.save();
      return !onlineStatus;
    } catch (error) {
      console.log(error.message);
    }
  };

  const toggleShowDeprecatedState = async () => {
    setShowDeprecated(!showDeprecated);
    handleSnackBarShow(
      showDeprecated
        ? "produits désactivés masqués"
        : "produits désactivés visibles"
    );
  };

  /********************************************************/
  /*********************** Dialog *************************/
  /********************************************************/

  // when dialog is called
  const handleClickOpen = async (productCode, operation) => {
    switch (operation) {
      case "switchOnlineStatus":
        const onlineStatus = await switchProductOnlineProp(productCode);
        const onlineStatusText = onlineStatus ? " en ligne" : " hors ligne";
        handleSnackBarShow(productCode + " est maintenant " + onlineStatusText);
        break;
      case "switchToDeprecatedStatus":
        setDialogTitle("Désactication du produit " + productCode);
        setDialogYesBtnText("confirmer");
        setDialogNoBtnText("annuler");
        setProductCodeForDialog(productCode);
        setDialogText(
          <span>
            Souhaitez-vous vraiment désactiver le produit {productCode}? <br />
            Celui-ci ne sera alors plus visible sur cette plateforme. Il sera
            considéré comme hors ligne et n'apparaitra plus dans la section
            Réapprovisionnements.
          </span>
        );
        setDialogForQuestionOpen(true);
        break;
      case "switchToNotDeprecatedStatus":
        await switchProductDeprecatedProp(productCode);
        handleSnackBarShow(productCode + " est maintenant réactivé");
        break;
      case "modifyProduct":
        setDialogTitle("Produit n° " + productCode);
        setDialogYesBtnText("");
        setDialogNoBtnText("fermer");
        setProductCodeForDialog(productCode);
        setDialogOpen(true);
        break;
      default:
    }
  };

  /********************************************************/
  /************************ SnackBar **********************/
  /********************************************************/

  const handleSnackBarShow = (textValue) => {
    setSnackBarOpen(true);
    setSnackBarText(textValue);
  };

  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  // when closing dialog box
  const handleModalClose = () => {
    setDialogOpen(false);
    setDialogForQuestionOpen(false);
  };

  const displayIcon = (action, row) => {
    if (row === undefined) {
      return false;
    } else {
      if (action === "switchToOnlineStatus") {
        return userRole !== "commercial" && !row.isOnline;
      } else if (action === "switchToOfflineStatus") {
        return userRole !== "commercial" && row.isOnline;
      } else if (action === "switchToDeprecatedStatus") {
        return userRole !== "commercial" && !row.isDeprecated;
      } else if (action === "switchToNotDeprecatedStatus") {
        return userRole !== "commercial" && row.isDeprecated;
      } else if (action === "editProduct") {
        return userRole !== "commercial";
      } else if (action === "viewProduct") {
        return userRole === "commercial";
      } else {
        return true;
      }
    }
  };

  useEffect(() => {
    setNumberColumns(columns, setStateNumberColumns);
  }, []);

  // when sorting changes
  useEffect(() => {
    if (!snackBarOpen) {
      setProductsToState();
    } // eslint-disable-next-line
  }, [sorting, pageSize, currentPage]);

  // when inline command is done
  useEffect(() => {
    setProductsToState(); // eslint-disable-next-line
  }, [snackBarOpen]);

  // when filters change
  useEffect(() => {
    if (debouncedFilters) {
      setProductsToState();
    } // eslint-disable-next-line
  }, [debouncedFilters]);

  const actions = [
    {
      icon: <RadioButtonUncheckedIcon />,
      tooltip: "activer le produit",
      action: (rowData) =>
        handleClickOpen(rowData.row.ARKTCODART, "switchToNotDeprecatedStatus"),
      displayIcon: (rowData) =>
        displayIcon("switchToNotDeprecatedStatus", rowData.row),
    },
    {
      icon: <BlockIcon />,
      tooltip: "désactiver le produit",
      action: (rowData) =>
        handleClickOpen(rowData.row.ARKTCODART, "switchToDeprecatedStatus"),
      displayIcon: (rowData) =>
        displayIcon("switchToDeprecatedStatus", rowData.row),
    },
    {
      icon: <CheckIcon style={{ color: "green" }} />,
      tooltip: "mettre le produit hors ligne",
      action: (rowData) =>
        handleClickOpen(rowData.row.ARKTCODART, "switchOnlineStatus"),
      displayIcon: (rowData) =>
        displayIcon("switchToOfflineStatus", rowData.row),
    },
    {
      icon: <CloseIcon style={{ color: "red" }} />,
      tooltip: "mettre le produit en ligne",
      action: (rowData) =>
        handleClickOpen(rowData.row.ARKTCODART, "switchOnlineStatus"),
      displayIcon: (rowData) =>
        displayIcon("switchToOnlineStatus", rowData.row),
    },
    {
      icon: <EditIcon />,
      tooltip: "éditer le produit",
      action: (rowData) =>
        handleClickOpen(rowData.row.ARKTCODART, "modifyProduct"),
      displayIcon: (rowData) => displayIcon("editProduct", rowData.row),
    },
    {
      icon: <InfoIcon />,
      tooltip: "voir le produit",
      action: (rowData) =>
        handleClickOpen(rowData.row.ARKTCODART, "modifyProduct"),
      displayIcon: (rowData) => displayIcon("viewProduct", rowData.row),
    },
  ];

  if (
    ((userRole === "master" || userRole === "superuser") &&
      !currentUser.get("productAble")) ||
    userRole === "client" ||
    userRole === "group"
  ) {
    return null;
  }

  return (
    <Page toolBarTitle="Gestion des produits" pageTitle="Gestion des produits">
      <div className={classes.subtitle1Wrapper}>
        <Typography variant="subtitle1" paragraph>
          Complétez et modifiez les informations Produits depuis cette page.
        </Typography>
      </div>
      <Paper>
        <DataGridView
          title="Liste des produits"
          rowsValues={stateProducts}
          columnHeaders={columns}
          currentPage={currentPage}
          onCurrentPageChange={changeCurrentPage}
          pageSize={pageSize}
          pageSizes={pageSizes}
          onPageSizeChange={changePageSize}
          filters={filters}
          onFiltersChange={changeFilters}
          sorting={sorting}
          onSortingChange={changeSorting}
          totalCount={totalCount}
          loading={loading}
          numberColumns={numberColumns}
          numberRedNegativeColumns={numberRedNegativeColumns}
          rowsPerPage="Nb produits par page:"
          contentInfoSupplier={contentInfo}
          columnExtensions={columnExtensions}
          withPagination={true}
          hasActions={true}
          actions={actions}
          actionWidth={160}
          withCustomAddButton
        >
          <Form
            onSubmit={() => {}}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form id="clientInfoForm" onSubmit={handleSubmit}>
                <div className={classes.fieldContainer}>
                  <Typography variant="body2" className={classes.switchText}>
                    Afficher les produits désactivés
                  </Typography>
                  <Field
                    name="showPrice"
                    component={Switch}
                    type="checkbox"
                    onChange={() => toggleShowDeprecatedState()}
                  />
                </div>
              </form>
            )}
          />
        </DataGridView>
      </Paper>

      <Dialog
        fullScreen
        open={dialogOpen}
        onClose={() => handleModalClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              color="inherit"
              onClick={() => handleModalClose()}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              Produit n° {productCodeForDialog}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <ProductDetail
            productCode={productCodeForDialog}
            readOnly={userRole !== "master" && userRole !== "superuser"}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleModalClose()} color="primary" autoFocus>
            {dialogNoBtnText}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogForQuestionOpen}
        onClose={() => handleModalClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => switchProductDeprecatedProp(productCodeForDialog)}
            color="primary"
          >
            {dialogYesBtnText}
          </Button>
          <Button onClick={() => handleModalClose()} color="primary" autoFocus>
            {dialogNoBtnText}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
        open={snackBarOpen}
        onClose={handleSnackBarClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackBarText}</span>}
      />
    </Page>
  );
};
export default Products;
