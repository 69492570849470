import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { Form, Field, FormSpy } from "react-final-form";
import AutoSave from "../../components/form/AutoSave";
import AutoCompleteVirtualized from "../../components/form/AutoCompleteVirtualized";
import { Checkbox, Select, TextField } from "final-form-material-ui";
import { makeStyles, Typography, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Parse from "parse";
import { getLists } from "../../reducers/lists";
import { optionsCreator, getOptions } from "../../utils2";

const useStyles = makeStyles(() => ({
  textField: {
    width: 600,
    marginTop: 25,
  },
  selectField: {
    width: 600,
  },
  fieldContainer: {
    display: "flex",
    flex: 1,
  },
  checkboxContainer: {
    marginTop: 16,
  },
  fieldText: {
    lineHeight: "48px",
  },
  selectContainer: {
    marginTop: 25,
  },
}));

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const HandleUserForm = (props) => {
  const { operation, userId, onHandleModalClose, onSnackBarOpen } = props;
  const classes = useStyles();
  const listsOptions = useSelector(getLists);
  const [isClientOrGroup, setIsClientOrGroup] = useState(false);
  //const [isClient, setIsClient] = useState(false);
  const [isCommercial, setIsCommercial] = useState(false);
  const [userData, setUserData] = useState(null);
  const [alertSnackBarOpen, setAlertSnackBarOpen] = useState(false);
  const catalogOptions = listsOptions.find(
    (listOptions) => listOptions.listName === "catalog"
  ).options;
  const catalogFields = catalogOptions.map(
    (catalogOption) => catalogOption.value + "Allowed"
  );
  const fieldsPerRole = {
    client: [
      "role",
      "contact",
      "client",
      "group",
      "username",
      "enabled",
      ...catalogFields,
    ],
    commercial: [
      "role",
      "commercial",
      "gender",
      "firstname",
      "lastname",
      "username",
      "enabled",
    ],
    group: [
      "role",
      "gender",
      "firstname",
      "lastname",
      "username",
      "group",
      "enabled",
      ...catalogFields,
    ],
    master: [
      "role",
      "gender",
      "firstname",
      "lastname",
      "username",
      "orderAble",
      "productAble",
      "resupplyAble",
      "availabilityAble",
      "sparePartAble",
      "discountAble",
      "clientAble",
      "userAble",
      "listAble",
      "supportAble",
      "contactAble",
      "enabled",
    ],
    superuser: [
      "role",
      "gender",
      "firstname",
      "lastname",
      "username",
      "orderAble",
      "productAble",
      "resupplyAble",
      "availabilityAble",
      "sparePartAble",
      "discountAble",
      "clientAble",
      "userAble",
      "listAble",
      "supportAble",
      "contactAble",
      "enabled",
    ],
  };

  // getting user from Mongo
  const getUserData = async (userId) => {
    let userData = {};
    if (operation === "addUser") {
      userData = { role: "client" };
    } else {
      const user = await new Parse.Query("User").get(userId);
      userData = { ...user.attributes };
      // pointer to string
      if (userData.contact !== undefined) {
        userData.contact = userData.contact.id;
      }
    }
    if (userData.role === "client") {
      // catalogFields are checked by default
      catalogFields.forEach((catalogField) => {
        if (userData[catalogField] === undefined) {
          userData[catalogField] = true;
        }
      });
      //setIsClient(true);
    }
    setIsClientOrGroup(userData.role === "client" || userData.role === "group");
    setIsCommercial(userData.role === "commercial");
    setUserData(userData);
  };

  const onSubmit = async (values) => {
    if (operation === "addUser") {
      const query = await Parse.Cloud.run("createUser", values);
      if (query === "userAdded") {
        onHandleModalClose("userAdded");
      } else {
        if (query === "missingEmailForContact") {
          // email needed, showing alert
          setAlertSnackBarOpen(true);
        } else {
          onSnackBarOpen(query);
        }
        console.log(query);
      }
    }

    if (operation === "modifyUser") {
      values.id = userId;
      const query = await Parse.Cloud.run("modifyUser", values);
      if (query === "userUpdated") {
        onHandleModalClose("userUpdated");
      } else {
        console.log(query);
        onSnackBarOpen(query);
      }
    }
  };

  // used by FormSpy
  const _updateState = async (formValues) => {
    let values = { ...formValues };
    if (values.role === "group") {
      delete values.client;
    } else if (values.role === "client") {
      delete values.group;
    }
    // if changing role we need to clean values
    const newValues = {};
    const role = values.role;
    Object.entries(values).forEach(([key, value]) => {
      if (fieldsPerRole[role].includes(key)) {
        newValues[key] = value;
      }
    });
    //setIsClient(newValues.role === 'client');
    setIsClientOrGroup(
      newValues.role === "client" || newValues.role === "group"
    );
    setIsCommercial(newValues.role === "commercial");
    setUserData(newValues);
  };

  useEffect(() => {
    getUserData(userId);
    // eslint-disable-next-line
  }, [operation]);

  if (userData === null) {
    return null;
  }

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={userData}
        render={({ handleSubmit, values }) => (
          <form id="editForm" onSubmit={handleSubmit}>
            <FormSpy
              subscription={{ values: true }}
              component={AutoSave}
              onFormChange={_updateState}
            />
            <div
              className={classNames(
                classes.fieldContainer,
                classes.selectContainer
              )}
            >
              <Field
                name="role"
                label="Rôle"
                component={Select}
                className={classes.selectField}
                required
              >
                {optionsCreator(listsOptions, "role")}
              </Field>
            </div>
            {isClientOrGroup && (
              <>
                <div
                  className={classNames(
                    classes.fieldContainer,
                    classes.selectContainer
                  )}
                >
                  <AutoCompleteVirtualized
                    name="contact"
                    label="Contact"
                    options={getOptions(listsOptions, "contacts")}
                    required
                    initValue={listsOptions
                      .find(
                        (listOptions) => listOptions.listName === "contacts"
                      )
                      .options.find(
                        (option) => option.value === userData.contact
                      )}
                  />
                </div>
                {userData.role === "client" && (
                  <div
                    className={classNames(
                      classes.fieldContainer,
                      classes.selectContainer
                    )}
                  >
                    <AutoCompleteVirtualized
                      name="client"
                      label="Magasin"
                      options={getOptions(listsOptions, "clients")}
                      initValue={listsOptions
                        .find(
                          (listOptions) => listOptions.listName === "clients"
                        )
                        .options.find(
                          (option) => option.value === userData.client
                        )}
                      required
                    />
                  </div>
                )}
                {userData.role === "group" && (
                  <div
                    className={classNames(
                      classes.fieldContainer,
                      classes.selectContainer
                    )}
                  >
                    <AutoCompleteVirtualized
                      name="group"
                      label="Groupement"
                      options={getOptions(listsOptions, "groups")}
                      initValue={
                        listsOptions
                          .find(
                            (listOptions) => listOptions.listName === "groups"
                          )
                          .options.find(
                            (option) => option.value === userData.group
                          ) !== undefined
                          ? listsOptions
                              .find(
                                (listOptions) =>
                                  listOptions.listName === "groups"
                              )
                              .options.find(
                                (option) => option.value === userData.group
                              )
                          : ""
                      }
                      required
                    />
                  </div>
                )}
              </>
            )}

            {isCommercial && (
              <div
                className={classNames(
                  classes.fieldContainer,
                  classes.selectContainer
                )}
              >
                <Field
                  name="commercial"
                  label="Représentant"
                  component={Select}
                  className={classes.selectField}
                  required
                >
                  {optionsCreator(listsOptions, "commercials")}
                </Field>
              </div>
            )}

            {!isClientOrGroup && (
              <>
                <div
                  className={classNames(
                    classes.fieldContainer,
                    classes.selectContainer
                  )}
                >
                  <Field
                    name="gender"
                    label="Genre"
                    component={Select}
                    className={classes.selectField}
                    disabled={isClientOrGroup}
                  >
                    {optionsCreator(listsOptions, "particle")}
                  </Field>
                </div>
                <div className={classes.fieldContainer}>
                  <Field
                    name="firstname"
                    component={TextField}
                    type="text"
                    label="Prénom"
                    className={classes.textField}
                    required
                    disabled={isClientOrGroup}
                  />
                </div>
                <div className={classes.fieldContainer}>
                  <Field
                    name="lastname"
                    component={TextField}
                    type="text"
                    label="Nom"
                    className={classes.textField}
                    disabled={isClientOrGroup}
                  />
                </div>

                <div className={classes.fieldContainer}>
                  <Field
                    name="username"
                    component={TextField}
                    type="email"
                    label="Adresse email"
                    className={classes.textField}
                    required
                    disabled={operation === "modifyUser" || isClientOrGroup}
                  />
                </div>
              </>
            )}
            {isClientOrGroup && (
              <div className={classes.checkboxContainer}>
                <Typography variant="caption" className={classes.fieldText}>
                  Accès aux catalogues
                </Typography>
                {catalogOptions.map((cataOptions) => (
                  <div
                    className={classes.fieldContainer}
                    key={cataOptions.value}
                  >
                    <Field
                      name={cataOptions.value + "Allowed"}
                      component={Checkbox}
                      type="checkbox"
                    />
                    <Typography variant="body1" className={classes.fieldText}>
                      {cataOptions.text}
                    </Typography>
                  </div>
                ))}
              </div>
            )}

            {!isClientOrGroup && !isCommercial && (
              <div className={classes.checkboxContainer}>
                <Typography variant="caption" className={classes.fieldText}>
                  Privilèges
                </Typography>

                <div className={classes.fieldContainer}>
                  <Field
                    name="orderAble"
                    component={Checkbox}
                    type="checkbox"
                  />
                  <Typography variant="body1" className={classes.fieldText}>
                    Passer des commandes
                  </Typography>
                </div>
                <div className={classes.fieldContainer}>
                  <Field
                    name="productAble"
                    component={Checkbox}
                    type="checkbox"
                  />
                  <Typography variant="body1" className={classes.fieldText}>
                    Gestion des produits
                  </Typography>
                </div>
                <div className={classes.fieldContainer}>
                  <Field
                    name="resupplyAble"
                    component={Checkbox}
                    type="checkbox"
                  />
                  <Typography variant="body1" className={classes.fieldText}>
                    Gestion des réapprovisionnements
                  </Typography>
                </div>
                <div className={classes.fieldContainer}>
                  <Field
                    name="availabilityAble"
                    component={Checkbox}
                    type="checkbox"
                  />
                  <Typography variant="body1" className={classes.fieldText}>
                    Gestion des disponibilités
                  </Typography>
                </div>
                <div className={classes.fieldContainer}>
                  <Field
                    name="sparePartAble"
                    component={Checkbox}
                    type="checkbox"
                  />
                  <Typography variant="body1" className={classes.fieldText}>
                    Gestion des pièces détachées
                  </Typography>
                </div>
                <div className={classes.fieldContainer}>
                  <Field
                    name="discountAble"
                    component={Checkbox}
                    type="checkbox"
                  />
                  <Typography variant="body1" className={classes.fieldText}>
                    Gestion des remises
                  </Typography>
                </div>
                <div className={classes.fieldContainer}>
                  <Field
                    name="clientAble"
                    component={Checkbox}
                    type="checkbox"
                  />
                  <Typography variant="body1" className={classes.fieldText}>
                    Gestion des clients
                  </Typography>
                </div>
                <div className={classes.fieldContainer}>
                  <Field name="userAble" component={Checkbox} type="checkbox" />
                  <Typography variant="body1" className={classes.fieldText}>
                    Gestion des utilisateurs
                  </Typography>
                </div>
                <div className={classes.fieldContainer}>
                  <Field name="listAble" component={Checkbox} type="checkbox" />
                  <Typography variant="body1" className={classes.fieldText}>
                    Gestion des listes
                  </Typography>
                </div>
                <div className={classes.fieldContainer}>
                  <Field
                    name="supportAble"
                    component={Checkbox}
                    type="checkbox"
                  />
                  <Typography variant="body1" className={classes.fieldText}>
                    Gestion du SAV
                  </Typography>
                </div>
                <div className={classes.fieldContainer}>
                  <Field
                    name="contactAble"
                    component={Checkbox}
                    type="checkbox"
                  />
                  <Typography variant="body1" className={classes.fieldText}>
                    Gestion du Contact
                  </Typography>
                </div>
              </div>
            )}

            <div className={classes.checkboxContainer}>
              <Typography variant="caption" className={classes.fieldText}>
                Statut
              </Typography>

              <div className={classes.fieldContainer}>
                <Field name="enabled" component={Checkbox} type="checkbox" />
                <Typography variant="body1" className={classes.fieldText}>
                  Activé
                </Typography>
              </div>
            </div>
          </form>
        )}
      />
      <Snackbar
        open={alertSnackBarOpen}
        autoHideDuration={10000}
        onClose={() => {
          setAlertSnackBarOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setAlertSnackBarOpen(false);
          }}
          severity="warning"
        >
          Vous ne pouvez pas choisir un contact n'ayant pas d'adresse email.
          Veuillez éditer le contact et renseigner une adresse email.
        </Alert>
      </Snackbar>
    </>
  );
};

export default HandleUserForm;
