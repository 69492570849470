import React, { PureComponent } from "react";
import { Form, Field } from "react-final-form";
import createDecorator from "final-form-focus";
import { TextField, Checkbox } from "final-form-material-ui";
import {
  withStyles,
  Typography,
  Grid,
  IconButton,
  Divider,
  Button,
} from "@material-ui/core";
import Parse from "parse";
import DeleteIcon from "@material-ui/icons/Delete";

const styles = (theme) => ({
  textField: {
    width: 400,
  },
  selectField: {
    width: 400,
  },
  fieldContainer: {
    display: "flex",
    flex: 1,
  },
  fieldText: {
    lineHeight: "48px",
  },
  gridLine: {
    padding: "8px 0",
    lineHeight: 2,
  },
  category: {
    margin: "3rem 0 0 0",
  },
  buttonContainer: {
    alignSelf: "flex-end",
  },
  errorMessage: {
    color: "red",
    textAlign: "center",
    margin: "1rem 0 0",
  },
  warning: {
    color: "red",
    textAlign: "center",
    margin: "0 0 1rem 0",
  },
});

const focusOnErrors = createDecorator();

class HandleListForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listName: null,
      description: null,
      options: [],
      sortById: false,
      optionMissing: false,
    };
  }
  componentDidMount() {
    if (this.props.operation === "modify") {
      this.getListData(this.props.listName);
    }
  }

  // getting list from mongo
  getListData = async (listName) => {
    const list = await new Parse.Query("Lists")
      .equalTo("listName", listName)
      .first();
    const attr = list.attributes;
    this.setState({
      listId: list.id,
      listName: attr.listName,
      description: attr.description,
      options: attr.options,
      sortById: attr.sortById,
      editable: attr.editable,
    });
  };

  static sortOptions = (arrayToSort, sortKey) => {
    const compare = (a, b) => {
      // Use toUpperCase() to ignore character casing
      // there's a condition because for automatic lists some data could be missing
      let A = a[sortKey] !== undefined ? a[sortKey].toUpperCase() : "";
      let B = b[sortKey] !== undefined ? b[sortKey].toUpperCase() : "";

      // checking if we are dealing with numbers
      if (!isNaN(A)) {
        A = parseInt(A);
      }
      if (!isNaN(B)) {
        B = parseInt(B);
      }

      let comparison = 0;
      if (A > B) {
        comparison = 1;
      } else if (A < B) {
        comparison = -1;
      }
      return comparison;
    };

    if (arrayToSort !== undefined && !!arrayToSort.length) {
      arrayToSort.sort(compare);
    }
  };

  setSortTypeAndSort = (obj) => {
    const { options, sortById } = this.state;
    const checked = !!obj.input.value;
    if (checked === sortById) {
      return null;
    }

    let newOptions = !!options.length ? options.slice() : [];
    const sortKey = checked ? "value" : "text";

    HandleListForm.sortOptions(newOptions, sortKey);
    this.setState({
      options: newOptions,
      sortById: checked,
    });

    return null;
  };

  addOption = (newOption) => {
    const { options, sortById } = this.state;
    let newOptions = !!options.length ? options.slice() : [];
    const sortKey = sortById ? "value" : "text";

    // checking if option exists and updating if so
    let optionExists = false;
    newOptions.forEach((option) => {
      if (option.value === newOption.value) {
        option.text = newOption.text;
        optionExists = true;
      }
    });
    // adding option if not existing
    if (!optionExists) {
      newOptions.push(newOption);
    }
    // sorting array
    HandleListForm.sortOptions(newOptions, sortKey);

    this.setState({
      options: newOptions,
      optionMissing: false,
    });
    // emptying optionForm
    document.getElementById("optionForm").dispatchEvent(new Event("reset"));
  };

  deleteOption = (elementToRemove) => {
    const { options } = this.state;
    let newOptions = options.slice();
    newOptions.splice(elementToRemove, 1);

    this.setState({
      options: newOptions,
      optionMissing: !newOptions.length,
    });
  };

  onSubmit = async (values) => {
    const { listId, listName, description, options, sortById } = this.state;
    const { operation, onHandleModalClose } = this.props;
    const listData = {
      listName,
      description,
      options,
      sortById,
    };
    // cancel submit if no option
    if (options.length === 0) {
      this.setState({
        optionMissing: true,
      });
      return;
    }

    let list;
    if (operation === "add") {
      const Lists = Parse.Object.extend("Lists");
      list = new Lists();
    } else {
      // operation === 'modify'
      list = await new Parse.Query("Lists").get(listId);
    }
    // saving
    list.set("listName", values.listName);
    list.set("description", values.description);
    list.set("sortById", values.sortById);
    list.set("options", listData.options);
    await list.save();
    const modalMessageType = operation === "add" ? "listAdded" : "listUpdated";
    onHandleModalClose(modalMessageType);
  };

  render() {
    const {
      listName,
      description,
      options,
      sortById,
      optionMissing,
      editable,
    } = this.state;
    const { classes, operation } = this.props;

    const listIsEditable = editable !== undefined ? editable : true;

    if (operation !== "add" && listName === null) {
      return null;
    }

    const listData = {
      listName,
      description,
      options,
      sortById,
    };
    const initialValues = operation === "add" ? {} : listData;
    const optionsList = !!options.length
      ? options.map((option, index) => {
          return (
            <React.Fragment key={index}>
              <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={5}>
                  <Typography variant="body1" className={classes.gridLine}>
                    {option.value}
                  </Typography>
                </Grid>
                <Grid item xs={5} className={classes.gridLine}>
                  <Typography variant="body1" className={classes.gridLine}>
                    {option.text}
                  </Typography>
                </Grid>
                {listIsEditable && (
                  <Grid item xs={2} className={classes.gridLine}>
                    <IconButton
                      aria-label="Delete"
                      onClick={() => this.deleteOption(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
              <div>
                <Divider />
              </div>
            </React.Fragment>
          );
        })
      : "";

    /**********************************************************/
    /*********************** validators ***********************/
    /**********************************************************/

    const required = (value) => (value ? undefined : "Champs obligatoire");
    const onlyCharsNbrs = (value) =>
      /^[a-zA-Z0-9_]+$/.test(value)
        ? undefined
        : "Ne doit contenir que des lettres, chiffres ou underscore (_)";
    const composeValidators =
      (...validators) =>
      (value) =>
        validators.reduce(
          (error, validator) => error || validator(value),
          undefined,
        );

    return (
      <>
        {!listIsEditable && (
          <Typography variant="subtitle2" className={classes.warning}>
            Cette liste est créée automatiquement. Seule sa description peut
            être modifiée ici.
          </Typography>
        )}
        <Form
          onSubmit={this.onSubmit}
          decorators={[focusOnErrors]}
          initialValues={initialValues}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            invalid,
          }) => (
            <form id="editForm" onSubmit={handleSubmit}>
              <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    <Field
                      name="listName"
                      label="Nom de la liste"
                      placeholder="Nom de la liste"
                      component={TextField}
                      fullWidth
                      disabled={!listIsEditable}
                      validate={composeValidators(required, onlyCharsNbrs)}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    <Field
                      name="description"
                      label="Description"
                      placeholder="Description"
                      component={TextField}
                      fullWidth
                      validate={required}
                    />
                  </div>
                </Grid>
              </Grid>
              <Typography variant="body1" className={classes.category}>
                Options de la liste
              </Typography>
              <div className={classes.fieldContainer}>
                <Field
                  name="sortById"
                  component={Checkbox}
                  type="checkbox"
                  disabled={!listIsEditable}
                />

                <Field name="sortById" subscription={{ value: true }}>
                  {this.setSortTypeAndSort}
                </Field>

                <Typography variant="body2" className={classes.fieldText}>
                  Trier les options par Id
                </Typography>
              </div>
            </form>
          )}
        />

        <Form
          onSubmit={this.addOption}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <form
              id="optionForm"
              onSubmit={(event) => {
                handleSubmit(event);
                form.reset();
              }}
            >
              <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={4}>
                  <div className={classes.fieldContainer}>
                    <Field
                      name="value"
                      label="Id de l'option"
                      placeholder="Id de l'option"
                      component={TextField}
                      className={classes.textField}
                      disabled={!listIsEditable}
                      validate={composeValidators(required, onlyCharsNbrs)}
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.fieldContainer}>
                    <Field
                      name="text"
                      label="Texte de l'option"
                      placeholder="Texte de l'option"
                      component={TextField}
                      className={classes.textField}
                      disabled={!listIsEditable}
                      validate={required}
                    />
                  </div>
                </Grid>
                <Grid item xs={3} className={classes.buttonContainer}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={submitting || pristine}
                  >
                    ajouter
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={12}>
                  {optionsList}
                </Grid>
              </Grid>
              {optionMissing && (
                <Typography variant="body2" className={classes.errorMessage}>
                  Vous devez renseigner au moins une option
                </Typography>
              )}
            </form>
          )}
        />
      </>
    );
  }
}
export default withStyles(styles)(HandleListForm);
