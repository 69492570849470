import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "2rem 0",
  },
  table: {
    width: 500,
  },
  green: {
    color: "#006600",
  },
  row: {
    padding: "0.5rem 0",
  },
});

const OrderSummary = ({ rawAmount, amount }) => {
  const classes = useStyles();
  const totalPercentageDiscount = (rawAmount - amount) / rawAmount;
  const rows = [
    {
      name: "Total Brut",
      discount: "",
      amount: new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
      }).format(rawAmount),
    },
    {
      name: "Remise",
      discount: new Intl.NumberFormat("fr-FR", {
        style: "percent",
        maximumFractionDigits: 1,
      }).format(totalPercentageDiscount),
      amount: new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
      }).format(amount - rawAmount),
    },
    {
      name: "Total remisé",
      discount: "",
      amount: new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
      }).format(amount),
    },
  ];

  return (
    <div className={classes.container}>
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} className={classes.row}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right" className={classes.green}>
                {row.discount}
              </TableCell>
              <TableCell align="right">{row.amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default OrderSummary;
