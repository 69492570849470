import React, { useState, useEffect } from "react";
import Parse from "parse";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../reducers/currentUser";
import {
  makeStyles,
  Menu,
  MenuItem,
  Chip,
  Avatar,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { goToPage } from "../../actions/main";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
// import PermIdentityIcon from '@material-ui/icons/PermIdentity';

const useStyles = makeStyles(() => ({
  chip: {
    cursor: "pointer",
  },
}));

const LogOut = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [name, setName] = useState("");
  const [avatar, setAvatar] = useState("");
  const currentUser = useSelector(getCurrentUser);

  const handleMenuOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const logOut = async () => {
    await Parse.User.logOut();
    const currentUser = Parse.User.current();
    if (currentUser === null) {
      goToPage("/login");
    }
  };

  const getNameAndAvatar = async () => {
    if (currentUser !== undefined) {
      const contact = currentUser.get("contact");
      let firstName = currentUser.get("firstname");
      let lastName = currentUser.get("lastname");
      let email = currentUser.get("email");
      if (contact !== undefined) {
        const contactQuery = await new Parse.Query("Contacts").get(contact.id);
        firstName = contactQuery.get("firstname");
        lastName = contactQuery.get("lastname");
        email = contactQuery.get("email");
      }

      setName(
        firstName !== "" && firstName !== undefined
          ? firstName + " " + lastName
          : email,
      );
      setAvatar(
        firstName !== "" && firstName !== undefined
          ? (firstName[0] + lastName[0]).toUpperCase()
          : email.substring(0, 2).toUpperCase(),
      );
    }
  };

  useEffect(() => {
    getNameAndAvatar();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Chip
        avatar={<Avatar>{avatar}</Avatar>}
        label={name}
        className={classes.chip}
        aria-owns={anchorEl ? "simple-menu" : undefined}
        aria-haspopup="true"
        onClick={handleMenuOpen}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {/*
          <MenuItem onClick={ () => goToPage('/profile') }>
            <ListItemIcon className={classes.icon}>
              <PermIdentityIcon />
            </ListItemIcon>
            <ListItemText inset primary="Profil" />
          </MenuItem>
          */}
        <MenuItem onClick={() => logOut()}>
          <ListItemIcon className={classes.icon}>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText inset primary="Déconnexion" />
        </MenuItem>
      </Menu>
    </>
  );
};

export default LogOut;
