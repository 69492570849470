import React, { useState, useEffect } from "react";
import { Form, Field } from "react-final-form";
import { Select } from "final-form-material-ui";
import { makeStyles, Button, Grid } from "@material-ui/core";
import Parse from "parse";
import { optionsCreator } from "../../utils2";

const useStyles = makeStyles(() => ({
  gridContainer: {
    width: "50%",
  },
  selectField: {
    width: "100%",
  },
  fieldContainer: {
    display: "flex",
    flex: 1,
    height: "48px",
  },
  buttonWrapper: {
    display: "flex",
    flex: 1,
    margin: "1rem 0 0 1rem",
  },
}));

const ClientContactForm = (props) => {
  const classes = useStyles();
  const { clientCode } = props;
  const [clientData, setClientData] = useState(null);
  const [contactOptions, setContactOptions] = useState([]);
  const [contactLoaded, setContactLoaded] = useState(false);

  const loadClient = async () => {
    const client = await new Parse.Query("Clients")
      .equalTo("CLKTCODE", clientCode)
      .first();
    let newClientData = { ...client.attributes };
    // from pointer to string
    if (newClientData.mainContact !== undefined) {
      newClientData.mainContact = newClientData.mainContact.id;
    }
    setClientData(newClientData);
    // creating contact options
    const contacts = await new Parse.Query("Contacts")
      .equalTo("CLKTCODE", client)
      .ascending("CLCTCONTA1")
      .find();
    setContactOptions(
      contacts.map((contact) => {
        const emailPart =
          contact.get("email") !== undefined
            ? " (" + contact.get("email") + ")"
            : "";
        const text =
          contact.get("firstname") !== undefined &&
          contact.get("lastname") !== undefined
            ? contact.get("firstname") +
              " " +
              contact.get("lastname") +
              emailPart
            : contact.get("CLCTCONTA1") + emailPart;
        return {
          value: contact.id,
          text,
        };
      }),
    );
    setContactLoaded(true);
  };

  const onSubmit = async (values) => {
    // saving to Mongo (Parse saves only modified fields)
    const client = await new Parse.Query("Clients")
      .equalTo("CLKTCODE", clientCode)
      .first();
    // getting pointer to contact
    const contact = await new Parse.Query("Contacts").get(values.mainContact);

    client.set("mainContact", contact);
    try {
      await client.save();
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    loadClient();
    // eslint-disable-next-line
  }, []);

  if (clientData === null || !contactLoaded) {
    return null;
  }
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={clientData}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form id="clientInfoForm" onSubmit={handleSubmit}>
          <Grid container spacing={0} className={classes.gridContainer}>
            <Grid item xs={6}>
              <div className={classes.fieldContainer}>
                <Field
                  name="mainContact"
                  label="Contact principal"
                  component={Select}
                  className={classes.selectField}
                  format={(value) => value || contactOptions}
                >
                  {optionsCreator("custom", contactOptions)}
                </Field>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.buttonWrapper}>
                <Button
                  type="submit"
                  disabled={submitting || pristine}
                  variant="contained"
                  color="primary"
                >
                  enregistrer
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default ClientContactForm;
