import React, { useState, useEffect } from "react";
import DataGridView from "../reactgrid/DataGridView";
import ProductArticleForm from "../../containers/form/ProductArticleForm";
import useDebounce from "../../hooks";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../reducers/currentUser";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { setNumberColumns } from "../../utils2";
import Parse from "parse";

const columns = [
  { name: "ARKTCOMART", title: "Code complémentaire", type: "string" },
  { name: "ARCTLIB02", title: "Libellé complémentaire", type: "string" },
  { name: "ARCTEAN13", title: "Code EAN", type: "numeric" },
];
const columnExtensions = [
  { columnName: "ARKTCOMART" },
  { columnName: "ARCTLIB02" },
  { columnName: "ARCTEAN13" },
];

const ProductArticleList = (props) => {
  const { productCode } = props;
  const currentUser = useSelector(getCurrentUser);
  const userRole =
    currentUser !== undefined ? currentUser.get("role") : undefined;
  const [numberColumns, setStateNumberColumns] = useState([]);
  const [articleExist, setArticleExist] = useState(false);
  const [articleId, setArticleId] = useState(null);
  const [rowsValues, setRowsValues] = useState(null);
  const [filters, setFilters] = useState([]);
  const debouncedFilters = useDebounce(filters, 500);
  const [sorting, setSorting] = useState([
    { columnName: "ARKTCOMART", direction: "desc" },
  ]);
  const [loading, setLoading] = useState(true);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarText, setSnackBarText] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogText, setDialogText] = useState("");
  const [dialogYesBtnText, setDialogYesBtnText] = useState("");
  const [dialogNoBtnText, setDialogNoBtnText] = useState("");
  const [dialogOperation, setDialogOperation] = useState(null);

  /********************************************************/
  /*********************** Table data *********************/
  /********************************************************/
  const changeFilters = (filters) => {
    setLoading(true);
    setFilters(filters);
  };
  const changeSorting = (sorting) => {
    setLoading(true);
    setSorting(sorting);
  };
  // const changeCurrentPage = currentPage => {
  //   setLoading(true);
  //   setCurrentPage(currentPage);
  // };
  // const changePageSize = pageSize => setPageSize(pageSize);

  const loadData = async () => {
    const product = await new Parse.Query("Products")
      .equalTo("ARKTCODART", productCode)
      .first();
    const articlesData = product.get("articles");
    let articleExist = false;
    if (articlesData.length > 0) {
      articleExist = true;
    }
    // special filtering after query
    filters.forEach((filter) => {
      let i = articlesData.length;
      while (i--) {
        // decrementing to preserve index (i)
        const columnValue = articlesData[i][filter.columnName];
        // if filter.value not in columnValue
        if (columnValue === undefined || !columnValue.includes(filter.value)) {
          //console.log('filter.value (' + filter.value + ') is not in ' + columnValue);
          articlesData.splice(i, 1);
        } else {
          //console.log('filter.value (' + filter.value + ') is in ' + columnValue);
        }
      }
    });
    const newRowsValues = articlesData.map((product) => {
      const row = {};
      columns.forEach((column) => {
        let value = product[column.name];
        // formatting data from db
        if (column.name === "ARCTEAN13") {
          value = value !== undefined ? value : "";
        }
        row[column.name] = value;
      });
      return row;
    });

    setArticleExist(articleExist);
    setRowsValues(newRowsValues);
    setLoading(false);
  };

  /********************************************************/
  /************************* Dialog ***********************/
  /********************************************************/

  const handleDialogOpen = (articleId, dialogOperation) => {
    switch (dialogOperation) {
      case "create":
        setDialogTitle("Ajout d'un article");
        setDialogText("");
        setDialogYesBtnText("ajouter");
        setDialogNoBtnText("annuler");
        break;
      case "modify":
        setDialogTitle("Modification d'un article");
        setDialogText("");
        setDialogYesBtnText("modifier");
        setDialogNoBtnText("annuler");
        break;
      case "view":
        setDialogTitle("Détail d'un article");
        setDialogText("");
        setDialogYesBtnText("");
        setDialogNoBtnText("fermer");
        break;
      case "delete":
        setDialogTitle("Suppression d'un article");
        setDialogText(
          "Voulez-vous vraiment supprimer l'article " + articleId + " ?",
        );
        setDialogYesBtnText("oui");
        setDialogNoBtnText("non");
        break;
      default:
    }
    setDialogOpen(true);
    setDialogOperation(dialogOperation);
    setArticleId(articleId);
  };

  const handleDialogClose = (action) => {
    switch (action) {
      case "added":
        handleSnackBarShow("article ajouté");
        break;
      case "updated":
        handleSnackBarShow("article mis à jour");
        break;
      case "deleted":
        handleSnackBarShow("article supprimé");
        break;
      default:
    }
    setDialogOpen(false);
  };

  const handleDialogValidate = async (articleId, operation) => {
    switch (operation) {
      case "create":
        document
          .getElementById("productArticleForm")
          .dispatchEvent(new Event("submit", { cancelable: true }));
        handleDialogClose("added");
        break;
      case "modify":
        document
          .getElementById("productArticleForm")
          .dispatchEvent(new Event("submit", { cancelable: true }));
        handleDialogClose("updated");
        break;

      case "delete":
        const product = await new Parse.Query("Products")
          .equalTo("ARKTCODART", productCode)
          .first();

        const articles = product.get("articles");
        const newArticleArray = articles.filter(
          (article) => article.ARKTCOMART !== articleId,
        );
        product.set("articles", newArticleArray);
        await product.save();
        handleDialogClose("deleted");
        break;
      default:
    }
  };

  /********************************************************/
  /************************ SnackBar **********************/
  /********************************************************/

  const handleSnackBarShow = (textValue) => {
    setSnackBarOpen(true);
    setSnackBarText(textValue);
  };
  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  const displayIcon = (action, row) => {
    if (row === undefined) {
      return false;
    } else {
      if (action === "modify" || action === "delete") {
        return userRole !== "commercial";
      } else if (action === "view") {
        return userRole === "commercial";
      } else {
        return true;
      }
    }
  };

  const actions = [
    {
      icon: <EditIcon />,
      action: (rowData) => handleDialogOpen(rowData.row.ARKTCOMART, "modify"),
      displayIcon: (rowData) => displayIcon("modify", rowData.row),
    },
    {
      icon: <InfoIcon />,
      tooltip: "voir l'article",
      action: (rowData) => handleDialogOpen(rowData.row.ARKTCODART, "view"),
      displayIcon: (rowData) => displayIcon("view", rowData.row),
    },
    {
      icon: <DeleteIcon />,
      action: (rowData) => handleDialogOpen(rowData.row.ARKTCOMART, "delete"),
      displayIcon: (rowData) => displayIcon("delete", rowData.row),
    },
  ];

  useEffect(() => {
    setNumberColumns(columns, setStateNumberColumns);
    loadData();
    // eslint-disable-next-line
  }, []);

  // when sorting or dialog is closed
  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, [sorting, dialogOpen]);

  // when filters change
  useEffect(() => {
    if (debouncedFilters) {
      loadData();
    }
    // eslint-disable-next-line
  }, [debouncedFilters]);

  if (rowsValues === null) {
    return null;
  }

  return (
    <>
      {!articleExist && userRole !== "commercial" && (
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleDialogOpen(null, "create")}
          >
            ajouter un article
          </Button>
        </div>
      )}
      {articleExist && (
        <DataGridView
          title="Liste des articles"
          rowsValues={rowsValues}
          columnHeaders={columns}
          filters={filters}
          onFiltersChange={changeFilters}
          sorting={sorting}
          onSortingChange={changeSorting}
          loading={loading}
          numberColumns={numberColumns}
          columnExtensions={columnExtensions}
          withPagination={false}
          hasActions={true}
          actions={actions}
          withAddButton={true}
          onAddButtonClick={() => handleDialogOpen(null, "create")}
        />
      )}

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogText}
          </DialogContentText>
          {(dialogOperation === "create" ||
            dialogOperation === "modify" ||
            dialogOperation === "view") && (
            <ProductArticleForm
              operation={dialogOperation}
              productCode={productCode}
              articleId={articleId}
              onHandleModalClose={handleDialogClose}
            />
          )}
        </DialogContent>
        <DialogActions>
          {dialogOperation !== "view" && (
            <Button
              onClick={() => handleDialogValidate(articleId, dialogOperation)}
              color="primary"
            >
              {dialogYesBtnText}
            </Button>
          )}
          <Button onClick={() => handleDialogClose()} color="primary" autoFocus>
            {dialogNoBtnText}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
        open={snackBarOpen}
        onClose={handleSnackBarClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackBarText}</span>}
      />
    </>
  );
};

export default ProductArticleList;

//
//
// const styles = theme => ({
//   gridContainer: {
//     width: '50%'
//   },
//   textField: {
//     width: '100%',
//     marginTop: 25
//   },
//   selectField: {
//     width: '100%',
//   },
//   fieldContainer: {
//     display: 'flex',
//     flex: 1,
//     height: '48px'
//   },
//   buttonWrapper: {
//     display: 'flex',
//     flex: 1,
//     justifyContent: 'center',
//     margin: '2rem 1rem 1rem 0'
//   },
//   errorMessage: {
//     textAlign: 'center',
//     color: 'red'
//   },
//   switchText: {
//     lineHeight: '48px'
//   }
// });
//
// const currentUser = Parse.User.current();
// const userRole = currentUser !== null ? currentUser.get('role') : undefined;
//
//
//
// class ProductArticleList extends PureComponent {
//   constructor(props) {
//     super(props);
//     this.state = {
//       articleExist: false,
//       values: [],
//       rowsValues: [],
//       columns: [
//         { name: 'ARKTCOMART', title: 'Code complémentaire', type: 'string' },
//         { name: 'ARCTLIB02', title: 'Libellé complémentaire', type: 'string' },
//         { name: 'ARCTEAN13', title: 'Code EAN', type: 'numeric' },
//       ],
//       columnExtensions: [
//         { columnName: 'ARKTCOMART' },
//         { columnName: 'ARCTLIB02' },
//         { columnName: 'ARCTEAN13' },
//       ],
//       filters: [],
//       sorting: [
//         { columnName: 'ARKTCOMART', direction: 'desc' }
//       ],
//       totalCount: 0,
//       pageSize: 15, //default count per page
//       pageSizes: [15, 25, 50, 100, 150],
//       currentPage: 0,
//       loading: true,
//       dialogOpen: false,
//       snackBarOpen: false,
//     };
//     this.changeFilters = this.changeFilters.bind(this);
//     this.changeSorting = this.changeSorting.bind(this);
//   }

// componentDidMount() {
//   setNumberColumns.call(this);
//   this.loadData();
// }
// componentDidUpdate() {
//   this.loadDebounced();
// }
//
// /********************************************************/
// /*********************** Table data *********************/
// /********************************************************/
// changeFilters(filters) {
//   console.log('filters:', filters);
//
//   this.setState({
//     loading: true,
//     filters,
//   });
// }
//
// changeSorting(sorting) {
//   this.setState ({
//     loading: true,
//     sorting,
//   });
// }
//
//
// loadData = async (forceReload) => {
//   const { filters, sorting, lastTableState } = this.state;
//   const { productCode } = this.props;
//   const filterState = JSON.stringify(filters);
//   const sortingState = JSON.stringify(sorting);
//   const tableState = filterState + sortingState;
//   const forceReloading = (forceReload === undefined) ? false : forceReload;
//
//   if ( lastTableState === tableState && !forceReloading ) {
//     // filters didn't change
//     this.setState({ loading: false });
//     return;
//   }
//
//   const product = await new Parse.Query("Products")
//     .equalTo("ARKTCODART", productCode)
//     .first();
//   const articlesData = product.attributes.articles;
//   let articleExist = false;
//   if (articlesData.length > 0) {
//     articleExist = true;
//   }
//   // special filtering after query
//   filters.forEach( filter => {
//     let i = articlesData.length;
//     while (i--) { // decrementing to preserve index (i)
//       const columnValue = articlesData[i][filter.columnName];
//       // if filter.value not in columnValue
//       if (columnValue === undefined || !columnValue.includes(filter.value)) {
//         console.log('filter.value (' + filter.value + ') is not in ' + columnValue);
//         articlesData.splice(i, 1);
//       } else {
//         console.log('filter.value (' + filter.value + ') is in ' + columnValue);
//       }
//     }
//   });
//
//   this.setState({
//     articleExist,
//     articlesData,
//     lastTableState: tableState
//   });
// };
//
// loadDebounced = debounce(this.loadData, 300);

/********************************************************/
/************************* Dialog ***********************/
/********************************************************/
//
// handleDialogOpen = (articleId, dialogOperation) => {
//   switch (dialogOperation) {
//     case 'create':
//       this.setState({
//         dialogTitle: "Ajout d'un article",
//         dialogText: "",
//         dialogYesBtnText: 'ajouter',
//         dialogNoBtnText: 'annuler'
//       });
//       break;
//     case 'modify':
//       this.setState({
//         dialogTitle: "Modification d'un article",
//         dialogText: "",
//         dialogYesBtnText: 'modifier',
//         dialogNoBtnText: 'annuler'
//       });
//       break;
//     case 'view':
//       this.setState({
//         dialogTitle: "Détail d'un article",
//         dialogText: "",
//         dialogYesBtnText: '',
//         dialogNoBtnText: 'fermer'
//       });
//       break;
//     case 'delete':
//       this.setState({
//         dialogTitle: "Suppression d'un article",
//         dialogText: "Voulez-vous vraiment supprimer l'article " + articleId + " ?",
//         dialogYesBtnText: 'oui',
//         dialogNoBtnText: 'non'
//       });
//       break;
//     default:
//   }
//   this.setState({
//     dialogOpen: true,
//     dialogOperation,
//     articleId
//   })
// };
//
//
// handleDialogClose = async (action) => {
//   switch (action) {
//     case 'added':
//       this.handleSnackBarShow('article ajouté');
//       break;
//     case 'updated':
//       this.handleSnackBarShow('article mis à jour');
//       break;
//     case 'deleted':
//       this.handleSnackBarShow('article supprimé');
//       break;
//     default:
//   }
//   // we always reload data on modal close
//   await this.loadData(true);
//   this.setState({ dialogOpen: false });
// };
//
// handleDialogValidate = async (articleId, operation) => {
//   const {productCode} = this.props;
//   switch (operation) {
//
//     case 'create':
//       document
//         .getElementById("productArticleForm")
//         .dispatchEvent(new Event("submit", { cancelable: true }));
//       this.handleDialogClose('added');
//       break;
//
//     case 'modify':
//       document
//         .getElementById("productArticleForm")
//         .dispatchEvent(new Event("submit", { cancelable: true }));
//       this.handleDialogClose('updated');
//       break;
//
//     case 'delete':
//       const product = await new Parse.Query("Products")
//         .equalTo("ARKTCODART", productCode)
//         .first();
//
//       const articles = product.get('articles');
//       const newArticleArray = [];
//
//       articles.forEach(article => {
//         if (article.ARKTCOMART !== articleId) {
//           newArticleArray.push(article);
//         }
//       });
//       product.set('articles', newArticleArray);
//       await product.save();
//       this.handleDialogClose('deleted');
//       break;
//     default:
//   }
// };
//
//
// /********************************************************/
// /************************ SnackBar **********************/
// /********************************************************/
//
// handleSnackBarShow = (textValue) => {
//   this.setState({ snackBarOpen: true, snackBarText: textValue });
// };
// handleSnackBarClose = () => {
//   this.setState({ snackBarOpen: false });
// };
//
// displayIcon = (action, row) => {
//   if (row === undefined) {
//     return false;
//   } else {
//     if (action === 'modify' || action === 'delete') {
//       return userRole !== 'commercial';
//     } else if (action === 'view') {
//       return userRole === 'commercial';
//     } else {
//       return true;
//     }
//   }
// };

// render() {
//   const { columns, columnExtensions, loading, filters, sorting, numberColumns,
//     articleExist, articlesData, articleId,
//     dialogOpen, dialogOperation, dialogTitle, dialogText, dialogYesBtnText, dialogNoBtnText, snackBarOpen, snackBarText } = this.state;
//   const {productCode} = this.props;
//
//   if (!articlesData) {
//     return null;
//   }
//
// const rowsValues = articlesData.map (
//   product => {
//     const row = {};
//     columns.forEach(column => {
//       let value = product[column.name];
//       // formatting data from db
//       if (column.name === 'ARCTEAN13') {
//         value = (value !== undefined) ? value : '';
//       }
//
//       row[column.name] = value;
//     });
//     return row;
//   }
// );
// const actions = [
//   {
//     icon: <EditIcon/>,
//     action: rowData => this.handleDialogOpen( rowData.row.ARKTCOMART, 'modify'),
//     displayIcon: rowData => this.displayIcon('modify', rowData.row)
//   },
//   {
//     icon: <InfoIcon/>,
//     tooltip: 'voir l\'article',
//     action: rowData => this.handleDialogOpen( rowData.row.ARKTCODART, 'view'),
//     displayIcon: rowData => this.displayIcon('view', rowData.row)
//   },
//   {
//     icon: <DeleteIcon/>,
//     action: rowData => this.handleDialogOpen( rowData.row.ARKTCOMART, 'delete' ),
//     displayIcon: rowData => this.displayIcon('delete', rowData.row)
//   }
// ];
//     return (
//       <>
//         { (!articleExist && userRole !== 'commercial') &&
//         <div>
//           <Button variant="contained" color="primary" onClick={ () => this.handleDialogOpen(null, 'create') }>
//             ajouter un article
//           </Button>
//         </div>
//         }
//         { articleExist &&
//           <DataGridView
//             title='Liste des articles'
//             rowsValues={rowsValues}
//             columnHeaders={columns}
//             filters={filters} onFiltersChange={this.changeFilters}
//             sorting={sorting} onSortingChange={this.changeSorting}
//             loading={loading}
//             numberColumns={numberColumns}
//             columnExtensions={columnExtensions}
//             withPagination={false}
//             hasActions={true} actions={actions}
//             withAddButton={true} onAddButtonClick={ () => this.handleDialogOpen(null, 'create') }
//           />
//         }
//
//         <Dialog
//           open={dialogOpen}
//           onClose={this.handleDialogClose}
//           aria-labelledby="alert-dialog-title"
//           aria-describedby="alert-dialog-description"
//           maxWidth="md"
//         >
//           <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
//           <DialogContent>
//             <DialogContentText id="alert-dialog-description">
//               {dialogText}
//             </DialogContentText>
//             { (dialogOperation === 'create' || dialogOperation === 'modify' || dialogOperation === 'view') && <ProductArticleForm operation={dialogOperation} productCode={productCode} articleId={articleId} onHandleModalClose={this.handleDialogClose} /> }
//
//           </DialogContent>
//           <DialogActions>
//             {dialogOperation !== 'view' &&
//             <Button onClick={() => this.handleDialogValidate(articleId, dialogOperation)} color="primary">
//               {dialogYesBtnText}
//             </Button>
//             }
//             <Button onClick={ () => this.handleDialogClose() } color="primary" autoFocus>
//               {dialogNoBtnText}
//             </Button>
//           </DialogActions>
//         </Dialog>
//
//         <Snackbar
//           anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
//           autoHideDuration={3000}
//           open={snackBarOpen}
//           onClose={this.handleSnackBarClose}
//           ContentProps={{
//             'aria-describedby': 'message-id',
//           }}
//           message={<span id="message-id">{snackBarText}</span>}
//         />
//
//
//       </>
//     );
//   }
// }
//
// export default withStyles(styles)(ProductArticleList);
