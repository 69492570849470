import React, { PureComponent } from "react";
import {
  withStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { connect } from "react-redux";
import { goToPage, toggleListItem, selectListItem } from "../../actions/main";
import { getNavigation } from "../../reducers/navigation";
import DashboardIcon from "@material-ui/icons/Dashboard";
import GroupIcon from "@material-ui/icons/Group";
import HomeIcon from "@material-ui/icons/Home";
import PersonIcon from "@material-ui/icons/Person";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ContactsIcon from "@material-ui/icons/Contacts";
import ListIcon from "@material-ui/icons/List";
import DiscountIcon from "@material-ui/icons/MonetizationOn";

const styles = (theme) => ({
  nested: {
    paddingLeft: theme.spacing(1 / 2),
  },
});

class ListEntry extends PureComponent {
  navigateTo = (uri, id) => {
    this.props.selectListItem(id);
    goToPage(uri);
  };

  render() {
    const {
      navigation,
      toggleListItem,
      id,
      classes,
      toPage = "",
      text,
      nested = false,
      collapsable = false,
      icon = false,
    } = this.props;
    const listItemClasses = nested ? classes.nested : "";

    let iconComponent;
    switch (icon) {
      case "Dashboard":
        iconComponent = <DashboardIcon />;
        break;
      case "Group":
        iconComponent = <GroupIcon />;
        break;
      case "Home":
        iconComponent = <HomeIcon />;
        break;
      case "Person":
        iconComponent = <PersonIcon />;
        break;
      case "QuestionAnswer":
        iconComponent = <QuestionAnswerIcon />;
        break;
      case "ShoppingCart":
        iconComponent = <ShoppingCartIcon />;
        break;
      case "StarBorder":
        iconComponent = <StarBorderIcon />;
        break;
      case "Contacts":
        iconComponent = <ContactsIcon />;
        break;
      case "List":
        iconComponent = <ListIcon />;
        break;
      case "Discount":
        iconComponent = <DiscountIcon />;
        break;
      default: {
      }
    }

    if (collapsable) {
      return (
        <ListItem
          button
          className={listItemClasses}
          onClick={() => toggleListItem(id)}
        >
          {icon && <ListItemIcon>{iconComponent}</ListItemIcon>}
          <ListItemText inset={nested} primary={text} />
          {navigation[id].open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
      );
    } else {
      return (
        <ListItem
          button
          selected={navigation[id].selected}
          className={listItemClasses}
          onClick={() => this.navigateTo(toPage, id)}
        >
          {icon && <ListItemIcon>{iconComponent}</ListItemIcon>}
          <ListItemText inset={nested} primary={text} />
        </ListItem>
      );
    }
  }
}

export default connect(
  (state) => ({
    navigation: getNavigation(state),
  }),
  {
    toggleListItem,
    selectListItem,
  },
)(withStyles(styles)(ListEntry));
