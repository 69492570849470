import React, { PureComponent } from "react";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import { withStyles, Typography, Button } from "@material-ui/core";
import Parse from "parse";

const styles = (theme) => ({
  message: {
    marginTop: "2rem",
  },
  textField: {
    width: "100%",
    marginTop: 25,
  },
  fieldContainer: {
    display: "flex",
    flex: 1,
  },
  buttonWrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    margin: "2rem 1rem 1rem 0",
  },
  forgottenPassword: {
    lineHeight: "36px",
    marginLeft: "1rem",
    cursor: "pointer",
  },
  errorMessage: {
    textAlign: "center",
    color: "red",
    margin: "2rem 0 0",
  },
  okMessage: {
    textAlign: "center",
    color: "green",
    margin: "2rem 0 0",
  },
  link: {
    textDecoration: "underline",
    cursor: "pointer",
  },
});

class ResetLoginForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {},
      emailInDb: false,
      emailNotFound: false,
      emailIsPristine: false,
      emailSent: false,
    };
  }

  onSubmit = async (values) => {
    //let {emailInDb, emailIsPristine} = this.state;
    const email = values.email;
    let emailInDb, emailIsPristine, emailNotFound;

    // email search
    if (!emailInDb) {
      try {
        const params = {
          email,
        };
        const user = await Parse.Cloud.run("getPristineUser", params);
        switch (user) {
          case "userIsPristine":
            emailInDb = true;
            emailIsPristine = true;
            emailNotFound = false;
            break;
          case "userNotFound":
            emailInDb = false;
            emailIsPristine = false;
            emailNotFound = true;
            break;
          case "userIsNotPristine":
            emailInDb = true;
            emailIsPristine = false;
            emailNotFound = false;
            break;
          default:
        }
        this.setState({
          emailInDb,
          emailIsPristine,
          emailNotFound,
        });
      } catch (error) {
        console.log(error.message);
      }

      if (emailInDb && !emailIsPristine) {
        try {
          await Parse.User.requestPasswordReset(email);
          this.setState({
            email,
            emailSent: true,
          });
        } catch (error) {
          console.log(error.message);
        }
      }
    }
  };

  render() {
    const {
      initialValues,
      emailInDb,
      emailNotFound,
      emailSent,
      email,
      emailIsPristine,
    } = this.state;
    const { classes, onFirstLogin } = this.props;
    return (
      <>
        {!emailInDb && (
          <Form
            onSubmit={this.onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form id="resetLoginForm" onSubmit={handleSubmit}>
                <Typography variant="body2" className={classes.message}>
                  Saississez l'email lié à votre compte
                </Typography>
                <div className={classes.fieldContainer}>
                  <Field
                    name="email"
                    component={TextField}
                    type="email"
                    placeholder="Adresse email"
                    className={classes.textField}
                    required
                  />
                </div>
                <div className={classes.buttonWrapper}>
                  <Button
                    type="submit"
                    disabled={submitting || pristine}
                    variant="contained"
                    color="primary"
                  >
                    recevoir un email
                  </Button>
                </div>
                {emailNotFound && (
                  <Typography variant="body2" className={classes.errorMessage}>
                    cet email ne correspond à aucun utilisateur
                  </Typography>
                )}
              </form>
            )}
          />
        )}
        {emailSent && (
          <Typography variant="body2" className={classes.okMessage}>
            un email pour réinitialiser votre mot de passe vient d'être envoyé à{" "}
            {email}
          </Typography>
        )}
        {emailIsPristine && (
          <Typography variant="body2" className={classes.errorMessage}>
            vous n'avez pas défini de mot de passe. Vous pouvez le faire{" "}
            <span className={classes.link} onClick={onFirstLogin}>
              ici
            </span>
          </Typography>
        )}
      </>
    );
  }
}

export default withStyles(styles)(ResetLoginForm);
