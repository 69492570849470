import React, { useState } from "react";
import { IconButton, Tooltip, Dialog } from "@material-ui/core";
import ProductStockChart from "./ProductStockChart";
import ProductCard from "./ProductCard";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import InfoIcon from "@material-ui/icons/Info";

const ProductsIcons = (props) => {
  const { productId, showChart, showCard, chartWithNegativeValues, size } =
    props;
  const buttonSize = size !== undefined ? size : "medium";
  const showNegativeQuantities =
    chartWithNegativeValues !== undefined ? chartWithNegativeValues : false;
  const [dataAsked, setDataAsked] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = async (dataAsked) => {
    let dialogContent = "";
    if (dataAsked === "stockChart") {
      dialogContent = (
        <ProductStockChart
          productId={productId}
          showNegativeQuantities={showNegativeQuantities}
        />
      );
    } else if (dataAsked === "info") {
      dialogContent = <ProductCard productId={productId} />;
    }

    setDataAsked(dialogContent);
    setOpen(true);
  };

  return (
    <>
      {showCard && (
        <Tooltip title="présentation produit" placement="top">
          <IconButton
            aria-label="Delete"
            onClick={(event) => handleClick("info", event)}
            size={buttonSize}
          >
            <InfoIcon />
          </IconButton>
        </Tooltip>
      )}
      {showChart && (
        <Tooltip title="évolution des stocks" placement="top">
          <IconButton
            aria-label="Delete"
            onClick={(event) => handleClick("stockChart", event)}
            size={buttonSize}
          >
            <ShowChartIcon />
          </IconButton>
        </Tooltip>
      )}

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth={chartWithNegativeValues ? "lg" : "md"}
      >
        {dataAsked}
      </Dialog>
    </>
  );
};

export default ProductsIcons;
