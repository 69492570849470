import React from "react";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../reducers/currentUser";
import { getNavigation } from "../../reducers/navigation";
import { List, Collapse } from "@material-ui/core";
import ListEntry from "../../components/navigation/ListEntry";

const InternalDrawerList = () => {
  const currentUser = useSelector(getCurrentUser);
  const navigation = useSelector(getNavigation);

  if (currentUser === undefined) {
    return null;
  }
  return (
    <List>
      <ListEntry id={0} toPage="/" text="Tableau de bord" icon="Dashboard" />

      <ListEntry id={1} text="Commandes" collapsable icon="ShoppingCart" />
      <Collapse in={navigation[1].open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {currentUser.get("orderAble") && (
            <>
              {/*<ListEntry id={2} toPage='/reassort' text='Réassort' nested={true}/>*/}
              <ListEntry
                id={3}
                toPage="/commande"
                text="Commande cadencée"
                nested={true}
              />
            </>
          )}
          <ListEntry
            id={4}
            toPage="/commandes"
            text="Historique"
            nested={true}
          />
        </List>
      </Collapse>

      <ListEntry id={5} text="Produits" collapsable icon="StarBorder" />
      <Collapse in={navigation[5].open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {currentUser.get("productAble") && (
            <ListEntry
              id={6}
              toPage="/produits"
              text="Informations"
              nested={true}
            />
          )}
          {currentUser.get("resupplyAble") && (
            <ListEntry
              id={7}
              toPage="/reappro"
              text="Réapprovisionnements"
              nested={true}
            />
          )}
          {false && currentUser.get("sparePartAble") && (
            <ListEntry
              id={8}
              toPage="/pieces_detachees"
              text="Pièces détachées"
              nested={true}
            />
          )}
        </List>
      </Collapse>

      {currentUser.get("discountAble") && (
        <ListEntry id={9} toPage="/remises" text="Remises" icon="Discount" />
      )}
      {currentUser.get("clientAble") && (
        <>
          <ListEntry id={10} text="Clients" collapsable icon="Group" />
          <Collapse in={navigation[10].open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListEntry
                id={11}
                toPage="/magasins"
                text="Magasins"
                nested={true}
              />
              <ListEntry
                id={12}
                toPage="/groupements"
                text="Groupements"
                nested={true}
              />
              <ListEntry
                id={13}
                toPage="/enseignes"
                text="Enseignes"
                nested={true}
              />
            </List>
          </Collapse>
        </>
      )}

      {currentUser.get("userAble") && (
        <ListEntry
          id={14}
          toPage="/utilisateurs"
          text="Utilisateurs"
          icon="Person"
        />
      )}
      {currentUser.get("contactAble") && (
        <ListEntry id={15} toPage="/contacts" text="Contacts" icon="Contacts" />
      )}
      {currentUser.get("listAble") && (
        <ListEntry id={16} toPage="/listes" text="Listes" icon="List" />
      )}
      {false && currentUser.get("supportAble") && (
        <>
          <ListEntry id={17} text="Support" collapsable icon="QuestionAnswer" />
          <Collapse in={navigation[17].open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListEntry
                id={18}
                toPage="/ticket"
                text="Ouvir un ticket SAV"
                nested={true}
              />
              <ListEntry
                id={19}
                toPage="/tickets"
                text="Historique des tickets"
                nested={true}
              />
            </List>
          </Collapse>
        </>
      )}
    </List>
  );
};

export default InternalDrawerList;
