import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getLists } from "../../../reducers/lists";
import { getCurrentUser } from "../../../reducers/currentUser";
import useDebounce from "../../../hooks";
import Page from "../Page";
import Parse from "parse";
import DataGridView from "../../../components/reactgrid/DataGridView";
import ClientDetail from "../../../components/client/ClientDetail";
import {
  makeStyles,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Snackbar,
  Toolbar,
  IconButton,
  AppBar,
} from "@material-ui/core";
import {
  setNumberColumns,
  filtersQuery,
  translateColumn,
} from "../../../utils2";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles(() => ({
  tableTitleWrapper: {
    display: "flex",
    alignItems: "center",
    minHeight: "64px",
    padding: "0 8px 0 24px",
  },
  subtitle1Wrapper: {
    padding: "12px 0 24px",
  },
  toolbar: {
    backgroundColor: "#46494c", //'#02cfa3'
  },
}));

const pageSizes = [15, 25, 50, 100, 150];

const Clients = () => {
  const classes = useStyles();
  const listsOptions = useSelector(getLists);
  const currentUser = useSelector(getCurrentUser);
  const userRole =
    currentUser !== undefined ? currentUser.get("role") : undefined;
  let columns = [
    { name: "CLKTCODE", title: "Code", type: "string" },
    { name: "CLCTNOM", title: "Nom", type: "string" },
    { name: "CLCTVILLE", title: "Ville", type: "string" },
    { name: "CLCTCP", title: "Code postal", type: "string" },
    { name: "CLCTREP1", title: "Représentant", type: "string" },
    { name: "CLCTCENACH", title: "Groupe", type: "string" },
    { name: "CLCTGROUPE", title: "Enseigne", type: "string" },
  ];
  if (userRole === "commercial") {
    columns.splice(4, 1); // removing CLCTREP1
  }
  const columnExtensions = [
    { columnName: "CLKTCODE" },
    { columnName: "CLCTNOM" },
    { columnName: "CLCTVILLE" },
    { columnName: "CLCTCP" },
    { columnName: "CLCTREP1" },
    { columnName: "CLCTCENACH" },
    { columnName: "CLCTGROUPE" },
  ];
  const [rows, setRows] = useState([]);
  const [filters, setFilters] = useState([]);
  const debouncedFilters = useDebounce(filters, 300);
  const [sorting, setSorting] = useState([
    { columnName: "CLCTNOM", direction: "asc" },
  ]);
  const [totalCount, setTotalCount] = useState(100);
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [columnsTranslations, setColumnsTranslations] = useState([]);
  const [numberColumns, setStateNumberColumns] = useState([]);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarText, setSnackBarText] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [clientCode, setClientCode] = useState(null);

  /********************************************************/
  /************ content info for paging panel *************/
  /********************************************************/
  const contentInfo = ({ from, to, count }) => {
    return `${from} ${from < to ? ` à ${to}` : ""} sur ${count} magasin(s)`;
  };

  /********************************************************/
  /*********************** Table data *********************/
  /********************************************************/
  const changeFilters = (filters) => {
    setLoading(true);
    setFilters(filters);
  };
  const changeSorting = (sorting) => {
    setLoading(true);
    setSorting(sorting);
  };
  const changeCurrentPage = (currentPage) => {
    setLoading(true);
    setCurrentPage(currentPage);
  };
  const changePageSize = (pageSize) => setPageSize(pageSize);

  const listToColumnTranslations = (arrayOfListNameAndColumnName) => {
    const newColumnTranslations = [];
    arrayOfListNameAndColumnName.forEach((listNameAndColumnName) => {
      let listOfOptions = [];
      // getting list
      listsOptions.forEach((list) => {
        if (list.listName === listNameAndColumnName.listName) {
          listOfOptions = list.options;
        }
      });
      newColumnTranslations.push({
        columnName: listNameAndColumnName.columnName,
        values: listOfOptions.map((option) => [option.value, option.text]),
      });
    });
    setColumnsTranslations([...columnsTranslations, ...newColumnTranslations]);
  };

  // getting clients from mongo
  const getClients = async () => {
    if (!columnsTranslations.length) {
      return null;
    }
    const query = await new Parse.Query("Clients")
      .select(
        "CLKTCODE",
        "CLCTNOM",
        "CLCTVILLE",
        "CLCTCP",
        "CLCTREP1",
        "CLCTCENACH",
        "CLCTGROUPE",
      )
      .notEqualTo("CLCTACTIF", "N");

    if (userRole === "commercial") {
      query.equalTo("CLCTREP1", currentUser.get("commercial"));
    }
    filtersQuery(query, columnsTranslations, { columns, filters, sorting });
    const totalCount = await query.count();

    // skipping according to currentPage and pageSize
    query.skip(pageSize * currentPage);
    query.limit(pageSize);

    const clients = await query.find();

    const rows = clients.map((client) => {
      const row = {};
      columns.forEach((column) => {
        let value = client.get(column.name);
        if (
          column.name === "CLCTREP1" ||
          column.name === "CLCTCENACH" ||
          column.name === "CLCTGROUPE"
        ) {
          row[column.name] = translateColumn(
            value,
            columnsTranslations.find(
              (columnTranslation) =>
                columnTranslation.columnName === column.name,
            ),
          );
        } else {
          row[column.name] = value;
        }
      });
      return row;
    });
    setRows(rows);
    setTotalCount(totalCount);
    setLoading(false);
  };

  /********************************************************/
  /*********************** Dialog *************************/
  /********************************************************/
  // when dialog is called
  const handleClickOpen = async (clientCode) => {
    setClientCode(clientCode);
    setDialogOpen(true);
  };
  // when closing dialog box
  const handleModalClose = async (action) => {
    if (action === "validate") {
      handleSnackBarShow("client mis à jour");
    }
    if (action === "pmiError") {
      handleSnackBarShow("erreur de mise à jour (PMI)");
    }
    // we always reload groups on modal close
    await getClients();
    setDialogOpen(false);
  };

  /********************************************************/
  /************************ SnackBar **********************/
  /********************************************************/
  const handleSnackBarShow = (textValue) => {
    setSnackBarOpen(true);
    setSnackBarText(textValue);
  };

  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  /********************************************************/
  /************************ Actions ***********************/
  /********************************************************/
  const displayIcon = (action, row) => {
    if (row === undefined) {
      return false;
    } else {
      if (action === "editClient") {
        return userRole !== "commercial";
      } else if (action === "showClient") {
        return userRole === "commercial";
      } else {
        return true;
      }
    }
  };

  const actions = [
    {
      icon: <EditIcon />,
      action: (rowData) => handleClickOpen(rowData.row.CLKTCODE),
      displayIcon: (rowData) => displayIcon("editClient", rowData.row),
    },
    {
      icon: <InfoIcon />,
      action: (rowData) => handleClickOpen(rowData.row.CLKTCODE),
      displayIcon: (rowData) => displayIcon("showClient", rowData.row),
    },
  ];

  /********************************************************/
  /*********************** useEffects *********************/
  /********************************************************/
  useEffect(() => {
    setNumberColumns(columns, setStateNumberColumns);
    listToColumnTranslations([
      { listName: "commercials", columnName: "CLCTREP1" },
      { listName: "groups", columnName: "CLCTCENACH" },
      { listName: "tradenames", columnName: "CLCTGROUPE" },
    ]);
    // eslint-disable-next-line
  }, []);

  // when page or sorting change or when columnsTranslations is loaded
  useEffect(() => {
    getClients();
    // eslint-disable-next-line
  }, [currentPage, sorting, pageSize, columnsTranslations]);

  // when filters change
  useEffect(() => {
    if (debouncedFilters) {
      getClients();
    }
    // eslint-disable-next-line
  }, [debouncedFilters]);

  if (!currentUser.get("clientAble") && userRole !== "commercial") {
    return null;
  }

  return (
    <Page toolBarTitle="Gestion des magasins" pageTitle="Gestion des magasins">
      {/*<Typography variant="h2">Gestion des magasins</Typography>*/}
      <div className={classes.subtitle1Wrapper}>
        <Typography variant="subtitle1" paragraph>
          Complétez et modifiez les informations sur les magasins depuis cette
          page. Toute modification est appliquée sur les 2 bases de données (PMI
          + Mongo).
        </Typography>
      </div>
      <Paper>
        <DataGridView
          title="Liste des magasins"
          rowsValues={rows}
          columnHeaders={columns}
          currentPage={currentPage}
          onCurrentPageChange={changeCurrentPage}
          pageSize={pageSize}
          pageSizes={pageSizes}
          onPageSizeChange={changePageSize}
          filters={filters}
          onFiltersChange={changeFilters}
          sorting={sorting}
          onSortingChange={changeSorting}
          totalCount={totalCount}
          loading={loading}
          numberColumns={numberColumns}
          rowsPerPage="Nb magasins par page:"
          contentInfoSupplier={contentInfo}
          columnExtensions={columnExtensions}
          withPagination={true}
          hasActions={true}
          actions={actions}
          noDataText="pas de magasin"
        />
      </Paper>

      <Dialog
        fullScreen
        open={dialogOpen}
        onClose={() => handleModalClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              color="inherit"
              onClick={() => handleModalClose()}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              Magasin n° {clientCode}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogTitle id="alert-dialog-title">
          Magasin n° {clientCode}
        </DialogTitle>
        <DialogContent>
          <ClientDetail
            clientCode={clientCode}
            onHandleModalClose={(action) => handleModalClose(action)}
            readOnly={userRole !== "master" && userRole !== "superuser"}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleModalClose()} color="primary" autoFocus>
            fermer
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
        open={snackBarOpen}
        onClose={handleSnackBarClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackBarText}</span>}
      />
    </Page>
  );
};

export default Clients;
