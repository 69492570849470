import React from "react";
import Switch from "@material-ui/core/Switch";

export default ({
  input: { checked, name, onChange: onInputChange, ...restInput },
  meta,
  onChange,
  ...rest
}) => {
  const handleChange = (event, checked) => {
    onInputChange(event);
    onChange && onChange(checked);
  };

  return (
    <Switch {...rest} name={name} onChange={handleChange} checked={!!checked} />
  );
};
