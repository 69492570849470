import React, { useState } from "react";
import Parse from "parse";
import { goToPage } from "../../actions/main";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../../actions/main";
import { makeStyles, Typography, Button } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  textField: {
    width: "100%",
    marginTop: 25,
  },

  fieldContainer: {
    display: "flex",
    flex: 1,
  },
  buttonWrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    margin: "2rem 1rem 1rem 0",
  },
  forgottenPassword: {
    lineHeight: "36px",
    marginLeft: "1rem",
    cursor: "pointer",
  },
  okMessage: {
    textAlign: "center",
    color: "green",
    margin: "2rem 0 0",
  },
  errorMessage: {
    textAlign: "center",
    color: "red",
  },
}));

const LoginForm = (props) => {
  const { onForgottenPassword, isPasswordReset } = props;
  const classes = useStyles();
  const [invalidUser, setInvalidUser] = useState(false);
  const [activatedUser, setActivatedUser] = useState(true);
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    try {
      const params = {
        email: values.email,
      };
      const query = await Parse.Cloud.run("isUserActivated", params);
      if (query) {
        await Parse.User.logIn(values.email, values.password);
        dispatch(setCurrentUser(Parse.User.current()));
        goToPage("/");
      } else {
        setActivatedUser(false);
      }
    } catch (error) {
      if (error.message === "Invalid username/password.") {
        setInvalidUser(true);
      } else {
        console.log("blam " + error.message);
      }
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{}}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form id="loginForm" onSubmit={handleSubmit}>
          <div className={classes.fieldContainer}>
            <Field
              name="email"
              component={TextField}
              type="email"
              placeholder="Adresse email"
              className={classes.textField}
              required
            />
          </div>
          <div className={classes.fieldContainer}>
            <Field
              name="password"
              component={TextField}
              type="password"
              placeholder="Mot de passe"
              className={classes.textField}
              required
            />
          </div>
          <div className={classes.buttonWrapper}>
            <Button
              type="submit"
              disabled={submitting || pristine}
              variant="contained"
              color="primary"
            >
              se loguer
            </Button>
            <Typography
              variant="body2"
              className={classes.forgottenPassword}
              onClick={onForgottenPassword}
            >
              mot de passe oublié ?
            </Typography>
          </div>
          {isPasswordReset && (
            <Typography variant="body2" className={classes.okMessage}>
              Mot de passe mis à jour avec succès, vous pouvez vous loguer.
            </Typography>
          )}
          {invalidUser && (
            <Typography variant="body2" className={classes.errorMessage}>
              utilisateur inconnu ou mot de passe erroné
            </Typography>
          )}
          {!activatedUser && (
            <Typography variant="body2" className={classes.errorMessage}>
              L'utilisateur n'est pas activé
            </Typography>
          )}
        </form>
      )}
    />
  );
};

export default LoginForm;

// class LoginForm extends PureComponent {
//   constructor(props) {
//     super(props);
//     this.state = {
//       initialValues: {},
//       invalidUser: false,
//       activatedUser: true
//     };
//   }
//
//   onSubmit = async values => {
//     try {
//       const params = {
//         email: values.email
//       };
//       const query = await Parse.Cloud.run("isUserActivated", params);
//       if (query) {
//         await Parse.User.logIn(values.email, values.password);
//         console.log('going to /');
//         goToPage('/');
//       } else {
//         this.setState({
//           activatedUser: false
//         });
//       }
//     } catch (error) {
//       if (error.message === 'Invalid username/password.') {
//         this.setState({
//           invalidUser: true
//         });
//       } else {
//         console.log('blam ' + error.message);
//       }
//     }
//   };
//
//   render() {
//     const { initialValues, invalidUser, activatedUser } = this.state;
//     const { classes, onForgottenPassword, isPasswordReset } = this.props;
//
//
//     return (
//       <Form
//         onSubmit={this.onSubmit}
//         initialValues={initialValues}
//         render={({ handleSubmit, form, submitting, pristine, values }) => (
//           <form id="loginForm" onSubmit={handleSubmit}>
//             <div className={classes.fieldContainer}>
//               <Field
//                 name="email"
//                 component={TextField}
//                 type="email"
//                 placeholder="Adresse email"
//                 className={classes.textField}
//                 required
//               />
//             </div>
//             <div className={classes.fieldContainer}>
//               <Field
//                 name="password"
//                 component={TextField}
//                 type="password"
//                 placeholder="Mot de passe"
//                 className={classes.textField}
//                 required
//               />
//             </div>
//             <div className={classes.buttonWrapper}>
//               <Button type="submit" disabled={submitting || pristine} variant="contained" color="primary">se loguer</Button>
//               <Typography variant="body2" className={classes.forgottenPassword} onClick={onForgottenPassword}>mot de passe oublié ?</Typography>
//             </div>
//             {isPasswordReset && <Typography variant="body2" className={classes.okMessage}>Mot de passe mis à jour avec succès, vous pouvez vous loguer.</Typography>}
//             {invalidUser && <Typography variant="body2" className={classes.errorMessage}>utilisateur inconnu ou mot de passe erroné</Typography>}
//             {!activatedUser && <Typography variant="body2" className={classes.errorMessage}>L'utilisateur n'est pas activé</Typography>}
//           </form>
//         )}
//       />
//     );
//   }
// }
//
// export default withStyles(styles)(LoginForm);
