import React from "react";
import Page from "./Page";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../reducers/currentUser";
import { makeStyles, Paper, Typography } from "@material-ui/core";
import ContactsList from "../../components/contacts/ContactsList";

const useStyles = makeStyles(() => ({
  subtitle1Wrapper: {
    padding: "12px 0 24px",
  },
}));
const Contacts = () => {
  const classes = useStyles();
  const currentUser = useSelector(getCurrentUser);

  if (!currentUser.get("contactAble")) {
    return null;
  }

  return (
    <Page toolBarTitle="Gestion des contacts" pageTitle="Gestion des contacts">
      <div className={classes.subtitle1Wrapper}>
        <Typography variant="subtitle1" paragraph>
          Modifiez ou supprimez des contacts depuis cette page.
        </Typography>
      </div>
      <Paper>
        <ContactsList />
      </Paper>
    </Page>
  );
};

export default Contacts;
