import React, { PureComponent } from "react";
import Page from "../Page";
import moment from "moment";
import Parse from "parse";
import { connect } from "react-redux";
import { getCurrentUser } from "../../../reducers/currentUser";
import {
  withStyles,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Snackbar,
  IconButton,
  Tooltip,
  Switch,
} from "@material-ui/core";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import DataGridView from "../../../components/reactgrid/DataGridView";
import ResuppliesForm from "../../form/ResuppliesForm";
import LocalProductionTimeForm from "../../form/LocalProductionTimeForm";
import WebSecurityStockForm from "../../form/WebSecurityStockForm";
import { debounce } from "lodash";
import { setNumberColumns, filtersQuery } from "../../../utils2";
import ProductIcons from "../../../components/product/ProductsIcons";
import { getArticlesMinStock } from "../../../utils/generateSuppliesData/getArticlesMinStock";
import { generateSuppliesData } from "../../../utils/generateSuppliesData/generateSuppliesData";

const styles = () => ({
  subtitle1Wrapper: {
    padding: "12px 0 24px",
  },
  defaultValue: {
    color: "#aaa",
  },
  of: {
    color: "#aaa",
    fontStyle: "italic",
  },
});

const createMonthsArray = (start, monthsCount) => {
  const monthsArray = [];
  let count = 0;
  while (count < monthsCount) {
    const name = moment(start).format("MM/YYYY");
    const title = moment(start).format("MMM YYYY");
    monthsArray.push({
      name: name,
      title: title,
    });
    count++;
    start.add(1, "months");
  }
  return monthsArray;
};

const monthsArray = createMonthsArray(moment(), 9);

const columnsMonthsExt = monthsArray.map((month) => {
  return { columnName: month.name, width: 120 };
});

const firstColumns = [
  { name: "action", title: " " },
  { name: "ARKTCODART", title: "Produit" },
  { name: "ARKTCOMART", title: "Art." },
  { name: "ARCTLIB01", title: "Modèle" },
  { name: "catalog", title: "Catalogue" },
  { name: "quantity", title: "Qté à J0" },
  { name: "onUnshippedOrders", title: "Qté en com." },
  { name: "webSecurityStock", title: "Stock Sécurité internet" },
  { name: "forceSale", title: "Forcer MAG en B2C" },
  { name: "localProductionTime", title: "Délai prod. locale" },
];
const firstColumnsExt = [
  { columnName: "action", width: 155, filteringEnabled: false },
  { columnName: "ARKTCODART", width: 90 },
  { columnName: "ARKTCOMART", width: 70, filteringEnabled: false },
  { columnName: "ARCTLIB01", width: 200, wordWrapEnabled: true },
  { columnName: "catalog", width: 120 },
  { columnName: "quantity", width: 70, wordWrapEnabled: true },
  { columnName: "onUnshippedOrders", width: 80, wordWrapEnabled: true },
  { columnName: "webSecurityStock", width: 95, wordWrapEnabled: true },
  { columnName: "forceSale", width: 95, wordWrapEnabled: true },
  { columnName: "localProductionTime", width: 125, wordWrapEnabled: true },
];

const columns = firstColumns.concat(monthsArray);
const columnExtensions = firstColumnsExt.concat(columnsMonthsExt);

class Resupplies extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columns,
      columnExtensions,
      fixedLeftColumns: [
        "action",
        "ARKTCODART",
        "ARKTCOMART",
        "ARCTLIB01",
        "catalog",
        "quantity",
        "onUnshippedOrders",
        "webSecurityStock",
        "forceSale",
        "localProductionTime",
      ],
      numberRedNegativeColumns: ["quantity"],
      filters: [],
      sorting: [{ columnName: "ARKTCODART", direction: "asc" }],
      loading: true,
      lastTableState: "",
      lastFilterSortingState: "",
      snackBarOpen: false,
      dialogOpen: false,
    };
    this.changeFilters = this.changeFilters.bind(this);
    this.changeSorting = this.changeSorting.bind(this);
  }

  componentDidMount() {
    setNumberColumns.call(this);
    this.getProducts();
  }
  componentDidUpdate() {
    this.getProductsDebounced();
  }

  /********************************************************/
  /*********************** Table data *********************/
  /********************************************************/
  changeFilters(filters) {
    this.setState({
      loading: true,
      filters,
    });
  }
  changeSorting(sorting) {
    this.setState({
      loading: true,
      sorting,
    });
  }

  buildMonthCellContent = (month, resupplies, destination) => {
    const { classes } = this.props;
    destination[month.name] = "-";

    if (resupplies !== undefined) {
      resupplies.forEach((article) => {
        const ARKTCOMART = destination.ARKTCOMART;
        if (ARKTCOMART === article.ARKTCOMART) {
          if (article.scheduledResupplies !== undefined) {
            article.scheduledResupplies.forEach((resupply, index) => {
              if (moment(resupply.date).format("MM/YYYY") === month.name) {
                const dateToDisplay = moment(resupply.date).format("DD/MM");
                if (destination[month.name] === "-") {
                  destination[month.name] = [];
                }
                destination[month.name].push(
                  <Typography key={"sr" + index} variant="body2">
                    {resupply.quantity} u. le {dateToDisplay}
                  </Typography>
                );
              }
            });
          }
          if (article.productionOrders !== undefined) {
            article.productionOrders.forEach((productionOrder, index) => {
              if (
                moment(productionOrder.date).format("MM/YYYY") === month.name
              ) {
                const dateToDisplay = moment(productionOrder.date).format(
                  "DD/MM"
                );
                if (destination[month.name] === "-") {
                  destination[month.name] = [];
                }
                destination[month.name].push(
                  <Tooltip
                    key={"po" + index}
                    title={"ordre de production " + productionOrder.orderRef}
                  >
                    <Typography variant="body2" className={classes.of}>
                      {productionOrder.quantity} u. le {dateToDisplay}
                    </Typography>
                  </Tooltip>
                );
              }
            });
          }
        }
      });
    }
  };

  /**
   * @param {boolean} checked
   * @param {string} ARKTCODART
   * @param {import("../../../types/Product").ProductArticle} [article] - Optional
   * @returns {JSX.Element}
   */
  buildForceSale = (checked, ARKTCODART, article) => (
    <Switch
      checked={checked}
      onChange={() => this.handleForceSale(!checked, ARKTCODART, article)}
    />
  );

  buildLocalProductionTimeCellContent = (localProductionTimeObj) => {
    if (localProductionTimeObj !== undefined) {
      return (
        <Typography variant="body2">
          {localProductionTimeObj.quantity} u. sous{" "}
          {localProductionTimeObj.days} j.
        </Typography>
      );
    } else {
      return "";
    }
  };

  // getting products from mongo
  getProducts = async () => {
    const { filters, sorting, lastTableState } = this.state;
    const { classes } = this.props;
    const filterState = JSON.stringify(filters);
    const sortingState = JSON.stringify(sorting);
    const tableState = filterState + sortingState;

    if (lastTableState === tableState) {
      // filters didn't change
      this.setState({ loading: false });
      return;
    }

    const query = new Parse.Query("Products");
    filtersQuery.call(this, query); // making a call to pass this
    query
      .limit(1000000)
      .select(
        "ARKTCODART",
        "ARCTLIB01",
        "catalog",
        "articles",
        "resupplies",
        "webSecurityStock",
        "supplies",
        "forceSale"
      )
      .notEqualTo("isDeprecated", true);
    const rawProducts = await query.find();
    const products = [];

    /* internal function */
    const buildRow = (product, article, minStockArticles) => {
      let toBeDisplayed = true;
      /** @type {import("../../../types/Product").ProductArticle[]} */
      const supplies = product.get("supplies");
      const resupplies = product.get("resupplies");
      const hasArticle = article !== undefined;
      const ARKTCODART = product.get("ARKTCODART");
      const ARKTCOMART = hasArticle ? article.ARKTCOMART : "";
      const webSecurityStock =
        (hasArticle
          ? article.webSecurityStock
          : product.get("webSecurityStock")) || 0;

      // checking if we should display the article
      if (resupplies !== undefined) {
        resupplies.forEach((resupply) => {
          if (
            resupply.ARKTCOMART === ARKTCOMART &&
            resupply.isVisibleInRessuplySection !== undefined &&
            !resupply.isVisibleInRessuplySection
          ) {
            toBeDisplayed = false;
          }
        });
      }

      if (toBeDisplayed) {
        const row = {};
        row.ARKTCODART = ARKTCODART;
        row.ARCTLIB01 =
          hasArticle && article.ARCTLIB02 !== undefined
            ? product.get("ARCTLIB01") + " (" + article.ARCTLIB02 + ")"
            : product.get("ARCTLIB01");
        row.ARKTCOMART = ARKTCOMART;
        row.catalog = product.get("catalog");

        row.action = (
          <>
            <Tooltip title={"masquer l'article"} placement="top">
              <IconButton
                aria-label="Delete"
                onClick={() =>
                  this.handleClickOpenByButton(ARKTCODART, ARKTCOMART)
                }
              >
                <VisibilityOffIcon />
              </IconButton>
            </Tooltip>
            <ProductIcons
              key={ARKTCODART}
              productId={ARKTCODART}
              showCard
              showChart
              chartWithNegativeValues
            />
          </>
        );
        const minStock = minStockArticles[ARKTCOMART] || 0;
        row.quantity =
          minStock - webSecurityStock > 0 ? minStock - webSecurityStock : 0;
        Object.keys(supplies).forEach((articleId) => {
          if (supplies[articleId].ARKTCOMART === ARKTCOMART) {
            row.onUnshippedOrders =
              supplies[articleId].onUnshippedOrders.quantity;
          }
        });
        row.webSecurityStock =
          webSecurityStock !== undefined ? webSecurityStock : 0;

        row.forceSale = this.buildForceSale(
          (article ? article.forceSale : product.get("forceSale")) || false,
          ARKTCODART,
          article
        );

        if (resupplies !== undefined) {
          resupplies.forEach((resupply) => {
            if (resupply.ARKTCOMART === row.ARKTCOMART) {
              row.localProductionTimeObj = resupply.localProductionTime;
            }
          });
        }
        row.localProductionTime = this.buildLocalProductionTimeCellContent(
          row.localProductionTimeObj
        );
        row.resupplies = resupplies; // necessary for ResuppliesForm
        monthsArray.forEach((month) => {
          this.buildMonthCellContent(month, resupplies, row);
        });
        products.push(row);
      }
    };

    rawProducts.forEach((product) => {
      const articles = product.attributes.articles;
      const minStockArticles = getArticlesMinStock(
        generateSuppliesData({
          supplies: product.attributes.supplies,
          resupplies: product.attributes.resupplies,
        })
      );
      if (articles.length === 0) {
        // product with no article
        buildRow(product, undefined, minStockArticles);
      } else {
        articles.forEach((article) => {
          buildRow(product, article, minStockArticles);
        });
      }
    });
    this.setState({
      products,
      lastTableState: tableState,
    });
  };

  getProductsDebounced = debounce(this.getProducts, 300);

  updateStateProduct = (type, ARKTCODART, ARKTCOMART, valueToUpdate) => {
    const { products } = this.state;
    let newProducts = products.slice();
    newProducts.forEach((product) => {
      if (
        product.ARKTCODART === ARKTCODART &&
        product.ARKTCOMART === ARKTCOMART
      ) {
        if (type === "resupplies") {
          product.resupplies = valueToUpdate;
          monthsArray.forEach((month) => {
            this.buildMonthCellContent(month, product.resupplies, product);
          });
        } else if (type === "webSecurityStock" || type === "forceSale") {
          product[type] = valueToUpdate;
        } else if (type === "threshold") {
        } else if (type === "localProductionTime") {
          product.localProductionTime =
            this.buildLocalProductionTimeCellContent(valueToUpdate);
          product.localProductionTimeObj = valueToUpdate;
        }
      }
    });
    this.setState({
      products: newProducts,
    });
  };

  // this hides a row and set the corresponding product/article to invisible in product.supplies
  hideProduct = async (ARKTCODART, ARKTCOMART) => {
    const { products } = this.state;

    // update database for future displays
    const query = new Parse.Query("Products");
    const product = await query.equalTo("ARKTCODART", ARKTCODART).first();

    let resupplies = query.get("resupplies");
    let articleFound = false;
    if (resupplies !== undefined) {
      resupplies.forEach((article) => {
        if (article.ARKTCOMART === ARKTCOMART) {
          articleFound = true;
          article.isVisibleInRessuplySection = false;
        }
      });
    }
    if (!articleFound) {
      const dataToPush = {
        ARKTCOMART,
        quantity: 0,
        scheduledResupplies: [],
        isVisibleInRessuplySection: false,
      };
      if (resupplies === undefined) {
        resupplies = [];
      }
      resupplies.push(dataToPush);
    }
    query.set("resupplies", resupplies);

    try {
      await query.save();
    } catch (error) {
      console.log(error.message);
    }

    // hides the row
    let newProducts = products.slice();
    newProducts.forEach((product, index) => {
      if (
        product.ARKTCODART === ARKTCODART &&
        product.ARKTCOMART === ARKTCOMART
      ) {
        newProducts.splice(index, 1);
      }
    });
    this.setState({
      products: newProducts,
    });
  };

  // when dialog is called
  handleClickOpenByCell = (props) => {
    const title =
      props.row.ARKTCOMART === ""
        ? props.row.ARCTLIB01 +
          " (" +
          props.row.ARKTCODART +
          ") - " +
          props.column.title
        : props.row.ARCTLIB01 +
          " (" +
          props.row.ARKTCODART +
          " " +
          props.row.ARKTCOMART +
          ") - " +
          props.column.title;
    // if it's a month column
    if (props.column.name.match(/\d{2}\/\d{4}/g)) {
      this.setState({
        dialogOpen: true,
        dialogType: "resupplyUpdate",
        dialogTitle: title,
        dialogText: "",
        dialogYesBtnText: "mettre à jour",
        dialogNoBtnText: "annuler",
        dialogARKTCODART: props.row.ARKTCODART,
        dialogARKTCOMART: props.row.ARKTCOMART,
        dialogResupplies: props.row.resupplies,
        dialogMonth: props.column.name,
      });
    } else if (props.column.name === "webSecurityStock") {
      // if it's for webSecurityStock (stoxk sécurité internet)
      this.setState({
        dialogOpen: true,
        dialogType: "webSecurityStockUpdate",
        dialogTitle: title,
        dialogText: "",
        dialogYesBtnText: "mettre à jour",
        dialogNoBtnText: "annuler",
        dialogARKTCODART: props.row.ARKTCODART,
        dialogARKTCOMART: props.row.ARKTCOMART,
        dialogWebSecurityStock: props.row.webSecurityStock,
      });
    } else if (props.column.name === "localProductionTime") {
      // if it's for localProductionTime (délai de production locale)
      this.setState({
        dialogOpen: true,
        dialogType: "localProductionTimeUpdate",
        dialogTitle: title,
        dialogText: "",
        dialogYesBtnText: "mettre à jour",
        dialogNoBtnText: "annuler",
        dialogARKTCODART: props.row.ARKTCODART,
        dialogARKTCOMART: props.row.ARKTCOMART,
        dialogLocalProductionTime: props.row.localProductionTimeObj,
      });
    }
  };

  handleClickOpenByButton = (ARKTCODART, ARKTCOMART) => {
    const product =
      ARKTCOMART !== "" ? ARKTCODART + " (" + ARKTCOMART + ")" : ARKTCODART;
    this.setState({
      dialogOpen: true,
      dialogType: "hideProduct",
      dialogTitle: "Masquer " + product,
      dialogText: (
        <>
          <span>
            Souhaitez-vous masquer l'article {product} de façon permanente ?
          </span>
          <br />
          <span>
            L'article pourra être démasqué dans la catégorie Produits &gt;
            Informations.
          </span>
        </>
      ),
      dialogYesBtnText: "masquer",
      dialogNoBtnText: "annuler",
      dialogARKTCODART: ARKTCODART,
      dialogARKTCOMART: ARKTCOMART,
    });
  };

  // when closing dialog box
  handleModalClose = async (action) => {
    switch (action) {
      case "resupplyUpdated":
        this.handleSnackBarShow("réappro mis à jour");
        await this.getProducts();
        break;
      case "webSecurityStockUpdated":
        this.handleSnackBarShow("stock sécurité internet mis à jour");
        await this.getProducts();
        break;
      case "thresholdUpdated":
        this.handleSnackBarShow("seuil de criticité mis à jour");
        await this.getProducts();
        break;
      case "localProductionTimeUpdated":
        this.handleSnackBarShow("délai de production local mis à jour");
        await this.getProducts();
        break;
      case "lineHidden":
        const { dialogARKTCODART, dialogARKTCOMART } = this.state;
        const product =
          dialogARKTCOMART !== ""
            ? dialogARKTCODART + " (" + dialogARKTCOMART + ")"
            : dialogARKTCODART;
        this.handleSnackBarShow("article " + product + " masqué");
        break;
      default:
    }
    this.setState({ dialogOpen: false });
  };

  handleModalValidate = async () => {
    const { dialogType, dialogARKTCODART, dialogARKTCOMART } = this.state;

    if (dialogType === "hideProduct") {
      this.hideProduct(dialogARKTCODART, dialogARKTCOMART);
      this.handleModalClose("lineHidden");
    } else {
      // just triggering form to launch onSubmit
      document
        .getElementById("editForm")
        .dispatchEvent(new Event("submit", { cancelable: true }));
    }
  };

  /********************************************************/
  /************************ SnackBar **********************/
  /********************************************************/

  handleSnackBarShow = (textValue) => {
    this.setState({ snackBarOpen: true, snackBarText: textValue });
  };

  handleSnackBarClose = () => {
    this.setState({ snackBarOpen: false });
  };

  /**
   * @param {boolean} checked
   * @param {string} ARKTCODART
   * @param {import("../../../types/Product").ProductArticle} [article] - Optional
   */
  handleForceSale = async (checked, ARKTCODART, article) => {
    const query = new Parse.Query("Products");
    const product = await query.equalTo("ARKTCODART", ARKTCODART).first();
    if (article)
      product.set(
        "articles",
        product.get("articles").map((_article) =>
          _article.ARKTCOMART === article.ARKTCOMART
            ? {
                ..._article,
                forceSale: checked,
              }
            : _article
        )
      );
    else product.set("forceSale", checked);
    this.updateStateProduct(
      "forceSale",
      ARKTCODART,
      article?.ARKTCOMART || "",
      this.buildForceSale(checked, ARKTCODART, article)
    );
    try {
      await product.save();
    } catch (error) {
      console.log(error.message);
    }
  };

  render() {
    const {
      dialogOpen,
      dialogTitle,
      dialogText,
      dialogYesBtnText,
      dialogNoBtnText,
      dialogARKTCODART,
      dialogARKTCOMART,
      dialogMonth,
      dialogResupplies,
      dialogType,
      dialogWebSecurityStock,
      dialogThreshold,
      dialogLocalProductionTime,
      snackBarOpen,
      snackBarText,
      products,
      columns,
      columnExtensions,
      fixedLeftColumns,
      loading,
      filters,
      sorting,
      numberColumns,
      numberRedNegativeColumns,
    } = this.state;
    const { classes, title, currentUser } = this.props;
    console.log("currentUser: ", currentUser);

    if (
      !products ||
      currentUser === undefined ||
      !currentUser.get("resupplyAble")
    ) {
      return null;
    }

    return (
      <Page toolBarTitle="Gestion des réappro" pageTitle="Gestion des réappro">
        <div className={classes.subtitle1Wrapper}>
          <Typography variant="subtitle1" paragraph>
            Mettez à jour les réapprovisionnements des produits et articles pour
            les 6 prochains mois depuis cette page.
            <br />
            Les produits masqués depuis cette page peuvent être découverts dans
            Produits &gt; Informations.
          </Typography>
        </div>

        <DataGridView
          title={title}
          rowsValues={products}
          columnHeaders={columns}
          isVirtualized={true}
          height={700}
          withPagination={false}
          filters={filters}
          onFiltersChange={this.changeFilters}
          sorting={sorting}
          onSortingChange={this.changeSorting}
          loading={loading}
          numberColumns={numberColumns}
          numberRedNegativeColumns={numberRedNegativeColumns}
          withFixedColumns={true}
          fixedLeftColumns={fixedLeftColumns}
          columnExtensions={columnExtensions}
          hasActions={false}
          withOnCellClickFunction
          onCellClickFunction={this.handleClickOpenByCell}
        />

        <Dialog
          open={dialogOpen}
          onClose={() => this.handleModalClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogText}
            </DialogContentText>

            {dialogType === "resupplyUpdate" && (
              <ResuppliesForm
                ARKTCODART={dialogARKTCODART}
                ARKTCOMART={dialogARKTCOMART}
                month={dialogMonth}
                resupplies={dialogResupplies}
                onHandleModalClose={this.handleModalClose}
                onSubmitUpdate={this.updateStateProduct}
              />
            )}
            {dialogType === "webSecurityStockUpdate" && (
              <WebSecurityStockForm
                ARKTCODART={dialogARKTCODART}
                ARKTCOMART={dialogARKTCOMART}
                webSecurityStock={dialogWebSecurityStock}
                onHandleModalClose={this.handleModalClose}
                onSubmitUpdate={this.updateStateProduct}
              />
            )}
            {dialogType === "localProductionTimeUpdate" && (
              <LocalProductionTimeForm
                ARKTCODART={dialogARKTCODART}
                ARKTCOMART={dialogARKTCOMART}
                localProductionTime={dialogLocalProductionTime}
                onHandleModalClose={this.handleModalClose}
                onSubmitUpdate={this.updateStateProduct}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleModalValidate()} color="primary">
              {dialogYesBtnText}
            </Button>
            <Button
              onClick={() => this.handleModalClose()}
              color="primary"
              autoFocus
            >
              {dialogNoBtnText}
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          autoHideDuration={3000}
          open={snackBarOpen}
          onClose={this.handleSnackBarClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{snackBarText}</span>}
        />
      </Page>
    );
  }
}

export default connect(
  (state) => ({
    currentUser: getCurrentUser(state),
  }),
  {}
)(withStyles(styles)(Resupplies));
