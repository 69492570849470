import React, { PureComponent } from "react";
import Parse from "parse";
import moment from "moment";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import {
  withStyles,
  Typography,
  Grid,
  IconButton,
  Divider,
  Button,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

const styles = (theme) => ({
  textField: {
    width: 400,
  },
  selectField: {
    width: 180,
  },
  fieldContainer: {
    display: "flex",
    flex: 1,
    marginRight: 24,
  },
  fieldText: {
    lineHeight: "48px",
  },
  gridLine: {
    padding: "8px 0",
    lineHeight: 2,
  },
  category: {
    margin: "3rem 0 0 0",
  },
  buttonContainer: {
    alignSelf: "flex-end",
  },
  errorMessage: {
    color: "red",
    textAlign: "center",
    margin: "1rem 0 0",
  },
  warning: {
    color: "red",
    textAlign: "center",
    margin: "0 0 1rem 0",
  },
});

class ResuppliesForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ARKTCODART: this.props.ARKTCODART,
      ARKTCOMART: this.props.ARKTCOMART,
      month: this.props.month,
      monthNumber: moment(this.props.month, "MM/YY").format("MM"),
      resupplies: this.props.resupplies,
    };
  }

  onSubmit = async () => {
    const { resupplies, ARKTCODART, ARKTCOMART } = this.state;
    const { onHandleModalClose, onSubmitUpdate } = this.props;

    const query = await new Parse.Query("Products")
      .equalTo("ARKTCODART", ARKTCODART)
      .first();

    query.set("resupplies", resupplies);

    try {
      await query.save();
    } catch (error) {
      console.log(error.message);
    }
    const suppliesPerDate = await Parse.Cloud.run("updateCurrentSupplies", {
      ARKTCODART,
    });
    if (!suppliesPerDate) {
      console.log("error");
    }

    onHandleModalClose("resupplyUpdated");
    onSubmitUpdate("resupplies", ARKTCODART, ARKTCOMART, resupplies);
  };

  addResupply = (newResupply) => {
    const { resupplies, ARKTCOMART, month, monthNumber } = this.state;
    const newARKTCOMART = {
      ARKTCOMART: ARKTCOMART,
      quantity: 0,
      scheduledResupplies: [],
    };
    let futureResupplies =
      resupplies !== undefined ? resupplies.slice() : [newARKTCOMART];

    // checking if current ARKTCOMART exists in resupplies
    let ARKTCOMARTExists = false;
    futureResupplies.forEach((article) => {
      if (article.ARKTCOMART === ARKTCOMART) {
        ARKTCOMARTExists = true;
      }
    });

    if (!ARKTCOMARTExists) {
      futureResupplies.push(newARKTCOMART);
    }
    // checking if day already has a resupply. If so we replace the quantity by the new one.
    let dayOfResupplyExists = false;
    futureResupplies.forEach((article) => {
      if (article.ARKTCOMART === ARKTCOMART) {
        if (article.scheduledResupplies !== undefined) {
          article.scheduledResupplies.forEach((resupply) => {
            const currentResupplyMonth = moment(resupply.date).format("MM");
            if (currentResupplyMonth === monthNumber) {
              if (!dayOfResupplyExists) {
                const resupplyDay = moment(resupply.date).format("DD");
                // if day is already defined, we replace existing quantity
                if (resupplyDay === newResupply.resupplyDay) {
                  dayOfResupplyExists = true;
                  resupply.quantity = parseInt(newResupply.quantity);
                }
              }
            }
          });
        } else {
          // scheduledResupplies might not exist yet
          article.scheduledResupplies = [];
        }
        // if day not defined yet, we add the resupply
        if (!dayOfResupplyExists) {
          const dayNumber = newResupply.resupplyDay;
          const year = moment(month, "MM/YYYY").format("YYYY");
          const date = moment
            .utc(monthNumber + "-" + dayNumber + "-" + year)
            .toDate();
          // console.log('date: ', date);

          futureResupplies.forEach((article) => {
            if (article.ARKTCOMART === ARKTCOMART) {
              article.scheduledResupplies.push({
                date: date,
                quantity: parseInt(newResupply.quantity),
              });
            }
          });
        }
        // updating totalQuantity
        article.quantity = 0;
        article.scheduledResupplies.forEach((resupply) => {
          article.quantity += parseInt(resupply.quantity);
        });
      }
    });
    this.setState({
      resupplies: futureResupplies,
    });
    // emptying optionForm
    // document.getElementById('resupplyForm').dispatchEvent(new Event('reset'));
  };

  deleteResupply = (dateOfElementToRemove) => {
    const { resupplies, ARKTCOMART } = this.state;
    let futureResupplies = resupplies.slice();

    futureResupplies.forEach((article) => {
      if (article.ARKTCOMART === ARKTCOMART) {
        article.scheduledResupplies.forEach((resupply, index) => {
          if (dateOfElementToRemove === resupply.date) {
            article.scheduledResupplies.splice(index, 1);
          }
        });
        // updating totalQuantity
        article.quantity = 0;
        article.scheduledResupplies.forEach((resupply) => {
          article.quantity += parseInt(resupply.quantity);
        });
      }
    });
    this.setState({
      resupplies: futureResupplies,
    });
  };

  render() {
    const { resupplies, ARKTCOMART, monthNumber } = this.state;
    const { classes } = this.props;
    let resuppliesForCurrentMonth = [];

    if (resupplies === null) {
      return null;
    }

    if (resupplies !== undefined) {
      resupplies.forEach((article) => {
        if (article.ARKTCOMART === ARKTCOMART) {
          // keeping only resupplies for current month
          if (article.scheduledResupplies !== undefined) {
            article.scheduledResupplies.forEach((resupply) => {
              if (moment(resupply.date).format("MM") === monthNumber) {
                resuppliesForCurrentMonth.push(resupply);
              }
            });
          }
        }
      });
    }

    const resuppliesList = !!resuppliesForCurrentMonth.length
      ? resuppliesForCurrentMonth.map((resupply, index) => {
          return (
            <React.Fragment key={index}>
              <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={5} className={classes.gridLine}>
                  <Typography variant="body1" className={classes.gridLine}>
                    {resupply.quantity}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body1" className={classes.gridLine}>
                    {moment(resupply.date).format("Do")}
                  </Typography>
                </Grid>
                <Grid item xs={2} className={classes.gridLine}>
                  <IconButton
                    aria-label="Delete"
                    onClick={() => this.deleteResupply(resupply.date)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <div>
                <Divider />
              </div>
            </React.Fragment>
          );
        })
      : "";

    /**********************************************************/
    /*********************** validators ***********************/
    /**********************************************************/

    const required = (value) => (value ? undefined : "Champs obligatoire");
    const dayOfMonthRule = (value) =>
      value > 0 && value < 32
        ? undefined
        : "Le jour du mois doit être compris entre 1 et 31.";
    const positiveValue = (value) =>
      value > 0 ? undefined : "La quantité définie doit être supérieure à 0.";
    const composeValidators =
      (...validators) =>
      (value) =>
        validators.reduce(
          (error, validator) => error || validator(value),
          undefined,
        );

    return (
      <>
        <Form
          onSubmit={this.addResupply}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            reset,
          }) => (
            <form
              id="resupplyForm"
              onSubmit={(event) => {
                handleSubmit(event);
                // form.reset();
              }}
            >
              <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={4}>
                  <div className={classes.fieldContainer}>
                    <Field
                      name="quantity"
                      label="Quantité"
                      placeholder="Quantité"
                      type="number"
                      component={TextField}
                      className={classes.textField}
                      validate={composeValidators(required, positiveValue)}
                      inputRef={(input) => input && input.focus()}
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.fieldContainer}>
                    <Field
                      name="resupplyDay"
                      label="Jour"
                      placeholder="Jour"
                      type="number"
                      component={TextField}
                      className={classes.textField}
                      validate={composeValidators(required, dayOfMonthRule)}
                    />
                  </div>
                </Grid>
                <Grid item xs={3} className={classes.buttonContainer}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={submitting || pristine}
                  >
                    ajouter
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={12}>
                  {resuppliesList}
                </Grid>
              </Grid>
            </form>
          )}
        />
        {/* next form is just there to initiate onSubmit when dialog validation is triggered */}
        <Form
          onSubmit={this.onSubmit}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            invalid,
          }) => <form id="editForm" onSubmit={handleSubmit} />}
        />
      </>
    );
  }
}
export default withStyles(styles)(ResuppliesForm);
