import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import { Form, Field, FormSpy } from "react-final-form";
import { TextField } from "final-form-material-ui";
import AutoSave from "../../components/form/AutoSave";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "0.9rem",
    "& input": {
      textAlign: "center",
      backgroundColor: "#eee",
      borderRadius: "1rem",
    },
  },
}));

const useWarningStyles = makeStyles((theme) => ({
  root: {
    fontSize: "0.9rem",
    "& input": {
      textAlign: "center",
      backgroundColor: "#ffd9d9",
      color: "#ad0000",
      borderRadius: "1rem",
    },
  },
}));

const CountCellForm = ({
  count,
  onUpdateQuantity,
  availableQuantity,
  supplyPerDate,
}) => {
  const theme = useTheme();
  const normalClasses = useStyles({ theme });
  const warningClasses = useWarningStyles({ theme });
  const quantityAskedGreaterThanStock = count > 0 && availableQuantity < 0;
  const inStockQuantity = count + availableQuantity;
  const classes = quantityAskedGreaterThanStock
    ? warningClasses
    : normalClasses;
  const nextAvailableDataDayWithCurrentCount = supplyPerDate.find(
    (supply) => supply.quantity >= count,
  );
  const nextAvailableDeliveryDateWithCurrentCount =
    nextAvailableDataDayWithCurrentCount !== undefined
      ? nextAvailableDataDayWithCurrentCount.formattedDay
      : undefined;
  // used by FormSpy
  const onSubmit = async (values) => {
    let newQuantity = values.quantity;
    if (!isNaN(newQuantity)) {
      onUpdateQuantity(parseInt(newQuantity));
    } else {
      onUpdateQuantity(0);
    }
  };
  const createForm = () => {
    return (
      <Form
        onSubmit={onSubmit}
        initialValues={{ quantity: count }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <FormSpy
              subscription={{ values: true }}
              component={AutoSave}
              onFormChange={onSubmit}
              debounce={400}
            />
            <Field
              name="quantity"
              component={TextField}
              className={classes.textField}
              InputProps={{ classes, disableUnderline: true }}
            />
          </form>
        )}
      />
    );
  };

  if (quantityAskedGreaterThanStock) {
    let message = [];
    message.push(
      <div key="1">
        Le nombre de produits en stock à cette date est de {inStockQuantity} et
        vous en avez commandé {count}.
      </div>,
    );
    if (nextAvailableDeliveryDateWithCurrentCount !== undefined) {
      message.push(
        <div key="2">
          La prochaine date de disponibilité pour cette quantité est le{" "}
          {nextAvailableDeliveryDateWithCurrentCount}. Les produits ne pourront
          pas être envoyés avant cette date.
        </div>,
      );
    } else {
      message.push(
        <div key="2">
          Il n'y a pas de date de réappro prévue pour couvrir le nombre de
          produits que vous avez demandé.
        </div>,
      );
    }
    return (
      <Tooltip title={message} placement="top">
        <div>{createForm()}</div>
      </Tooltip>
    );
  }
  return createForm();
};

export default CountCellForm;
