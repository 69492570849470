import React from "react";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import moment from "moment";

const NumberFormatter = ({ locale, value }) => {
  if (value !== "") {
    return <>{new Intl.NumberFormat(locale).format(value)}</>;
  } else {
    return "";
  }
};
export function NumberProvider(props) {
  return <DataTypeProvider formatterComponent={NumberFormatter} {...props} />;
}

const NumberFormatterRedNegative = ({ locale, value }) => {
  if (value !== "") {
    if (value < 1) {
      return (
        <span style={{ color: "red" }}>
          {new Intl.NumberFormat(locale).format(value)}
        </span>
      );
    } else {
      return <>{new Intl.NumberFormat(locale).format(value)}</>;
    }
  } else {
    return "";
  }
};
export function NumberProviderRedNegative(props) {
  return (
    <DataTypeProvider
      formatterComponent={NumberFormatterRedNegative}
      {...props}
    />
  );
}

const PercentFormatter = ({ value }) => {
  if (value !== "") {
    return <span style={{ color: "green" }}>{value * 100} %</span>;
  } else {
    return "";
  }
};
export function PercentProvider(props) {
  return <DataTypeProvider formatterComponent={PercentFormatter} {...props} />;
}

const DateFormatter = ({ value }) => {
  if (value !== undefined) {
    return <>{moment(value).format("DD/MM/YYYY")}</>;
  } else {
    return "";
  }
};
export function DateProvider(props) {
  return <DataTypeProvider formatterComponent={DateFormatter} {...props} />;
}
