/* taken from https://stackoverflow.com/questions/50845136/how-to-add-a-button-inside-a-cell-in-the-devextreme-react-grid */

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@material-ui/core";
import { Getter, Template, Plugin } from "@devexpress/dx-react-core";
import { Table } from "@devexpress/dx-react-grid-material-ui";

const pluginDependencies = [{ name: "Table" }];

const ACTIONS_COLUMN_TYPE = "actionsColumnType";
const TABLE_HEADING_TYPE = "heading";
const TABLE_FILTER_TYPE = "filter";
const regexSymbol = /\((.*?)\)/;

function tableColumnsWithActions(tableColumns, width) {
  return [
    ...tableColumns,
    { key: ACTIONS_COLUMN_TYPE, type: ACTIONS_COLUMN_TYPE, width: width },
  ];
}

function isHeadingActionsTableCell(tableRow, tableColumn) {
  const tableRowType = regexSymbol.exec(tableRow.type.toString());
  return (
    tableRowType[1] === TABLE_HEADING_TYPE &&
    tableColumn.type === ACTIONS_COLUMN_TYPE
  );
}

function isFilterActionsTableCell(tableRow, tableColumn) {
  const tableRowType = regexSymbol.exec(tableRow.type.toString());
  return (
    tableRowType[1] === TABLE_FILTER_TYPE &&
    tableColumn.type === ACTIONS_COLUMN_TYPE
  );
}

function isActionsTableCell(tableRow, tableColumn) {
  const tableRowType = regexSymbol.exec(tableRow.type.toString());
  return (
    tableRowType[1] !== TABLE_HEADING_TYPE &&
    tableRowType[1] !== TABLE_FILTER_TYPE &&
    tableColumn.type === ACTIONS_COLUMN_TYPE
  );
}

export default class ActionsColumn extends PureComponent {
  render() {
    const { actions, width } = this.props;
    const tableColumnsComputed = ({ tableColumns }) =>
      tableColumnsWithActions(tableColumns, width);

    return (
      <Plugin name="ActionsColumn" dependencies={pluginDependencies}>
        <Getter name="tableColumns" computed={tableColumnsComputed} />

        <Template
          name="tableCell"
          predicate={({ tableRow, tableColumn }) =>
            isHeadingActionsTableCell(tableRow, tableColumn)
          }
        >
          <Table.Cell>Actions</Table.Cell>
        </Template>

        <Template
          name="tableCell"
          predicate={({ tableRow, tableColumn }) =>
            isFilterActionsTableCell(tableRow, tableColumn)
          }
        >
          <Table.Cell />
        </Template>

        <Template
          name="tableCell"
          predicate={({ tableRow, tableColumn }) =>
            isActionsTableCell(tableRow, tableColumn)
          }
        >
          {(params) => (
            <Table.Cell {...params} row={params.tableRow.row}>
              {actions.map((action, index) => {
                const rowData = params.tableRow;
                if (
                  action.displayIcon !== undefined &&
                  !action.displayIcon(rowData)
                ) {
                  return null;
                }
                // console.log('action.getIcon: ', action.getIcon);
                if (action.tooltip !== undefined) {
                  return (
                    <Tooltip title={action.tooltip} placement="top" key={index}>
                      <IconButton onClick={() => action.action(rowData)}>
                        {action.getIcon !== undefined &&
                          action.getIcon(rowData)}
                        {action.getIcon === undefined && action.icon}
                      </IconButton>
                    </Tooltip>
                  );
                }
                return (
                  <IconButton
                    onClick={() => action.action(rowData)}
                    key={index}
                  >
                    {action.getIcon !== undefined && action.getIcon(rowData)}
                    {action.getIcon === undefined && action.icon}
                  </IconButton>
                );
              })}
            </Table.Cell>
          )}
        </Template>
      </Plugin>
    );
  }
}
ActionsColumn.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.PropTypes.shape({
      icon: PropTypes.node,
      action: PropTypes.func.isRequired,
      displayIcon: PropTypes.func,
    }),
  ).isRequired,
  width: PropTypes.number,
};
ActionsColumn.defaultProps = {
  width: 120,
};
