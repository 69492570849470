import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { getProducts, getProductsToPreselect } from "../../reducers/order";
import { resetOrder } from "../../actions/main";
import {
  withStyles,
  Typography,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import HorizontalLinearStepper from "../HorizontalLinearStepper";
import ClockedOrderProcess from "../../containers/order/ClockedOrderProcess";
import DialogForForcedDiscounts from "../../components/order/DialogForForcedDiscounts";
import { createForcedDiscountData } from "../../utils2";

const styles = (theme) => ({
  toolbar: {
    backgroundColor: "#46494c", //'#02cfa3'
  },
});

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

class ClockedOrderDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      snackBarOpen: false,
      alertSnackBarOpen: false,
      arrayOfBlockedClients: [],
    };
  }

  /********************************************************/
  /*********************** Dialog *************************/
  /********************************************************/

  // when closing dialog box
  handleModalClose = async (action, data) => {
    const { setDialogOpen, resetOrder } = this.props;
    if (action === "orderConfirmed") {
      this.handleSnackBarShow("Commande confirmée");
    }
    if (action === "validated") {
      this.handleSnackBarShow("Commande validée");
    }
    if (action === "orderConfirmedAndValidated") {
      this.handleSnackBarShow("Commande confirmée et validée");

      // this will initiate a check to see if there's a forced clientDiscount and then ask if we should apply
      this.setState({
        forcedDiscountData: createForcedDiscountData(data), // data are shippings
      });
    }
    if (action === "orderCancelled") {
      this.handleSnackBarShow("Commande annulée");
    }
    if (action === "savingError") {
      this.handleSnackBarShow("Erreur lors de l'enregistrement");
    }
    if (action === "oneOfClientsIsBlocked") {
      this.setState({
        alertSnackBarOpen: true,
        arrayOfBlockedClients: data,
      });
    }
    setDialogOpen(false);
    resetOrder();
  };

  handleModalForForcedDiscountsClose = (action) => {
    if (action === "changeDiscount") {
      this.handleSnackBarShow("Remise(s) client mise(s) à jour");
    }
    if (action === "saveError") {
      this.handleSnackBarShow("Erreur lors de la sauvegarde");
    }
    this.setState({
      forcedDiscountData: undefined,
    });
  };

  /********************************************************/
  /************************ SnackBar **********************/
  /********************************************************/
  handleSnackBarShow = (textValue) => {
    this.setState({ snackBarOpen: true, snackBarText: textValue });
  };

  handleSnackBarClose = () => {
    this.setState({ snackBarOpen: false });
  };

  handleAlertSnackBarClose = () => {
    this.setState({ alertSnackBarOpen: false });
  };
  /********************************************************/
  /************************ Stepper ***********************/
  /********************************************************/
  getSteps = () => {
    return [
      "Choix des produits et Cadences",
      "Quantités",
      "Confirmation de commande",
    ];
  };

  getOptionalSteps = () => {
    return [];
  };

  getStepContent = (
    activeStep,
    handleBack,
    handleNext,
    handleSkip,
    contentHeight,
  ) => {
    const { productsOrder, productsToPreselect } = this.props;
    let enableButton = true;
    if (activeStep === 0) {
      enableButton =
        (productsOrder !== undefined && productsOrder.length) ||
        (productsOrder === undefined &&
          productsToPreselect !== undefined &&
          productsToPreselect.length);
    } /* else if (activeStep === 1) {
      console.log('here');
      let totalQuantityOrdered = 0;
      productsOrder.forEach(product => {
        totalQuantityOrdered += product.quantities.quantity;
      });
      disableButton = !totalQuantityOrdered;
    }*/
    return (
      <ClockedOrderProcess
        steps={this.getSteps()}
        activeStep={activeStep}
        optionalSteps={this.getOptionalSteps()}
        onHandleModalClose={this.handleModalClose}
        onHandleBack={handleBack}
        onHandleNext={handleNext}
        onHandleSkip={handleSkip}
        contentHeight={contentHeight}
        disableNextButton={!enableButton}
      />
    );
  };

  render() {
    const {
      snackBarOpen,
      snackBarText,
      forcedDiscountData,
      alertSnackBarOpen,
      arrayOfBlockedClients,
    } = this.state;
    const { classes, dialogOpen } = this.props;
    return (
      <>
        <Dialog
          fullScreen
          open={dialogOpen}
          onClose={() => this.handleModalClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <AppBar className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
              <IconButton
                color="inherit"
                onClick={() => this.handleModalClose("orderCancelled")}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.flex}>
                 Commande cadencée
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <HorizontalLinearStepper
              onGetSteps={this.getSteps()}
              onGetStepContent={this.getStepContent}
              optionalSteps={this.getOptionalSteps()}
              navigationIsExternal
              onHandleModalValidate={this.handleModalValidate}
              onHandleModalClose={this.handleModalClose}
            />
          </DialogContent>
        </Dialog>

        {forcedDiscountData !== undefined &&
          forcedDiscountData.forcedDiscountExists && (
            <DialogForForcedDiscounts
              forcedDiscountData={forcedDiscountData}
              onDialogClose={this.handleModalForForcedDiscountsClose}
            />
          )}

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          autoHideDuration={3000}
          open={snackBarOpen}
          onClose={this.handleSnackBarClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{snackBarText}</span>}
        />

        <Snackbar
          open={alertSnackBarOpen}
          autoHideDuration={15000}
          onClose={this.handleAlertSnackBarClose}
        >
          <Alert onClose={this.handleAlertSnackBarClose} severity="warning">
            {arrayOfBlockedClients.length === 1 && (
              <span>
                Suite à un blocage client La commande a pu être confirmée mais
                ne peut pas être validée. Veuillez contacter le responsable
                financier pour plus d'information.
              </span>
            )}
            {arrayOfBlockedClients.length > 1 && (
              <span>
                Suite au blocage d'au moins un des clients, la validation des
                commandes n'a pas pu avoir lieu. Ces dernières sont néanmoins
                bien confirmées. Veuillez contacter le responsable financier
                pour plus d'information.
              </span>
            )}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

export default connect(
  (state) => ({
    productsOrder: getProducts(state),
    productsToPreselect: getProductsToPreselect(state),
  }),
  {
    resetOrder,
  },
)(withStyles(styles)(ClockedOrderDialog));
