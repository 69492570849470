import React, { PureComponent } from "react";
import {
  withStyles,
  Typography,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from "@material-ui/core";
import ClientInfoForm from "../../containers/form/ClientInfoForm";
import ClientContactForm from "../../containers/form/ClientContactForm";
import ClientDiscountList from "../discount/ClientDiscountList";
import ContactsList from "./../contacts/ContactsList";

const styles = (theme) => ({
  tabContent: {
    padding: 24,
    minWidth: "300px",
  },
  explanation: {
    marginBottom: "2rem",
  },
});

const TabContainer = (props) => {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
};

class ClientDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabOpened: 0,
      currentFormIsPristine: true,
      dialogOpen: false,
    };
  }

  handleTabChange = (event, tabOpened) => {
    const { currentFormIsPristine } = this.state;
    if (!!currentFormIsPristine) {
      this.setState({
        tabOpened,
      });
    } else {
      this.handleDialogOpen(tabOpened);
    }
  };

  discardAndChangeTab = async () => {
    const { tabAsked } = this.state;
    await this.setState({ currentFormIsPristine: true });
    this.handleTabChange(null, tabAsked);
    this.handleDialogClose();
  };

  saveAndChangeTab = async () => {
    const { tabAsked, tabOpened } = this.state;
    const formNames = ["clientInfoForm", ""];

    document
      .getElementById(formNames[tabOpened])
      .dispatchEvent(new Event("submit", { cancelable: true }));
    await this.setState({ currentFormIsPristine: true });
    this.handleTabChange(null, tabAsked);
    this.handleDialogClose();
  };

  handleDialogOpen = (tabAsked) => {
    this.setState({ dialogOpen: true, tabAsked });
  };

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  };

  setNewFormState = (pristineState) => {
    this.setState({
      currentFormIsPristine: pristineState,
    });
  };

  render() {
    const { classes, clientCode, readOnly } = this.props;
    const { tabOpened, dialogOpen } = this.state;
    return (
      <>
        <Tabs
          value={tabOpened}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.handleTabChange}
        >
          <Tab label="informations" />
          <Tab label="contacts" />
          <Tab label="remises" />
        </Tabs>
        {tabOpened === 0 && (
          <TabContainer>
            <ClientInfoForm
              clientCode={clientCode}
              onFormChange={this.setNewFormState}
            />
          </TabContainer>
        )}
        {tabOpened === 1 && (
          <TabContainer>
            <ClientContactForm
              clientCode={clientCode}
              onFormChange={this.setNewFormState}
            />
            <ContactsList clientCode={clientCode} />
          </TabContainer>
        )}
        {tabOpened === 2 && (
          <TabContainer>
            <Typography variant="body2" className={classes.explanation}>
              Vous trouverez ci-dessous toutes les remises qui concernent le
              produit {clientCode}, que ce soit par rapport à son code produit,
              sa marque, son catalogue, etc....
              <br />
              Ces remises sont modifiables dans la section "Remises".
            </Typography>

            <ClientDiscountList
              readOnly={readOnly}
              addable={false}
              clientCode={clientCode}
            />
          </TabContainer>
        )}

        <Dialog
          open={dialogOpen}
          onClose={this.handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Enregistrer les changements ?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Au changement d'onglet vous perdez toutes les modifications
              apportées. Souhaitez-vous enregistrer les changements ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.saveAndChangeTab()} color="primary">
              Oui
            </Button>
            <Button
              onClick={() => this.discardAndChangeTab()}
              color="primary"
              autoFocus
            >
              Non
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(ClientDetail);
