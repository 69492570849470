import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";

const styles = (theme) => ({
  button: {
    margin: "14px 0",
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    marginTop: "55px",
    marginBottom: "1rem",
  },
  hLSWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  contentContainer: {
    display: "flex",
    flex: 1,
  },
  navigationContainer: {
    display: "flex",
    justifyContent: "flex-end",
    height: "64px",
  },
});

class HorizontalLinearStepper extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeStep:
        this.props.forcedActiveStep !== undefined
          ? this.props.forcedActiveStep
          : 0,
      skipped: new Set(),
      contentContainerHeight: 630,
    };
  }

  componentDidMount() {
    const contentContainerHeight = this.contentContainer.clientHeight - 138;
    this.setState({ contentContainerHeight });
  }

  isStepOptional = (step) => {
    const { optionalSteps } = this.props;
    return optionalSteps === undefined ? false : optionalSteps.includes(step);
  };

  handleNext = () => {
    const { activeStep } = this.state;
    let { skipped } = this.state;
    if (this.isStepSkipped(activeStep)) {
      skipped = new Set(skipped.values());
      skipped.delete(activeStep);
    }
    this.setState({
      activeStep: activeStep + 1,
      skipped,
    });
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1,
    });
  };

  handleSkip = () => {
    const { activeStep } = this.state;
    if (!this.isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }
    this.setState((state) => {
      const skipped = new Set(state.skipped.values());
      skipped.add(activeStep);
      return {
        activeStep: state.activeStep + 1,
        skipped,
      };
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  isStepSkipped(step) {
    return this.state.skipped.has(step);
  }

  render() {
    const {
      classes,
      onGetSteps,
      onGetStepContent,
      navigationIsExternal = false,
    } = this.props;
    const { activeStep, contentContainerHeight } = this.state;
    const steps = onGetSteps;

    return (
      <div className={classes.hLSWrapper}>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label, index) => {
            const props = {};
            const labelProps = {};
            if (this.isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optionnel</Typography>
              );
            }
            if (this.isStepSkipped(index)) {
              props.completed = false;
            }
            return (
              <Step key={label} {...props}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        {activeStep === steps.length ? (
          <>
            <Typography className={classes.instructions}>
              Toutes les étapes sont terminées - Vous avez fini !
            </Typography>
            <Button onClick={this.handleReset} className={classes.button}>
              Remettre à zéro
            </Button>
          </>
        ) : (
          <>
            <div
              className={classes.contentContainer}
              ref={(contentContainer) =>
                (this.contentContainer = contentContainer)
              }
            >
              {onGetStepContent(
                activeStep,
                this.handleBack,
                this.handleNext,
                this.handleSkip,
                contentContainerHeight,
              )}
            </div>
            {!navigationIsExternal && (
              <Grid container spacing={0}>
                <Grid item xs={9}>
                  <div className={classes.navigationContainer}>
                    <Button
                      disabled={activeStep === 0}
                      onClick={this.handleBack}
                      className={classes.button}
                    >
                      Retour
                    </Button>
                    {this.isStepOptional(activeStep) && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleSkip}
                        className={classes.button}
                      >
                        Sauter
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Terminé" : "Suivant"}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </div>
    );
  }
}

HorizontalLinearStepper.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(HorizontalLinearStepper);
