import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core";
import ArticleImageEntry from "./ArticleImageEntry";
import Parse from "parse";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  errorText: {
    color: "red",
  },
  warningText: {
    color: "orange",
  },
  okText: {
    color: "green",
  },
});

class ProductImageList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: null,
    };
  }

  componentDidMount() {
    this.getProductImageData();
  }

  handleChange = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  // loading from database
  getProductImageData = async () => {
    const { productCode } = this.props;
    const product = await new Parse.Query("Products")
      .equalTo("ARKTCODART", productCode)
      .first();
    let imagesInDb = product.get("images");
    let imagesExist = imagesInDb !== undefined;

    const articles = product.get("articles");
    // imagesInDb is used to create the entries in the image tab
    // we have to ensure that imagesInDb has all the articles available
    if (articles.length) {
      articles.forEach((article) => {
        const articleCode = article.ARKTCOMART;
        let articleIsInImages = false;
        if (imagesExist) {
          imagesInDb.forEach((imageInDb) => {
            if (imageInDb.articleCode === articleCode) {
              articleIsInImages = true;
            }
          });
        }
        // If article not found in imagesInDb, we add it to imagesInDb
        if (!articleIsInImages) {
          if (imagesExist) {
            imagesInDb.push({ articleCode });
          } else {
            imagesInDb = [{ articleCode }];
          }
        }
        imagesExist = imagesInDb !== undefined;
      });
    }

    // checking if there's a 'generic' articleCode. If not we add an empty object of it so that we can add images
    let mainObjectExists = false;
    // if we have images in db, we sort and we try to see if a generic version exist
    if (imagesExist) {
      // sorting (to be checked)
      imagesInDb.sort((a, b) =>
        a.articleCode > b.articleCode
          ? 1
          : b.articleCode > a.articleCode
            ? -1
            : 0,
      );
      imagesInDb.forEach((articleData) => {
        if (articleData.articleCode === "generic") {
          mainObjectExists = true;
        }
      });
    }
    // adding if no 'generic' articleCode
    if (!mainObjectExists && imagesExist) {
      imagesInDb.unshift({ articleCode: "generic" });
    } else if (!mainObjectExists) {
      imagesInDb = [{ articleCode: "generic" }];
    }

    // sometimes generic is not at beginning of array as we wan't it to be...
    if (imagesInDb[0].articleCode !== "generic") {
      let genericImage = {};
      let genericImageIndex = 0;
      imagesInDb.forEach((imageInDB, index) => {
        if (imageInDB.articleCode === "generic") {
          genericImage = imageInDB;
          genericImageIndex = index;
        }
      });
      imagesInDb.splice(genericImageIndex, 1); // removing generic
      imagesInDb.unshift(genericImage); // adding generic at beginning of array
    }

    this.setState({
      imagesInDb,
    });
  };

  render() {
    const { classes, productCode } = this.props;
    const { imagesInDb, expanded } = this.state;

    if (!imagesInDb) {
      return null;
    }

    const expansionPanelList = imagesInDb.map((article, index) => {
      const panelName = "panel" + index;

      // checking if required images are in db
      let miniatureExists = false;
      let mainImageExists = false;
      let imageCount = 0;
      Object.entries(article).forEach(([key]) => {
        if (key !== "articleCode") imageCount++;
        if (key === "miniature") miniatureExists = true;
        if (key === "main") mainImageExists = true;
      });

      const articleName =
        article.articleCode === "generic" ? "générique" : article.articleCode;
      const imageText = imageCount > 1 ? " images" : " image";
      const imageCountText =
        imageCount > 0 ? imageCount + imageText : "aucune image";
      const headingText =
        "Version " + articleName + " (" + imageCountText + ")";
      const missingPictureClass =
        article.articleCode === "generic"
          ? classes.errorText
          : classes.warningText;
      let secondaryHeadingText;
      if (miniatureExists) {
        if (mainImageExists) {
          secondaryHeadingText = "2 images obligatoires présentes";
        } else {
          secondaryHeadingText = (
            <>
              miniature présente mais{" "}
              <span className={missingPictureClass}>
                image principale manquante
              </span>
            </>
          );
        }
      } else {
        if (mainImageExists) {
          secondaryHeadingText = (
            <>
              <span className={missingPictureClass}>miniature manquante</span>{" "}
              et image principale présente
            </>
          );
        } else {
          secondaryHeadingText = (
            <span className={missingPictureClass}>
              miniature et image principale manquantes
            </span>
          );
        }
      }

      return (
        <ArticleImageEntry
          productCode={productCode}
          articleDataInDb={article}
          headingText={headingText}
          secondaryHeadingText={secondaryHeadingText}
          panelName={panelName}
          expanded={expanded}
          onPanelChange={this.handleChange(panelName)}
          key={index}
          onImageChange={() => this.getProductImageData()}
        />
      );
    });

    return <div className={classes.root}>{expansionPanelList}</div>;
  }
}

export default withStyles(styles)(ProductImageList);
