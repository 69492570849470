import React, { PureComponent } from "react";
import Parse from "parse";
import moment from "moment";
import { connect } from "react-redux";
import { getLists } from "../../reducers/lists";
import { withStyles, Typography } from "@material-ui/core";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableTreeColumn,
} from "@devexpress/dx-react-grid-material-ui";
import { TreeDataState, CustomTreeData } from "@devexpress/dx-react-grid";
import {
  NumberProvider,
  PercentProvider,
  DateProvider,
} from "../../components/reactgrid/Formatter";
import CustomTableHeaderCell from "../../components/reactgrid/CustomTableHeaderCell";

const styles = (theme) => ({
  tableContainer: {
    position: "relative",
    "& col:nth-child(1)": {
      // code
      width: "12% !important",
    },
    "& col:nth-child(2)": {
      // libellé
      width: "20% !important",
    },
  },
  totalTableContainer: {
    display: "flex",
    width: "100%",
    minHeight: "100px",
    flexDirection: "row-reverse",
    margin: "2rem 0",
  },
  totalTablePlacer: {
    width: "400px",
  },
  orderInfoWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    margin: "2rem",
  },
  orderInfo1: {
    flexDirection: "column",
    minWidth: "300px",
    marginRight: "1rem",
  },
  orderInfo2: {
    flexDirection: "column",
    minWidth: "300px",
  },
});

class OrderDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      orderData: {},
      rows: [],
      columns: [
        { name: "LCCTCODART", title: "Code article" },
        { name: "LCCTLIB01", title: "Libellé" },
        { name: "LCCTCOMART", title: "Code complémentaire" },
        { name: "deliveryDateWished", title: "Date de livraison prévue" },
        { name: "LCCNQTECDE", title: "Quantité commandée" },
        { name: "discount", title: "Remise appliquée" },
        { name: "LCCNPUBRUT", title: "Prix catalogue HT (€)\t" },
        { name: "LCCNPUNET", title: "Prix unitaire net HT (€)" },
        { name: "totalNetPrice", title: "Montant total net HT (€)" },
        { name: "LCCNQTEEXP", title: "Quantité expédiée" },
        { name: "LCCTSOLACE", title: "S/A expédition" },
        { name: "LCCJDEREX", title: "Date dernière expédition" },
        { name: "LCCTNOEXP", title: "N° dernière expédition ou n° cde" },
      ],
      numberColumns: ["LCCNQTECDE", "LCCNPUBRUT", "LCCNPUNET", "totalNetPrice"],
      percentColumns: ["discount"],
      dateColumns: ["LCCJDEREX"],
      tableColumnsExtensions: [
        { columnName: "LCCTCODART" },
        { columnName: "LCCTLIB01" },
        { columnName: "LCCTCOMART" },
        { columnName: "discount", align: "right" },
        { columnName: "LCCNQTECDE", align: "right" },
        { columnName: "LCCNPUBRUT", align: "right" },
        { columnName: "LCCNPUNET", align: "right" },
        { columnName: "totalNetPrice", align: "right" },
        { columnName: "LCCNQTEEXP", align: "right" },
        { columnName: "LCCTSOLACE" },
        { columnName: "LCCJDEREX", align: "right" },
        { columnName: "LCCTNOEXP", align: "right" },
      ],
      expandedRowIds: [],
      columnsForTotal: [
        { name: "desc", title: "desc" },
        { name: "discount", title: "discount" },
        { name: "amount", title: "amount" },
      ],
      numberForTotalCol: ["amount"],
      percentForTotalCol: ["discount"],
      tableForTotalColExt: [
        { columnName: "desc" },
        { columnName: "discount", align: "right" },
        { columnName: "amount", align: "right" },
      ],
      rowsForTotal: [],
    };
    this.changeExpandedRowIds = this.changeExpandedRowIds.bind(this);
    this.getOrderData(this.props.orderNumber);
  }

  getOrderData = async (orderNumber) => {
    const query = await new Parse.Query("Orders")
      .equalTo("ECKTNUMERO", orderNumber)
      .include("contact")
      .first();
    const orderData = { ...query.attributes };
    const childRows = [];
    orderData.lines.forEach((line, index) => {
      const info = {};
      info.id = index;
      info.totalNetPrice = line.LCCNPUNET * line.LCCNQTECDE;
      info.discount =
        line.LCCNPUBRUT !== 0
          ? Math.round((line.LCCNPUNET / line.LCCNPUBRUT - 1) * 100) / 100
          : "";
      info.deliveryDateWished =
        line.LCCJDELARR !== undefined
          ? moment(line.LCCJDELARR).format("DD/MM/YYYY")
          : line.LCCJDELEXP !== undefined
            ? moment(line.LCCJDELEXP).add(7, "day").format("DD/MM/YYYY")
            : moment().startOf("day").add(7, "day").format("DD/MM/YYYY");
      Object.entries(line).forEach(([key, value]) => {
        info[key] = value;
      });
      childRows.push(info);
    });

    // *************** grouping *************** //
    let nextIdForParent = childRows.length;
    let rows = [];
    const productCount = {};
    childRows.forEach((row) => {
      if (productCount.hasOwnProperty(row.LCCTCODART)) {
        productCount[row.LCCTCODART] += 1;
      } else {
        productCount[row.LCCTCODART] = 1;
      }
    });

    // assigning product count
    const parentRows = [];
    const expandedRowIds = [];

    Object.entries(productCount).forEach(([productCode, count]) => {
      if (count > 1) {
        // grouping
        let nameForGrouping = "";
        let codeForGrouping = "";
        childRows.forEach((childRow) => {
          if (childRow.LCCTCODART === productCode) {
            nameForGrouping =
              nameForGrouping !== "" ? nameForGrouping : childRow.LCCTLIB01;
            codeForGrouping =
              codeForGrouping !== "" ? codeForGrouping : childRow.LCCTCODART;
          }
        });
        // creating new parent line for grouping
        const info = {};
        info.id = nextIdForParent++;
        info.LCCTLIB01 = nameForGrouping;
        info.LCCTCODART = codeForGrouping;
        info.discount = "";
        info.LCCNQTECDE = "";
        info.LCCNPUBRUT = "";
        info.LCCNPUNET = "";
        info.totalNetPrice = "";
        info.parentId = null;

        parentRows.push(info);
      }
      // assigning parentIds to child rows
      childRows.forEach((childRow) => {
        let hasParentId = false;
        parentRows.forEach((parentRow) => {
          if (!hasParentId && childRow.LCCTCODART === parentRow.LCCTCODART) {
            childRow.parentId = parentRow.id;
            childRow.LCCTLIB01 = ""; // 'libellé' is only shown in parent line
            hasParentId = true;
          }
        });
        if (!hasParentId) {
          childRow.parentId = null;
        }
      });
      // adding parentRows array to rows array
      rows = parentRows.concat(childRows);
    });
    // ************ end of grouping *********** //

    // *************** sorting **************** //
    // by code (LCCTCODART)
    rows.sort((a, b) =>
      a.LCCTCODART > b.LCCTCODART ? 1 : b.LCCTCODART > a.LCCTCODART ? -1 : 0,
    );

    // *********** post treatment ************* //
    // putting rows concerning PORTVENTEFRANCE at the end of the array
    rows.forEach((row, index) => {
      Object.entries(row).forEach(([key, value]) => {
        if (
          key === "LCCTCODART" &&
          value === "PORTVENTEFRANCE" &&
          row.parentId === null
        ) {
          // selecting parent
          rows.splice(index, 1);
          rows.push(row);
        }
      });
    });

    // defining expandedRowIds for product to be opened (expanded)
    rows.forEach((row, index) => {
      expandedRowIds.push(index);
    });

    // *************** rowsForTotal *************** //
    let rawTotal = 0;
    let netTotal = 0;
    //console.log('rows: ', rows);
    rows.forEach((row) => {
      Object.entries(row).forEach(([key, value]) => {
        if (key === "LCCNPUBRUT" && typeof value === "number") {
          rawTotal += value * row.LCCNQTECDE;
        }
        if (key === "LCCNPUNET" && typeof value === "number") {
          //console.log('value: ', value);
          netTotal += value * row.LCCNQTECDE;
        }
      });
    });
    netTotal = Math.round(netTotal * 100) / 100;
    rawTotal = Math.round(rawTotal * 100) / 100;
    const discountAmount = netTotal - rawTotal;
    const discountTotal = new Intl.NumberFormat("fr-FR", {
      style: "percent",
      maximumFractionDigits: 1,
    }).format(netTotal / rawTotal - 1);
    if (orderData.contact !== undefined) {
      const emailPart =
        orderData.contact.get("email") !== undefined
          ? " (" + orderData.contact.get("email") + ")"
          : "";
      orderData.contactFormatted =
        orderData.contact.get("firstname") !== undefined &&
        orderData.contact.get("lastname") !== undefined
          ? orderData.contact.get("firstname") +
            " " +
            orderData.contact.get("lastname") +
            emailPart
          : orderData.contact.get("CLCTCONTA1") + emailPart;
    }
    this.setState({
      orderData,
      rows,
      expandedRowIds,
      rowsForTotal: [
        { desc: "Total Brut", discount: "", amount: rawTotal },
        { desc: "Remise", discount: discountTotal, amount: discountAmount },
        { desc: "Total Remisé", discount: "", amount: netTotal },
      ],
    });
  };

  getChildRows = (row, rootRows) => {
    const childRows = rootRows.filter(
      (r) => r.parentId === (row ? row.id : null),
    );
    return childRows.length ? childRows : null;
  };

  changeExpandedRowIds(expandedRowIds) {
    this.setState({ expandedRowIds });
  }

  render() {
    const { classes, listsOptions } = this.props;
    const {
      orderData,
      rows,
      columns,
      numberColumns,
      percentColumns,
      dateColumns,
      tableColumnsExtensions,
      expandedRowIds,
      rowsForTotal,
      columnsForTotal,
      numberForTotalCol,
      tableForTotalColExt,
    } = this.state;

    if (orderData.lines === undefined) {
      return null;
    }

    // getting commercial name and order origin
    let commercial = "";
    let orderOrigin = "";
    listsOptions.forEach((listOption) => {
      if (listOption.listName === "commercials") {
        listOption.options.forEach((option) => {
          if (option.value === orderData.lines[0].LCCTCREP1) {
            commercial = option.text;
          }
        });
      }
      if (listOption.listName === "ordersOrigins") {
        listOption.options.forEach((option) => {
          if (option.value === orderData.lines[0].LCCTCREP2) {
            orderOrigin = option.text;
          }
        });
      }
    });

    return (
      <>
        <div className={classes.tableContainer}>
          <div className={classes.orderInfoWrapper}>
            <div className={classes.orderInfo1}>
              <Typography variant="body2" gutterBottom>
                Client : {orderData.ECCTNOM}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Numéro de commande : {orderData.ECKTNUMERO}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Date de commande :{" "}
                {moment(orderData.ECCJCRE).format("DD/MM/YYYY")}
              </Typography>
            </div>
            <div className={classes.orderInfo1}>
              <Typography variant="body2" gutterBottom>
                Saisi par :{" "}
                {orderData.orderAuthor !== undefined
                  ? orderData.orderAuthor.get("firstname") +
                    " " +
                    orderData.orderAuthor.get("lastname")
                  : "-"}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Contact :{" "}
                {orderData.contactFormatted !== undefined
                  ? orderData.contactFormatted
                  : "-"}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {orderData.status === "validated" && (
                  <>Validé par {orderData.ECCTUTIMOD}</>
                )}
                {orderData.status !== "validated" && <>Non validé</>}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Commercial : {commercial}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Vecteur de commande : {orderOrigin}
              </Typography>
            </div>
          </div>

          <Grid rows={rows} columns={columns}>
            <TreeDataState
              expandedRowIds={expandedRowIds}
              onExpandedRowIdsChange={this.changeExpandedRowIds}
            />
            <CustomTreeData getChildRows={this.getChildRows} />

            <NumberProvider for={numberColumns} />
            <PercentProvider for={percentColumns} />
            <DateProvider for={dateColumns} />

            <Table height="auto" columnExtensions={tableColumnsExtensions} />
            <TableHeaderRow cellComponent={CustomTableHeaderCell} />
            <TableTreeColumn for="LCCTCODART" />
          </Grid>
        </div>
        <div className={classes.totalTableContainer}>
          <div className={classes.totalTablePlacer}>
            <Grid rows={rowsForTotal} columns={columnsForTotal}>
              <NumberProvider for={numberForTotalCol} />
              <Table height="auto" columnExtensions={tableForTotalColExt} />
            </Grid>
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  (state) => ({
    listsOptions: getLists(state),
  }),
  {},
)(withStyles(styles)(OrderDetail));
