import React, { PureComponent } from "react";
import Parse from "parse";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import { Select, TextField } from "final-form-material-ui";
import { withStyles, Typography, Button, Grid } from "@material-ui/core";
import { optionsCreator } from "../../utils2";
import Switch from "../../components/form/Switch";
import { getLists } from "../../reducers/lists";
import SubTitleField from "../../components/form/productInfo/subTitleField";
import StickFields from "../../components/form/productInfo/stickFields";
import businessSmuOptions from "../../components/form/options/BusinessSmu";

const styles = (theme) => ({
  gridContainer: {
    width: "50%",
  },
  textField: {
    width: "100%",
  },
  selectField: {
    width: "100%",
  },
  fieldContainer: {
    display: "flex",
    flex: 1,
    height: "48px",
    margin: "0 36px 0 0",
  },
  buttonWrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    margin: "2rem 1rem 1rem 0",
  },
  errorMessage: {
    textAlign: "center",
    color: "red",
  },
  switchText: {
    lineHeight: "48px",
  },
});

class ProductInfoForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      productData: {},
      values: [],
      catalog: undefined,
    };
  }
  componentDidMount() {
    this.loadProductData();
  }

  loadProductData = async () => {
    const { productCode } = this.props;
    const product = await new Parse.Query("Products")
      .equalTo("ARKTCODART", productCode)
      .first();

    // getting isVisibleInRessuplySection value
    let productVisibilityIsAlreadyDefined = false;
    const resupplies = product.get("resupplies");
    if (resupplies !== undefined) {
      resupplies.forEach((article) => {
        if (
          article.ARKTCOMART === "" &&
          article.isVisibleInRessuplySection !== undefined
        ) {
          productVisibilityIsAlreadyDefined = true;
          product.set(
            "isVisibleInRessuplySection",
            article.isVisibleInRessuplySection,
          );
        }
      });
    }

    this.setState({
      productData: product.attributes,
      catalog: product.attributes.catalog,
      productVisibilityIsAlreadyDefined,
    });
  };

  updateProductType = (productType) => {
    this.setState({
      productType: productType.input.value,
    });
    return null;
  };

  updateCatalog = (catalog) => {
    const value = catalog.input.value;
    if (this.state.catalog !== value) {
      this.setState({
        catalog: value,
      });
    }
    return null;
  };

  onSubmit = async (values) => {
    const { readOnly } = this.props;
    if (!!readOnly) {
      return null;
    }
    const { productCode } = this.props;
    const product = await new Parse.Query("Products")
      .equalTo("ARKTCODART", productCode)
      .first();
    Object.entries(values).forEach(([key, value]) => {
      // treating visibility of Product (and not articles) in ressuply section
      if (key === "isVisibleInRessuplySection") {
        let resupplies = product.get("resupplies");
        resupplies.forEach((article) => {
          if (article.ARKTCOMART === "") {
            article.isVisibleInRessuplySection = value;
          }
        });
        product.unset("isVisibleInRessuplySection");
      } else {
        product.set(key, value);
      }
    });
    await product.save();
    await this.loadProductData();
  };

  render() {
    const {
      productData,
      productType,
      catalog,
      productVisibilityIsAlreadyDefined,
    } = this.state;
    const { classes, onFormChange, listsOptions, readOnly } = this.props;

    return (
      <Form
        onSubmit={this.onSubmit}
        initialValues={productData}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form
            id="productInfoForm"
            onSubmit={handleSubmit}
            onChange={onFormChange(pristine)}
          >
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={6}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="isOnline"
                    component={Switch}
                    type="checkbox"
                    disabled={readOnly}
                  />
                  <Typography variant="body2" className={classes.switchText}>
                    En ligne
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="showPrice"
                    component={Switch}
                    type="checkbox"
                    disabled={readOnly}
                  />
                  <Typography variant="body2" className={classes.switchText}>
                    Afficher le prix
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={6}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="forSale"
                    component={Switch}
                    type="checkbox"
                    disabled={readOnly}
                  />
                  <Typography variant="body2" className={classes.switchText}>
                    Disponible à la vente
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="forSaleForPrivateSales"
                    component={Switch}
                    type="checkbox"
                    disabled={readOnly}
                  />
                  <Typography variant="body2" className={classes.switchText}>
                    Disponible pour les ventes-privées
                  </Typography>
                </div>
              </Grid>
            </Grid>
            {productVisibilityIsAlreadyDefined && (
              <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={6}>
                  <div className={classes.fieldContainer}>
                    <Field
                      name="isVisibleInRessuplySection"
                      component={Switch}
                      type="checkbox"
                      disabled={readOnly}
                    />
                    <Typography variant="body2" className={classes.switchText}>
                      Produit visible dans Réappro
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            )}

            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={4}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="ARKTCODART"
                    component={TextField}
                    type="text"
                    placeholder="Référence"
                    label="Référence"
                    className={classes.textField}
                    disabled
                  />
                </div>
              </Grid>
              <Grid item xs={8}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="ARCTLIB01"
                    component={TextField}
                    type="text"
                    placeholder="Libellé produit"
                    label="Libellé produit"
                    className={classes.textField}
                    required
                    disabled={readOnly}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={6}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="ARCTEAN13"
                    component={TextField}
                    type="text"
                    placeholder="Code EAN"
                    label="Code EAN"
                    className={classes.textField}
                    disabled
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="ARCTMODTEC"
                    component={TextField}
                    type="text"
                    placeholder="Code douanier"
                    label="Code douanier"
                    className={classes.textField}
                    disabled
                  />
                </div>
              </Grid>
              {catalog === "businessSmu" && (
                <Grid item xs={6}>
                  <div className={classes.fieldContainer}>
                    <Field
                      name="businessSmu"
                      label="Enseigne du SMU"
                      component={Select}
                      className={classes.selectField}
                      disabled={readOnly}
                    >
                      {businessSmuOptions()}
                    </Field>
                  </div>
                </Grid>
              )}
            </Grid>

            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={6}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="activity"
                    label="Activité"
                    component={Select}
                    className={classes.selectField}
                    disabled={readOnly}
                  >
                    {optionsCreator(listsOptions, "activity")}
                  </Field>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="univers"
                    label="Univers"
                    component={Select}
                    className={classes.selectField}
                    disabled={readOnly}
                  >
                    {optionsCreator(listsOptions, "univers")}
                  </Field>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={6}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="color"
                    label="Couleur"
                    component={Select}
                    className={classes.selectField}
                    disabled={readOnly}
                  >
                    {optionsCreator(listsOptions, "color")}
                  </Field>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="gender"
                    label="Genre"
                    component={Select}
                    className={classes.selectField}
                    disabled={readOnly}
                  >
                    {optionsCreator(listsOptions, "gender")}
                  </Field>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={6}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="catalog"
                    label="Catalogue"
                    component={Select}
                    className={classes.selectField}
                    disabled={readOnly}
                  >
                    {optionsCreator(listsOptions, "catalog")}
                  </Field>
                  <Field name="catalog" subscription={{ value: true }}>
                    {this.updateCatalog}
                  </Field>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="brand"
                    label="Marque"
                    component={Select}
                    className={classes.selectField}
                    disabled={readOnly}
                  >
                    {optionsCreator(listsOptions, "brand")}
                  </Field>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={6}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="productType"
                    label="Type de produit"
                    component={Select}
                    className={classes.selectField}
                    disabled={readOnly}
                  >
                    {optionsCreator(listsOptions, "productType")}
                  </Field>
                  <Field name="productType" subscription={{ value: true }}>
                    {this.updateProductType}
                  </Field>
                </div>
              </Grid>
              {productType !== "stick" && (
                <SubTitleField productType={productType} disabled={readOnly} />
              )}
            </Grid>
            {productType === "stick" && <StickFields />}
            {!readOnly && (
              <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={12}>
                  <div className={classes.buttonWrapper}>
                    <Button
                      type="submit"
                      disabled={submitting || pristine}
                      variant="contained"
                      color="primary"
                    >
                      enregistrer
                    </Button>
                  </div>
                </Grid>
              </Grid>
            )}
          </form>
        )}
      />
    );
  }
}

export default connect(
  (state) => ({
    listsOptions: getLists(state),
  }),
  {},
)(withStyles(styles)(ProductInfoForm));
