import React from "react";
import { useSelector } from "react-redux";
import { List, Collapse } from "@material-ui/core";
import { getNavigation } from "../../reducers/navigation";
import ListEntry from "../../components/navigation/ListEntry";

const CommercialDrawerList = () => {
  const navigation = useSelector(getNavigation);
  return (
    <List>
      <ListEntry id={0} toPage="/" text="Accueil" icon="Home" />

      <ListEntry id={1} text="Commandes" collapsable icon="ShoppingCart" />
      <Collapse in={navigation[1].open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListEntry
            id={2}
            toPage="/commande"
            text="Commande cadencée"
            nested={true}
          />
          <ListEntry
            id={3}
            toPage="/commandes"
            text="Historique"
            nested={true}
          />
        </List>
      </Collapse>

      <ListEntry id={4} toPage="/produits" text="Produits" icon="StarBorder" />
      <ListEntry id={5} toPage="/remises" text="Remises" icon="Discount" />

      <ListEntry id={6} text="Clients" collapsable icon="Group" />
      <Collapse in={navigation[6].open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListEntry id={7} toPage="/magasins" text="Magasins" nested={true} />
          <ListEntry
            id={8}
            toPage="/groupements"
            text="Groupements"
            nested={true}
          />
          <ListEntry
            id={9}
            toPage="/enseignes"
            text="Enseignes"
            nested={true}
          />
        </List>
      </Collapse>
    </List>
  );
};

export default CommercialDrawerList;
