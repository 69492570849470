import React from "react";
import Parse from "parse";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "./reducers/currentUser";
import { setCurrentUser } from "./actions/main";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";

const theme = createMuiTheme({
  overrides: {
    MuiIconButton: {
      root: {
        padding: "8px",
      },
    },
    MuiTableCell: {
      body: {
        fontWeight: "500",
      },
      root: {
        padding: "inherit",
      },
    },
    TableCell: {
      cell: {
        // padding: '2px 56px 2px 24px'
        height: "41px",
      },
    },
    TableHeaderCell: {
      cell: {
        "&:first-child": {
          paddingLeft: 8,
        },
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: blue,
    secondary: blue,
  },
});

const App = (props) => {
  const { children } = props;
  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();

  if (currentUser === undefined) {
    const currentUser = Parse.User.current();
    // in case of refresh (F5)
    if (currentUser !== null) {
      dispatch(setCurrentUser(currentUser));
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      <div>{children}</div>
    </MuiThemeProvider>
  );
};

export default App;
