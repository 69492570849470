import React, { useState, useEffect } from "react";
import Parse from "parse";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Fab,
  Snackbar,
  Button,
  Grid,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../reducers/currentUser";
import { getLists } from "../../reducers/lists";
import OrderSummary from "../../components/order/OrderSummary";
import { Select, TextField } from "final-form-material-ui";
import { Form, Field, FormSpy } from "react-final-form";
import AutoCompleteVirtualized from "../../components/form/AutoCompleteVirtualized";
import AutoSave from "../form/AutoSave";
import {
  optionsCreator,
  updateOnUnshippedOrders,
  getOptions,
  generateAvailabilityText,
  validSendingDayCheck,
  getNextDeliveryDate,
} from "../../utils2";
import ProductIcons from "../../components/product/ProductsIcons";
import AddIcon from "@material-ui/icons/Add";
import HandleContactForm from "../../containers/form/HandleContactForm";
import FormatLineSpacingIcon from "@material-ui/icons/FormatLineSpacing";
import Alert from "@material-ui/lab/Alert";
import { generateSuppliesData } from "../../utils/generateSuppliesData/generateSuppliesData";

const useStyles = makeStyles(() => ({
  tableContainer: {
    position: "relative",
    "& col:nth-child(1)": {
      // code
      width: "12% !important",
    },
    "& col:nth-child(2)": {
      // libellé
      width: "20% !important",
    },
  },
  orderInfoWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    margin: "2rem",
  },
  orderInfo1: {
    flexDirection: "column",
    minWidth: "300px",
    maxWidth: "500px",
  },
  gridContainer: {
    padding: "1rem 1rem 0 0",
  },
  gridContainer2: {
    padding: "1rem 1rem 1rem 0",
  },
  selectField: {
    width: "100%",
  },
  textField: {
    width: 70,
    "& input": {
      textAlign: "right",
    },
  },
  dateTextField: {
    width: 140,
  },
  alignRight: {
    textAlign: "right",
  },
  buttonContainer: {
    padding: "0.5rem 0",
    textAlign: "center",
  },
  textAsField: {
    minHeight: "2.5rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

const prefixWithChar = (value, char, finalLength) => {
  let newString = value.toString();
  for (let i = value.toString().length; i < finalLength; i++) {
    newString = char + newString;
  }
  return newString;
};

const completeWithChar = (string, char, finalLength) => {
  let newString = string.toString();
  const initialLength = newString !== undefined ? newString.length : 0;
  for (let i = initialLength; i < finalLength; i++) {
    newString += char;
  }
  return newString;
};

const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

const OrderEdit = (props) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const currentUser = useSelector(getCurrentUser);
  const userRole =
    currentUser !== undefined ? currentUser.get("role") : undefined;
  const listsOptions = useSelector(getLists);
  const [orderNumber] = useState(props.orderNumber);
  const [onClose] = useState(props.onClose);
  const [orderData, setOrderData] = useState("");
  const [amount, setAmount] = useState(0);
  const [rawAmount, setRawAmount] = useState(0);
  const [modifyAndValidateText, setModifyAndValidateText] = useState(
    "enregistrer & valider"
  );
  const [modifyText, setModifyText] = useState("enregistrer");
  const [openDialog, setOpenDialog] = useState(false);
  const [openValidationDialog, setOpenValidationDialog] = useState(false);
  const [addContactDialogOpen, setAddContactDialogOpen] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarText, setSnackBarText] = useState("");
  const [alertSnackBarOpen, setAlertSnackBarOpen] = useState(false);
  const [alertSnackBarText, setAlertSnackBarText] = useState("");

  const [articlesOptionsOfProductToAdd, setArticlesOptionsOfProductToAdd] =
    useState([]);
  const [productIdToAdd, setProductIdToAdd] = useState(null);
  const [articleIdToAdd, setArticleIdToAdd] = useState(null);
  const [contactOptions, setContactOptions] = useState([]);
  const { onValidateOrder } = props;
  const [incrementalUpdater, setIncrementalUpdater] = useState(0);
  const [disableButtons, setDisableButtons] = useState(false);

  const buildContactOptions = async (clientId) => {
    const client = await new Parse.Query("Clients")
      .equalTo("CLKTCODE", clientId)
      .first();
    const contacts = await new Parse.Query("Contacts")
      .equalTo("CLKTCODE", client)
      .ascending("CLCTCONTA1")
      .find();
    const contactOptions = contacts.map((contact) => {
      const emailPart =
        contact.get("email") !== undefined
          ? " (" + contact.get("email") + ")"
          : "";
      const text =
        contact.get("firstname") !== undefined &&
        contact.get("lastname") !== undefined
          ? contact.get("firstname") + " " + contact.get("lastname") + emailPart
          : contact.get("CLCTCONTA1") + emailPart;
      return {
        value: contact.id,
        text,
      };
    });
    setContactOptions(contactOptions);
  };

  const getOrderData = async (orderNumber) => {
    const query = await new Parse.Query("Orders")
      .equalTo("ECKTNUMERO", orderNumber)
      .include("contact")
      .first();
    const lines = query.get("lines");

    const newOrderData = { ...query.attributes };

    // getting contactOptions
    await buildContactOptions(newOrderData.ECCTCODE);

    // from pointer to string
    if (newOrderData.contact !== undefined) {
      newOrderData.contact = newOrderData.contact.id;
    }

    newOrderData.ECCTCREP1 = lines[0].LCCTCREP1;
    newOrderData.ECCTCREP2 = lines[0].LCCTCREP2;

    // getting discount info and formatting date
    await asyncForEach(lines, async (line) => {
      line.LCCNRHLIG = Math.abs(line.LCCNRHLIG);
      line.LCCJDELARR = moment(line.LCCJDELARR).format("YYYY-MM-DD");
      line.LCCJDELEXP = moment(line.LCCJDELEXP).format("YYYY-MM-DD");

      // getting availability test
      if (line.LCCNQTECDE) {
        const productObj = await new Parse.Query("Products")
          .equalTo("ARKTCODART", line.LCCTCODART)
          .first();
        if (productObj) {
          const params = {
            supplies: productObj.get("supplies"),
            resupplies: productObj.get("resupplies"),
            removeNegativeQuantities: false,
          };
          const product = {
            productObj: productObj.attributes,
            suppliesPerDate: generateSuppliesData(params),
          };
          const productHasArticle =
            productObj.get("articles") !== undefined &&
            productObj.get("articles").length > 0;
          line.availabilityText = generateAvailabilityText(
            productHasArticle,
            product,
            line.LCCTCOMART
          );
        }
      }
    });
    calculateTotalAmounts(lines);
    setOrderData(newOrderData);

    // modifying button texts for imported orders
    if (newOrderData.status === "imported") {
      setModifyAndValidateText("enregistrer, confirmer & valider");
      setModifyText("enregistrer & confirmer");
    }
  };

  useEffect(() => {
    getOrderData(orderNumber);
    // eslint-disable-next-line
  }, []);

  const calculateTotalAmounts = (lines) => {
    // getting discount info and calculating amount and rawAmount used for total
    let tempAmount = 0;
    let tempRawAmount = 0;
    lines.forEach((line) => {
      line.LCCNRHLIG = Math.abs(line.LCCNRHLIG);
      tempAmount += line.LCCNQTECDE * line.LCCNPUNET;
      tempRawAmount += line.LCCNQTECDE * line.LCCNPUBRUT;
    });
    setAmount(tempAmount);
    setRawAmount(tempRawAmount);
  };

  const addProduct = async () => {
    const product = await new Parse.Query("Products")
      .equalTo("ARKTCODART", productIdToAdd)
      .first();
    const newLine = {
      ...orderData.lines[orderData.lines.length - 1],
      LCKTLIGNE: prefixWithChar(orderData.lines.length + 1, "0", 3),
      LCCTTYPART: product.get("ARCTTYPART"),
      LCCTNATURE: product.get("ARITNATURE"),
      LCCTCODART: product.get("ARKTCODART"),
      LCCTCOMART: articleIdToAdd !== null ? articleIdToAdd : "",
      LCCTLIB01: product.get("ARCTLIB01"),
      LCCNQTECDE: 1,
      LCCNPDSCDE:
        product.get("ARCNPDSUNI") !== undefined ? product.get("ARCNPDSUNI") : 0,
      LCCNVOLCDE:
        product.get("ARCNVOLUNI") !== undefined ? product.get("ARCNVOLUNI") : 0,
      LCCNLONCDE:
        product.get("ARCNLONGUE") !== undefined ? product.get("ARCNLONGUE") : 0,
      LCCNLARCDE:
        product.get("ARCNLARGEU") !== undefined ? product.get("ARCNLARGEU") : 0,
      LCCNEPACDE:
        product.get("ARCNEPAISS") !== undefined ? product.get("ARCNEPAISS") : 0,
      LCCNPUBRUT: product.get("ARCNTARIF1"),
      LCCNPUNET: product.get("ARCNTARIF1"),
      LCCNRHLIG: 0,
      LCCNPUREVI: product.get("ARCNTARIF6"),
      LCCTSECART: product.get("ARCTSECART"),
      LCCTSECCDE: product.get("ARCTSECART"),
      LCCTIMPUT: product.get("ARCTSIMPUT"),
      LCCTAFFMAC: product.get("ARCTCODFAM"),
      LCCNVALCDE: product.get("ARCNTARIF9"),
      LCCNBASMAR: product.get("ARCNTARIF6"),
      LCCTLIB02:
        product.get("ARCTLIB02") !== undefined ? product.get("ARCTLIB02") : "",
      articleRef: (
        completeWithChar(product.get("ARKTCODART"), " ", 18) + articleIdToAdd
      ).trim(),
    };
    // console.log('newLine: ', newLine);
    const newOrderData = { ...orderData };
    newOrderData.ECCNPDSNET += newLine.LCCNPDSCDE;
    newOrderData.ECCNVOLUME += newLine.LCCNVOLCDE;
    newOrderData.lines.push(newLine);
    calculateTotalAmounts(newOrderData.lines);
    setOrderData(newOrderData);
    // console.log('newOrderData: ', newOrderData);
    // reseting form
    setArticlesOptionsOfProductToAdd([]);
    setProductIdToAdd(null);
    // closing dialog
    setOpenDialog(false);
  };

  const deleteLine = (index) => {
    let newOrderData = { ...orderData };
    newOrderData.ECCNPDSNET -= newOrderData.lines[index].LCCNPDSCDE;
    newOrderData.ECCNVOLUME -= newOrderData.lines[index].LCCNVOLCDE;
    newOrderData.lines.splice(index, 1);
    calculateTotalAmounts(newOrderData.lines);
    setOrderData(newOrderData);
    // console.log('newOrderData: ', newOrderData);
  };

  const spreadDate = (name, line) => {
    let newOrderData = { ...orderData };
    newOrderData.lines.forEach((orderLine) => {
      orderLine[name] = line[name];
    });
    setOrderData(newOrderData);
    setIncrementalUpdater(incrementalUpdater + 1);
  };

  const spreadDiscount = (discount) => {
    let newOrderData = { ...orderData };
    newOrderData.lines.forEach((line) => {
      line.LCCNRHLIG = parseFloat(discount);
      line.LCCNPUNET =
        Math.round(
          (line.LCCNPUBRUT - (line.LCCNPUBRUT * line.LCCNRHLIG) / 100) * 100
        ) / 100;
    });
    // recalculating total amounts
    calculateTotalAmounts(newOrderData.lines);

    setOrderData(newOrderData);
    setIncrementalUpdater(incrementalUpdater + 1);
  };

  const handleAddProductFormChange = async (productId, articleId) => {
    const productHasChanged = productId !== productIdToAdd;
    const articleHasChanged = articleId !== articleIdToAdd;
    setProductIdToAdd(productId);
    if (!productHasChanged || !articleHasChanged) {
      setArticleIdToAdd(
        articleId !== "genericVersionOfProduct" ? articleId : ""
      );
    }
    if (productHasChanged && productId !== undefined && productId !== null) {
      const query = await new Parse.Query("Products")
        .equalTo("ARKTCODART", productId)
        .first();
      const articles = query.get("articles");
      setArticlesOptionsOfProductToAdd(
        articles.map((article) => ({
          value:
            article.ARKTCOMART !== ""
              ? article.ARKTCOMART
              : "genericVersionOfProduct",
          text: article.ARKTCOMART !== "" ? article.ARKTCOMART : "Générique",
        }))
      );
      articles.length
        ? setArticleIdToAdd(articles[0].ARKTCOMART)
        : setArticleIdToAdd(null);
    }
    // reset
    if (productId === null) {
      setArticlesOptionsOfProductToAdd([]);
    }
  };

  const onRowSubmit = async (values) => {
    // message if selected day for shipping is wrong
    let alertToBeDisplayed = false;
    let textToDisplayInAlert = "";
    if (
      !validSendingDayCheck(moment(values.LCCJDELEXP, "YYYY-MM-DD")).validity
    ) {
      textToDisplayInAlert = validSendingDayCheck(
        moment(values.LCCJDELEXP)
      ).reason;
      alertToBeDisplayed = true;
    }
    // updating deliverydate if old deliverydate is not possible
    const nextDeliveryDate = await getNextDeliveryDate(
      values.LCCJDELEXP,
      orderData.ECCTCODE
    );
    if (moment(values.LCCJDELARR, "YYYY-MM-DD").isBefore(nextDeliveryDate)) {
      values.LCCJDELARR = moment(nextDeliveryDate).format("YYYY-MM-DD");
      if (textToDisplayInAlert !== "") {
        textToDisplayInAlert +=
          " De plus, la date de livraison a été mise à jour car l'ancienne n'était pas tenable.";
      } else {
        textToDisplayInAlert =
          "La date de livraison a été mise à jour car l'ancienne n'était pas tenable.";
      }
      alertToBeDisplayed = true;
    }
    if (alertToBeDisplayed) {
      setAlertSnackBarText(textToDisplayInAlert);
      setAlertSnackBarOpen(true);
    }

    // retrieving product
    let productId = "";
    orderData.lines.forEach((line) => {
      if (
        line.LCCTCODART === values.LCCTCODART &&
        line.LCCTCOMART === values.LCCTCOMART
      ) {
        productId = line.LCCTCODART;
      }
    });
    const product = await new Parse.Query("Products")
      .equalTo("ARKTCODART", productId)
      .first();

    let LCCNRHLIG = 0;
    let LCCNQTECDE = 0;
    let LCCNPUNET = 0;

    // when one of the value is changed it's changed to a string. We use that to know what has changed.
    if (typeof values.LCCNRHLIG === "string") {
      LCCNRHLIG = parseFloat(values.LCCNRHLIG);
      LCCNPUNET =
        Math.round(
          (values.LCCNPUBRUT - (values.LCCNPUBRUT * LCCNRHLIG) / 100) * 100
        ) / 100;
      LCCNQTECDE = values.LCCNQTECDE;
    } else if (typeof values.LCCNQTECDE === "string") {
      LCCNRHLIG = values.LCCNRHLIG;
      LCCNQTECDE = parseFloat(values.LCCNQTECDE);
      LCCNPUNET =
        Math.round(
          (values.LCCNPUBRUT - (values.LCCNPUBRUT * LCCNRHLIG) / 100) * 100
        ) / 100;
    } else if (typeof values.LCCNPUNET === "string") {
      LCCNRHLIG =
        Math.round(
          ((values.LCCNPUBRUT - values.LCCNPUNET) / values.LCCNPUBRUT) *
            100 *
            100
        ) / 100;
      LCCNQTECDE = values.LCCNQTECDE;
      LCCNPUNET = parseFloat(values.LCCNPUNET);
    } else {
      LCCNRHLIG = values.LCCNRHLIG;
      LCCNQTECDE = values.LCCNQTECDE;
      LCCNPUNET = parseFloat(values.LCCNPUNET);
    }

    let newOrderData = { ...orderData };
    // setting new values
    let oldLCCNPDSCDE = 0;
    let newLCCNPDSCDE = 0;
    let oldLCCNVOLCDE = 0;
    let newLCCNVOLCDE = 0;
    newOrderData.lines.forEach((line) => {
      if (
        line.LCCTCODART === values.LCCTCODART &&
        line.LCCTCOMART === values.LCCTCOMART
      ) {
        oldLCCNPDSCDE = line.LCCNPDSCDE;
        oldLCCNVOLCDE = line.LCCNVOLCDE;
        line.LCCJDELEXP = values.LCCJDELEXP;
        line.LCCJDELARR = values.LCCJDELARR;
        // line.LCCJDELDDE = values.LCCJDELARR;
        line.LCCNRHLIG = LCCNRHLIG;
        line.LCCNQTECDE = LCCNQTECDE;
        line.LCCNPUNET = LCCNPUNET;
        line.LCCNPDSCDE = newLCCNPDSCDE =
          product.get("ARCNPDSUNI") !== undefined
            ? product.get("ARCNPDSUNI") * LCCNQTECDE
            : 0;
        line.LCCNVOLCDE = newLCCNVOLCDE =
          product.get("ARCNVOLUNI") !== undefined
            ? product.get("ARCNVOLUNI") * LCCNQTECDE
            : 0;
        line.LCCNVALCDE = LCCNPUNET * LCCNQTECDE;
        line.LCCNBASMAR = product.get("ARCNTARIF6") * LCCNQTECDE;
      }
    });
    newOrderData.ECCNPDSNET =
      orderData.ECCNPDSNET - oldLCCNPDSCDE + newLCCNPDSCDE;
    newOrderData.ECCNVOLUME =
      orderData.ECCNVOLUME - oldLCCNVOLCDE + newLCCNVOLCDE;
    // recalculating total amounts
    calculateTotalAmounts(newOrderData.lines);

    // updating orderData
    setOrderData(newOrderData);
    // console.log('newOrderData: ', newOrderData);
  };

  const onSelectSubmit = async (values) => {
    // treating ECCTCREP1 & ECCTCREP2 (should be put in LCCTCREP1 & LCCTCREP2)
    let newOrderData = { ...orderData };
    newOrderData.lines.forEach((line) => {
      line.LCCTCREP1 = values.ECCTCREP1;
      line.LCCTCREP2 = values.ECCTCREP2;
    });

    newOrderData.ECCTDOMACT = values.ECCTDOMACT;
    newOrderData.ECCTREFCDE = values.ECCTREFCDE;
    newOrderData.ECCTTRANPO = values.ECCTTRANPO;
    newOrderData.contact = values.contact;
    setOrderData(newOrderData);
  };

  const buildTableRow = (index) => {
    const line = orderData.lines[index];
    return (
      <TableRow key={line.LCCTCODART + line.LCCTCOMART}>
        <Form
          key={line.LCCTCODART + line.LCCTCOMART + "_Form"}
          onSubmit={onRowSubmit}
          initialValues={line}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <>
              <FormSpy
                subscription={{ values: true }}
                component={AutoSave}
                onFormChange={async (values) => {
                  if (!pristine) {
                    await onRowSubmit(values);
                    form.reset({ ...orderData.lines[index] });
                  }
                }}
              />
              <TableCell>
                <ProductIcons
                  key={line.LCCTCODART}
                  productId={line.LCCTCODART}
                  showCard
                  showChart
                  chartWithNegativeValues
                />
              </TableCell>
              <TableCell>{line.LCCTCODART}</TableCell>
              <TableCell>{line.LCCTCOMART}</TableCell>
              <TableCell>{line.LCCTLIB01}</TableCell>
              <TableCell>{line.availabilityText}</TableCell>
              <TableCell align="right">
                <Field
                  name="LCCJDELEXP"
                  component={TextField}
                  type="date"
                  fullWidth
                  required
                  className={classes.dateTextField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Tooltip
                  title="propager cette date d'expédition aux autres lignes"
                  placement="top"
                >
                  <IconButton
                    aria-label="Delete"
                    onClick={() => spreadDate("LCCJDELEXP", line)}
                    size="medium"
                  >
                    <FormatLineSpacingIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell align="right">
                <Field
                  name="LCCJDELARR"
                  component={TextField}
                  type="date"
                  fullWidth
                  required
                  className={classes.dateTextField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Tooltip
                  title="propager cette date de livraison aux autres lignes"
                  placement="top"
                >
                  <IconButton
                    aria-label="Delete"
                    onClick={() => spreadDate("LCCJDELARR", line)}
                    size="medium"
                  >
                    <FormatLineSpacingIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell align="right">
                <Field
                  name="LCCNQTECDE"
                  component={TextField}
                  type="number"
                  className={classes.textField}
                  required
                />
              </TableCell>
              <TableCell align="right">
                {userRole !== "client" && (
                  <>
                    <Field
                      name="LCCNRHLIG"
                      component={TextField}
                      type="number"
                      className={classes.textField}
                      required
                    />
                    <Tooltip
                      title="généraliser cette remise aux autres lignes"
                      placement="top"
                    >
                      <IconButton
                        aria-label="Delete"
                        onClick={() => spreadDiscount(line.LCCNRHLIG)}
                        size="medium"
                      >
                        <FormatLineSpacingIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                {userRole === "client" && line.LCCNRHLIG}
              </TableCell>
              <TableCell align="right">
                {new Intl.NumberFormat("fr-FR").format(line.LCCNPUBRUT)}
              </TableCell>
              <TableCell align="right">
                {userRole !== "client" && (
                  <Field
                    name="LCCNPUNET"
                    component={TextField}
                    type="number"
                    className={classes.textField}
                    required
                  />
                )}
                {userRole === "client" &&
                  new Intl.NumberFormat("fr-FR").format(line.LCCNPUNET)}
              </TableCell>
              <TableCell align="right">
                {new Intl.NumberFormat("fr-FR").format(
                  line.LCCNQTECDE * line.LCCNPUNET
                )}
              </TableCell>
              <TableCell className={classes.alignRight}>
                <IconButton onClick={() => deleteLine(index)}>
                  <Tooltip
                    title="Supprimer l'article"
                    placement="top"
                    classes={{ tooltip: classes.toolTip }}
                  >
                    <DeleteIcon />
                  </Tooltip>
                </IconButton>
              </TableCell>
            </>
          )}
        />
      </TableRow>
    );
  };

  const saveOrderAndClose = async (action) => {
    setDisableButtons(true);
    //  console.log('orderData: ', orderData);
    // only lines are affected in orderEdit, let's update only them
    const query = await new Parse.Query("Orders")
      .equalTo("ECKTNUMERO", orderNumber)
      .first();
    const client = await new Parse.Query("Clients")
      .equalTo("CLKTCODE", query.get("ECCTCODE"))
      .first();

    const lines = orderData.lines.map((line) => {
      line.LCCNRHLIG = -1 * line.LCCNRHLIG;
      line.LCCJDELARR = moment.utc(line.LCCJDELARR).toDate();
      line.LCCJDELDDE = moment.utc(line.LCCJDELARR).toDate(); // LCCJDELDDE should always have the same value as LCCJDELARR
      line.LCCSDELARR = moment(line.LCCJDELARR).format("YYYYww");
      line.LCCJDELEXP = moment.utc(line.LCCJDELEXP).toDate();
      line.LCCSDELEXP = moment(line.LCCJDELEXP).format("YYYYww");
      delete line.availabilityText;
      return line;
    });

    if (orderData.contact !== undefined) {
      const contact = await new Parse.Query("Contacts").get(orderData.contact);
      query.set("contact", contact);
    }

    query
      .set("lines", lines)
      .set("amount", amount)
      .set("rawAmount", rawAmount)
      .set("discount", -1 * (rawAmount - amount))
      .set("ECCTTRANPO", orderData.ECCTTRANPO)
      .set("ECCTDOMACT", orderData.ECCTDOMACT)
      .set("ECCTREFCDE", orderData.ECCTREFCDE)
      .set("status", "confirmed") // to switch status from imported orders
      .set("manuallyEditedByClient", userRole === "client");

    try {
      await query.save();
      await updateOnUnshippedOrders([query.attributes]);
      if (action === "validate") {
        await onValidateOrder(orderNumber);
        onClose();
      } else {
        onClose("orderUpdated");
      }
    } catch (error) {
      console.log(error);
    }
  };

  /********************************************************/
  /************************* dialog ***********************/
  /********************************************************/
  const handleAddContactClickOpen = () => {
    setAddContactDialogOpen(true);
  };

  const handleAddContactFormSubmit = () => {
    document
      .getElementById("contactForm")
      .dispatchEvent(new Event("submit", { cancelable: true }));
  };

  const handleAddContactModalClose = async (action) => {
    if (action === "contactAdded") {
      await buildContactOptions(orderData.ECCTCODE);
      handleSnackBarShow("contact ajouté");
    }
    setAddContactDialogOpen(false);
  };
  /********************************************************/
  /************************ SnackBar **********************/
  /********************************************************/

  const handleSnackBarShow = (textValue) => {
    setSnackBarOpen(true);
    setSnackBarText(textValue);
  };

  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  if (orderData === "") {
    return null;
  }

  return (
    <>
      <div className={classes.tableContainer} key={incrementalUpdater}>
        <div className={classes.orderInfoWrapper}>
          <div className={classes.orderInfo1}>
            <Typography variant="body2" gutterBottom>
              Client : {orderData.ECCTNOM}
            </Typography>
            <Typography variant="body2" gutterBottom>
              Numéro de commande : {orderData.ECKTNUMERO}
            </Typography>
            <Typography variant="body2" gutterBottom>
              Date de commande :{" "}
              {moment(orderData.ECCJCRE).format("DD/MM/YYYY")}
            </Typography>
          </div>
          <Form
            onSubmit={onSelectSubmit}
            initialValues={orderData}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form id="selectForm" onSubmit={handleSubmit}>
                <div className={classes.orderInfo1}>
                  <FormSpy
                    subscription={{ values: true }}
                    component={AutoSave}
                    onFormChange={onSelectSubmit}
                  />
                  <Typography variant="body2" gutterBottom>
                    Saisi par :{" "}
                    {orderData.orderAuthor.get("firstname") !== undefined
                      ? orderData.orderAuthor.get("firstname") +
                        " " +
                        orderData.orderAuthor.get("lastname")
                      : "-"}
                  </Typography>
                  <Grid container spacing={0} className={classes.gridContainer}>
                    <Grid item xs={12}>
                      {userRole !== "commercial" && userRole !== "client" && (
                        <Field
                          name="ECCTCREP1"
                          label="Commercial"
                          component={Select}
                          fullWidth
                          format={(value) => value || []}
                          className={classes.selectField}
                        >
                          {optionsCreator(listsOptions, "commercials")}
                        </Field>
                      )}
                      {(userRole === "commercial" || userRole === "client") && (
                        <Grid item xs={12} className={classes.textAsField}>
                          <Typography variant="body2" gutterBottom>
                            Commercial :{" "}
                            {
                              listsOptions
                                .find(
                                  (lists) => lists.listName === "commercials"
                                )
                                .options.find(
                                  (option) =>
                                    option.value === orderData.ECCTCREP1
                                ).text
                            }
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={
                        userRole === "commercial" || userRole === "client"
                          ? 12
                          : 10
                      }
                    >
                      <Field
                        name="contact"
                        label="Contact"
                        component={Select}
                        fullWidth
                        format={(value) => value || []}
                        className={classes.selectField}
                      >
                        {optionsCreator("custom", contactOptions)}
                      </Field>
                    </Grid>
                    {userRole !== "commercial" && userRole !== "client" && (
                      <Grid item xs={2} className={classes.buttonContainer}>
                        <Tooltip title={"ajouter un contact"}>
                          <IconButton
                            aria-label="Delete"
                            onClick={handleAddContactClickOpen}
                          >
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      {userRole !== "client" && (
                        <Field
                          name="ECCTCREP2"
                          label="Vecteur de commande"
                          component={Select}
                          fullWidth
                          format={(value) => value || []}
                          className={classes.selectField}
                        >
                          {optionsCreator(listsOptions, "ordersOrigins")}
                        </Field>
                      )}
                      {(userRole === "commercial" || userRole === "client") && (
                        <Grid item xs={12} className={classes.textAsField}>
                          <Typography variant="body2" gutterBottom>
                            Vecteur de commande :{" "}
                            {
                              listsOptions
                                .find(
                                  (lists) => lists.listName === "ordersOrigins"
                                )
                                .options.find(
                                  (option) =>
                                    option.value === orderData.ECCTCREP2
                                ).text
                            }
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {userRole !== "client" && (
                        <Field
                          name="ECCTTRANPO"
                          label="Transporteur"
                          component={Select}
                          fullWidth
                          format={(value) => value || []}
                          className={classes.selectField}
                        >
                          {optionsCreator(listsOptions, "carrier")}
                        </Field>
                      )}
                      {(userRole === "commercial" || userRole === "client") && (
                        <Grid item xs={12} className={classes.textAsField}>
                          <Typography variant="body2" gutterBottom>
                            Transporteur :{" "}
                            {
                              listsOptions
                                .find((lists) => lists.listName === "carrier")
                                .options.find(
                                  (option) =>
                                    option.value === orderData.ECCTTRANPO
                                ).text
                            }
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {userRole !== "client" && (
                        <Field
                          name="ECCTDOMACT"
                          label="Type de commande"
                          component={Select}
                          fullWidth
                          format={(value) => value || []}
                          className={classes.selectField}
                        >
                          {optionsCreator(listsOptions, "orderTypes")}
                        </Field>
                      )}
                      {(userRole === "commercial" || userRole === "client") && (
                        <Grid item xs={12} className={classes.textAsField}>
                          <Typography variant="body2" gutterBottom>
                            Type de commande :{" "}
                            {
                              listsOptions
                                .find(
                                  (lists) => lists.listName === "orderTypes"
                                )
                                .options.find(
                                  (option) =>
                                    option.value === orderData.ECCTDOMACT
                                ).text
                            }
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="ECCTREFCDE"
                        label="Reférence client"
                        component={TextField}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              </form>
            )}
          />
        </div>
        {userRole === "client" && (
          <Grid container spacing={0} className={classes.gridContainer2}>
            <Grid item xs={12}>
              <Alert elevation={0} variant="filled" severity="warning">
                Lors de l'ajout de produit la remise n'est pas appliquée
                immédiatement. Cette dernière est appliquée par nos soins.
              </Alert>
            </Grid>
          </Grid>
        )}

        <Fab
          size="small"
          color="primary"
          aria-label="Add"
          className={classes.fab}
        >
          <AddIcon onClick={() => setOpenDialog(true)} />
        </Fab>

        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell> </TableCell>
              <TableCell>Code article</TableCell>
              <TableCell>Libellé</TableCell>
              <TableCell>Code complémentaire</TableCell>
              <TableCell>Disponibilité</TableCell>
              <TableCell align="right">Date d'expédition</TableCell>
              <TableCell align="right">Date de livraison</TableCell>
              <TableCell align="right">Quantité commandée</TableCell>
              <TableCell align="right">Remise appliquée</TableCell>
              <TableCell align="right">Prix catalogue HT (€)</TableCell>
              <TableCell align="right">Prix unitaire net HT (€)</TableCell>
              <TableCell align="right">Montant total net HT (€)</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderData.lines.map((line, index) => buildTableRow(index))}
          </TableBody>
        </Table>

        <OrderSummary rawAmount={rawAmount} amount={amount} />
      </div>
      <DialogActions>
        {userRole !== "commercial" && userRole !== "client" && (
          <>
            {orderData.contact !== undefined && (
              <Button
                variant="contained"
                color="primary"
                disabled={disableButtons}
                onClick={() => setOpenValidationDialog(true)}
              >
                {modifyAndValidateText}
              </Button>
            )}
            {orderData.contact === undefined && (
              <Tooltip
                title="Ajoutez un contact à votre commande pour pouvoir la valider"
                placement="top"
                classes={{ tooltip: classes.toolTip }}
              >
                <div>
                  <Button variant="contained" color="primary" disabled>
                    {modifyAndValidateText}
                  </Button>
                </div>
              </Tooltip>
            )}
          </>
        )}
        <Button
          variant="contained"
          color="primary"
          disabled={disableButtons}
          onClick={() => saveOrderAndClose()}
        >
          {modifyText}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={disableButtons}
          onClick={() => onClose()}
        >
          fermer
        </Button>
      </DialogActions>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Ajout de produit</DialogTitle>
        <DialogContent>
          <Form
            onSubmit={() => addProduct()}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form id="addProductForm" onSubmit={handleSubmit}>
                <FormSpy
                  subscription={{ values: true }}
                  component={AutoSave}
                  onFormChange={async (values) => {
                    if (values.productId !== undefined) {
                      await handleAddProductFormChange(
                        values.productId,
                        values.articleId
                      );
                    }
                  }}
                />
                <AutoCompleteVirtualized
                  name="productId"
                  label="Produit à ajouter"
                  options={getOptions(listsOptions, "onlineProducts")}
                />
                {!!articlesOptionsOfProductToAdd.length && (
                  <Field
                    name="articleId"
                    label="Article"
                    component={Select}
                    fullWidth
                    format={(value) =>
                      value || articlesOptionsOfProductToAdd[0].value
                    }
                    className={classes.selectField}
                  >
                    {optionsCreator("custom", articlesOptionsOfProductToAdd)}
                  </Field>
                )}
              </form>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => addProduct()}
            color="primary"
            disabled={productIdToAdd === null}
          >
            Ajouter
          </Button>
          <Button
            onClick={() => setOpenDialog(false)}
            color="primary"
            autoFocus
          >
            Annuler
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openValidationDialog}
        onClose={() => setOpenValidationDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Validation de la commande
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            En validant la commande, vous envoyez celle-ci dans PMI. Cette
            dernière pourra alors uniquement être modifiée sous PMI.
            <br />
            Souhaitez-vous continuer ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => saveOrderAndClose("validate")}
            color="primary"
            disabled={disableButtons}
          >
            {modifyAndValidateText}
          </Button>
          <Button
            onClick={() => setOpenValidationDialog(false)}
            color="primary"
            autoFocus
            disabled={disableButtons}
          >
            Annuler
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={addContactDialogOpen}
        onClose={() => setAddContactDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Ajout d'un contact</DialogTitle>
        <DialogContent>
          <HandleContactForm
            defaultClientId={[orderData.ECCTCODE]}
            onHandleModalClose={handleAddContactModalClose}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddContactFormSubmit} color="primary">
            ajouter
          </Button>
          <Button
            onClick={() => setAddContactDialogOpen(false)}
            color="primary"
            autoFocus
          >
            annuler
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
        open={snackBarOpen}
        onClose={handleSnackBarClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackBarText}</span>}
      />
      <Snackbar
        open={alertSnackBarOpen}
        autoHideDuration={10000}
        onClose={() => {
          setAlertSnackBarOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setAlertSnackBarOpen(false);
          }}
          severity="warning"
        >
          {alertSnackBarText}
        </Alert>
      </Snackbar>
    </>
  );
};

export default OrderEdit;
