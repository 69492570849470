import React, { useState, useEffect } from "react";
import Parse from "parse";
import {
  makeStyles,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  DialogContent,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import GetAppIcon from "@material-ui/icons/GetApp";

const useStyles = makeStyles(() => ({
  embed: {
    width: "100%",
    height: "100%",
    marginTop: 56,
  },
  toolbar: {
    backgroundColor: "#46494c", //'#02cfa3'
  },
  downloadLink: {
    color: "#fff",
    position: "absolute",
    right: "2rem",
  },
}));
const PdfOrder = (props) => {
  const { orderNumber, onDialogClose } = props;
  const classes = useStyles();
  const [pdf, setPdf] = useState(null);

  const getPdf = async () => {
    const params = {
      orderNumber,
    };
    setPdf(await Parse.Cloud.run("getOrderPdf", params));
  };

  useEffect(() => {
    getPdf();
    // eslint-disable-next-line
  }, []);

  if (pdf === null) {
    return null;
  }
  return (
    <>
      <AppBar>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            onClick={() => onDialogClose(false)}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
          <a
            href={
              "data:application/pdf;headers=" +
              pdf.headers +
              ";base64," +
              pdf.body
            }
            download={"commande " + orderNumber}
            className={classes.downloadLink}
          >
            <IconButton
              color="inherit"
              aria-label="Close"
              className={classes.downloadButton}
            >
              <GetAppIcon />
            </IconButton>
          </a>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            pdf de la commande {orderNumber}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <embed
          className={classes.embed}
          type="application/pdf"
          src={
            "data:application/pdf;headers=" +
            pdf.headers +
            ";base64," +
            pdf.body
          }
          style={{ minHeight: "100vh", width: "100%" }}
        />
      </DialogContent>
    </>
  );
};
export default PdfOrder;
