import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  PagingState,
  CustomPaging,
  FilteringState,
  SortingState,
  SelectionState,
  IntegratedSelection,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  VirtualTable,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
  TableSelection,
  TableFixedColumns,
  TableBandHeader,
} from "@devexpress/dx-react-grid-material-ui";
import {
  withStyles,
  Typography,
  CircularProgress,
  Button,
} from "@material-ui/core";
import ActionsColumn from "../../components/reactgrid/ActionsCol";
import { NumberProvider } from "../../components/reactgrid/Formatter";
import { NumberProviderRedNegative } from "../../components/reactgrid/Formatter";
import CustomTableHeaderCell from "../../components/reactgrid/CustomTableHeaderCell";

const styles = (theme) => ({
  tableTopWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  tableTitleWrapper: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    minHeight: "64px",
    padding: "0 8px 0 24px",
  },
  titleExplanation: {
    margin: "2px 0 0 0.5rem",
  },
  subtitle1Wrapper: {
    padding: "12px 0 24px",
  },
  addBtnWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0 24px",
  },
  tableContainer: {
    position: "relative",
    "& col:nth-child(5)": {
      // Magasin livré
      width: "25% !important",
    },
  },
  toolTip: {
    maxWidth: "230px",
  },
  circularProgress: {
    position: "absolute",
    top: 250,
    left: "50%",
  },
  noData: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    fontSize: 12,
    fontWeight: "bold",
  },
  cell: {
    width: "100%",
    padding: theme.spacing(1),
  },
  input: {
    fontSize: "14px",
    width: "50%",
  },
});

class DataGridView extends PureComponent {
  constructor(props) {
    super(props);

    this.changeSelection = (selection) => {
      const { onSelectionChange } = this.props;
      onSelectionChange(selection);
    };

    this.cell = this.cell.bind(this);
  }

  static propTypes = {
    rowsValues: PropTypes.array, // rows values of table
    columnHeaders: PropTypes.array, // column headers of table
    currentPage: PropTypes.number, // the current visible page
    onCurrentPageChange: PropTypes.func, // on change current page
    pageSize: PropTypes.number, // row per page
    onPageSizeChange: PropTypes.func, //on change page size
    pageSizes: PropTypes.array, // list of selectable display limit
    totalCount: PropTypes.number, // total count of remote data
    loading: PropTypes.bool, // is loading data
    filters: PropTypes.any, // filters
    withFilter: PropTypes.bool, // to use filters
    onFiltersChange: PropTypes.func, // on change filters
    filterPlaceholder: PropTypes.string, //filter placeholder
    sorting: PropTypes.any, // sorting
    withSorting: PropTypes.bool, // to use sorting
    rowsPerPage: PropTypes.string, // rows per page message
    contentInfoSupplier: PropTypes.func, // content info for paging panel
    columnExtensions: PropTypes.any, //customizing column appearance
    withPagination: PropTypes.bool, // to use pagination
    hasActions: PropTypes.bool, // to use actions
    actions: PropTypes.any, // actions
    actionWidth: PropTypes.number, // action columns witdh
    withAddButton: PropTypes.bool, // to add a add button
    onAddButtonClick: PropTypes.func, // on click on add button
  };

  _contentInfo = ({ from, to, count }) => {
    return `${from} ${from < to ? ` à ${to}` : ""} sur ${count}`;
  };

  cell = (props) => {
    const { onCellClickFunction } = this.props;
    return (
      <Table.Cell
        onClick={() => onCellClickFunction(props)}
        tabIndex={0}
        {...props}
      />
    );
  };
  /********************** FilterCell **********************/

  render() {
    const {
      classes,
      title = false,
      titleExplanation,
      rowsValues,
      columnHeaders,
      numberColumns,
      numberRedNegativeColumns,
      currentPage,
      onCurrentPageChange,
      pageSize,
      onPageSizeChange,
      pageSizes,
      withPagination = true,
      filters,
      onFiltersChange,
      filterPlaceholder = "Filtrer...",
      withFilter = true,
      sorting,
      withSorting = true,
      onSortingChange,
      hasActions = false,
      actions,
      actionWidth = 130,
      columnExtensions,
      totalCount,
      loading,
      rowsPerPage = "Lignes par page : ",
      contentInfoSupplier,
      withAddButton = false,
      withAddButtonText = "ajouter",
      onAddButtonClick,
      noDataText = "pas de données",
      withCustomAddButton = false,
      withSelection = false,
      isVirtualized = false,
      height,
      withFixedColumns = false,
      fixedLeftColumns,
      fixedRightColumns,
      columnBands = [],
      withOnCellClickFunction,
      selection,
    } = this.props;
    const dateFilterOperations = [
      "equal",
      "greaterThanOrEqual",
      "lessThanOrEqual",
    ];
    const dateCols = columnHeaders.filter((column) => column.type === "date");
    const dateColumns = dateCols.map((col) => col.name);

    return (
      <>
        <Grid rows={rowsValues} columns={columnHeaders}>
          {(title || withAddButton) && (
            <div className={classes.tableTopWrapper}>
              {title && (
                <Typography variant="h6" className={classes.tableTitleWrapper}>
                  {title}
                  <Typography
                    variant="body2"
                    className={classes.titleExplanation}
                  >
                    {titleExplanation}
                  </Typography>
                </Typography>
              )}
              {withAddButton && (
                <div className={classes.addBtnWrapper}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onAddButtonClick}
                  >
                    {withAddButtonText}
                  </Button>
                </div>
              )}
              {withCustomAddButton && (
                <div className={classes.addBtnWrapper}>
                  {this.props.children}
                </div>
              )}
            </div>
          )}

          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={onCurrentPageChange}
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
          />
          <CustomPaging totalCount={totalCount} />

          {numberColumns !== undefined && (
            <NumberProvider for={numberColumns} />
          )}

          {numberRedNegativeColumns !== undefined && (
            <NumberProviderRedNegative for={numberRedNegativeColumns} />
          )}

          <DataTypeProvider
            for={dateColumns}
            availableFilterOperations={dateFilterOperations}
          />
          {/* removing filter icons for all columns but dates */}
          <DataTypeProvider
            for={columnHeaders
              .filter((column) => !dateColumns.includes(column.name))
              .map((column) => column.name)}
            availableFilterOperations={[]}
          />

          <FilteringState
            filters={filters}
            onFiltersChange={onFiltersChange}
            columnExtensions={columnExtensions}
          />
          {withSorting && (
            <SortingState sorting={sorting} onSortingChange={onSortingChange} />
          )}
          {withSelection && (
            <SelectionState
              selection={selection}
              onSelectionChange={this.changeSelection}
            />
          )}
          {withSelection && <IntegratedSelection />}

          {isVirtualized && !withOnCellClickFunction && (
            <VirtualTable
              columnExtensions={columnExtensions}
              height={height}
              messages={{ noData: loading ? "chargement..." : noDataText }}
            />
          )}

          {isVirtualized && withOnCellClickFunction && (
            <VirtualTable
              columnExtensions={columnExtensions}
              height={height}
              cellComponent={this.cell}
              messages={{ noData: loading ? "chargement..." : noDataText }}
            />
          )}

          {!isVirtualized && !withOnCellClickFunction && (
            <Table
              columnExtensions={columnExtensions}
              messages={{ noData: loading ? "chargement..." : noDataText }}
            />
          )}
          {!isVirtualized && withOnCellClickFunction && (
            <Table
              columnExtensions={columnExtensions}
              cellComponent={this.cell}
              messages={{ noData: loading ? "chargement..." : noDataText }}
            />
          )}

          <TableHeaderRow
            cellComponent={CustomTableHeaderCell}
            showSortingControls={withSorting}
            messages={{ sortingHint: "Trier" }}
          />
          {!!columnBands.length && (
            <TableBandHeader columnBands={columnBands} />
          )}
          {withFilter && (
            <TableFilterRow
              messages={{
                filterPlaceholder: filterPlaceholder,
                equal: "Egal",
                greaterThanOrEqual: "A partir de",
                lessThanOrEqual: "Jusqu'à",
              }}
              showFilterSelector
            />
          )}
          {withFixedColumns && (
            <TableFixedColumns
              leftColumns={fixedLeftColumns}
              rightColumns={fixedRightColumns}
            />
          )}
          {withSelection && <TableSelection showSelectAll />}
          {hasActions && (
            <ActionsColumn actions={actions} width={actionWidth} />
          )}
          {withPagination && (
            <PagingPanel
              pageSizes={pageSizes}
              messages={{
                rowsPerPage: rowsPerPage,
                info: contentInfoSupplier || this._contentInfo,
              }}
            />
          )}
        </Grid>
        {loading && <CircularProgress className={classes.circularProgress} />}
      </>
    );
  }
}
export default withStyles(styles)(DataGridView);
