import React, { PureComponent } from "react";
import classNames from "classnames";
import { withStyles, Typography } from "@material-ui/core";

const styles = (theme) => ({
  chipContainer: {
    display: "flex",
    alignItems: "center",
  },
  shippingChip: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  normal: {
    width: "2rem",
    height: "2rem",
    borderRadius: "1rem",
  },
  small: {
    width: "1.4rem",
    height: "1.4rem",
    borderRadius: "0.7rem",
  },
  textInChip: {
    color: "#fff",
    textAlign: "center",
  },
  shipping1: { backgroundColor: "#d88989" },
  shipping2: { backgroundColor: "#86dba4" },
  shipping3: { backgroundColor: "#88a0d9" },
  shipping4: { backgroundColor: "#cc9966" },
  shipping5: { backgroundColor: "#3cb44b" },
  shipping6: { backgroundColor: "#4363d8" },
  shipping7: { backgroundColor: "#f58231" },
  shipping8: { backgroundColor: "#911eb4" },
  shipping9: { backgroundColor: "#800000" },
  shipping10: { backgroundColor: "#000075" },
  shipping11: { backgroundColor: "#9a6324" },
  shipping12: { backgroundColor: "#008080" },
  shipping13: { backgroundColor: "#f032e6" },
  shipping14: { backgroundColor: "#808080" },
  shipping15: { backgroundColor: "#000000" },
});

class ShippingChip extends PureComponent {
  render() {
    const { classes, number, size } = this.props;
    const classNameSize = size === undefined ? classes.normal : classes[size];
    const className = "shipping" + number;

    return (
      <div className={classes.chipContainer}>
        <div
          className={classNames(
            classes.shippingChip,
            classNameSize,
            classes[className],
          )}
        >
          <Typography variant="body2" className={classes.textInChip}>
            {number}
          </Typography>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ShippingChip);
