import React, { useState } from "react";
import Parse from "parse";
import moment from "moment";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../reducers/currentUser";
import {
  makeStyles,
  Typography,
  Button,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Collapse,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  subtitle1Wrapper: {
    padding: "12px 0 24px",
  },
  importButtonWrapper: {
    marginBottom: "1rem",
  },
  input: {
    display: "none",
  },
  button: {
    margin: "0 22px 0 0",
  },
  detailsLink: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  linearProgressSpace: {
    height: 4,
    width: "100%",
  },
  successDetails: {
    marginBottom: "0.5rem",
  },
}));

const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

const completeWithChar = (string, char, finalLength) => {
  let newString = string.toString();
  const initialLength = newString !== undefined ? newString.length : 0;
  for (let i = initialLength; i < finalLength; i++) {
    newString += char;
  }
  return newString;
};

const prefixWithChar = (value, char, finalLength) => {
  let newString = value.toString();
  for (let i = value.toString().length; i < finalLength; i++) {
    newString = char + newString;
  }
  return newString;
};

// Code 0 = 0% - exonéré zone EXPORT hors UE
// Code 1 = 20% - tva taux normal à 20%
// Code 2 = 10% - tva à 10% (pas utilisé à ce jour)
// Code 3 = 0% - exonéré zone UE
// Code 4 = 0% - TVA autoliquidée
// Code 5 = 5.5% - tva à 5.5%
const defaultVATCode = 1;

const OrderImport = (props) => {
  const { onLoadData, onSnackBarShow } = props;
  const classes = useStyles();
  const currentUser = useSelector(getCurrentUser);
  const [orders, setOrders] = useState([]);
  const [ordersErrors, setOrdersErrors] = useState({});
  const [progress, setProgress] = useState(0);
  const [treatingOrders, setTreatingOrders] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openErrorDetails, setOpenErrorDetails] = useState(false);
  const [openSuccessDetails, setOpenSuccessDetails] = useState(false);

  const fileSelectedHandler = (event) => {
    const newOrdersErrors = {};
    const file = event.target.files[0];
    const textType = /.*?(vnd.ms-excel|text\/plain|text\/csv)/; // |text\/csv added on 05/18/22. Was it on purpose that it was not supported before ?
    if (file.type.match(textType)) {
      const reader = new FileReader();

      reader.onload = async () => {
        let lines = reader.result.split(/\r?\n/);
        // removing empty lines
        lines = lines.filter((line) => line !== ";;;;;;;;;;");

        const newOrders = [];
        let currentClientId = "";
        let currentDeliveryDate = "";
        let currentOrder = {};
        let errorRef = 0;
        const creationMoment = moment();
        const creationMomentFormatted = creationMoment.format("YYYYMMDDHHmm");
        const creationMomentFormatted2 = creationMoment.format("YYYYMMDD");
        const creationMomentDate = moment
          .utc(creationMomentFormatted2)
          .toDate();
        const orderRef =
          lines.length > 1
            ? "MULTIMP_" + creationMomentFormatted
            : "SINGIMP_" + creationMomentFormatted;
        // getting last invoiceNumber in base
        const query = await new Parse.Query("Orders")
          .startsWith("ECKTNUMERO", "6")
          .descending("ECKTNUMERO")
          .select("ECKTNUMERO")
          .first();
        let lastInvoiceNumber = 599999;
        if (query !== undefined) {
          lastInvoiceNumber = parseInt(query.get("ECKTNUMERO"));
        }
        const treatOrderAndPushInOrders = async (order) => {
          let totalAmount = 0;
          let totalRawAmount = 0;
          let ECCNPDSNET = 0;
          let ECCNVOLUME = 0;
          errorRef++;
          // getting client
          const client = await new Parse.Query("Clients")
            .equalTo("CLKTCODE", order.clientId)
            .include("mainContact")
            .first();

          if (client !== undefined) {
            let priorityVATCode = undefined;
            const clientVATCode = client.get("CLCTTAXE");
            if (clientVATCode !== undefined) {
              priorityVATCode = parseInt(clientVATCode);
            }

            let contact = undefined;
            if (client.get("mainContact") !== undefined) {
              contact = client.get("mainContact");
            } else {
              contact = await new Parse.Query("Contacts")
                .equalTo("CLKTCODE", client)
                .ascending("CLCTCONTA1")
                .first();
            }

            //---------- internal functions ----------//

            // to be applied on LCCTCODTVA
            const getAppliedVATCodeOnProduct = (productVATCode) => {
              let VATCode = undefined;
              if (priorityVATCode !== undefined) {
                VATCode = priorityVATCode;
              }
              if (
                (VATCode === undefined ||
                  VATCode === 1 ||
                  VATCode === 2 ||
                  VATCode === 5) &&
                productVATCode !== undefined
              ) {
                VATCode = productVATCode;
              }
              return VATCode !== undefined ? VATCode : defaultVATCode;
            };

            const createLines = async () => {
              const linesToReturn = [];
              let productErrorFound = false;
              await asyncForEach(order.lines, async (orderLine) => {
                // getting product details
                const product = await new Parse.Query("Products")
                  .equalTo("ARKTCODART", orderLine.productId)
                  .first();

                if (product !== undefined) {
                  // getting article details (stored in productArticle)
                  let productArticle = {};
                  const productArticles = product.get("articles");
                  if (productArticles.length) {
                    productArticles.forEach((prodArticle) => {
                      if (prodArticle.ARKTCOMART === orderLine.articleId) {
                        productArticle = prodArticle;
                      }
                    });
                  }

                  const quantity = orderLine.quantity;
                  const LCCNPDSCDE =
                    product.get("ARCNPDSUNI") !== undefined
                      ? product.get("ARCNPDSUNI") * quantity
                      : 0;
                  const LCCNVOLCDE =
                    product.get("ARCNVOLUNI") !== undefined
                      ? product.get("ARCNVOLUNI") * quantity
                      : 0;
                  totalAmount += orderLine.amount * quantity;
                  totalRawAmount += product.get("ARCNTARIF1") * quantity;
                  ECCNPDSNET += LCCNPDSCDE;
                  ECCNVOLUME += LCCNVOLCDE;

                  const LCCJDELEXPMoment = moment(order.deliveryDate).subtract(
                    client.get("CLCTJOURAC"),
                    "day",
                  );
                  const LCCJDELEXPMomentFormatted =
                    LCCJDELEXPMoment.format("YYYYMMDD");
                  const LCCJDELEXP = moment
                    .utc(LCCJDELEXPMomentFormatted)
                    .toDate();
                  const articleRef = (
                    completeWithChar(product.get("ARKTCODART"), " ", 18) +
                    orderLine.articleId
                  ).trim();
                  const line = {
                    LCKTSOC: "100",
                    LCKTNUMERO: invoiceNumber.toString(),
                    LCKTLIGNE: prefixWithChar(linesToReturn.length + 1, "0", 3),
                    LCKTPSF: "000",
                    LCCTTYPE: "S",
                    LCCJCRE: creationMomentDate,
                    LCCTCODE: client.get("CLKTCODE"),
                    LCCTTYPART: product.get("ARCTTYPART"),
                    LCCTNATURE: product.get("ARITNATURE"),
                    LCCTCODART: product.get("ARKTCODART"),
                    LCCTCOMART: orderLine.articleId,
                    LCCTLIB01: product.get("ARCTLIB01"),
                    LCCNQTECDE: quantity,
                    LCCNPDSCDE: LCCNPDSCDE,
                    LCCNVOLCDE: LCCNVOLCDE,
                    LCCNLONCDE:
                      product.get("ARCNLONGUE") !== undefined
                        ? product.get("ARCNLONGUE")
                        : 0,
                    LCCNLARCDE:
                      product.get("ARCNLARGEU") !== undefined
                        ? product.get("ARCNLARGEU")
                        : 0,
                    LCCNEPACDE:
                      product.get("ARCNEPAISS") !== undefined
                        ? product.get("ARCNEPAISS")
                        : 0,
                    LCCTUNICDE: "UN",
                    LCCTUNISTO: "UN",
                    LCCTUNIFAC: "UN",
                    LCCNPUBRUT: product.get("ARCNTARIF1"),
                    LCCNRHECR: 0,
                    LCCNPUNET: orderLine.amount,
                    LCCNRHLIG:
                      -1 *
                      (orderLine.discountPercentage !== 0
                        ? orderLine.discountPercentage
                        : Math.round(
                            ((product.get("ARCNTARIF1") - orderLine.amount) /
                              product.get("ARCNTARIF1")) *
                              10000,
                          ) / 100),
                    LCCNRHBAS1: 0,
                    LCCNRHBAS2: 0,
                    LCCNRHBAS3: 0,
                    LCCNQTEEXP: 0,
                    LCCNPDSEXP: 0,
                    LCCNVOLEXP: 0,
                    LCCNQTECON: 0,
                    LCCNPDSCON: 0,
                    LCCNVOLCON: 0,
                    LCCTCREP1: client.get("CLCTREP1"),
                    LCCTCREP2: "97",
                    LCCNTXREP1: client.get("CLCNTXREP1"),
                    LCCNTXREP2: 0,
                    LCCNBASCOM: 0,
                    LCCSDELEXP: moment(order.deliveryDate)
                      .subtract(client.get("CLCTJOURAC"), "day")
                      .format("YYYYww"),
                    LCCJDELEXP: LCCJDELEXP,
                    LCCTDEPOT:
                      productArticle.ARCTCODEPC !== undefined
                        ? productArticle.ARCTCODEPC !== undefined
                          ? productArticle.ARCTCODEPC
                          : "MAG"
                        : product.get("ARCTCODEPC") !== undefined
                          ? product.get("ARCTCODEPC")
                          : "MAG",
                    //LCCTNOAFFA: invoiceNumber.toString(),
                    LCCNPUREVI: product.get("ARCNTARIF6"),
                    LCCTSECART: product.get("ARCTSECART"),
                    LCCTSECCDE: product.get("ARCTSECART"),
                    LCCTPRIORI:
                      client.get("CLCTPRIORI") !== undefined
                        ? client.get("CLCTPRIORI")
                        : "",
                    LCCTCODTVA: getAppliedVATCodeOnProduct(
                      product.get("ARCTCODTVA"),
                    ),
                    LCCTCODTAR: "0",
                    LCCTDEVISE: "EUR",
                    LCCTIMPUT: product.get("ARCTSIMPUT"),
                    LCCTAFFMAC: product.get("ARCTCODFAM"),
                    LCCNVALCDE: orderLine.amount * quantity,
                    LCCTFATN: product.get("ARCTFATN"),
                    LCCNBASMAR: product.get("ARCNTARIF6") * quantity,
                    LCCTLIB02:
                      product.get("ARCTLIB02") !== undefined
                        ? product.get("ARCTLIB02")
                        : "",
                    LCCJDELDDE: moment(order.deliveryDate).toDate(),
                    LCCJDELARR: moment(order.deliveryDate).toDate(),
                    LCCSDELARR: moment(order.deliveryDate).format("YYYYww"),
                    LCCSDELDEM: moment(order.deliveryDate).format("YYYYww"),

                    // fields added because in PMI orders these columns don't appear as NULL but as ' ' or 0
                    LCCTETS: "",
                    LCCTEXPU: "",
                    LCCTSOLACE: "",
                    LCCJDEREX: "",
                    LCCTNOEXP: "",
                    LCCTSOLACC: "",
                    LCCJDERCO: "",
                    LCCTNUMLOT: "",
                    //LCCTNOLAFF: '001',
                    LCCSDELFAB: "",
                    LCCJDELFAB: "",
                    LCCTTOPRAL: "",
                    LCCTTOPBES: "",
                    LCCTTOPEDI: "",
                    LCCTETACDE: "",
                    LCCTREVPRI: "",
                    LCCTEDIPU: "",
                    LCCNTXDEVI: 0,
                    LCCTREFCDE: "",
                    LCCTCONDIT: "",
                    LCCTGAMREM: "",
                    LCCTENVOIF: "",
                    LCCTZONLIV: "",
                    LCCTREPERE: "",
                    LCCTTYPLIB: "",
                    LCCTLANCEM: "",
                    LCCTLIGLAN: "",
                    LCCTSOLACQ: "",
                    LCCNDENSIT: 0,
                    LCCTANNOTA: "",
                    LCCTSTATUT: "",
                    LCCTREFEXT: "",
                    LCCTPALLOC: "",
                    LCCTDATREF: "",
                    LCCTCERCON: "",
                    LCCJCERCON: "",
                    LCCT12DELP: "",
                    LCCT12DELD: "",
                    LCCNQTELAN: 0,
                    LCCNCUMQRE: 0,
                    LCCTTOPGE1: "",
                    LCCTTOPGE2: "",
                    LCCTFAMART: "",
                    LCCTRUBAFF: "",
                    LCCTAFFACH: "",
                    LCCTLIGACH: "",
                    LCCNLIBRE1: 0,
                    LCCNLIBRE2: 0,
                    LCCTTOP01: "",
                    LCCTTOP02: "",
                    LCCTTOP03: "",
                    LCCTTOP04: "",
                    LCCTHREXP: "",
                    LCCTEDI1: "",
                    LCCNPOUNI: 0,
                    LCCNPREPA: 0,
                    LCCTGENERE: "",
                    LCCNFORFAI: 0,
                    LCCNIDGMAO: 0,
                    LCCNIDSAV: 0,
                    LCCNNUMOP: 0,
                    LCCNECOTX: 0,

                    orderRef,
                    articleRef: articleRef,
                  };
                  if (
                    productArticle.ARCTCODMPL !== undefined ||
                    product.get("ARCTCODMPL") !== undefined
                  ) {
                    line.LCCTCODEMP =
                      productArticle.ARCTCODMPL !== undefined
                        ? productArticle.ARCTCODMPL
                        : product.get("ARCTCODMPL");
                  }
                  /*
                  if (product.get('ARCTCODMPL') !== undefined) {
                    line.LCCTCODEMP = product.get('ARCTCODMPL');
                  }*/
                  linesToReturn.push(line);
                } else {
                  // product not found
                  productErrorFound = true;
                  if (newOrdersErrors[errorRef] === undefined) {
                    newOrdersErrors[errorRef] = {
                      clientId: order.clientId,
                      deliveryDate: order.deliveryDate,
                      productsNotFound: [],
                      clientNotFound: false,
                    };
                  }
                  if (
                    !newOrdersErrors[errorRef].productsNotFound.includes(
                      orderLine.productId,
                    )
                  ) {
                    newOrdersErrors[errorRef].productsNotFound.push(
                      orderLine.productId,
                    );
                  }
                }
              });
              return productErrorFound ? "" : linesToReturn;
            };

            const createShippingFeesLine = async (orderLines) => {
              // getting fees product invoiceNumber in base
              const feesProduct = await new Parse.Query("Products")
                .equalTo("ARKTCODART", "PORTVENTEFRANCE")
                .first();

              const shippingFees =
                totalAmount >= 200 ? 0 : feesProduct.get("ARCNTARIF1");
              totalAmount += shippingFees;
              totalRawAmount += shippingFees;

              // only adding shipping line if shippingFees > 0
              if (shippingFees > 0) {
                const LCCJDELEXPMoment = moment(order.deliveryDate).subtract(
                  client.get("CLCTJOURAC"),
                  "day",
                );
                const LCCJDELEXPMomentFormatted =
                  LCCJDELEXPMoment.format("YYYYMMDD");
                const LCCJDELEXP = moment
                  .utc(LCCJDELEXPMomentFormatted)
                  .toDate();

                const shippingFeesLine = {
                  LCKTSOC: "100",
                  LCKTNUMERO: invoiceNumber.toString(),
                  LCKTLIGNE: prefixWithChar(orderLines.length + 1, "0", 3),
                  LCKTPSF: "000",
                  LCCTTYPE: "S",
                  LCCJCRE: creationMomentDate,
                  LCCTCODE: client.get("CLKTCODE"),
                  LCCTTYPART: feesProduct.get("ARCTTYPART"),
                  LCCTNATURE: feesProduct.get("ARITNATURE"),
                  LCCTCODART: feesProduct.get("ARKTCODART"),
                  LCCTCOMART: "",
                  LCCTLIB01: feesProduct.get("ARCTLIB01"),
                  LCCNQTECDE: 1,
                  // LCCNPDSCDE: (productObj.ARCNPDSUNI !== undefined) ? productObj.ARCNPDSUNI * quantity : 0,
                  // LCCNVOLCDE: (productObj.ARCNVOLUNI !== undefined) ? productObj.ARCNVOLUNI * quantity : 0,
                  // LCCNLONCDE: (productObj.ARCNLONGUE !== undefined) ? productObj.ARCNLONGUE : 0,
                  // LCCNLARCDE: (productObj.ARCNLARGEU !== undefined) ? productObj.ARCNLARGEU : 0,
                  // LCCNEPACDE: (productObj.ARCNEPAISS !== undefined) ? productObj.ARCNEPAISS : 0,
                  LCCTUNICDE: "UN",
                  LCCTUNISTO: "UN",
                  LCCTUNIFAC: "UN",
                  LCCNPUBRUT: shippingFees,
                  LCCNRHECR: 0,
                  LCCNPUNET: shippingFees,
                  LCCNRHLIG: 0,
                  // LCCNRHBAS1: 0,
                  // LCCNRHBAS2: 0,
                  // LCCNRHBAS3: 0,
                  // LCCNQTEEXP: 0,
                  // LCCNPDSEXP: 0,
                  // LCCNVOLEXP: 0,
                  // LCCNQTECON: 0,
                  // LCCNPDSCON: 0,
                  // LCCNVOLCON: 0,
                  // LCCTCREP1: shipping.clientCommercial,
                  // LCCTCREP2: shipping.orderOrigin,
                  // LCCNTXREP1: shipping.clientCommercialRate,
                  // LCCNTXREP2: 0,
                  // LCCNBASCOM: 0,
                  LCCSDELEXP: moment(order.deliveryDate)
                    .subtract(client.get("CLCTJOURAC"), "day")
                    .format("YYYYww"),
                  LCCJDELEXP: LCCJDELEXP,
                  // LCCTDEPOT: (productArticle.ARCTCODEPC !== undefined) ?
                  //   (productArticle.ARCTCODEPC !== undefined) ? productArticle.ARCTCODEPC : 'MAG' :
                  //   (productObj.ARCTCODEPC !== undefined) ? productObj.ARCTCODEPC : 'MAG',
                  //LCCTNOAFFA: invoiceNumber.toString(),
                  LCCNPUREVI: feesProduct.get("ARCNTARIF6"),
                  LCCTSECART: feesProduct.get("ARCTSECART"),
                  LCCTSECCDE: feesProduct.get("ARCTSECART"),
                  // LCCTPRIORI: (shipping.client.dbObj.CLCTPRIORI !== undefined) ? shipping.client.dbObj.CLCTPRIORI : '',
                  LCCTCODTVA: getAppliedVATCodeOnProduct(
                    feesProduct.get("ARCTCODTVA"),
                  ),
                  LCCTCODTAR: "0",
                  LCCTDEVISE: "EUR",
                  LCCTIMPUT: feesProduct.get("ARCTSIMPUT"),
                  LCCTAFFMAC: feesProduct.get("ARCTCODFAM"),
                  LCCNVALCDE: shippingFees,
                  LCCTFATN: feesProduct.get("ARCTFATN"),
                  LCCNBASMAR: shippingFees,
                  // LCCTLIB02: (productArticle.ARCTLIB02 !== undefined) ?
                  //   productArticle.ARCTLIB02 :
                  //   (productObj.ARCTLIB02 !== undefined) ?
                  //     productObj.ARCTLIB02 : ''
                  // ,
                  LCCJDELDDE: moment(order.deliveryDate).toDate(),
                  LCCJDELARR: moment(order.deliveryDate).toDate(),
                  LCCSDELARR: moment(order.deliveryDate).format("YYYYww"),
                  // LCCSDELDEM: moment(shipping.deliveryDate).format('YYYYww'),
                  orderRef,
                  articleRef: feesProduct.get("ARKTCODART"),
                };
                orderLines.push(shippingFeesLine);
              }
            };
            //-------- end internal functions --------//

            // to be asked: clientPermanentDiscountApplied, preSeasonDiscountApplied
            const invoiceNumber = ++lastInvoiceNumber;
            const orderLines = await createLines(); // we return [] if a product is not found. next line prevents from saving order on error
            if (orderLines.length) {
              await createShippingFeesLine(orderLines);

              const newOrder = {
                ECKTSOC: "100",
                ECKTNUMERO: invoiceNumber.toString(),
                ECKTINDICE: "000",
                ECCTTYPE1: "C",
                ECCTTYPE2: "S",
                ECCJCRE: creationMomentDate,
                ECCTCODE: client.get("CLKTCODE"),
                ECCTNOM: client.get("CLCTNOM"),
                ECCTRUE1:
                  client.get("CLCTRUE1") !== undefined
                    ? client.get("CLCTRUE1")
                    : "",
                ECCTRUE2: client.get("CLCTRUE2"),
                ECCTCP: client.get("CLCTCP"),
                ECCTVILLE: client.get("CLCTVILLE"),
                ECCTPAYS: client.get("CLCTPAYS"),
                ECCTCPAYS: client.get("CLCTCPAYS"),
                ECCTNOMLIV: client.get("CLCTNOMLIV"),
                ECCTRUE1LI: client.get("CLCTRUE1LI"),
                ECCTRUE2LI: client.get("CLCTRUE2LI"),
                ECCTCPLIV: client.get("CLCTCPLIV"),
                ECCTVILLIV: client.get("CLCTVILLIV"),
                ECCTPAYSLI:
                  client.get("CLCTPAYSLI") !== undefined
                    ? client.get("CLCTPAYSLI")
                    : "",
                ECCTCPAYLI: client.get("CLCTCPAYLI"),
                ECCTTRANPO: client.get("CLCTTRANPO"),
                ECCTREGFAC: client.get("CLCTREGFAC"),
                ECCTREGBL: client.get("CLCTREGBL"),
                ECCTCECHEA: client.get("CLCTCECHEA"),
                ECCTBECHEA: client.get("CLCTBECHEA"),
                ECCTCREG: client.get("CLCTCREG"),
                ECCTMREG: client.get("CLCTMREG"),
                ECCTREGFIS: client.get("CLCTREGFIS"),
                ECCTACTIVI: client.get("CLCTACTIVI"),
                ECCNPDSNET: ECCNPDSNET,
                ECCNVOLUME: ECCNVOLUME,
                ECCTDEVISE: "EUR",
                ECCTCODFAC:
                  client.get("CLCTCODFAC") !== undefined
                    ? client.get("CLCTCODFAC")
                    : "",
                ECCTCENACH: client.get("CLCTCOMPTA"), // not a mistake
                ECCTUTIMOD: "",
                status: "imported",
                ECCTTOP08: "0",
                ECCTDOMACT: "2000",
                clientPermanentDiscountApplied: "-1",
                preSeasonDiscountApplied: -1,
                amount: totalAmount,
                rawAmount: totalRawAmount,
                discount:
                  (-1 * Math.round((totalRawAmount - totalAmount) * 100)) / 100,
                orderRef, // This is to trace the Multi orders import.
                lines: orderLines,
                orderAuthor: currentUser,
                rep1: orderLines[0].LCCTCREP1,
                orderConfirmationSent: false,
                contact,
              };
              newOrders.push(newOrder);
            }
          } else {
            // client not found
            if (newOrdersErrors[errorRef] === undefined) {
              newOrdersErrors[errorRef] = {
                clientId: order.clientId,
                deliveryDate: order.deliveryDate,
                productsNotFound: [],
              };
            }
            newOrdersErrors[errorRef].clientNotFound = true;
          }
        };

        if (lines.length) {
          setTreatingOrders(true);
          await asyncForEach(lines, async (line, indexLine) => {
            setProgress((indexLine / lines.length) * 100);
            const data = line.split(/;/);
            if (data.length > 1) {
              const discountPercentage = Math.abs(data[6]);
              const amountBeforeDiscount = Number(
                data[5].replace(" €", "").replace(",", "."),
              );
              const amountAfterDiscount =
                amountBeforeDiscount * (1 - discountPercentage / 100);
              const lineObject = {
                productId: data[2],
                articleId: data[9],
                quantity: parseFloat(data[3]),
                amount: Math.round(amountAfterDiscount * 100) / 100,
                discountPercentage,
              };
              // console.log('lineObject: ', lineObject);
              const thisRoundClientId = prefixWithChar(data[1], "0", 6);
              const isNewOrder =
                currentClientId === "" ||
                thisRoundClientId !== currentClientId ||
                data[4] !== currentDeliveryDate;
              if (isNewOrder) {
                if (currentClientId !== "") {
                  await treatOrderAndPushInOrders(currentOrder);
                }
                currentClientId = thisRoundClientId;
                currentDeliveryDate = data[4];
                currentOrder = {
                  clientId: currentClientId,
                  deliveryDate: currentDeliveryDate,
                  lines: [],
                };
              }
              currentOrder.lines.push(lineObject);
            }
          });
          // saving last one
          await treatOrderAndPushInOrders(currentOrder);
          setProgress(100);

          //console.log('orders: ', newOrders);
          //console.log('newOrdersErrors: ', newOrdersErrors);
          setOrders(newOrders);
          setOrdersErrors(newOrdersErrors);
          setTimeout(() => {
            setTreatingOrders(false);
            setOpenDialog(true);
          }, 500);
        } else {
          console.log("csv is empty");
        }
      };
      reader.readAsText(file, "ISO-8859-1");
    } else {
      console.log("File not supported!");
    }
    document.getElementById("importOrderForm").reset();
  };

  const saveOrdersToMongo = async () => {
    await asyncForEach(orders, async (objectToSave) => {
      const Order = Parse.Object.extend("Orders");
      const order = new Order();
      Object.entries(objectToSave).forEach(([key, value]) => {
        order.set(key, value);
      });
      try {
        await order.save();
      } catch (error) {
        console.log(error);
        return "error";
      }
    });
    onLoadData(true);
    setOpenDialog(false);
    onSnackBarShow("commande(s) importée(s)");
  };

  return (
    <>
      <div className={classes.importButtonWrapper}>
        <form id="importOrderForm">
          <input
            accept=".csv, text/plain"
            className={classes.input}
            id="importButton"
            type="file"
            onChange={fileSelectedHandler}
          />
          {/* button is placed directly in History inside DataGridView */}
        </form>
      </div>
      {treatingOrders && (
        <LinearProgress variant="determinate" value={progress} />
      )}

      {!treatingOrders && <div className={classes.linearProgressSpace} />}

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Import de commande(s)</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Résumé de l'import:
            <br />- commandes sans erreur: {orders.length} 
            {orders.length && (
              <span
                className={classes.detailsLink}
                onClick={() => setOpenSuccessDetails(!openSuccessDetails)}
              >
                (détails)
              </span>
            )}
          </DialogContentText>
          {orders.length && (
            <Collapse
              in={openSuccessDetails}
              timeout="auto"
              unmountOnExit
              className={classes.successDetails}
            >
              {orders.map((order) => {
                return (
                  <Typography variant="body1" key={order.ECKTNUMERO}>
                    {order.ECKTNUMERO}: {order.ECCTNOM} ({order.ECCTCODE}) -{" "}
                    {new Intl.NumberFormat("fr-FR", {
                      style: "currency",
                      currency: "EUR",
                    }).format(order.amount)}{" "}
                    -{" "}
                    {order.lines[0] !== undefined
                      ? moment(order.lines[0].LCCJDELARR).format("DD/MM/YYYY")
                      : "vide"}
                  </Typography>
                );
              })}
            </Collapse>
          )}
          <DialogContentText id="alert-dialog-description">
            - commandes avec erreur : {Object.keys(ordersErrors).length} 
            {Object.keys(ordersErrors).length && (
              <span
                className={classes.detailsLink}
                onClick={() => setOpenErrorDetails(!openErrorDetails)}
              >
                (détails)
              </span>
            )}
          </DialogContentText>
          {Object.keys(ordersErrors).length && (
            <Collapse in={openErrorDetails} timeout="auto" unmountOnExit>
              {Object.entries(ordersErrors).map(([orderId, data]) => {
                let stringToReturn = "";
                if (data.clientNotFound) {
                  stringToReturn +=
                    "code client non trouvé (" + data.clientId + ")";
                }
                if (data.productsNotFound.length) {
                  if (stringToReturn !== "") {
                    stringToReturn += ", ";
                  }
                  stringToReturn +=
                    "code produit non trouvé. (" + data.productsNotFound + ")";
                }
                return (
                  <Typography variant="body1" key={orderId}>
                    commande {orderId}: {stringToReturn}
                  </Typography>
                );
              })}
            </Collapse>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => saveOrdersToMongo()} color="primary">
            {Object.keys(ordersErrors).length !== 0 && (
              <span>Importer les commandes sans erreur</span>
            )}
            {!Object.keys(ordersErrors).length && (
              <span>Importer les commandes</span>
            )}
          </Button>
          <Button
            onClick={() => setOpenDialog(false)}
            color="primary"
            autoFocus
          >
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default OrderImport;
