import React, { PureComponent } from "react";
import { Form, Field, FormSpy } from "react-final-form";
import AutoSave from "../../components/form/AutoSave";
import { Select } from "final-form-material-ui";
import Switch from "../../components/form/Switch";
import { withStyles, Grid, Typography } from "@material-ui/core";
import Parse from "parse";
import { optionsCreator } from "../../utils2";
import { getEndOfNextSeason } from "../../utils2";

const styles = () => ({
  gridContainer: {
    width: "100%",
  },
  textField: {
    width: "100%",
  },
  selectField: {
    width: "100%",
  },
  fieldContainer: {
    display: "flex",
    flex: 1,
    height: "48px",
  },
  switchContainer: {
    display: "flex",
    flex: 1,
    height: "48px",
    marginTop: "16px",
  },
  buttonWrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    margin: "2rem 1rem 1rem 0",
  },
  errorMessage: {
    textAlign: "center",
    color: "red",
  },
  switchText: {
    lineHeight: "48px",
  },
});

class ForcedClientDiscountForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  _updateStateFormValues = async (formValues) => {
    this.setState({ formValues });
  };

  onSubmit = async (values) => {
    const { onModalClose } = this.props;
    const endOfNextSeason = getEndOfNextSeason();

    await Promise.all(
      Object.entries(values).map(async ([key, value]) => {
        if (key.includes("enable") && value === true) {
          // if we want to overwrite current client discount
          const clientId = key.replace("enable", "");
          const newRate = values["rate" + clientId];
          const client = await new Parse.Query("Clients")
            .equalTo("CLKTCODE", clientId)
            .first();

          const discount = await new Parse.Query("Discounts")
            .equalTo("includedClients", client)
            .equalTo("type", "clientPermanent")
            .first();

          if (discount) {
            discount.set("percentageDiscount", newRate);
            discount.set("endDate", endOfNextSeason);
            try {
              await discount.save();
              onModalClose("changeDiscount");
            } catch (error) {
              console.log(error.message);
              onModalClose("saveError");
            }
          } else {
            console.log("discount not found");
            onModalClose("saveError");
          }
        }
      }),
    );
  };

  renderForcedClientDiscountFields = () => {
    const { formValues } = this.state;
    const { classes, forcedDiscountData } = this.props;
    const fieldsToReturn = [];
    Object.entries(forcedDiscountData.forcedDiscountByClientId).forEach(
      ([clientId, optionValues], index) => {
        const currentDiscount =
          forcedDiscountData.currentClientDiscountByClientId[clientId];
        const switchFieldName = "enable" + clientId;
        const switchText =
          "Mettre à jour la remise du client " +
          clientId +
          " (" +
          currentDiscount +
          "% actuellement)";
        const selectFieldName = "rate" + clientId;
        const selectLabelName = "Remise pour le client " + clientId;
        const disableSelect =
          formValues === undefined ||
          (formValues !== undefined &&
            formValues[switchFieldName] !== undefined &&
            !formValues[switchFieldName]);
        const optionValuesAndTexts = optionValues.map((optionValue) => ({
          value: optionValue,
          text: optionValue,
        }));

        /**********************************************************/
        /*********************** validators ***********************/
        /**********************************************************/

        const hasToBeDefinedIfSwitchOn = (value, allValues) =>
          !(
            !allValues[switchFieldName] ||
            (allValues[switchFieldName] &&
              allValues[selectFieldName] !== undefined)
          )
            ? "Valeur à préciser"
            : undefined;

        fieldsToReturn.push(
          <div key={"clientDiscountFields" + index}>
            <Grid item xs={12}>
              <div className={classes.fieldContainer}>
                <Field
                  name={switchFieldName}
                  component={Switch}
                  type="checkbox"
                />
                <Typography variant="body2" className={classes.switchText}>
                  {switchText}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.fieldContainer}>
                <Field
                  name={selectFieldName}
                  label={selectLabelName}
                  component={Select}
                  fullWidth
                  className={classes.selectField}
                  disabled={disableSelect}
                  validate={hasToBeDefinedIfSwitchOn}
                >
                  {optionsCreator("custom", optionValuesAndTexts)}
                </Field>
              </div>
            </Grid>
          </div>,
        );
      },
    );

    return fieldsToReturn;
  };

  render() {
    const { classes } = this.props;

    return (
      <Form
        onSubmit={this.onSubmit}
        initialValues={{}}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form id="forcedClientDiscountForm" onSubmit={handleSubmit}>
            {false && (
              <div>
                <pre>{JSON.stringify(this.state, null, 2)}</pre>
              </div>
            )}
            <FormSpy
              subscription={{ values: true }}
              component={AutoSave}
              onFormChange={this._updateStateFormValues}
            />
            <Grid container spacing={0} className={classes.gridContainer}>
              {this.renderForcedClientDiscountFields()}
            </Grid>
          </form>
        )}
      />
    );
  }
}

export default withStyles(styles)(ForcedClientDiscountForm);
