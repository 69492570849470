import React from "react";
import { MenuItem } from "@material-ui/core";

export default function businessSmuOptions() {
  let optionArray = [];
  const entries = [
    {
      value: "intersport",
      text: "Intersport",
    },
    {
      value: "sport2000",
      text: "Sport 2000",
    },
  ];
  entries.forEach((entry, index) => {
    optionArray.push(
      <MenuItem key={index} value={entry.value}>
        {entry.text}
      </MenuItem>,
    );
  });
  return optionArray;
}
