import React, { useState, useEffect } from "react";
import Page from "./Page";
import Parse from "parse";
import HandleListForm from "../form/HandleListForm";
import { setListsValid } from "../../actions/main";
import { getCurrentUser } from "../../reducers/currentUser";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Snackbar,
} from "@material-ui/core";
import {
  FilteringState,
  IntegratedFiltering,
  SortingState,
  IntegratedSorting,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
} from "@devexpress/dx-react-grid-material-ui";
import { useSelector, useDispatch } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ActionsColumn from "../../components/reactgrid/ActionsCol";

const useStyles = makeStyles((theme) => ({
  tableTopWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "",
  },
  tableTitleWrapper: {
    display: "flex",
    alignItems: "center",
    minHeight: "64px",
    padding: "0 8px 0 24px",
  },
  subtitle1Wrapper: {
    padding: "12px 0 24px",
  },
  addBtnWrapper: {
    display: "flex",
    alignItems: "center",
    padding: "0 24px",
  },
}));

const COLUMNS = [
  { name: "listName", title: "Nom de la liste" },
  { name: "description", title: "Description" },
  { name: "editable", title: "Type de liste" },
];

const Lists = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const currentUser = useSelector(getCurrentUser);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarText, setSnackBarText] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOperation, setDialogOperation] = useState(null);
  const [listData, setListData] = useState(null);
  const [lists, setLists] = useState(null);
  const [dialogTitle, setDialogTitle] = useState("Ajout d'une liste");
  const [dialogText, setDialogText] = useState("");
  const [dialogYesBtnText, setDialogYesBtnText] = useState("ajouter");
  const [dialogNoBtnText, setDialogNoBtnText] = useState("annuler");
  const [withValidationButton, setWithValidationButton] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    getListsData();
  }, []);

  // getting lists from mongo
  const getListsData = async () => {
    const query = await new Parse.Query("Lists")
      .select("listName", "description", "editable")
      .limit(1000000)
      .find();
    const lists = [];
    query.forEach((list) => {
      const listCopy = Object.assign({}, list.attributes);
      listCopy.editable =
        listCopy.editable !== undefined && !listCopy.editable
          ? "automatique"
          : "manuelle";
      lists.push(listCopy);
    });
    setLists(lists);
  };

  // when dialog is called
  const handleClickOpen = (data, operation) => {
    switch (operation) {
      case "add":
        setDialogTitle("Ajout d'une liste");
        setDialogText("");
        setDialogYesBtnText("ajouter");
        setDialogNoBtnText("annuler");
        setWithValidationButton(true);
        break;
      case "modify":
        setDialogTitle("Modification d'une liste");
        setDialogText("");
        setDialogYesBtnText("modifier");
        setDialogNoBtnText("annuler");
        setWithValidationButton(true);
        break;
      case "delete":
        setDialogTitle("Suppression d'une liste");
        if (data.row.editable === "automatique") {
          setDialogText(
            "La liste " +
              data.row.listName +
              " (" +
              data.row.description +
              ") est une liste automatique, vous ne pouvez pas la supprimer.",
          );
          setDialogNoBtnText("fermer");
          setWithValidationButton(false);
        } else {
          setDialogText(
            "Voulez-vous vraiment supprimer la liste " +
              data.row.listName +
              " (" +
              data.row.description +
              ") ?",
          );
          setDialogYesBtnText("oui");
          setDialogNoBtnText("non");
          setWithValidationButton(true);
        }
        break;
      default:
    }
    setDialogOpen(true);
    setDialogOperation(operation);
    setListData(data);
  };

  // when closing dialog box
  const handleModalClose = (action) => {
    switch (action) {
      case "listAdded":
        handleSnackBarShow("liste ajoutée");
        break;
      case "listUpdated":
        handleSnackBarShow("liste mise à jour");
        break;
      case "listDeleted":
        handleSnackBarShow("liste supprimée");
        break;
      default:
    }
    // we always reload lists on modal close
    getListsData();
    setDialogOpen(false);
  };

  const handleModalValidate = async (listData, operation) => {
    switch (operation) {
      case "delete":
        const list = await new Parse.Query("Lists")
          .equalTo("listName", listData.row.listName)
          .first();
        if (list) {
          try {
            await list.destroy();
          } catch (error) {
            console.log("Error: " + error.code + " " + error.message);
          }
        }
        handleModalClose("listDeleted");
        break;
      default:
        // for add and modify
        document
          .getElementById("editForm")
          .dispatchEvent(new Event("submit", { cancelable: true }));
        break;
    }
    dispatch(setListsValid(false));
  };

  /********************************************************/
  /************************ SnackBar **********************/
  /********************************************************/

  const handleSnackBarShow = (textValue) => {
    setSnackBarOpen(true);
    setSnackBarText(textValue);
  };

  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  const actions = [
    {
      icon: <EditIcon />,
      action: (rowData) => handleClickOpen(rowData.row.listName, "modify"),
    },
    {
      icon: <DeleteIcon />,
      action: (rowData) => handleClickOpen(rowData, "delete"),
    },
  ];

  if (!currentUser.get("listAble") || !lists) {
    return null;
  }

  return (
    <Page toolBarTitle="Gestion des listes" pageTitle="Gestion des listes">
      <div className={classes.subtitle1Wrapper}>
        <Typography variant="subtitle1" paragraph>
          Ajoutez, modifiez ou supprimez les listes depuis cette page.
          <br />
          Les listes sont notamment utilisées dans les listes déroulantes de la
          catégorie Produit.
        </Typography>
      </div>

      <Paper>
        <Grid rows={lists} columns={COLUMNS}>
          <div className={classes.tableTopWrapper}>
            <Typography variant="h6" className={classes.tableTitleWrapper}>
              Listes
            </Typography>
            <div className={classes.addBtnWrapper}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleClickOpen(null, "add")}
              >
                ajouter
              </Button>
            </div>
          </div>

          <FilteringState defaultFilters={[]} />
          <IntegratedFiltering />
          <SortingState
            defaultSorting={[{ columnName: "listName", direction: "asc" }]}
          />
          <IntegratedSorting />
          <Table />
          <TableHeaderRow
            showSortingControls
            messages={{ sortingHint: "Trier" }}
          />
          <TableFilterRow messages={{ filterPlaceholder: "Filtrer" }} />
          <ActionsColumn actions={actions} width={130} />
        </Grid>
      </Paper>

      <Dialog
        open={dialogOpen}
        onClose={() => handleModalClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogText}
          </DialogContentText>
          {(dialogOperation === "add" || dialogOperation === "modify") && (
            <HandleListForm
              operation={dialogOperation}
              listName={listData}
              onHandleModalClose={handleModalClose}
            />
          )}
        </DialogContent>
        <DialogActions>
          {withValidationButton && (
            <Button
              onClick={() => handleModalValidate(listData, dialogOperation)}
              color="primary"
            >
              {dialogYesBtnText}
            </Button>
          )}
          <Button onClick={() => handleModalClose()} color="primary" autoFocus>
            {dialogNoBtnText}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
        open={snackBarOpen}
        onClose={handleSnackBarClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackBarText}</span>}
      />
    </Page>
  );
};

export default Lists;
