import React, { useState, useEffect } from "react";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import Switch from "../../components/form/Switch";
import {
  withStyles,
  Button,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Parse from "parse";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../reducers/currentUser";

const useStyles = makeStyles(() => ({
  gridContainer: {
    width: "50%",
  },
  textField: {
    width: "100%",
  },
  selectField: {
    width: "100%",
  },
  fieldContainer: {
    display: "flex",
    flex: 1,
    height: "48px",
  },
  buttonWrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    margin: "2rem 1rem 1rem 0",
  },
  errorMessage: {
    textAlign: "center",
    color: "red",
  },
  switchText: {
    lineHeight: "48px",
  },
}));
const priceLinkedPrefix = "priceLinked";

const ProductPriceForm = (props) => {
  const { productCode, readOnly, onFormChange } = props;
  const classes = useStyles();
  const currentUser = useSelector(getCurrentUser);
  const userRole =
    currentUser !== undefined ? currentUser.get("role") : undefined;
  console.log("userRole: ", userRole);
  const [productData, setProductData] = useState(null);

  const loadProductData = async () => {
    const product = await new Parse.Query("Products")
      .equalTo("ARKTCODART", productCode)
      .first();
    // putting priceLinked data at the root level so that value is loaded in form
    const articles = product.get("articles");
    if (!!articles.length) {
      articles.forEach((article) => {
        const key = priceLinkedPrefix + article.ARKTCOMART;
        const value =
          article.pricesLinkedToProduct !== undefined
            ? article.pricesLinkedToProduct
            : false;
        product.set(key, value);
      });
    }
    setProductData(product.attributes);
  };

  const displayPriceLinkSwitches = (articles) => {
    if (!articles.length) {
      return null;
    }
    return articles.map((article, index) => {
      const name = priceLinkedPrefix + article.ARKTCOMART;
      return (
        <Grid key={index} item xs={12}>
          <div className={classes.fieldContainer}>
            <Field
              name={name}
              component={Switch}
              type="checkbox"
              disabled={readOnly}
            />
            <Typography variant="body2" className={classes.switchText}>
              lier l'article {article.ARKTCOMART} à ces prix
            </Typography>
          </div>
        </Grid>
      );
    });
  };

  const onSubmit = async (values) => {
    if (!!readOnly) {
      return null;
    }
    const product = await new Parse.Query("Products")
      .equalTo("ARKTCODART", productCode)
      .first();

    Object.entries(values).forEach(([key, value]) => {
      if (key === "ARCNTARIF9") {
        value = parseFloat(value);
      }

      if (key.startsWith(priceLinkedPrefix)) {
        console.log("key: ", key);
        const ARKTCOMART = key.replace(priceLinkedPrefix, "");
        values.articles.forEach((article) => {
          if (article.ARKTCOMART === ARKTCOMART) {
            article.pricesLinkedToProduct = value;
          }
        });
        product.unset(key); // key is set in product but should not be... weird...
      } else {
        product.set(key, value);
      }
    });
    product.set("articles", values.articles); // articles is not always modified, but simpler this way
    console.log("product: ", product);
    await product.save();
    await loadProductData();
  };

  useEffect(() => {
    loadProductData();
    // eslint-disable-next-line
  }, []);
  if (!productData) {
    return null;
  }
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={productData}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form
          id="productPriceForm"
          onSubmit={handleSubmit}
          onChange={onFormChange(pristine)}
        >
          <Grid container spacing={0} className={classes.gridContainer}>
            <Grid item xs={12}>
              <div className={classes.fieldContainer}>
                <Field
                  name="ARCNTARIF9"
                  component={TextField}
                  type="number"
                  placeholder="Prix public TTC (€)"
                  label="Prix public TTC (€)"
                  className={classes.textField}
                  required
                  disabled={readOnly}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.fieldContainer}>
                <Field
                  name="ARCNTARIF8"
                  component={TextField}
                  type="number"
                  placeholder="Prix de vente conseillé actuel TTC (€) (éditable dans PMI)"
                  label="Prix de vente conseillé actuel TTC (€) (éditable dans PMI)"
                  className={classes.textField}
                  disabled
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.fieldContainer}>
                <Field
                  name="ARCNTARIF1"
                  component={TextField}
                  type="number"
                  placeholder="Prix catalogue revendeur HT (€)"
                  label="Prix catalogue revendeur HT (€)"
                  className={classes.textField}
                  required
                  disabled={readOnly}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.fieldContainer}>
                <Field
                  name="ARCNTARIF2"
                  component={TextField}
                  type="number"
                  placeholder="Prix catalogue revendeur actuel HT (€) (éditable dans PMI)"
                  label="Prix catalogue revendeur actuel HT (€) (éditable dans PMI)"
                  className={classes.textField}
                  disabled
                />
              </div>
            </Grid>
            {userRole !== "commercial" && (
              <>
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    <Field
                      name="ARCNTARIF6"
                      component={TextField}
                      type="number"
                      placeholder="Coût de revient net HT (€) (éditable dans PMI)"
                      label="Coût de revient net HT (€) (éditable dans PMI)"
                      className={classes.textField}
                      disabled
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    <Field
                      name="ARCNTARIF7"
                      component={TextField}
                      type="number"
                      placeholder="Coût de revient brut HT (€) (éditable dans PMI)"
                      label="Coût de revient brut HT (€) (éditable dans PMI)"
                      className={classes.textField}
                      disabled
                    />
                  </div>
                </Grid>
              </>
            )}
            {displayPriceLinkSwitches(productData.articles)}
          </Grid>

          {!readOnly && (
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={12}>
                <div className={classes.buttonWrapper}>
                  <Button
                    type="submit"
                    disabled={submitting || pristine}
                    variant="contained"
                    color="primary"
                  >
                    enregistrer
                  </Button>
                </div>
              </Grid>
            </Grid>
          )}
        </form>
      )}
    />
  );
};
export default ProductPriceForm;

// class ProductPriceForm extends PureComponent {
//   constructor(props) {
//     super(props);
//     this.state = {
//       values: [],
//     };
//   }
//   componentDidMount() {
//     this.loadProductData();
//   }
//
//   loadProductData = async () => {
//     const { productCode } = this.props;
//     const query = new Parse.Query("Products");
//     query.equalTo("ARKTCODART", productCode);
//     const product = await query.first();
//
//     // putting priceLinked data at the root level so that value is loaded in form
//     const articles = product.get("articles");
//     if (!!articles.length) {
//       articles.forEach(article => {
//         const key = priceLinkedPrefix + article.ARKTCOMART;
//         const value = (article.pricesLinkedToProduct !== undefined) ? article.pricesLinkedToProduct : false;
//         product.set(key, value);
//       })
//     }
//     this.setState({
//       productData: product.attributes
//     });
//   };
//
//
//   displayPriceLinkSwitches = articles => {
//     const { classes, readOnly } = this.props;
//
//     if (!articles.length) {
//       return null;
//     }
//     return articles.map( (article, index) => {
//       const name = priceLinkedPrefix + article.ARKTCOMART;
//       return <Grid key={index} item xs={12}>
//         <div className={classes.fieldContainer}>
//           <Field
//             name={name}
//             component={Switch}
//             type="checkbox"
//             disabled={readOnly}
//           />
//           <Typography variant="body2" className={classes.switchText}>lier l'article {article.ARKTCOMART} à ces prix</Typography>
//         </div>
//       </Grid>
//     })
//   };
//
//
//   onSubmit = async values => {
//     const { readOnly } = this.props;
//     if (!!readOnly) {
//       return null;
//     }
//     const { productCode } = this.props;
//     const query = new Parse.Query("Products");
//     query.equalTo("ARKTCODART", productCode);
//     const product = await query.first();
//
//     Object.entries(values).forEach(([key, value]) => {
//       if (key === 'ARCNTARIF9') {
//         value = parseFloat(value);
//       }
//
//       if (key.startsWith(priceLinkedPrefix)) {
//         console.log("key: ", key);
//         const ARKTCOMART = key.replace(priceLinkedPrefix, "");
//         values.articles.forEach(article => {
//           if (article.ARKTCOMART === ARKTCOMART) {
//             article.pricesLinkedToProduct = value;
//           }
//         });
//         product.unset(key); // key is set in product but should not be... weird...
//       } else {
//         product.set(key, value);
//       }
//     });
//     product.set("articles", values.articles); // articles is not always modified, but simpler this way
//     console.log('product: ', product);
//     await product.save();
//     await this.loadProductData();
//   };
//
//   render() {
//     const { productData } = this.state;
//     const { classes, onFormChange, readOnly } = this.props;
//
//     if (!productData) {
//       return null;
//     }
//
//     return (
//       <Form
//         onSubmit={this.onSubmit}
//         initialValues={productData}
//         render={({ handleSubmit, form, submitting, pristine, values }) => (
//           <form
//             id="productPriceForm"
//             onSubmit={handleSubmit}
//             onChange={ onFormChange(pristine) }>
//
//
//             <Grid container spacing={0} className={classes.gridContainer}>
//               <Grid item xs={12}>
//                 <div className={classes.fieldContainer}>
//                   <Field
//                     name="ARCNTARIF9"
//                     component={TextField}
//                     type="number"
//                     placeholder="Prix public TTC (€)"
//                     label="Prix public TTC (€)"
//                     className={classes.textField}
//                     required
//                     disabled={readOnly}
//                   />
//                 </div>
//               </Grid>
//               <Grid item xs={12}>
//                 <div className={classes.fieldContainer}>
//                   <Field
//                     name="ARCNTARIF8"
//                     component={TextField}
//                     type="number"
//                     placeholder="Prix de vente conseillé actuel TTC (€) (éditable dans PMI)"
//                     label="Prix de vente conseillé actuel TTC (€) (éditable dans PMI)"
//                     className={classes.textField}
//                     disabled
//                   />
//                 </div>
//               </Grid>
//               <Grid item xs={12}>
//                 <div className={classes.fieldContainer}>
//                   <Field
//                     name="ARCNTARIF1"
//                     component={TextField}
//                     type="number"
//                     placeholder="Prix catalogue revendeur HT (€)"
//                     label="Prix catalogue revendeur HT (€)"
//                     className={classes.textField}
//                     required
//                     disabled={readOnly}
//                   />
//                 </div>
//               </Grid>
//               <Grid item xs={12}>
//                 <div className={classes.fieldContainer}>
//                   <Field
//                     name="ARCNTARIF2"
//                     component={TextField}
//                     type="number"
//                     placeholder="Prix catalogue revendeur actuel HT (€) (éditable dans PMI)"
//                     label="Prix catalogue revendeur actuel HT (€) (éditable dans PMI)"
//                     className={classes.textField}
//                     disabled
//                   />
//                 </div>
//               </Grid>
//               <Grid item xs={12}>
//                 <div className={classes.fieldContainer}>
//                   <Field
//                     name="ARCNTARIF6"
//                     component={TextField}
//                     type="number"
//                     placeholder="Coût de revient net HT (€) (éditable dans PMI)"
//                     label="Coût de revient net HT (€) (éditable dans PMI)"
//                     className={classes.textField}
//                     disabled
//                   />
//                 </div>
//               </Grid>
//               <Grid item xs={12}>
//                 <div className={classes.fieldContainer}>
//                   <Field
//                     name="ARCNTARIF7"
//                     component={TextField}
//                     type="number"
//                     placeholder="Coût de revient brut HT (€) (éditable dans PMI)"
//                     label="Coût de revient brut HT (€) (éditable dans PMI)"
//                     className={classes.textField}
//                     disabled
//                   />
//                 </div>
//               </Grid>
//               {this.displayPriceLinkSwitches(productData.articles)}
//             </Grid>
//
//             {!readOnly &&
//               <Grid container spacing={0} className={classes.gridContainer}>
//                 <Grid item xs={12}>
//                   <div className={classes.buttonWrapper}>
//                     <Button type="submit" disabled={submitting || pristine} variant="contained" color="primary">enregistrer</Button>
//                   </div>
//                 </Grid>
//               </Grid>
//             }
//           </form>
//         )}
//       />
//     );
//   }
// }
//
// export default withStyles(styles)(ProductPriceForm);
