import React from "react";
import { List } from "@material-ui/core";
import ListEntry from "../../components/navigation/ListEntry";

const GroupDrawerList = () => {
  return (
    <List>
      <ListEntry id={0} toPage="/" text="Accueil" icon="Home" />
      <ListEntry
        id={1}
        toPage="/commande"
        text="Commande cadencée"
        icon="ShoppingCart"
      />
      <ListEntry id={2} toPage="/commandes" text="Historique" icon="List" />
    </List>
  );
};
export default GroupDrawerList;
