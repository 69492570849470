import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Page from "./Page";
import Parse from "parse";
import useDebounce from "../../hooks";
import HandleUserForm from "../form/HandleUserForm";
import { getCurrentUser } from "../../reducers/currentUser";
import {
  makeStyles,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Snackbar,
} from "@material-ui/core";
import DataGridView from "../../components/reactgrid/DataGridView";
import { setNumberColumns, translateColumn } from "../../utils2";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles(() => ({
  tableTopWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "",
  },
  tableTitleWrapper: {
    display: "flex",
    alignItems: "center",
    minHeight: "64px",
    padding: "0 8px 0 24px",
  },
  subtitle1Wrapper: {
    padding: "12px 0 24px",
  },
  addBtnWrapper: {
    display: "flex",
    alignItems: "center",
    padding: "0 24px",
  },
}));
const columns = [
  { name: "firstname", title: "Prénom" },
  { name: "lastname", title: "Nom" },
  { name: "username", title: "Email" },
  { name: "role", title: "Rôle" },
  { name: "enabled", title: "Statut" },
];
const columnExtensions = [
  { columnName: "firstname" },
  { columnName: "lastname" },
  { columnName: "username" },
  { columnName: "role" },
  { columnName: "enabled" },
];
const columnsTranslations = [
  {
    columnName: "enabled",
    values: [
      [true, "activé"],
      [false, "désactivé"],
    ],
  },
  {
    columnName: "role",
    values: [
      ["superuser", "super utilisateur"],
      ["master", "master"],
      ["commercial", "commercial"],
      ["client", "magasin"],
      ["group", "groupement"],
    ],
  },
];
const pageSizes = [15, 25, 50, 100, 150];

const Users = () => {
  const classes = useStyles();
  const currentUser = useSelector(getCurrentUser);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarText, setSnackBarText] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState(null);
  const [dialogText, setDialogText] = useState(null);
  const [dialogYesBtnText, setDialogYesBtnText] = useState(null);
  const [dialogNoBtnText, setDialogNoBtnText] = useState(null);
  const [dialogOperation, setDialogOperation] = useState(null);
  const [filters, setFilters] = useState([]);
  const debouncedFilters = useDebounce(filters, 300);
  const [sorting, setSorting] = useState([
    { columnName: "firstname", direction: "asc" },
    { columnName: "lastname", direction: "asc" },
  ]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [numberColumns, setStateNumberColumns] = useState([]);
  const [rows, setRows] = useState(null);
  const [userId, setUserId] = useState(null);

  /********************************************************/
  /************ content info for paging panel *************/
  /********************************************************/
  const contentInfo = ({ from, to, count }) => {
    return `${from} ${from < to ? ` à ${to}` : ""} sur ${count} utilisateur(s)`;
  };

  /********************************************************/
  /*********************** Navigation *********************/
  /********************************************************/
  const changeFilters = (filters) => {
    setLoading(true);
    setFilters(filters);
  };
  const changeSorting = (sorting) => {
    setLoading(true);
    setSorting(sorting);
  };
  const changeCurrentPage = (currentPage) => {
    setLoading(true);
    setCurrentPage(currentPage);
  };
  const changePageSize = (pageSize) => setPageSize(pageSize);

  /********************************************************/
  /*********************** Table data *********************/
  /********************************************************/
  const getUsers = async () => {
    const userCountParams = {
      roles: ["commercial", "master", "superuser", "client", "group"],
    };

    const usersCount = await Parse.Cloud.run("getUsers", userCountParams);
    const totalCount = usersCount.length;

    const params = {
      roles: ["commercial", "master", "superuser", "client", "group"],
      skip: pageSize * currentPage,
      limit: pageSize,
      filters,
      columnsTranslations,
    };
    const users = await Parse.Cloud.run("getUsers", params);
    const rows = users.map((user) => {
      const row = {};
      if (user.get("contact") !== undefined) {
        row.firstname = user.get("contact").get("firstname");
        row.lastname = user.get("contact").get("lastname");
        row.username = user.get("contact").get("email");
      } else {
        row.firstname = user.get("firstname");
        row.lastname = user.get("lastname");
        row.username = user.get("username");
      }
      row.enabled = translateColumn(
        user.get("enabled"),
        columnsTranslations.find(
          (columnTranslation) => columnTranslation.columnName === "enabled",
        ),
      );
      row.role = translateColumn(
        user.get("role"),
        columnsTranslations.find(
          (columnTranslation) => columnTranslation.columnName === "role",
        ),
      );
      row.userId = user.id;
      return row;
    });
    setRows(rows);
    setTotalCount(totalCount);
    setLoading(false);
  };

  /********************************************************/
  /************************* Dialog ***********************/
  /********************************************************/
  // when dialog is called
  const handleClickOpen = (data, operation) => {
    switch (operation) {
      case "addUser":
        setDialogTitle("Ajout d'un utilisateur");
        setDialogText("");
        setDialogYesBtnText("ajouter");
        setDialogNoBtnText("annuler");
        break;
      case "modifyUser":
        setDialogTitle("Modification d'un utilisateur");
        setDialogText("");
        setDialogYesBtnText("modifier");
        setDialogNoBtnText("annuler");
        setUserId(data);
        break;
      case "deleteUser":
        setDialogTitle("Suppression d'un utilisateur");
        setDialogText(
          data.row.lastname !== undefined
            ? "Voulez-vous vraiment supprimer l'utilisateur " +
                data.row.firstname +
                " " +
                data.row.lastname +
                " ?"
            : "Voulez-vous vraiment supprimer l'utilisateur " +
                data.row.firstname +
                " ?",
        );
        setDialogYesBtnText("oui");
        setDialogNoBtnText("non");
        setUserId(data.row.userId);
        break;
      default:
    }
    setDialogOpen(true);
    setDialogOperation(operation);
  };

  // when closing dialog box
  const handleModalClose = async (action) => {
    switch (action) {
      case "userAdded":
        handleSnackBarShow("utilisateur ajouté");
        break;
      case "userUpdated":
        handleSnackBarShow("utilisateur mis à jour");
        break;
      case "userDeleted":
        handleSnackBarShow("utilisateur supprimé");
        break;
      default:
    }
    setDialogOpen(false);
  };

  const handleModalValidate = async (userId, operation) => {
    switch (operation) {
      case "deleteUser":
        const params = { userId };
        const query = await Parse.Cloud.run("deleteUser", params);
        if (query === "userDeleted") {
          handleModalClose("userDeleted");
        }
        break;
      default:
        // for addUser and modifyUser
        document
          .getElementById("editForm")
          .dispatchEvent(new Event("submit", { cancelable: true }));
        break;
    }
  };

  /********************************************************/
  /*********************** SnackBar ***********************/
  /********************************************************/
  const handleSnackBarShow = (textValue) => {
    setSnackBarOpen(true);
    setSnackBarText(textValue);
  };

  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  /********************************************************/
  /*********************** Actions ************************/
  /********************************************************/
  const actions = [
    {
      icon: <EditIcon />,
      action: (rowData) => handleClickOpen(rowData.row.userId, "modifyUser"),
    },
    {
      icon: <DeleteIcon />,
      action: (rowData) => handleClickOpen(rowData, "deleteUser"),
    },
  ];

  /********************************************************/
  /********************** useEffects **********************/
  /********************************************************/
  useEffect(() => {
    setNumberColumns(columns, setStateNumberColumns);
  }, []);

  // when page or sorting change or when columnsTranslations is loaded
  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, [currentPage, sorting, pageSize, columnsTranslations, dialogOpen]);

  // when filters change
  useEffect(() => {
    if (debouncedFilters) {
      getUsers();
    }
    // eslint-disable-next-line
  }, [debouncedFilters]);

  if (rows === null || !currentUser.get("userAble")) {
    return null;
  }

  return (
    <Page
      toolBarTitle="Gestion des utilisateurs"
      pageTitle="Gestion des utilisateurs"
    >
      {/*<Typography variant="h2">Gestion des utilisateurs</Typography>*/}
      <div className={classes.subtitle1Wrapper}>
        <Typography variant="subtitle1" paragraph>
          Ajoutez, modifiez ou supprimez des utilisateurs depuis cette page.
        </Typography>
      </div>
      <Paper>
        <DataGridView
          title="Liste des utilisateurs"
          withAddButton
          onAddButtonClick={() => handleClickOpen(null, "addUser")}
          rowsValues={rows}
          columnHeaders={columns}
          currentPage={currentPage}
          onCurrentPageChange={changeCurrentPage}
          pageSize={pageSize}
          pageSizes={pageSizes}
          onPageSizeChange={changePageSize}
          filters={filters}
          onFiltersChange={changeFilters}
          sorting={sorting}
          onSortingChange={changeSorting}
          totalCount={totalCount}
          loading={loading}
          numberColumns={numberColumns}
          rowsPerPage="Nb utilisateurs par page:"
          contentInfoSupplier={contentInfo}
          columnExtensions={columnExtensions}
          withPagination={true}
          hasActions={true}
          actions={actions}
        />
      </Paper>

      <Dialog
        open={dialogOpen}
        onClose={() => handleModalClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"xl"}
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogText}
          </DialogContentText>
          {(dialogOperation === "addUser" ||
            dialogOperation === "modifyUser") && (
            <HandleUserForm
              operation={dialogOperation}
              userId={userId}
              onHandleModalClose={handleModalClose}
              onSnackBarOpen={handleSnackBarShow}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleModalValidate(userId, dialogOperation)}
            color="primary"
          >
            {dialogYesBtnText}
          </Button>
          <Button onClick={() => handleModalClose()} color="primary" autoFocus>
            {dialogNoBtnText}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
        open={snackBarOpen}
        onClose={handleSnackBarClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackBarText}</span>}
      />
    </Page>
  );
};

export default Users;
