"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOrdersByArticlesByDays = void 0;
var dateToStringKey_1 = require("../dateToStringKey");
function getOrdersByArticlesByDays(supplies) {
    var maxDateKey = dateToStringKey_1.dateToStringKey(new Date());
    var ordersByArticleByDay = Object.entries(supplies).reduce(function (acc, _a) {
        var ARKTCOMART = _a[0], onUnshippedOrders = _a[1].onUnshippedOrders;
        onUnshippedOrders.orders.forEach(function (_a) {
            var shippingDate = _a.shippingDate, quantity = _a.quantity;
            if (!acc[ARKTCOMART])
                acc[ARKTCOMART] = {};
            var shippingDateKey = dateToStringKey_1.dateToStringKey(shippingDate);
            if (acc[ARKTCOMART][shippingDateKey])
                acc[ARKTCOMART][shippingDateKey] += quantity;
            else
                acc[ARKTCOMART][shippingDateKey] = quantity;
            if (shippingDateKey > maxDateKey)
                maxDateKey = shippingDateKey;
        });
        return acc;
    }, {});
    return { maxDateKey: maxDateKey, ordersByArticleByDay: ordersByArticleByDay };
}
exports.getOrdersByArticlesByDays = getOrdersByArticlesByDays;
