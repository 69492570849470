const order = (state = {}, action) => {
  switch (action.type) {
    case "SET_PRODUCTS": {
      return {
        ...state,
        products: action.products,
      };
    }
    case "SET_PRODUCTS_TO_PRESELECT": {
      return {
        ...state,
        productsToPreselect: action.productsToPreselect,
      };
    }
    case "SET_SHIPPINGS": {
      return {
        ...state,
        shippings: action.shippings,
      };
    }
    case "SET_CLIENT": {
      return {
        ...state,
        client: action.client,
      };
    }
    case "SET_OBJECT_OF_CONTACT_OPTIONS": {
      return {
        ...state,
        objectOfContactOptions: action.objectOfContactOptions,
      };
    }
    case "RESET_ORDER": {
      return {};
    }
    default: {
      return state;
    }
  }
};

export default order;

export function getProducts(state) {
  return state.order.products;
}
export function getProductsToPreselect(state) {
  return state.order.productsToPreselect;
}
export function getShippings(state) {
  return state.order.shippings;
}
export function getClient(state) {
  return state.order.client;
}
export function getObjectOfContactOptions(state) {
  return state.order.objectOfContactOptions;
}
export function getOrder(state) {
  return state.order;
}
