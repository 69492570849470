import React, { PureComponent } from "react";
import { Form, Field } from "react-final-form";
import RTE from "../../components/form/RichTextMarkdown";
import { withStyles, Button, Grid, Typography } from "@material-ui/core";
import Parse from "parse";

const styles = (theme) => ({
  gridContainer: {
    width: "50%",
    margin: "0 0 32px 0",
  },
  buttonWrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    margin: "2rem 1rem 1rem 0",
  },
  errorMessage: {
    color: "red",
  },
});

class ProductDescriptionForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      shortDescLetterCount: 0,
      productData: {},
      values: [],
    };
  }
  componentDidMount() {
    this.loadProductData();
  }

  loadProductData = async () => {
    const { productCode } = this.props;
    const query = new Parse.Query("Products");
    query.equalTo("ARKTCODART", productCode);
    const product = await query.first();

    this.setState({
      productData: product.attributes,
    });
  };

  checkLetterCount = (value) => {
    let text = value.input.value;
    text = text.replace(/<[^>]*>/g, "");
    text = text.replace(/&nbsp;/g, " ");

    this.setState({
      shortDescLetterCount: text.length,
    });

    return null;
  };

  onSubmit = async (values) => {
    const { readOnly } = this.props;
    if (!!readOnly) {
      return null;
    }
    const { productCode } = this.props;
    const query = new Parse.Query("Products");
    query.equalTo("ARKTCODART", productCode);
    const product = await query.first();
    Object.entries(values).forEach(([key, value]) => {
      product.set(key, value);
    });
    await product.save();
    this.loadProductData();
  };

  render() {
    const { productData, shortDescLetterCount } = this.state;
    const { classes, onFormChange, readOnly } = this.props;
    const shortDescTooLong = shortDescLetterCount > 160;

    return (
      <Form
        onSubmit={this.onSubmit}
        initialValues={productData}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form
            id="productDescriptionForm"
            onSubmit={handleSubmit}
            onChange={onFormChange(pristine)}
          >
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={12}>
                <Typography variant="body2" gutterBottom>
                  Courte description ({shortDescLetterCount} / 160 caractères){" "}
                  {shortDescTooLong && (
                    <span className={classes.errorMessage}>
                      description trop longue !
                    </span>
                  )}
                </Typography>
                <Field
                  component={RTE}
                  type="text"
                  name="shortDescription"
                  readOnly={readOnly}
                />
                <Field name="shortDescription" subscription={{ value: true }}>
                  {this.checkLetterCount}
                </Field>
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={12}>
                <Typography variant="body2" gutterBottom>
                  Description
                </Typography>
                <Field
                  component={RTE}
                  type="text"
                  name="description"
                  readOnly={readOnly}
                />
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={12}>
                <Typography variant="body2" gutterBottom>
                  Description technique
                </Typography>
                <Field
                  component={RTE}
                  type="text"
                  name="technicalDescription"
                  readOnly={readOnly}
                />
              </Grid>
            </Grid>

            {!readOnly && (
              <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={12}>
                  <div className={classes.buttonWrapper}>
                    <Button
                      type="submit"
                      disabled={submitting || pristine || shortDescTooLong}
                      variant="contained"
                      color="primary"
                    >
                      enregistrer
                    </Button>
                  </div>
                </Grid>
              </Grid>
            )}
          </form>
        )}
      />
    );
  }
}

export default withStyles(styles)(ProductDescriptionForm);
