import React from "react";
import { TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  customHeaderCell: {
    "& div": {
      whiteSpace: "normal",
      wordWrap: "break-word",
      lineHeight: "1.2rem",
      fontSize: "0.8rem",
    },
  },
});
const CustomTableHeaderCellBase = ({ classes, ...restProps }) => {
  restProps.value = restProps.column.title || restProps.column.name;
  return (
    <TableHeaderRow.Cell className={classes.customHeaderCell} {...restProps} />
  );
};

export default withStyles(styles)(CustomTableHeaderCellBase);
