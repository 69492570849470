import React, { useState, useEffect } from "react";
import moment from "moment";
import Parse from "parse";
import useDebounce from "../../hooks";
import { useSelector } from "react-redux";
import { getLists } from "../../reducers/lists";
import { getCurrentUser } from "../../reducers/currentUser";
import HandleDiscountForm from "../../containers/form/HandleDiscountForm";
import HorizontalLinearStepper from "../HorizontalLinearStepper";
import {
  makeStyles,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Snackbar,
  AppBar,
  Toolbar,
  IconButton,
} from "@material-ui/core";
import DataGridView from "../reactgrid/DataGridView";
import { setNumberColumns, filtersQuery, translateColumn } from "../../utils2";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(() => ({
  tableTopWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "",
  },
  tableTitleWrapper: {
    display: "flex",
    alignItems: "center",
    minHeight: "64px",
    padding: "0 8px 0 24px",
  },
  addBtnWrapper: {
    display: "flex",
    alignItems: "center",
    padding: "0 24px",
  },
  toolbar: {
    backgroundColor: "#46494c", //'#02cfa3'
  },
  noDiscount: {
    textAlign: "center",
    margin: "10rem 0 0",
  },
  expiré: {
    background:
      "repeating-linear-gradient(135deg, #fff0f0, #fff0f0 8px, #fff6f6 8px, #fff6f6 16px)",
    color: "#fff",
  },
}));
const columns = [
  { name: "includedTradeNames", title: "Enseigne" },
  { name: "startDate", title: "Début", type: "date" },
  { name: "endDate", title: "Fin", type: "date" },
  { name: "percentageDiscount", title: "Remise", type: "numeric" },
  { name: "francoMinAmount", title: "Franco", type: "string" },
  {
    name: "endOfYearTradenamePercentageDiscount",
    title: "RFA %",
    type: "string",
  },
  { name: "endOfYearTradenameFixDiscount", title: "RFA fixe", type: "string" },
  { name: "updatedAt", title: "Dernière modif.", type: "date" },
  { name: "lastUpdater", title: "Modifié par", type: "string" },
];
const columnExtensions = [
  { columnName: "includedTradeNames", width: 200, wordWrapEnabled: true },
  { columnName: "startDate", width: 125, align: "right" },
  { columnName: "endDate", width: 125, align: "right" },
  { columnName: "percentageDiscount", width: 70, align: "right" },
  { columnName: "francoMinAmount", width: 70, align: "right" },
  {
    columnName: "endOfYearTradenamePercentageDiscount",
    width: 70,
    align: "right",
    filteringEnabled: false,
  },
  {
    columnName: "endOfYearTradenameFixDiscount",
    width: 70,
    align: "right",
    filteringEnabled: false,
  },
  { columnName: "updatedAt" },
  { columnName: "lastUpdater" },
];
const pageSizes = [15, 25, 50, 100, 150];

const TradenameDiscountList = (props) => {
  const { editable, addable } = props;
  const classes = useStyles();
  const currentUser = useSelector(getCurrentUser);
  const listsOptions = useSelector(getLists);
  const [numberColumns, setStateNumberColumns] = useState([]);
  const [filters, setFilters] = useState([]);
  const debouncedFilters = useDebounce(filters, 500);
  const [sorting, setSorting] = useState([
    { columnName: "percentageDiscount", direction: "asc" },
  ]);
  const [totalCount, setTotalCount] = useState(100);
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [discounts, setDiscounts] = useState(undefined);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarText, setSnackBarText] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState(null);
  const [dialogText, setDialogText] = useState(null);
  const [dialogYesBtnText, setDialogYesBtnText] = useState(null);
  const [dialogNoBtnText, setDialogNoBtnText] = useState(null);
  const [dialogOperation, setDialogOperation] = useState(null);
  const [discountId, setDiscountId] = useState(null);
  const [columnsTranslations, setColumnsTranslations] = useState([]);

  /********************************************************/
  /************ content info for paging panel**************/
  /********************************************************/
  const contentInfo = ({ from, to, count }) => {
    return `${from} ${from < to ? ` à ${to}` : ""} sur ${count} remise(s)`;
  };

  /********************************************************/
  /*********************** Navigation *********************/
  /********************************************************/
  const changeFilters = (filters) => {
    setLoading(true);
    setFilters(filters);
  };
  const changeSorting = (sorting) => {
    setLoading(true);
    setSorting(sorting);
  };
  const changeCurrentPage = (currentPage) => {
    setLoading(true);
    setCurrentPage(currentPage);
  };
  const changePageSize = (pageSize) => setPageSize(pageSize);

  /********************************************************/
  /*********************** Table data *********************/
  /********************************************************/
  const getDiscounts = async () => {
    if (columnsTranslations.length < 1) {
      return null;
    }

    let queryResult;

    const query = new Parse.Query("Discounts")
      .select(
        "includedTradeNames",
        "startDate",
        "endDate",
        "percentageDiscount",
        "francoMinAmount",
        "createdAt",
        "creator",
        "updatedAt",
        "lastUpdater",
        "status",
      )
      .include("includedTradeNames")
      .include("creator")
      .include("lastUpdater")
      .equalTo("type", "clientPermanent")
      .exists("includedTradeNames");
    /*
    // code for client section
    if (clientCode !== undefined) {
      const client = await new Parse.Query("Clients")
        .equalTo("CLKTCODE", clientCode)
        .first();// for list in Discount section, we display everything
      query.equalTo("includedClients", client);
    }
*/
    const arrayOfColumnsToIgnore = ["includedTradeNames"];
    filtersQuery(
      query,
      columnsTranslations,
      { columns, filters, sorting },
      arrayOfColumnsToIgnore,
    );

    // filtering special columns
    const includedTradeNamesFilter = filters.find(
      (filter) => filter.columnName === "includedTradeNames",
    );
    if (includedTradeNamesFilter !== undefined) {
      const includedTradeNames = await new Parse.Query("Tradenames").matches(
        "name",
        includedTradeNamesFilter.value,
        "i",
      );
      query.matchesQuery("includedTradeNames", includedTradeNames);
    }

    const totalCount = await query.count();
    // skipping according to currentPage and pageSize
    queryResult = await query
      .skip(pageSize * currentPage)
      .limit(pageSize)
      .find();

    const tradeNames = await new Parse.Query("Tradenames")
      .select("CLCTGROUPE", "RFAPercentage")
      .find();

    const discounts = [];

    // formatting data
    if (Array.isArray(queryResult)) {
      queryResult.forEach((discount) => {
        let data = { ...discount.attributes }; // shallow copy to complete object
        data.id = discount.id;

        // tradenames
        // from array of pointers to array of strings
        const includedTradeNames = discount
          .get("includedTradeNames")
          .map((includedTradeName) => includedTradeName.get("CLCTGROUPE"));
        data.includedTradeNames = translateColumn(
          includedTradeNames,
          columnsTranslations.find(
            (columnTranslation) =>
              columnTranslation.columnName === "includedTradeNames",
          ),
        );

        // formatting dates
        data.updatedAt = moment(data.updatedAt).format("DD/MM/YYYY HH:mm");
        data.createdAt = moment(data.createdAt).format("DD/MM/YYYY HH:mm");
        data.startDate = moment(data.startDate).format("DD/MM/YYYY");
        data.endDate = moment(data.endDate).format("DD/MM/YYYY");

        // formatting lastUpdater name
        data.lastUpdater =
          data.lastUpdater.get("firstname").charAt(0) +
          ". " +
          data.lastUpdater.get("lastname");

        // to be done
        data.endOfYearTradenamePercentageDiscount = "-";
        data.endOfYearTradenameFixDiscount = "-";
        tradeNames.forEach((tradeName) => {
          if (includedTradeNames[0] === tradeName.get("CLCTGROUPE")) {
            const RFAPercentage = tradeName.get("RFAPercentage");
            const RFAFix = tradeName.get("RFAFix");
            if (RFAPercentage !== undefined) {
              data.endOfYearTradenamePercentageDiscount = RFAPercentage;
            }
            if (RFAFix !== undefined) {
              data.endOfYearTradenameFixDiscount = RFAFix;
            }
          }
        });

        discounts.push(data);
      });
    }
    setDiscounts(discounts);
    setTotalCount(totalCount);
    setLoading(false);
  };

  const listToColumnTranslations = (arrayOfListNameAndColumnName) => {
    const newColumnTranslations = [];
    arrayOfListNameAndColumnName.forEach((listNameAndColumnName) => {
      let listOfOptions = [];
      // getting list
      listsOptions.forEach((list) => {
        if (list.listName === listNameAndColumnName.listName) {
          listOfOptions = list.options;
        }
      });
      newColumnTranslations.push({
        columnName: listNameAndColumnName.columnName,
        values: listOfOptions.map((option) => [option.value, option.text]),
      });
    });
    setColumnsTranslations([...columnsTranslations, ...newColumnTranslations]);
  };

  /********************************************************/
  /************************* Modal ************************/
  /********************************************************/
  const handleClickOpen = (data, operation) => {
    switch (operation) {
      case "add":
        setDialogTitle("Création d'une remise");
        setDialogText("");
        setDialogYesBtnText("ajouter");
        setDialogNoBtnText("annuler");
        break;
      case "modify":
        setDialogTitle("Modification d'une remise");
        setDialogText("");
        setDialogYesBtnText("modifier");
        setDialogNoBtnText("annuler");
        setDiscountId(data);
        break;
      case "delete":
        setDialogTitle("Suppression d'une remise");
        setDialogText(
          'Voulez-vous vraiment supprimer la remise \'" + data.row.name + "\' (" + data.row.description + ") ?',
        );
        setDialogYesBtnText("oui");
        setDialogNoBtnText("non");
        setDiscountId(data.row.id);
        break;
      default:
    }
    setDialogOpen(true);
    setDialogOperation(operation);
  };

  // when closing dialog box
  const handleModalClose = async (action) => {
    switch (action) {
      case "discountAdded":
        handleSnackBarShow("remise ajoutée");
        break;
      case "discountUpdated":
        handleSnackBarShow("remise mise à jour");
        break;
      case "discountDeleted":
        handleSnackBarShow("remise supprimée");
        break;
      default:
    }
    setDialogOpen(false);
  };

  const handleModalValidate = async (discountData, operation) => {
    switch (operation) {
      case "delete":
        const discount = await new Parse.Query("Discounts").get(discountId);
        if (discount) {
          try {
            await discount.destroy();
          } catch (error) {
            console.log("Error: " + error.code + " " + error.message);
          }
        }
        await handleModalClose("discountDeleted");
        break;
      default:
        // for add and modify
        document
          .getElementById("editForm")
          .dispatchEvent(new Event("submit", { cancelable: true }));
        break;
    }
  };

  /********************************************************/
  /************************ actions ***********************/
  /********************************************************/
  const actions = [
    {
      icon: <EditIcon />,
      action: (rowData) => handleClickOpen(rowData.row.id, "modify"),
    },
    {
      icon: <DeleteIcon />,
      action: (rowData) => handleClickOpen(rowData, "delete"),
    },
  ];

  /********************************************************/
  /************************ SnackBar **********************/
  /********************************************************/
  const handleSnackBarShow = (textValue) => {
    setSnackBarOpen(true);
    setSnackBarText(textValue);
  };
  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  /********************************************************/
  /************************ Stepper ***********************/
  /********************************************************/
  const getSteps = () => {
    return ["Nom et type", "Périmètre", "Période", "Conditions et remise"];
  };
  const getOptionalSteps = () => {
    return [];
  };
  const getStepContent = (activeStep, handleBack, handleNext, handleSkip) => {
    return (
      <HandleDiscountForm
        steps={getSteps()}
        activeStep={activeStep}
        optionalSteps={getOptionalSteps()}
        operation={dialogOperation}
        discountId={discountId}
        onHandleModalClose={handleModalClose}
        onChosenDiscountType={(dialogTitle) => setDialogTitle(dialogTitle)}
        onHandleBack={handleBack}
        onHandleNext={handleNext}
        onHandleSkip={handleSkip}
        currentUser={currentUser}
      />
    );
  };

  useEffect(() => {
    listToColumnTranslations([
      { listName: "tradenames", columnName: "includedTradeNames" },
      { listName: "clients", columnName: "clients" },
    ]);
    setNumberColumns(columns, setStateNumberColumns);
    // eslint-disable-next-line
  }, []);

  // when page or sorting change or dialog is closed
  useEffect(() => {
    getDiscounts();
    // eslint-disable-next-line
  }, [currentPage, sorting, pageSize, dialogOpen, columnsTranslations]);

  // when filters change
  useEffect(() => {
    if (debouncedFilters) {
      getDiscounts();
    }
    // eslint-disable-next-line
  }, [debouncedFilters]);

  if (discounts === undefined) {
    return null;
  }

  const tableHasData = discounts !== undefined && discounts.length > 0;

  return (
    <>
      {!tableHasData && !editable && (
        <Typography variant="body1" className={classes.noDiscount}>
          Aucune remise n'a été trouvée
        </Typography>
      )}
      {/* if table is empty we only display it in Discount section because it's the only way to add discounts! */}
      {(tableHasData || editable) && (
        <Paper>
          <DataGridView
            title="Remises Enseigne Permanentes"
            withAddButton={addable}
            onAddButtonClick={() => handleClickOpen(null, "add")}
            rowsValues={discounts}
            columnHeaders={columns}
            currentPage={currentPage}
            onCurrentPageChange={changeCurrentPage}
            pageSize={pageSize}
            pageSizes={pageSizes}
            onPageSizeChange={changePageSize}
            filters={filters}
            onFiltersChange={changeFilters}
            sorting={sorting}
            onSortingChange={changeSorting}
            totalCount={totalCount}
            loading={loading}
            numberColumns={numberColumns}
            rowsPerPage="Nb de remises par page:"
            contentInfoSupplier={contentInfo}
            columnExtensions={columnExtensions}
            withPagination={true}
            hasActions={tableHasData && editable}
            actions={actions}
            noDataText="pas de remise enseigne"
          />
        </Paper>
      )}

      <Dialog
        fullScreen={dialogOperation !== "delete"}
        open={dialogOpen}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {dialogOperation !== "delete" && (
          <AppBar>
            <Toolbar className={classes.toolbar}>
              <IconButton
                color="inherit"
                onClick={handleModalClose}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.flex}>
                {dialogTitle}
              </Typography>
            </Toolbar>
          </AppBar>
        )}

        {dialogOperation === "delete" && (
          <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        )}

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogText}
          </DialogContentText>
          {(dialogOperation === "add" || dialogOperation === "modify") && (
            <HorizontalLinearStepper
              onGetSteps={getSteps()}
              onGetStepContent={getStepContent}
              optionalSteps={getOptionalSteps()}
              navigationIsExternal
              dialogOperation={dialogOperation}
              onHandleModalValidate={handleModalValidate}
              onHandleModalClose={handleModalClose}
            />
          )}
        </DialogContent>

        {dialogOperation === "delete" && (
          <DialogActions>
            <Button
              onClick={() => handleModalValidate("", "delete")}
              color="primary"
            >
              {dialogYesBtnText}
            </Button>
            <Button
              onClick={() => handleModalClose()}
              color="primary"
              autoFocus
            >
              {dialogNoBtnText}
            </Button>
          </DialogActions>
        )}
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
        open={snackBarOpen}
        onClose={handleSnackBarClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackBarText}</span>}
      />
    </>
  );
};

export default TradenameDiscountList;
