import React, { useState, useEffect } from "react";
import Parse from "parse";
import { useSelector, useDispatch } from "react-redux";
import useDebounce from "../../hooks";
import DataGridView from "../reactgrid/DataGridView";
import {
  setNumberColumns,
  filtersQuery,
  listsToColumnsTranslations,
  translateColumn,
  generateEmptyQuantities,
} from "../../utils2";
import { setProducts } from "../../actions/main";
import {
  getProducts,
  getProductsToPreselect,
  getShippings,
} from "../../reducers/order";
import { getLists } from "../../reducers/lists";
import { getCurrentUser } from "../../reducers/currentUser";
import ProductIcons from "../product/ProductsIcons";
import { Typography, CircularProgress, makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { generateSuppliesData } from "../../utils/generateSuppliesData/generateSuppliesData";

const columns = [
  { name: "images", title: " ", type: "string" },
  { name: "buttons", title: " ", type: "string" },
  { name: "ARKTCODART", title: "Référence", type: "string" },
  { name: "ARCTLIB01", title: "Modèle", type: "string" },
  { name: "productType", title: "Type", type: "string" },
  { name: "catalog", title: "Catalogue", type: "string" },
  { name: "brand", title: "Marque", type: "string" },
  { name: "currentSupplies", title: "Disponible", type: "string" },
  { name: "ARCNTARIF9", title: "Prix public TTC (€)", type: "numeric" },
  { name: "ARCNTARIF1", title: "Prix brut HT (€)", type: "numeric" },
];
const columnExtensions = [
  { columnName: "images", width: 50, filteringEnabled: false },
  { columnName: "buttons", width: 100, filteringEnabled: false },
  { columnName: "ARCTLIB01", width: 250 },
  { columnName: "ARKTCODART", width: 110 },
  { columnName: "productType", width: 140 },
  { columnName: "catalog", width: 140 },
  { columnName: "brand", width: 140 },
  { columnName: "currentSupplies", width: 105 },
  { columnName: "ARCNTARIF9", align: "right", width: 85 },
  { columnName: "ARCNTARIF1", align: "right", width: 85 },
];

const ProductsList = (props) => {
  const { title, height } = props;

  const useStyles = makeStyles(() => ({
    tableTitleWrapper: {
      display: "flex",
      alignItems: "center",
      whiteSpace: "nowrap",
      minHeight: "64px",
      padding: "0 8px 0 24px",
    },
    circularProgressWrapper: {
      position: "relative",
      width: "100%",
      height: height,
      overflow: "hidden",
    },
    circularProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
    },
    skeletonHeader: {
      position: "absolute",
      height: 120,
      padding: "20px 26px 60px 26px",
      display: "flex",
      justifyContent: "space-between",
    },
    skeletonHeaderEl: {
      margin: "0 42px 0 0",
    },
    skeletonLines: {
      position: "absolute",
      width: "100%",
      top: 170,
      margin: 26,
    },
    skeletonLinesEl: {
      marginBottom: 20,
    },
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const userRole =
    currentUser !== undefined ? currentUser.get("role") : undefined;
  const [filters, setFilters] = useState([
    { columnName: "catalog", value: "CATA" },
  ]);
  const debouncedFilters = useDebounce(filters, 300);
  const [sorting, setSorting] = useState([
    { columnName: "ARKTCODART", direction: "asc" },
  ]);
  const shippings = useSelector(getShippings);
  const productsOrder = useSelector(getProducts);
  const productsToPreselect = useSelector(getProductsToPreselect);
  const [loading, setLoading] = useState(true);
  const [productsToPreselectLoaded, setProductsToPreselectLoaded] =
    useState(false);
  const [stateProducts, setStateProducts] = useState([]);
  const [numberColumns, setStateNumberColumns] = useState([]);
  const [selection, setSelection] = useState([]);
  const lists = useSelector(getLists);
  const listsNameToTranslate = ["catalog", "brand", "productType"];
  const columnsTranslations = [];
  listsToColumnsTranslations(listsNameToTranslate, lists, columnsTranslations);

  /********************************************************/
  /*********************** Table data *********************/
  /********************************************************/
  const changeFilters = (filters) => {
    setLoading(true);
    setFilters(filters);
  };

  const changeSorting = (sorting) => {
    setLoading(true);
    setSorting(sorting);
  };

  // getting products from mongo
  const setProductsToState = async () => {
    // shippings needs to be set in order discounts to be calculated bellow
    if (shippings === undefined) {
      return null;
    }
    const query = new Parse.Query("Products");

    filtersQuery(query, columnsTranslations, { columns, filters, sorting });
    query
      .limit(1000000)
      .select(
        "ARCTLIB01",
        "ARKTCODART",
        "productType",
        "ARCNTARIF1",
        "ARCNTARIF6",
        "ARCNTARIF9",
        "ARCTTYPART",
        "ARITNATURE",
        "ARCTSECART",
        "ARCTSIMPUT",
        "ARCTCODFAM",
        "ARCTLIB02",
        "ARCNPDSUNI",
        "ARCNVOLUNI",
        "ARCNLONGUE",
        "ARCNLARGEU",
        "ARCNEPAISS",
        "ARCTCODMPL",
        "ARCTCODTVA",
        "ARCTFATN",
        "articles",
        "supplies",
        "resupplies",
        "images",
        "currentSupplies",
        "brand",
        "catalog",
        "webSecurityStock"
      )
      .notEqualTo("isDeprecated", true)
      .equalTo("isOnline", true);
    // not used in productsList just in saveOrdersToMongo (but will almost not change query size) : ARCNTARIF6, ARCTTYPART, ARITNATURE, ARCTSECART, ARCTSIMPUT, ARCTCODFAM, ARCTLIB02, ARCNPDSUNI, ARCNVOLUNI, ARCNLONGUE, ARCNLARGEU, ARCNEPAISS, ARCTCODMPL, ARCTCODTVA, ARCTFATN, ARCTCODEPC

    // filtering query by catalog for clients and groups
    const catalogsNotAllowed = [];
    if (userRole === "client" || userRole === "group") {
      Object.entries(currentUser.attributes).forEach(([key, value]) => {
        if (key.includes("Allowed") && !value) {
          catalogsNotAllowed.push(key.replace("Allowed", ""));
        }
      });
      // if catalog filter has a value
      if (query._where.catalog !== undefined) {
        if (catalogsNotAllowed.length) {
          catalogsNotAllowed.forEach((catalogNotAllowed) => {
            const i = query._where.catalog.$in.indexOf(catalogNotAllowed);
            if (i !== -1) {
              query._where.catalog.$in.splice(i, 1);
            }
          });
        }
        // if catalog filter does not have a value
      } else {
        if (catalogsNotAllowed.length) {
          query._where.catalog = {
            $nin: catalogsNotAllowed,
          };
        }
      }
    }

    const rawProducts = await query.find();

    const products = rawProducts.map((product) => {
      const row = {};
      columns.forEach((column) => {
        let value = product.get(column.name);
        // currentSupplies is temporary, needs to be rethought (for articles. -10 in the waiting time)
        if (column.name === "currentSupplies" && value !== undefined) {
          let totalQuantityAvailable = 0;
          let allArticlesAvailable = true;

          value.forEach((article) => {
            const quantity = article.quantity;
            if (!quantity) {
              allArticlesAvailable = false;
            }
            totalQuantityAvailable += quantity;
          });

          if (!!totalQuantityAvailable && allArticlesAvailable) {
            // all available
            value = "oui";
          } else if (!!totalQuantityAvailable && !allArticlesAvailable) {
            // partially available
            value = "partiellement";
          } else if (!totalQuantityAvailable) {
            value = "non";
          }
        } else if (column.name === "images" && value !== undefined) {
          value.forEach((image) => {
            if (
              image.articleCode === "generic" &&
              image.miniature !== undefined
            ) {
              value = (
                <img
                  src={image.miniature.secure_url}
                  width="28"
                  height="28"
                  alt=""
                />
              );
              row.imageUrl = image.miniature.secure_url;
            }
          });
          value = row.imageUrl === undefined ? "" : value;
        } else if (column.name === "buttons") {
          value = (
            <ProductIcons
              productId={product.get("ARKTCODART")}
              showCard
              showChart={userRole !== "client" && userRole !== "commercial"}
              chartWithNegativeValues
              size="small"
            />
          );
        }
        row[column.name] = value;
      });

      // translating from columnsTranslations
      listsNameToTranslate.forEach((listName) => {
        row[listName] = translateColumn(
          product.get(listName),
          columnsTranslations.find(
            (columnTranslation) => columnTranslation.columnName === listName
          )
        );
      });

      // next line is used for selection
      row["productObj"] = product;
      return row;
    });
    // removing undefined rows (the ones corresponding to products not to be displayed)
    for (let i = products.length - 1; i >= 0; --i) {
      if (products[i] === undefined) products.splice(i, 1);
    }
    setStateProducts(products);
    setLoading(false);
  };

  const getNewSelection = (products) => {
    if (productsToPreselect !== undefined && !productsToPreselectLoaded) {
      let selection = [];
      productsToPreselect.forEach((productToPreselect) => {
        products.forEach((product, index) => {
          // console.log('------------',);
          // console.log('productToPreselect: ', productToPreselect);
          // console.log('product.ARKTCODART: ', product.ARKTCODART);
          if (productToPreselect === product.ARKTCODART) {
            selection.push(index);
          }
        });
      });
      return selection;
    } else if (productsOrder !== undefined) {
      let selection = [];
      productsOrder.forEach((productOrder) => {
        products.forEach((product, index) => {
          if (productOrder.ARKTCODART === product.ARKTCODART) {
            selection.push(index);
          }
        });
      });
      return selection;
    }
    return [];
  };

  const updateProductsOrder = (selection) => {
    const productsSelected = [];

    // We have to keep:
    //    - unlisted products (UNLISTED) because it means products are filtered and as a consequence not in selection (but should still be kept)
    //    - products in selection (SELECTION)
    // UNLISTED
    if (productsOrder !== undefined) {
      productsOrder.forEach((productOrder) => {
        let productIsCurrentlyListed = false;
        stateProducts.forEach((product) => {
          if (product.ARKTCODART === productOrder.ARKTCODART) {
            productIsCurrentlyListed = true;
          }
        });
        if (!productIsCurrentlyListed) {
          // if it was in productsOrder we keep it because quantities might not be zero (in case we come back from next order step)
          productsSelected.push(productOrder);
        }
      });
    }

    // SELECTION
    selection.forEach((selectionIndex) => {
      let articles = stateProducts[selectionIndex].productObj.get("articles");

      // sorting sizes for display purpose
      const clothingOrder = ["XXS", "XS", "S", "M", "L", "XL", "XXL"];
      const sortedArticles = [];
      articles.forEach((article) => {
        const ARKTCOMART = article.ARKTCOMART;
        if (clothingOrder.includes(ARKTCOMART)) {
          clothingOrder.forEach((size, index) => {
            if (ARKTCOMART === size) {
              sortedArticles[index] = article;
            }
          });
        }
      });
      const filteredArticles = sortedArticles.filter((el) => el !== null);
      // here we find out if the product has clothing sizes
      const articlesToDisplay = !!sortedArticles.length
        ? filteredArticles
        : articles;

      let productWasInSelectedProducts = false;
      if (productsOrder !== undefined) {
        productsOrder.forEach((productOrder) => {
          if (
            stateProducts[selectionIndex].ARKTCODART === productOrder.ARKTCODART
          ) {
            productWasInSelectedProducts = true;
            // if it was in productsOrder we keep it because quantities might not be zero (in case we come back from next order step)
            productsSelected.push(productOrder);
          }
        });
      }
      if (!productWasInSelectedProducts) {
        // if not in productsOrder we build it
        productsSelected.push({
          productObj: stateProducts[selectionIndex].productObj.attributes,
          ARKTCODART: stateProducts[selectionIndex].ARKTCODART,
          ARCTLIB01: stateProducts[selectionIndex].ARCTLIB01,
          imageUrl: stateProducts[selectionIndex].imageUrl,
          quantities: generateEmptyQuantities(shippings, articlesToDisplay),
          suppliesPerDate: generateSuppliesData({
            supplies: stateProducts[selectionIndex].productObj.get("supplies"),
            resupplies:
              stateProducts[selectionIndex].productObj.get("resupplies"),
          }),
          webSecurityStock:
            stateProducts[selectionIndex].productObj.get("webSecurityStock"),
        });
      }
    });
    dispatch(setProducts(productsSelected));
    setSelection(selection);
  };

  useEffect(() => {
    setNumberColumns(columns, setStateNumberColumns);
  }, []);

  // when shippings and sorting change or when productsOrder changes through SelectedProductsSummary
  useEffect(() => {
    setProductsToState(); // eslint-disable-next-line
  }, [shippings, sorting, productsOrder]);

  // when filters change
  useEffect(() => {
    if (debouncedFilters) {
      setProductsToState();
    } // eslint-disable-next-line
  }, [debouncedFilters]);

  // for selection and for productsToPreselect (when we duplicate an order)
  useEffect(() => {
    if (
      productsToPreselect !== undefined &&
      !productsToPreselectLoaded &&
      stateProducts.length
    ) {
      const newSelection = getNewSelection(stateProducts);
      setSelection(newSelection);
      updateProductsOrder(newSelection);
      setProductsToPreselectLoaded(true);
    } else if (productsOrder !== undefined && productsOrder.length) {
      const newSelection = getNewSelection(stateProducts);
      setSelection(newSelection);
    }
    // eslint-disable-next-line
  }, [stateProducts]);

  // showing skeleton while loading products
  if (!stateProducts) {
    return (
      <div className={classes.circularProgressWrapper}>
        <Typography variant="h6" className={classes.tableTitleWrapper}>
          Liste des produits
        </Typography>
        <CircularProgress className={classes.circularProgress} />
        <div className={classes.skeletonHeader}>
          <Skeleton
            variant="text"
            width={200}
            className={classes.skeletonHeaderEl}
          />
          <Skeleton
            variant="text"
            width={200}
            className={classes.skeletonHeaderEl}
          />
          <Skeleton
            variant="text"
            width={200}
            className={classes.skeletonHeaderEl}
          />
          <Skeleton
            variant="text"
            width={200}
            className={classes.skeletonHeaderEl}
          />
          <Skeleton
            variant="text"
            width={200}
            className={classes.skeletonHeaderEl}
          />
          <Skeleton
            variant="text"
            width={200}
            className={classes.skeletonHeaderEl}
          />
        </div>
        <div className={classes.skeletonLines}>
          <Skeleton animation="wave" className={classes.skeletonLinesEl} />
          <Skeleton animation="wave" className={classes.skeletonLinesEl} />
          <Skeleton animation="wave" className={classes.skeletonLinesEl} />
          <Skeleton animation="wave" className={classes.skeletonLinesEl} />
          <Skeleton animation="wave" className={classes.skeletonLinesEl} />
          <Skeleton animation="wave" className={classes.skeletonLinesEl} />
          <Skeleton animation="wave" className={classes.skeletonLinesEl} />
          <Skeleton animation="wave" className={classes.skeletonLinesEl} />
          <Skeleton animation="wave" className={classes.skeletonLinesEl} />
          <Skeleton animation="wave" className={classes.skeletonLinesEl} />
          <Skeleton animation="wave" className={classes.skeletonLinesEl} />
        </div>
      </div>
    );
  }

  return (
    <>
      <DataGridView
        title={title}
        rowsValues={stateProducts}
        columnHeaders={columns}
        isVirtualized={true}
        height={height}
        withPagination={false}
        withSelection={true}
        selection={selection}
        onSelectionChange={updateProductsOrder}
        filters={filters}
        onFiltersChange={changeFilters}
        sorting={sorting}
        onSortingChange={changeSorting}
        loading={loading}
        numberColumns={numberColumns}
        columnExtensions={columnExtensions}
        hasActions={false}
        noDataText="pas de produit"
      />
    </>
  );
};

export default ProductsList;
