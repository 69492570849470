import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../reducers/currentUser";
import Parse from "parse";
import { getProducts, getClient, getShippings } from "../../reducers/order";
import {
  makeStyles,
  Paper,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Tooltip,
} from "@material-ui/core";
import { saveOrdersToMongo } from "../../actions/saveOrdersToMongo";
import ProductsList from "../../components/order/ProductsList";
import SelectedProductsList from "../../components/order/SelectedProductsList";
import SelectedProducts from "../../components/order/SelectedProductsSummary";
import AmountByShipping from "../../components/order/AmountByShipping";
import OrderSummary from "../../components/order/OrderSummary";
import Shippings from "../../components/order/Shippings";
import { updateOnUnshippedOrders, isClientBlocked } from "../../utils2";

const useStyles = makeStyles(() => ({
  paper: {
    marginBottom: "14px",
    paddingBottom: "1rem",
  },
  tableTitleWrapper: {
    display: "flex",
    alignItems: "center",
    minHeight: "64px",
    padding: "0 24px",
  },
  positioner: {
    padding: "0 24px",
  },
  highLine: {
    lineHeight: "34px",
  },
  dialogActions: {
    padding: "1rem",
  },
  orderSummaryContainer: {
    padding: "0 1rem",
  },
}));

const ClockedOrderProcess = (props) => {
  const {
    activeStep,
    onHandleNext,
    onHandleBack,
    onHandleSkip,
    onHandleModalClose,
    optionalSteps,
    contentHeight,
    disableNextButton,
  } = props;
  const classes = useStyles();
  const currentUser = useSelector(getCurrentUser);
  const userRole =
    currentUser !== undefined ? currentUser.get("role") : undefined;
  const productsOrder = useSelector(getProducts);
  const shippings = useSelector(getShippings);
  const client = useSelector(getClient);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rawAmount, setRawAmount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [shippingsHaveContact, setShippingsHaveContact] = useState(false);
  const buttonIsDisabled =
    disableNextButton === undefined ? true : disableNextButton;
  const backButtonTexts = [
    "",
    "retour à l'ajout de produit",
    "retour au choix des quantités",
  ];
  const nextButtonTexts = ["continuer", "continuer", "confirmer la commande"];
  const productCount = productsOrder !== undefined ? productsOrder.length : 0;

  useEffect(() => {
    // data for OrderSummary
    if (productsOrder !== undefined) {
      let newRawAmount = 0;
      let newAmount = 0;
      productsOrder.forEach((product) => {
        const quantity = product.quantities.quantity;
        const rawPrice = product.productObj.ARCNTARIF1;
        newRawAmount += quantity * rawPrice;
        newAmount += product.quantities.amount;
      });
      setRawAmount(newRawAmount);
      setAmount(newAmount);
    }
  }, [productsOrder]);
  useEffect(() => {
    if (shippings !== undefined) {
      let allShippingsHaveContact = true;
      shippings.forEach((shipping) => {
        if (shipping.contact === undefined) {
          allShippingsHaveContact = false;
        }
      });
      setShippingsHaveContact(allShippingsHaveContact);
    }
  }, [shippings]);

  const handleNext = async (action) => {
    // if not last step we go to next one
    if (activeStep !== 2) {
      onHandleNext();
    } else {
      // keeping shippings for checkForcedDiscount
      const shippingsCopy = shippings.slice();

      // checking if one of the client in order is blocked
      let arrayOfBlockedClients = [];
      await Promise.all(
        shippings.map(async (shipping) => {
          const clientId = shipping.client.id;
          if (
            (await isClientBlocked(clientId)) &&
            !arrayOfBlockedClients.includes(clientId)
          ) {
            arrayOfBlockedClients.push(clientId);
          }
        }),
      );
      // saving/updating order to mongodb. if we confirm only validator is ''. If we validate we have to provide validator's name
      if (action === "confirm" || arrayOfBlockedClients.length) {
        const ordersSaved = await saveOrdersToMongo(
          productsOrder,
          shippings,
          "",
          currentUser,
        );
        await updateOnUnshippedOrders(ordersSaved);
        // console.log('productsOrder: ', productsOrder);
        // console.log('shippings: ', shippings);
        // console.log('ordersSaved: ', ordersSaved);
        // closing order dialog
        ordersSaved !== "error"
          ? onHandleModalClose("orderConfirmed", shippingsCopy)
          : onHandleModalClose("savingError");
        // alerting of client blocked
        if (arrayOfBlockedClients.length) {
          onHandleModalClose("oneOfClientsIsBlocked", arrayOfBlockedClients);
        }
      } else if (action === "confirmAndValidate") {
        const validator =
          currentUser.get("firstname") + " " + currentUser.get("lastname");
        // console.log("productsOrder: ", productsOrder);
        let ordersSaved = await saveOrdersToMongo(
          productsOrder,
          shippings,
          validator,
          currentUser,
        );
        ordersSaved.forEach((orderSaved) => {
          delete orderSaved.orderRef;
          delete orderSaved.rep1;
          delete orderSaved.orderAuthor;
          delete orderSaved.contact;
        });
        // console.log("ordersSaved: ", ordersSaved);
        await Parse.Cloud.run("saveOrderToPmi", { ordersSaved });
        // closing confirmation dialog
        setDialogOpen(false);
        // closing order dialog
        onHandleModalClose("orderConfirmedAndValidated", shippingsCopy);
      }
    }
  };

  const isStepOptional = (step) => {
    return optionalSteps === undefined ? false : optionalSteps.includes(step);
  };

  if (client === undefined) {
    return null;
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={activeStep !== 2 ? 9 : 12}>
          <Paper>
            {activeStep === 0 && (
              <ProductsList title="Liste des produits" height={contentHeight} />
            )}
            {activeStep !== 0 && (
              <SelectedProductsList
                height={contentHeight}
                activeStep={activeStep}
              />
            )}
            {activeStep === 2 && (
              <div className={classes.orderSummaryContainer}>
                <OrderSummary rawAmount={rawAmount} amount={amount} />
              </div>
            )}
            <DialogActions className={classes.dialogActions}>
              <Button
                disabled={activeStep === 0}
                onClick={onHandleBack}
                className={classes.button}
              >
                {backButtonTexts[activeStep]}
              </Button>
              {isStepOptional(activeStep) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onHandleSkip}
                  className={classes.button}
                >
                  Sauter
                </Button>
              )}
              {activeStep !== 2 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                  disabled={buttonIsDisabled}
                >
                  {nextButtonTexts[activeStep]}
                </Button>
              )}

              {/* next button is just for admin */}
              {activeStep === 2 && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleNext("confirm")}
                    className={classes.button}
                    disabled={buttonIsDisabled}
                  >
                    {nextButtonTexts[activeStep]}
                  </Button>
                  {userRole !== "commercial" && userRole !== "client" && (
                    <>
                      {shippingsHaveContact && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setDialogOpen(true);
                          }}
                          className={classes.button}
                          disabled={buttonIsDisabled}
                        >
                          confirmer et valider la commande
                        </Button>
                      )}
                      {!shippingsHaveContact && (
                        <Tooltip
                          title="Ajoutez un contact à votre commande pour pouvoir la valider"
                          placement="top"
                          classes={{ tooltip: classes.toolTip }}
                        >
                          <div>
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.button}
                              disabled
                            >
                              confirmer et valider la commande
                            </Button>
                          </div>
                        </Tooltip>
                      )}
                    </>
                  )}
                </>
              )}
            </DialogActions>
          </Paper>
        </Grid>
        {activeStep !== 2 && (
          <Grid item xs={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Typography
                    variant="h6"
                    className={classes.tableTitleWrapper}
                  >
                    Cadences
                  </Typography>
                  <Shippings activeStep={activeStep} />
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Typography
                    variant="h6"
                    className={classes.tableTitleWrapper}
                  >
                    Commande en cours
                  </Typography>
                  <div className={classes.positioner}>
                    <Typography variant="body2" className={classes.highLine}>
                      {productCount} produit(s) sélectionné(s)
                    </Typography>
                  </div>
                  <SelectedProducts />
                </Paper>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Typography
                    variant="h6"
                    className={classes.tableTitleWrapper}
                  >
                    Total de la commande
                  </Typography>
                  <AmountByShipping />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Sauver et valider la commande
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            En validant la commande, vous envoyez celle-ci dans PMI. Cette
            dernière pourra alors uniquement être modifiée sous PMI.
            <br />
            Souhaitez-vous continuer ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleNext("confirmAndValidate")}
            color="primary"
          >
            Oui
          </Button>
          <Button
            onClick={() => {
              setDialogOpen(false);
            }}
            color="primary"
            autoFocus
          >
            Non
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ClockedOrderProcess;
