import { browserHistory } from "react-router";
import Parse from "parse";

const { push } = browserHistory;

/********************************************************/
/********************** navigation **********************/
/********************************************************/

export function goToPage(uri) {
  push(uri);
}
export const toggleListItem = (id) => ({
  type: "TOGGLE_LIST_ITEM",
  id,
});
export const selectListItem = (id) => ({
  type: "SELECT_LINK",
  id,
});

/********************************************************/
/************************* lists ************************/
/********************************************************/
export function updateLists(store) {
  return async (nextState, replace, callback) => {
    const state = store.getState();
    let listsValid = true;
    if (
      state.lists === undefined ||
      state.lists.listsValid === undefined ||
      state.lists.listsValid === false
    ) {
      listsValid = false;
    }
    if (!listsValid) {
      const query = await new Parse.Query("Lists").limit(1000000).find();
      const lists = query.map((data) => {
        return data.attributes;
      });
      store.dispatch({
        type: "SET_LISTS",
        lists,
      });

      listsValid = true;
      store.dispatch({
        type: "SET_LISTS_VALIDITY",
        listsValid,
      });
    }
    callback();
  };
}

export const setListsValid = (listsValid) => ({
  type: "SET_LISTS_VALIDITY",
  listsValid,
});

/********************************************************/
/************************* order ************************/
/********************************************************/

export const setProducts = (products) => ({
  type: "SET_PRODUCTS",
  products,
});
export const setProductsToPreselect = (productsToPreselect) => ({
  type: "SET_PRODUCTS_TO_PRESELECT",
  productsToPreselect,
});
export const setShippings = (shippings) => ({
  type: "SET_SHIPPINGS",
  shippings,
});
export const setClient = (client) => ({
  type: "SET_CLIENT",
  client,
});
export const setObjectOfContactOptions = (objectOfContactOptions) => ({
  type: "SET_OBJECT_OF_CONTACT_OPTIONS",
  objectOfContactOptions,
});
export const resetOrder = () => ({
  type: "RESET_ORDER",
});
/********************************************************/
/************************* user *************************/
/********************************************************/

export const setCurrentUser = (currentUser) => ({
  type: "SET_CURRENT_USER",
  currentUser,
});
