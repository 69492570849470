import React, { PureComponent } from "react";
import {
  withStyles,
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ImageHandler from "../ImageHandler";

const styles = (theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    transition: "font-size .5s ease",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
});

class ArticleImageEntry extends PureComponent {
  render() {
    const {
      classes,
      productCode,
      headingText,
      secondaryHeadingText,
      panelName,
      expanded,
      onPanelChange,
      onImageChange,
      articleDataInDb,
    } = this.props;
    const isExpanded = expanded === panelName;
    return (
      <ExpansionPanel expanded={isExpanded} onChange={onPanelChange}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>{headingText}</Typography>
          <Typography className={classes.secondaryHeading}>
            {secondaryHeadingText}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {isExpanded && (
            <Grid container spacing={0}>
              <ImageHandler
                productCode={productCode}
                articleDataInDb={articleDataInDb}
                onImageChange={onImageChange}
                imageName="miniature"
                imageDisplayName="Miniature"
              />
              <ImageHandler
                productCode={productCode}
                articleDataInDb={articleDataInDb}
                onImageChange={onImageChange}
                imageName="main"
                imageDisplayName="Image principale"
              />
              <ImageHandler
                productCode={productCode}
                articleDataInDb={articleDataInDb}
                onImageChange={onImageChange}
                imageName="image2"
                imageDisplayName="Image 2"
              />
              <ImageHandler
                productCode={productCode}
                articleDataInDb={articleDataInDb}
                onImageChange={onImageChange}
                imageName="image3"
                imageDisplayName="Image 3"
              />
              <ImageHandler
                productCode={productCode}
                articleDataInDb={articleDataInDb}
                onImageChange={onImageChange}
                imageName="image4"
                imageDisplayName="Image 4"
              />
              <ImageHandler
                productCode={productCode}
                articleDataInDb={articleDataInDb}
                onImageChange={onImageChange}
                imageName="image5"
                imageDisplayName="Image 5"
              />
              <ImageHandler
                productCode={productCode}
                articleDataInDb={articleDataInDb}
                onImageChange={onImageChange}
                imageName="image6"
                imageDisplayName="Image 6"
              />
            </Grid>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default withStyles(styles)(ArticleImageEntry);
