import React, { useState, useEffect, useCallback } from "react";
import Parse from "parse";
import moment from "moment";
import classNames from "classnames";
import ShippingChip from "./ShippingChip";
import {
  getShippings,
  getProducts,
  getClient,
  getObjectOfContactOptions,
} from "../../reducers/order";
import { getLists } from "../../reducers/lists";
import { getCurrentUser } from "../../reducers/currentUser";
import {
  setShippings,
  setProducts,
  setObjectOfContactOptions,
} from "../../actions/main";
import { useSelector, useDispatch } from "react-redux";
import {
  getClientDiscounts,
  optionsCreator,
  updateProductOrderDiscountAndAmount,
  generateEmptyQuantities,
  getEarliestShippingAndDeliveryDates,
  buildContactsOptions,
} from "../../utils2";
import {
  makeStyles,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Button,
  IconButton,
  Fab,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { Form, Field } from "react-final-form";
import { Select, TextField } from "final-form-material-ui";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import HandleContactForm from "../../containers/form/HandleContactForm";
import { generateSuppliesData } from "../../utils/generateSuppliesData/generateSuppliesData";

const useStyles = makeStyles(() => ({
  tableContentWrapper: {
    overflow: "hidden",
    padding: "0 24px",
    maxHeight: "232px",
  },
  clientContainer: {
    display: "flex",
    flex: 1,
    position: "relative",
    marginLeft: "16px",
  },
  clientFieldContainer: {
    maxWidth: 200,
  },
  clientText: {
    maxWidth: 250,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  dateContainer: {
    display: "flex",
    width: "100px",
  },
  dateFieldContainer: {
    width: 145,
  },
  actionContainer: {
    display: "flex",
  },
  shippingLineContainer: {
    padding: "0.5rem 0",
  },
  shippingLine: {
    display: "flex",
    justifyContent: "space-between",
  },
  textInShippingLine: {
    lineHeight: "1rem",
    whiteSpace: "nowrap",
  },
  fullHeight: {
    lineHeight: "4rem",
  },
  discountText: {
    position: "absolute",
    bottom: "-3px",
    color: "#555",
    whiteSpace: "nowrap",
  },
  deliveryDate: {
    textAlign: "right",
  },
  fieldContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "16px",
    marginRight: "16px",
    minWidth: "50px",
  },
  numberFieldContainer: {
    width: 50,
  },
  buttonContainer: {
    display: "flex",
    margin: "1rem 1rem 0 0",
    justifyContent: "flex-end",
  },
  buttonContainer2: {
    padding: "0.5rem 0",
  },
  dialogNote: {
    paddingBottom: "1rem",
    color: "#666",
  },
  tableTopWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "",
  },
  addBtnWrapper: {
    display: "flex",
    alignItems: "center",
    padding: "0 24px",
  },
}));

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Shippings = (props) => {
  const { activeStep } = props;
  const classes = useStyles();

  const currentUser = useSelector(getCurrentUser);
  const userRole =
    currentUser !== undefined ? currentUser.get("role") : undefined;

  const productsOrder = useSelector(getProducts);
  const shippings = useSelector(getShippings);
  const client = useSelector(getClient);
  const listsOptions = useSelector(getLists);
  const objectOfContactOptions = useSelector(getObjectOfContactOptions);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [addContactDialogOpen, setAddContactDialogOpen] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarText, setSnackBarText] = useState("");
  const [alertSnackBarOpen, setAlertSnackBarOpen] = useState(false);
  const [
    alertBadDeliveryDateSnackBarOpen,
    setAlertBadDeliveryDateSnackBarOpen,
  ] = useState(false);
  const [stateShippings, setStateShippings] = useState([]);
  const [clientIdForAddContactDialog, setClientIdForAddContactDialog] =
    useState("");
  const dispatch = useDispatch();

  const addShipping = useCallback(
    async (source, formValues) => {
      let sourceShippings = {};
      if (formValues === undefined) {
        sourceShippings = source === "state" ? stateShippings : shippings;
      } else {
        // when we change existing shipping in form and add a new shipping, changes made are not kept. formValues is the current state of form. We pass use formValues to create sourceShippings
        sourceShippings = await formValuesToShippings(formValues);
      }
      // console.log('sourceShippings: ', sourceShippings);
      let newShipping = {};
      // if we have a sourceShipping we copy the last shipping
      if (sourceShippings !== undefined) {
        newShipping = { ...sourceShippings[sourceShippings.length - 1] };
        newShipping.id = Date.now();
      } else {
        const clientDbObj =
          client.type === "group"
            ? client.dbObj.clients[0].attributes
            : client.dbObj;
        const selectedClient = {
          id: clientDbObj.CLKTCODE,
          name: clientDbObj.CLCTNOM,
          dbObj: clientDbObj,
        };

        const clientDiscounts = await getClientDiscounts(selectedClient.id);

        // searching for client & preSeason discount's percentages
        let forcedClientDiscount = 0;
        let forcedPreSeasonDiscount = 0;
        clientDiscounts.forEach((discount) => {
          if (discount.type === "preSeason") {
            forcedPreSeasonDiscount = discount.percentageDiscount;
          } else if (discount.type === "clientPermanent") {
            forcedClientDiscount = discount.percentageDiscount;
          }
        });
        const shippingAndDeliveryDate =
          await getEarliestShippingAndDeliveryDates(selectedClient.id);
        newShipping = {
          id: Date.now(),
          shippingDate: shippingAndDeliveryDate.shippingDate,
          earliestDeliveryDate: shippingAndDeliveryDate.deliveryDate,
          deliveryDate: shippingAndDeliveryDate.deliveryDate,
          client: selectedClient,
          // for contact we try to take the main contact, then the first on the list. If no contact defined >> undefined.
          contact:
            clientDbObj.mainContact !== undefined
              ? clientDbObj.mainContact.id
              : objectOfContactOptions[clientDbObj.CLKTCODE] !== undefined &&
                objectOfContactOptions[clientDbObj.CLKTCODE][0] !== undefined
              ? objectOfContactOptions[clientDbObj.CLKTCODE][0].value
              : undefined,
          clientDiscounts,
          forcedClientDiscount,
          originalClientDiscount: forcedClientDiscount,
          forcedPreSeasonDiscount,
          clientCommercial: clientDbObj.CLCTREP1,
          clientCommercialRate: clientDbObj.CLCNTXREP1,
          orderType: "2000", // to be validated by Olivier
          orderOrigin: "97", // to be validated by Olivier
          carrier: "DPD1",
          shippingFees: 8,
        };
      }

      let newShippings = [];
      if (shippings === undefined) {
        // initialization
        newShippings = [newShipping];
      } else {
        newShippings = [...sourceShippings];
        newShippings.push(newShipping);
      }

      if (source !== "state") {
        dispatch(setShippings(newShippings));
      }
      // console.log('newShippings: ', newShippings);
      setStateShippings(newShippings);

      // eslint-disable-next-line
    },
    [client.dbObj, client.type, dispatch, shippings, stateShippings]
  );

  useEffect(() => {
    if (shippings === undefined) {
      addShipping();
      setDialogOpen(true); // to open shippings dialog only at beginning of order
    } else if (stateShippings.length === 0) {
      // necessary when coming back from activeStep 2 (last step) to previous
      setStateShippings(shippings);
    }
    // eslint-disable-next-line
  }, [addShipping, shippings]);

  const deleteShippingFromState = (index) => {
    const newStateShippings = stateShippings.slice();
    newStateShippings.splice(index, 1);
    setStateShippings(newStateShippings);
  };

  const shippingsToFormValues = (shippings) => {
    const formValues = {};
    if (shippings !== undefined) {
      shippings.forEach((shipping, index) => {
        const shippingIdName = "shippingId" + index;
        formValues[shippingIdName] = shipping.id;

        const clientOrderRefName = "clientOrderRef" + index;
        formValues[clientOrderRefName] = shipping.clientOrderRef;

        const earliestDeliveryDateName = "earliestDeliveryDate" + index;
        formValues[earliestDeliveryDateName] = moment(
          shipping.earliestDeliveryDate
        ).format("YYYY-MM-DD");

        const deliveryDateName = "deliveryDate" + index;
        formValues[deliveryDateName] = moment(shipping.deliveryDate).format(
          "YYYY-MM-DD"
        );

        const shippingDateName = "shippingDate" + index;
        formValues[shippingDateName] = moment(shipping.shippingDate).format(
          "YYYY-MM-DD"
        );

        const clientDiscountName = "forcedClientDiscount" + index;
        formValues[clientDiscountName] = shipping.forcedClientDiscount;

        const originalClientDiscountName = "originalClientDiscount" + index;
        formValues[originalClientDiscountName] =
          shipping.originalClientDiscount;

        const preSeasonDiscountName = "forcedPreSeasonDiscount" + index;
        formValues[preSeasonDiscountName] = shipping.forcedPreSeasonDiscount;

        const clientCommercialName = "clientCommercial" + index;
        formValues[clientCommercialName] = shipping.clientCommercial;

        const orderTypeName = "orderType" + index;
        formValues[orderTypeName] = shipping.orderType;

        const orderOriginName = "orderOrigin" + index;
        formValues[orderOriginName] = shipping.orderOrigin;

        const clientCommercialRateName = "clientCommercialRate" + index;
        formValues[clientCommercialRateName] = shipping.clientCommercialRate;

        const carrierName = "carrier" + index;
        formValues[carrierName] = shipping.carrier;

        const shippingFeesName = "shippingFees" + index;
        formValues[shippingFeesName] = shipping.shippingFees;

        if (shipping.client !== undefined) {
          const clientName = "client" + index;
          formValues[clientName] = shipping.client.id;

          const contactName = "contact" + index;
          formValues[contactName] = shipping.contact;
        }
      });
      return formValues;
    } else {
      return null;
    }
  };

  const formValuesToShippings = async (values) => {
    // getting number of shippings
    let shippingCount = 0;
    Object.entries(values).forEach(([key, value]) => {
      if (key.startsWith("deliveryDate")) {
        shippingCount++;
      }
    });
    const newShippings = [];
    for (let i = 0; i < shippingCount; i++) {
      const shipping = {};
      const shippingIdFieldName = "shippingId" + i;
      const clientOrderRefName = "clientOrderRef" + i;
      const earliestDeliveryDateName = "earliestDeliveryDate" + i;
      const deliveryDateFieldName = "deliveryDate" + i;
      const shippingDateFieldName = "shippingDate" + i;
      const clientFieldName = "client" + i;
      const contactFieldName = "contact" + i;
      const clientDiscountFieldName = "forcedClientDiscount" + i;
      const originalClientDiscountFieldName = "originalClientDiscount" + i;
      const preSeasonDiscountFieldName = "forcedPreSeasonDiscount" + i;
      const clientCommercialFieldName = "clientCommercial" + i;
      const orderTypeFieldName = "orderType" + i;
      const orderOriginFieldName = "orderOrigin" + i;
      const clientCommercialRateFieldName = "clientCommercialRate" + i;
      const carrierFieldName = "carrier" + i;
      const shippingFeesFieldName = "shippingFees" + i;
      shipping.id = values[shippingIdFieldName];
      shipping.earliestDeliveryDate = moment(
        values[earliestDeliveryDateName],
        "YYYY-MM-DD"
      );
      shipping.deliveryDate = moment(
        values[deliveryDateFieldName],
        "YYYY-MM-DD"
      );
      shipping.shippingDate = moment(
        values[shippingDateFieldName],
        "YYYY-MM-DD"
      );
      shipping.clientOrderRef = values[clientOrderRefName];

      if (shipping.deliveryDate.isBefore(shipping.earliestDeliveryDate)) {
        shipping.deliveryDate = shipping.earliestDeliveryDate;
        setAlertBadDeliveryDateSnackBarOpen(true);
      }
      if (values[clientDiscountFieldName] !== undefined) {
        shipping.forcedClientDiscount = parseFloat(
          values[clientDiscountFieldName]
        );
      }
      shipping.originalClientDiscount = values[originalClientDiscountFieldName];
      if (values[preSeasonDiscountFieldName] !== undefined) {
        shipping.forcedPreSeasonDiscount = values[preSeasonDiscountFieldName];
      }
      shipping.clientCommercial = values[clientCommercialFieldName];
      shipping.orderType = values[orderTypeFieldName];
      shipping.orderOrigin = values[orderOriginFieldName];
      shipping.shippingFees = parseFloat(values[shippingFeesFieldName]);
      if (values[clientCommercialRateFieldName] !== undefined) {
        shipping.clientCommercialRate = values[clientCommercialRateFieldName];
      }
      shipping.carrier = values[carrierFieldName];

      // setting client (for groups)
      if (values[clientFieldName] !== undefined) {
        shipping.client = {};
        shipping.client.id = values[clientFieldName];
        shipping.clientDiscounts = await getClientDiscounts(shipping.client.id);
        const clientDbObj = await new Parse.Query("Clients")
          .equalTo("CLKTCODE", values[clientFieldName])
          .first();
        shipping.client.name = clientDbObj.get("CLCTNOM");
        shipping.client.dbObj = clientDbObj.attributes;
      }
      // verifying contact : if onSubmit called and contact is one of client then we erase it (could happen with FormSpy's onSubmit and change of client)
      let contactIsRelevant = false;
      objectOfContactOptions[shipping.client.id].forEach((contact) => {
        if (contact.value === values[contactFieldName]) {
          contactIsRelevant = true;
        }
      });
      shipping.contact = contactIsRelevant
        ? values[contactFieldName]
        : undefined;
      // console.log('shipping: ', shipping);
      newShippings.push(shipping);
    }
    return newShippings;
  };

  // if shippings change (shipping added, shipping removed), productSelected should be updated
  const updateProductsOrder = (newStateShippings) => {
    // console.log("updateProductsOrder called");
    let quantityAskedGreaterThanStock = false;
    if (productsOrder !== undefined) {
      const shippingIds = stateShippings.map((shipping) => shipping.id);
      const newProductsOrder = [...productsOrder];

      // for each product
      newProductsOrder.forEach((productOrder) => {
        // for each article
        productOrder.quantities.articles.forEach((article) => {
          // if shipping has been deleted we have to remove it from articles. to do so we have to have reverse loop because if we remove by splice the index might be wrong (if several deletes)
          // for each shipping...
          // ... we delete unexisting shippings
          for (let i = article.shippings.length - 1; i >= 0; --i) {
            const shipping = article.shippings[i];

            if (!shippingIds.includes(shipping.id)) {
              article.shippings.splice(i, 1);
            }
          }
          // ... we add new shippings
          shippingIds.forEach((shippingId) => {
            let shippingIsNotInArticle = true;
            article.shippings.forEach((shipping) => {
              if (shipping.id === shippingId) {
                shippingIsNotInArticle = false;
              }
            });
            if (shippingIsNotInArticle) {
              article.shippings.push({
                id: shippingId,
                quantity: 0,
                amount: 0,
              });
            }
          });
        });
        // sometimes if you change deliveryDate, available supplies may change and ordered quantities are not available anymore.
        // We have to warn user in that case
        if (!!productsOrder.length) {
          const shippingsPerShippingId = {};
          newStateShippings.forEach((shipping) => {
            shippingsPerShippingId[shipping.id] = {
              date: shipping.deliveryDate,
            };
            productsOrder[0].suppliesPerDate[0].supplies.forEach(
              (day, index) => {
                if (moment(shipping.deliveryDate).isSame(day.date)) {
                  shippingsPerShippingId[shipping.id].indexInSuppliesPerDate =
                    index;
                }
              }
            );
          });

          // copying old prop
          productOrder.oldQuantities = { ...productOrder.quantities };

          // getting 'suppliesPerDate' with 0 quantity ordered
          productOrder.suppliesPerDate = generateSuppliesData({
            supplies: productOrder.productObj.supplies,
            resupplies: productOrder.productObj.resupplies,
            removeNegativeQuantities: false,
          });
          // getting an empty 'quantities'
          productOrder.quantities = generateEmptyQuantities(
            newStateShippings,
            productOrder.productObj.articles
          );

          // foreach article
          productOrder.oldQuantities.articles.forEach((oldArticle) => {
            // foreach shipping
            oldArticle.shippings.forEach((shipping, shippingIndex) => {
              // if shipping still exists
              if (shippingsPerShippingId[shipping.id] !== undefined) {
                let availableSupply = 0;
                // getting availableSupply (in new suppliesPerDate)
                productOrder.suppliesPerDate.forEach((suppliesArticle) => {
                  if (
                    suppliesArticle.ARKTCOMART === oldArticle.ARKTCOMART &&
                    suppliesArticle.supplies[
                      shippingsPerShippingId[shipping.id].indexInSuppliesPerDate
                    ] !== undefined
                  ) {
                    availableSupply =
                      suppliesArticle.supplies[
                        shippingsPerShippingId[shipping.id]
                          .indexInSuppliesPerDate
                      ].quantity;
                  }
                });
                const newQuantity = shipping.quantity;
                if (
                  shipping.quantity > 0 &&
                  availableSupply < shipping.quantity
                ) {
                  quantityAskedGreaterThanStock = true;
                }
                // console.log("ARKTCODART: ", productOrder.ARKTCODART);
                // console.log("ARKTCOMART: ", oldArticle.ARKTCOMART);
                // console.log("availableSupply: ", availableSupply);
                // console.log("newQuantity: ", newQuantity);
                // console.log("-----------");
                if (!!newQuantity) {
                  // A: incrementing quantity ordered
                  productOrder.quantities.articles.forEach((article) => {
                    if (article.ARKTCOMART === oldArticle.ARKTCOMART) {
                      const delta =
                        newQuantity - article.shippings[shippingIndex].quantity;
                      productOrder.quantities.quantity += delta;
                      article.quantity += delta;
                      article.shippings[shippingIndex].quantity += delta;
                    }
                  });

                  // B: decrementing supplies
                  const params = {
                    supplies: productOrder.productObj.supplies,
                    resupplies: productOrder.productObj.resupplies,
                    quantities: productOrder.quantities,
                    removeNegativeQuantities: false,
                  };
                  productOrder.suppliesPerDate = generateSuppliesData(params);
                }
              }
            });
          });
        }

        // we recalculate the total
        let total = 0;
        productOrder.quantities.articles.forEach((article) => {
          article.shippings.forEach((shipping) => {
            total += shipping.quantity;
          });
        });
        productOrder.quantities.quantity = total;
      });
      // updating discount and prices in case % changed
      updateProductOrderDiscountAndAmount(newProductsOrder, newStateShippings);
    }

    dispatch(setProducts(productsOrder));
    if (quantityAskedGreaterThanStock) {
      setAlertSnackBarOpen(true);
    }
  };

  const onSubmit = async (values) => {
    const newStateShippings = await formValuesToShippings(values);
    dispatch(setShippings(newStateShippings));
    setStateShippings(newStateShippings);
    updateProductsOrder(newStateShippings);
  };
  /*
  const onFormSpyChange = async values => {
    console.log('onFormSpyChange called');
    const newStateShippings = await formValuesToShippings(values);
    if (!_.isEqual(stateShippings, newStateShippings)) {
      console.log('it s different');
      console.log("stateShippings: ", stateShippings);
      console.log("newStateShippings: ", newStateShippings);
      setStateShippings(newStateShippings);
    }
  };
*/
  const renderShippings = (source, isEditable) => {
    const editable = isEditable === "editable";
    const sourceShippings = source === "state" ? stateShippings : shippings;

    if (sourceShippings === undefined || currentUser === undefined) {
      return null;
    }

    const shippingsToReturn = [];

    sourceShippings.forEach((shipping, index) => {
      // searching if a preSeason discount exist to enable forcedPreseasonDiscount field
      let preSeasonDiscountExists = false;
      if (shipping !== undefined) {
        shipping.clientDiscounts.forEach((discount) => {
          if (discount.type === "preSeason") {
            preSeasonDiscountExists = true;
          }
        });
      }
      const contactOptions = optionsCreator(
        "custom",
        objectOfContactOptions[shipping.client.id]
      );
      const deliveryDate = moment(shipping.deliveryDate).format("DD/MM/YYYY");
      const dateFieldName = "deliveryDate" + index;
      const clientFieldName = "client" + index;
      const clientOrderRef = "clientOrderRef" + index;
      const contactFieldName = "contact" + index;
      const clientDiscountFieldName = "forcedClientDiscount" + index;
      const preSeasonDiscountFieldName = "forcedPreSeasonDiscount" + index;
      const clientCommercialFieldName = "clientCommercial" + index;
      const orderOriginFieldName = "orderOrigin" + index;
      const orderTypeFieldName = "orderType" + index;
      const clientCommercialRateFieldName = "clientCommercialRate" + index;
      const carrierFieldName = "carrier" + index;
      const clientIsGroup = client.type === "group";
      const gpListName = "z_gp_" + client.dbObj.CLKTCODE;
      const clientNameClasses = editable
        ? classNames(
            classes.textInShippingLine,
            classes.clientText,
            classes.fullHeight
          )
        : classNames(classes.textInShippingLine, classes.clientText);
      shippingsToReturn.push(
        <div key={index} className={classes.shippingLineContainer}>
          <div key={index} className={classes.shippingLine}>
            <ShippingChip number={index + 1} />
            {(!editable || !clientIsGroup) && (
              <div className={classes.clientContainer}>
                <Typography variant="body2" className={clientNameClasses}>
                  {shipping.client.name}
                </Typography>
                {!editable && (
                  <Typography
                    variant="caption"
                    className={classes.discountText}
                  >
                    remises: client {shipping.forcedClientDiscount}%  •  si
                    présaison {shipping.forcedPreSeasonDiscount}%
                  </Typography>
                )}
              </div>
            )}
            {editable && clientIsGroup && (
              <div
                className={classNames(
                  classes.fieldContainer,
                  classes.clientFieldContainer
                )}
              >
                <Field
                  name={clientFieldName}
                  label="Magasin concerné"
                  component={Select}
                  fullWidth
                >
                  {optionsCreator(listsOptions, gpListName)}
                </Field>
              </div>
            )}
            {editable && (
              <div
                className={classNames(
                  classes.fieldContainer,
                  classes.clientFieldContainer
                )}
              >
                <Field
                  name={clientOrderRef}
                  component={TextField}
                  label="Ref. client"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            )}
            {editable && userRole !== "client" && userRole !== "group" && (
              <div
                className={classNames(
                  classes.fieldContainer,
                  classes.clientFieldContainer
                )}
              >
                <Field
                  name={contactFieldName}
                  label="Contact"
                  component={Select}
                  fullWidth
                >
                  {contactOptions}
                </Field>
              </div>
            )}
            {editable && userRole !== "client" && userRole !== "group" && (
              <div className={classes.buttonContainer2}>
                <Tooltip title={"ajouter un contact"}>
                  <IconButton
                    aria-label="Delete"
                    onClick={() =>
                      handleAddContactClickOpen(shipping.client.id)
                    }
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )}
            <div
              className={classNames(
                classes.fieldContainer,
                classes.dateFieldContainer
              )}
            >
              {editable && (
                <Tooltip
                  title={
                    "La date de livraison la plus proche est le " +
                    shipping.earliestDeliveryDate.format("dddd DD MMMM YYYY")
                  }
                  placement="top"
                >
                  <span>
                    <Field
                      name={dateFieldName}
                      component={TextField}
                      label="date de livraison"
                      type="date"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </span>
                </Tooltip>
              )}
              {!editable && (
                <Typography
                  variant="body2"
                  className={classNames(
                    classes.deliveryDate,
                    classes.textInShippingLine
                  )}
                >
                  {deliveryDate}
                </Typography>
              )}
            </div>
            {editable && (
              <>
                <div className={classes.fieldContainer}>
                  <div className={classes.numberFieldContainer}>
                    <Field
                      name={clientDiscountFieldName}
                      component={TextField}
                      label="remise client"
                      type="number"
                      fullWidth
                      disabled={userRole === "client" || userRole === "group"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>

                {(userRole === "client" || userRole === "group") && (
                  <div className={classes.fieldContainer}>
                    <div className={classes.numberFieldContainer}>
                      <Field
                        name={preSeasonDiscountFieldName}
                        component={TextField}
                        label="remise présaison"
                        type="number"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                )}
                {userRole !== "client" && userRole !== "group" && (
                  <>
                    <Tooltip title="Applicable si les conditions de présaisons sont remplies.">
                      <div className={classes.fieldContainer}>
                        <div className={classes.numberFieldContainer}>
                          <Field
                            name={preSeasonDiscountFieldName}
                            component={TextField}
                            label="remise présaison"
                            type="number"
                            fullWidth
                            disabled={!preSeasonDiscountExists}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </div>
                    </Tooltip>
                    <div className={classes.fieldContainer}>
                      <Field
                        name={clientCommercialFieldName}
                        label="Représentant"
                        component={Select}
                        fullWidth
                      >
                        {optionsCreator(listsOptions, "commercials")}
                      </Field>
                    </div>

                    <div className={classes.fieldContainer}>
                      <div className={classes.numberFieldContainer}>
                        <Field
                          name={clientCommercialRateFieldName}
                          component={TextField}
                          label="commission"
                          type="number"
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            {editable && userRole !== "client" && userRole !== "group" && (
              <>
                <div className={classes.fieldContainer}>
                  <Field
                    name={orderOriginFieldName}
                    label="Origine commande"
                    component={Select}
                    fullWidth
                  >
                    {optionsCreator(listsOptions, "ordersOrigins")}
                  </Field>
                </div>
                {/* à ajouter*/}
                <div className={classes.fieldContainer}>
                  <Field
                    name={orderTypeFieldName}
                    label="Type de commande"
                    component={Select}
                    fullWidth
                  >
                    {optionsCreator(listsOptions, "orderTypes")}
                  </Field>
                </div>
              </>
            )}
            {editable && userRole === "master" && (
              <div className={classes.fieldContainer}>
                <Field
                  name={carrierFieldName}
                  label="Transporteur"
                  component={Select}
                  fullWidth
                >
                  {optionsCreator(listsOptions, "carrier")}
                </Field>
              </div>
            )}
            {
              editable && userRole !== "master" && (
                <div className={classes.fieldContainer} />
              ) /* mandatory for display purpose */
            }
            {editable && sourceShippings.length > 1 && (
              <div className={classes.actionContainer}>
                <IconButton
                  aria-label="Delete"
                  onClick={() => deleteShippingFromState(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
          </div>
          {editable &&
            userRole === "commercial" &&
            shipping.originalClientDiscount.toString() !==
              shipping.forcedClientDiscount.toString() && (
              <Typography variant="caption">
                Le taux de remise client choisi (
                {shipping.forcedClientDiscount.toString()} %) est différent de
                celui défini en base (
                {shipping.originalClientDiscount.toString()} %). En cas de
                différence une demande est automatiquement faite pour que cette
                commande soit acceptée.
              </Typography>
            )}
        </div>
      );
    });
    if (editable) {
      return <div>{shippingsToReturn}</div>;
    } else {
      return (
        <div className={classes.tableContentWrapper}>{shippingsToReturn}</div>
      );
    }
  };

  // const buildContactsOptions = async client => {
  //   /* internal function */
  //   const getContactsOptions = async clientId => {
  //     // we have to get contacts from clients in group, not just current client
  //     const client = await new Parse.Query("Clients")
  //       .equalTo("CLKTCODE", clientId)
  //       .first();
  //     const group = await new Parse.Query("Groups")
  //       .select('clients')
  //       .equalTo("clients", client)
  //       .first();
  //     let contacts = null;
  //     if (group !== undefined) {
  //       contacts = await new Parse.Query("Contacts")
  //         .containedIn("CLKTCODE", group.get('clients'))
  //         .ascending("CLCTCONTA1")
  //         .find();
  //     } else {
  //       contacts = await new Parse.Query("Contacts")
  //         .equalTo("CLKTCODE", client)
  //         .ascending("CLCTCONTA1")
  //         .find();
  //     }
  //     return contacts.map(contact => {
  //       const emailPart = (contact.get('email') !== undefined) ? " (" + contact.get('email') + ")" : "";
  //       const text = (contact.get('firstname') !== undefined && contact.get('lastname') !== undefined) ?
  //         contact.get('firstname') + " " + contact.get('lastname') + emailPart
  //         :
  //         contact.get('CLCTCONTA1') + emailPart;
  //       return ({
  //         value: contact.id,
  //         text,
  //       })
  //     });
  //   };
  //
  //   const newObjectOfContactOptions = {};
  //   if (client.type === 'group') {
  //     await Promise.all(client.dbObj.clients.map(async client => {
  //       const clientId = client.get('CLKTCODE');
  //       newObjectOfContactOptions[clientId] = await getContactsOptions(clientId);
  //     }))
  //   } else {
  //     const clientId = client.dbObj.CLKTCODE;
  //     newObjectOfContactOptions[clientId] = await getContactsOptions(clientId);
  //   }
  //   await dispatch( setObjectOfContactOptions(newObjectOfContactOptions));
  // };

  /********************************************************/
  /************************* dialog ***********************/
  /********************************************************/

  // when dialog is called
  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  // when dialog is confirmed
  const handleModalValidate = () => {
    document
      .getElementById("shippingForm")
      .dispatchEvent(new Event("submit", { cancelable: true }));

    handleModalClose("updated");
  };

  // when closing dialog box
  const handleModalClose = (action) => {
    if (action === "updated") {
      handleSnackBarShow("cadence(s) mise(s) à jour");
    } else {
      setStateShippings(shippings);
    }
    setDialogOpen(false);
  };

  const handleAddContactClickOpen = (clientId) => {
    setClientIdForAddContactDialog(clientId);
    setAddContactDialogOpen(true);
  };

  const handleAddContactFormSubmit = () => {
    document
      .getElementById("contactForm")
      .dispatchEvent(new Event("submit", { cancelable: true }));
  };

  const handleAddContactModalClose = async (action) => {
    if (action === "contactAdded") {
      await dispatch(
        setObjectOfContactOptions(await buildContactsOptions(client))
      );
      handleSnackBarShow("contact ajouté");
    }
    setAddContactDialogOpen(false);
  };

  /********************************************************/
  /************************ SnackBar **********************/
  /********************************************************/

  const handleSnackBarShow = (textValue) => {
    setSnackBarOpen(true);
    setSnackBarText(textValue);
  };

  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };
  if (shippings === undefined) {
    return null;
  }

  return (
    <>
      {renderShippings("store", "notEditable")}
      {(activeStep === 0 || activeStep === 1) && (
        <div className={classes.buttonContainer}>
          <Button color="primary" onClick={handleClickOpen}>
            Modifier
          </Button>
        </div>
      )}

      <Dialog
        open={dialogOpen}
        onClose={() => handleModalClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={userRole !== "client" && userRole !== "group" ? "xl" : "md"}
      >
        <Form
          onSubmit={onSubmit}
          initialValues={shippingsToFormValues(stateShippings)}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form id="shippingForm" onSubmit={handleSubmit}>
              <div className={classes.tableTopWrapper}>
                <DialogTitle id="alert-dialog-title">
                  Définition/Modification de cadence
                </DialogTitle>
                <div className={classes.addBtnWrapper}>
                  <Fab
                    size="small"
                    color="primary"
                    aria-label="Add"
                    className={classes.fab}
                  >
                    <AddIcon onClick={() => addShipping("state", values)} />
                  </Fab>
                </div>
              </div>
              <DialogContent>
                {userRole === "master" && (
                  <Typography variant="body2" className={classes.dialogNote}>
                    <i>Note</i>: Les champs "remise client", "remise présaison"
                    et "commission" permettent de forcer une valeur
                    particulière. Laisser vide pour que la valeur déclarée dans
                    le système s'applique.
                  </Typography>
                )}
                {/*<FormSpy subscription={{ values: true }} component={AutoSave} onFormChange={onFormSpyChange} />*/}
                {renderShippings("state", "editable")}
              </DialogContent>
            </form>
          )}
        />
        <DialogActions>
          <Button onClick={() => handleModalValidate()} color="primary">
            Enregistrer
          </Button>
          <Button onClick={() => handleModalClose()} color="primary" autoFocus>
            Annuler
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={addContactDialogOpen}
        onClose={() => setAddContactDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Ajout d'un contact</DialogTitle>
        <DialogContent>
          <HandleContactForm
            defaultClientId={[clientIdForAddContactDialog]}
            onHandleModalClose={handleAddContactModalClose}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddContactFormSubmit} color="primary">
            ajouter
          </Button>
          <Button
            onClick={() => setAddContactDialogOpen(false)}
            color="primary"
            autoFocus
          >
            annuler
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={alertSnackBarOpen}
        autoHideDuration={30000}
        onClose={() => {
          setAlertSnackBarOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setAlertSnackBarOpen(false);
          }}
          severity="warning"
        >
          Suite à la modification de la date de cadence les quantités commandées
          sont partiellement ou totalement indisponibles. Cela signifie que la
          date de livraison demandée n'est pas garantie.
          <br />
          Vous avez 2 possibilités:
          <ul>
            <li>
              modifier la date de livraison pour une date ultérieure en vous
              aidant de la courbe d'évolution des stocks(bouton graph)
            </li>
            <li>
              passer commande en conservant la ou les dates de cadence. Nous
              reviendrons vers vous dans ce cas.
            </li>
          </ul>
        </Alert>
      </Snackbar>

      <Snackbar
        open={alertBadDeliveryDateSnackBarOpen}
        autoHideDuration={10000}
        onClose={() => {
          setAlertBadDeliveryDateSnackBarOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setAlertBadDeliveryDateSnackBarOpen(false);
          }}
          severity="warning"
        >
          Une de vos dates de livraison de cadence était trop proche. Nous
          l'avons modifié à la date de livraison la plus proche possible.
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
        open={snackBarOpen}
        onClose={handleSnackBarClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackBarText}</span>}
      />
    </>
  );
};

export default Shippings;
