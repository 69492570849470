import React, { PureComponent } from "react";
import { Router, Route, IndexRoute } from "react-router";
import { updateLists } from "./actions/main";
import App from "./App";
import Clients from "./containers/pages/Clients/Clients";
import Contact from "./containers/pages/Support/Contact";
import Contacts from "./containers/pages/Contacts";
import ClockedCommand from "./containers/pages/Orders/ClockedOrder";
import Discount from "./containers/pages/Discount";
import Groups from "./containers/pages/Clients/Groups";
import Home from "./containers/pages/Home";
import Login from "./containers/pages/Login";
import Lists from "./containers/pages/Lists";
import OrderHistory from "./containers/pages/Orders/History";
import Products from "./containers/pages/Products/Products";
import Profile from "./containers/pages/Profile";
import Restocking from "./containers/pages/Orders/Restocking";
import Resupplies from "./containers/pages/Products/Resupplies";
import SpareParts from "./containers/pages/Products/SpareParts";
import Ticket from "./containers/pages/Support/Ticket";
import TicketHistory from "./containers/pages/Support/History";
import Trademarks from "./containers/pages/Clients/Tradenames";
import Users from "./containers/pages/Users";
import PdfOrder from "./components/order/PdfOrder";

export default class Routes extends PureComponent {
  render() {
    const { history, store } = this.props;

    return (
      <Router history={history}>
        <Route path="/" component={App} onEnter={updateLists(store)}>
          <IndexRoute component={Home} />
          <Route
            path="/magasins"
            component={Clients}
            onEnter={updateLists(store)}
          />
          <Route
            path="/commande"
            component={ClockedCommand}
            onEnter={updateLists(store)}
          />
          <Route
            path="/commandes"
            component={OrderHistory}
            onEnter={updateLists(store)}
          />
          <Route path="/commande_pdf" component={PdfOrder} />
          <Route path="/contact" component={Contact} />
          <Route
            path="/contacts"
            component={Contacts}
            onEnter={updateLists(store)}
          />
          <Route path="/enseignes" component={Trademarks} />
          <Route path="/groupements" component={Groups} />
          <Route path="/listes" component={Lists} />
          <Route path="/login" component={Login} />
          <Route path="/pieces_detachees" component={SpareParts} />
          <Route
            path="/produits"
            component={Products}
            onEnter={updateLists(store)}
          />
          <Route path="/profile" component={Profile} />
          <Route
            path="/reassort"
            component={Restocking}
            onEnter={updateLists(store)}
          />
          <Route
            path="/remises"
            component={Discount}
            onEnter={updateLists(store)}
          />
          <Route
            path="/reappro"
            component={Resupplies}
            onEnter={updateLists(store)}
          />
          <Route path="/ticket" component={Ticket} />
          <Route path="/tickets" component={TicketHistory} />
          <Route
            path="/utilisateurs"
            component={Users}
            onEnter={updateLists(store)}
          />
        </Route>
      </Router>
    );
  }
}
