import React from "react";
import diff from "object-diff";
/*
Here is how to call it
<FormSpy subscription={{ values: true }} component={AutoSave} />
*/
class AutoSave extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: props.values,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { debounce = 0 } = this.props;

    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(this.save, debounce);
  }

  save = () => {
    const { values, onFormChange } = this.props;

    // This diff step is totally optional
    const difference = diff(this.state.values, values);
    if (Object.keys(difference).length) {
      // values have changed
      this.setState({ values });
      if (onFormChange !== undefined) {
        onFormChange(values);
      }
    }
  };

  render() {
    // return (
    //   <pre>{JSON.stringify(this.state, null, 2) }</pre>
    // )
    return null;
  }
}

export default AutoSave;
