"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fixStockJ0 = void 0;
var getArticlesMinStock_1 = require("../../../../utils/generateSuppliesData/getArticlesMinStock");
function fixStockJ0(product, supplies) {
    return Object.entries(getArticlesMinStock_1.getArticlesMinStock(supplies)).reduce(function (sum, _a) {
        var articleId = _a[0], minStock = _a[1];
        if (articleId) {
            var article = product.articles.find(function (article) { return article.ARKTCOMART === articleId; });
            return (sum +
                minStock -
                (article.webSecurityStock
                    ? minStock < article.webSecurityStock
                        ? minStock
                        : article.webSecurityStock
                    : 0));
        }
        else
            return (sum +
                minStock -
                (product.webSecurityStock
                    ? minStock < product.webSecurityStock
                        ? minStock
                        : product.webSecurityStock
                    : 0));
    }, 0);
}
exports.fixStockJ0 = fixStockJ0;
