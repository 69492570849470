import React, { PureComponent } from "react";
import Parse from "parse";
import HandleContactForm from "../../containers/form/HandleContactForm";
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Snackbar,
} from "@material-ui/core";
import DataGridView from "../../components/reactgrid/DataGridView";
import { debounce } from "lodash";
import { setNumberColumns, filtersQuery } from "../../utils2";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

const styles = (theme) => ({
  tableTopWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "",
  },
  tableTitleWrapper: {
    display: "flex",
    alignItems: "center",
    minHeight: "64px",
    padding: "0 8px 0 24px",
  },
  addBtnWrapper: {
    display: "flex",
    alignItems: "center",
    padding: "0 24px",
  },
});

class Contacts extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      snackBarOpen: false,
      dialogOpen: false,
      columns: [
        { name: "CLCTCONTA1", title: "Nom dans PMI" },
        { name: "firstname", title: "Prénom" },
        { name: "lastname", title: "Nom" },
        { name: "email", title: "Email" },
        { name: "phone", title: "Télephone" },
        { name: "mobile", title: "Portable" },
        { name: "fax", title: "Fax" },
        { name: "CLKTCODE", title: "Magasin(s)" },
      ],
      columnExtensions: [
        { columnName: "CLCTCONTA1" },
        { columnName: "firstname" },
        { columnName: "lastname" },
        { columnName: "email", width: 220 },
        { columnName: "phone" },
        { columnName: "mobile" },
        { columnName: "fax" },
        { columnName: "CLKTCODE", wordWrapEnabled: true },
      ],
      filters: [],
      sorting: [
        { columnName: "firstname", direction: "asc" },
        { columnName: "lastname", direction: "asc" },
      ],
      totalCount: 0,
      pageSize: 15, //default count per page
      pageSizes: [15, 25, 50, 100, 150],
      currentPage: 0,
      loading: true,
    };
    this.changeFilters = this.changeFilters.bind(this);
    this.changeSorting = this.changeSorting.bind(this);
    this.changeCurrentPage = this.changeCurrentPage.bind(this);
  }

  componentDidMount() {
    setNumberColumns.call(this);
    this.getContacts();
  }
  componentDidUpdate() {
    this.getContactsDebounced();
  }

  /********************************************************/
  /*********************** Table data *********************/
  /********************************************************/
  changeFilters(filters) {
    this.setState({
      loading: true,
      filters,
    });
  }
  changeSorting(sorting) {
    this.setState({
      loading: true,
      sorting,
    });
  }
  changeCurrentPage(currentPage) {
    this.setState({
      loading: true,
      currentPage,
    });
  }

  //---- change page size ----//
  changePageSize = (pageSize) => this.setState({ pageSize });

  //---------------------------------------//
  //---- content info for paging panel ----//
  //---------------------------------------//
  _contentInfo = ({ from, to, count }) => {
    return `${from} ${from < to ? ` à ${to}` : ""} sur ${count} contact(s)`;
  };
  // getting contacts from mongo
  getContacts = async (forceReload = false) => {
    const {
      columns,
      filters,
      sorting,
      pageSize,
      currentPage,
      lastTableState,
      lastFilterSortingState,
    } = this.state;
    const { clientCode } = this.props;
    const filterState = JSON.stringify(filters);
    const sortingState = JSON.stringify(sorting);
    const tableState = filterState + sortingState + pageSize + currentPage;
    const filterSortingState = filterState + sortingState;

    if (lastTableState === tableState && !forceReload) {
      // filters didn't change
      this.setState({ loading: false });
      return;
    }
    // if filters or sorting changed we go back to first page
    if (lastFilterSortingState !== filterSortingState) {
      this.setState({ currentPage: 0 });
    }

    const query = await new Parse.Query("Contacts")
      .select(
        "CLCTCONTA1",
        "firstname",
        "lastname",
        "email",
        "phone",
        "mobile",
        "fax",
        "CLKTCODE",
      )
      .include("CLKTCODE");
    if (clientCode !== undefined) {
      const client = await new Parse.Query("Clients")
        .equalTo("CLKTCODE", clientCode)
        .first();
      query.equalTo("CLKTCODE", client);
    }
    filtersQuery.call(this, query);
    const totalCount = await query.count();

    // skipping according to currentPage and pageSize
    query.skip(pageSize * currentPage).limit(pageSize);

    const contacts = await query.find();
    const rows = contacts.map((contact) => {
      const row = {
        id: contact.id,
      };
      columns.forEach((column) => {
        let value = contact.get(column.name);

        // formatting includedClients from array to string with ,
        if (column.name === "CLKTCODE") {
          value = value.map((entry, index) => {
            const textToDisplay = entry.get("CLCTNOM");
            return value.length - 1 === index
              ? textToDisplay
              : textToDisplay + ", ";
          });
        }
        row[column.name] = value;
      });
      return row;
    });

    this.setState({
      rows,
      totalCount,
      lastFilterSortingState: filterSortingState,
      lastTableState: tableState,
    });
  };

  getContactsDebounced = debounce(this.getContacts, 300);

  // when dialog is called
  handleClickOpen = (row, operation) => {
    switch (operation) {
      case "addContact":
        this.setState({
          dialogTitle: "Création d'un contact",
          dialogText: "",
          dialogYesBtnText: "ajouter",
          dialogNoBtnText: "annuler",
        });
        break;
      case "modifyContact":
        this.setState({
          dialogTitle: "Modification d'un contact",
          dialogText: "",
          dialogYesBtnText: "modifier",
          dialogNoBtnText: "annuler",
        });
        break;
      case "deleteContact":
        this.setState({
          dialogTitle: "Suppression d'un contact",
          dialogText:
            "Voulez-vous vraiment supprimer le contact " +
            row.firstname +
            " " +
            row.lastname +
            " (" +
            row.email +
            ") ?",
          dialogYesBtnText: "oui",
          dialogNoBtnText: "non",
        });
        break;
      default:
    }

    this.setState({
      dialogOpen: true,
      dialogOperation: operation,
      contactId: operation !== "addContact" ? row.id : undefined,
    });
  };

  // when closing dialog box
  handleModalClose = async (action) => {
    switch (action) {
      case "contactAdded":
        this.handleSnackBarShow("contact créé");
        break;
      case "contactUpdated":
        this.handleSnackBarShow("contact mis à jour");
        break;
      case "contactDeleted":
        this.handleSnackBarShow("contact supprimé");
        break;
      default:
    }
    // we always reload contacts on modal close
    await this.getContacts(true); // true to force reload
    this.setState({ dialogOpen: false });
  };

  handleModalValidate = async (contactId, operation) => {
    switch (operation) {
      case "deleteContact":
        const contact = await new Parse.Query("Contacts").get(contactId);
        try {
          await contact.destroy();
          this.handleModalClose("contactDeleted");
        } catch (error) {
          console.log("Error: " + error.code + " " + error.message);
        }
        break;
      default:
        // for add && modify
        document
          .getElementById("contactForm")
          .dispatchEvent(new Event("submit", { cancelable: true }));
    }
  };

  handleSnackBarShow = (textValue) => {
    this.setState({ snackBarOpen: true, snackBarText: textValue });
  };

  handleSnackBarClose = () => {
    this.setState({ snackBarOpen: false });
  };

  render() {
    const {
      dialogOperation,
      contactId,
      rows,
      dialogOpen,
      dialogTitle,
      dialogText,
      dialogYesBtnText,
      dialogNoBtnText,
      snackBarOpen,
      snackBarText,
      columns,
      columnExtensions,
      currentPage,
      pageSize,
      pageSizes,
      totalCount,
      loading,
      filters,
      sorting,
      numberColumns,
    } = this.state;
    const { clientCode } = this.props;

    if (!rows) {
      return null;
    }

    const actions = [
      {
        icon: <EditIcon />,
        action: (rowData) => this.handleClickOpen(rowData.row, "modifyContact"),
      },
      {
        icon: <DeleteIcon />,
        action: (rowData) => this.handleClickOpen(rowData.row, "deleteContact"),
      },
    ];

    return (
      <>
        <DataGridView
          title={
            clientCode !== undefined
              ? "Liste des contacts du client"
              : "Liste des contacts"
          }
          withAddButton
          onAddButtonClick={() => this.handleClickOpen(null, "addContact")}
          rowsValues={rows}
          columnHeaders={columns}
          currentPage={currentPage}
          onCurrentPageChange={this.changeCurrentPage}
          pageSize={pageSize}
          pageSizes={pageSizes}
          onPageSizeChange={this.changePageSize}
          filters={filters}
          onFiltersChange={this.changeFilters}
          sorting={sorting}
          onSortingChange={this.changeSorting}
          totalCount={totalCount}
          loading={loading}
          numberColumns={numberColumns}
          rowsPerPage="Nb contacts par page:"
          contentInfoSupplier={this._contentInfo}
          columnExtensions={columnExtensions}
          withPagination={true}
          hasActions={true}
          actions={actions}
        />

        <Dialog
          open={dialogOpen}
          onClose={() => this.handleModalClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogText}
            </DialogContentText>
            {dialogOperation !== "deleteContact" && (
              <HandleContactForm
                contactId={contactId}
                onHandleModalClose={this.handleModalClose}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                this.handleModalValidate(contactId, dialogOperation)
              }
              color="primary"
            >
              {dialogYesBtnText}
            </Button>
            <Button
              onClick={() => this.handleModalClose()}
              color="primary"
              autoFocus
            >
              {dialogNoBtnText}
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          autoHideDuration={3000}
          open={snackBarOpen}
          onClose={this.handleSnackBarClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{snackBarText}</span>}
        />
      </>
    );
  }
}
export default withStyles(styles)(Contacts);
