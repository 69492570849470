import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Form, Field, FormSpy } from "react-final-form";
import { Select, TextField } from "final-form-material-ui";
import Switch from "../../components/form/Switch";
import { withStyles, Grid, Typography } from "@material-ui/core";
import Parse from "parse";
import AutoSave from "../../components/form/AutoSave";
import { getLists } from "../../reducers/lists";
import { optionsCreator } from "../../utils2";

const styles = () => ({
  gridContainer: {
    width: 800,
  },
  textField: {
    width: "100%",
  },
  selectField: {
    width: "100%",
  },
  fieldContainer: {
    display: "flex",
    flex: 1,
  },
  gridLine: {
    padding: "8px 0",
    lineHeight: 2,
  },
  buttonContainer: {
    alignSelf: "flex-end",
  },
  category: {
    margin: "3rem 0 1rem 0",
  },
  errorMessage: {
    color: "red",
  },
  switchText: {
    lineHeight: "48px",
  },
});

class ProductArticleForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      productType: null,
      articleData: {},
    };
  }

  componentDidMount() {
    this.getArticleData();
  }

  // getting article from mongo
  getArticleData = async () => {
    const { productCode, operation, articleId } = this.props;

    const product = await new Parse.Query("Products")
      .equalTo("ARKTCODART", productCode)
      .first();
    const productType =
      product.get("productType") !== undefined
        ? product.get("productType")
        : null;

    if (operation === "modify" || operation === "view") {
      const articles = product.get("articles");
      let articleData = {};
      articles.forEach((article) => {
        if (article.ARKTCOMART === articleId) {
          articleData = article;
        }
      });

      // getting isVisibleInRessuplySection value
      let articleVisibilityIsAlreadyDefined = false;
      const resupplies = product.get("resupplies");
      if (resupplies !== undefined) {
        resupplies.forEach((article) => {
          if (
            article.ARKTCOMART === articleId &&
            article.isVisibleInRessuplySection !== undefined
          ) {
            articleVisibilityIsAlreadyDefined = true;
            articleData.isVisibleInRessuplySection =
              article.isVisibleInRessuplySection;
          }
        });
      }

      this.setState({
        articleVisibilityIsAlreadyDefined,
        productType,
        articleData,
        pricesLinkedToProduct: articleData.pricesLinkedToProduct,
      });
    } else {
      this.setState({
        articleVisibilityIsAlreadyDefined: false,
        productType,
        articleData: {
          ARITCONART: null,
        },
      });
    }
  };

  completeWithChar = (string, char, finalLength) => {
    let newString = string.toString();
    const initialLength = newString !== undefined ? newString.length : 0;
    for (let i = initialLength; i < finalLength; i++) {
      newString += char;
    }
    return newString;
  };

  onSubmit = async (values) => {
    console.log("values: ", values);
    const { productCode, articleId } = this.props;
    const product = await new Parse.Query("Products")
      .equalTo("ARKTCODART", productCode)
      .first();

    let articles = product.get("articles");
    const newArticle = {};
    Object.entries(values).forEach(([key, value]) => {
      // treating visibility of Article in ressuply section
      if (key === "isVisibleInRessuplySection") {
        let resupplies = product.get("resupplies");
        resupplies.forEach((article) => {
          if (article.ARKTCOMART === articleId) {
            article.isVisibleInRessuplySection = value;
          }
        });
      } else {
        newArticle[key] = value;
      }
      // in case we want to add a generic, we leave empty and just add an EAN13
      if (newArticle.ARKTCOMART === undefined) {
        newArticle.ARKTCOMART = "";
      }
      newArticle.ARITCONART = (
        this.completeWithChar(productCode, " ", 18) + newArticle.ARKTCOMART
      ).trim();
    });

    // updating article
    let articleFound = false;
    articles.forEach((article, index) => {
      if (article.ARKTCOMART === articleId) {
        articleFound = true;
        articles[index] = newArticle;
      }
    });
    // if it's a creation we didn't find it yet. Let's push it
    if (!articleFound) {
      articles.push(newArticle);
    }
    product.set("articles", articles);

    await product.save();
    await this.getArticleData();
  };

  // used by FormSpy
  _updateStatePricesLinkedToProduct = (values) => {
    this.setState({
      pricesLinkedToProduct: values.pricesLinkedToProduct,
    });
  };

  render() {
    const {
      articleVisibilityIsAlreadyDefined,
      productType,
      articleData,
      pricesLinkedToProduct,
    } = this.state;
    const { classes, operation, listsOptions } = this.props;
    const initialValues = operation === "add" ? {} : articleData;
    const sizeDisabled = productType === null;
    let typeOfProduct = "general";
    if (productType === "stick") typeOfProduct = "stick";
    if (productType === "textile") typeOfProduct = "textile";

    return (
      <Form
        onSubmit={this.onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form id="productArticleForm" onSubmit={handleSubmit}>
            <Typography variant="subtitle2" className={classes.category}>
              Informations générales
            </Typography>

            {articleVisibilityIsAlreadyDefined && (
              <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={6}>
                  <div className={classes.fieldContainer}>
                    <Field
                      name="isVisibleInRessuplySection"
                      component={Switch}
                      type="checkbox"
                      disabled={operation === "view"}
                    />
                    <Typography variant="body2" className={classes.switchText}>
                      Article visible dans Réappro
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            )}

            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={4}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="ARKTCOMART"
                    component={TextField}
                    placeholder="Code complémentaire"
                    label="Code complémentaire"
                    className={classes.textField}
                    required
                    disabled={operation === "view"}
                  />
                </div>
              </Grid>
              <Grid item xs={8}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="ARCTLIB02"
                    component={TextField}
                    type="text"
                    placeholder="Libellé complémentaire"
                    label="Libellé complémentaire"
                    className={classes.textField}
                    required
                    disabled={operation === "view"}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={6}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="size"
                    label="Taille"
                    component={Select}
                    className={classes.selectField}
                    disabled={sizeDisabled || operation === "view"}
                  >
                    {typeOfProduct === "stick" &&
                      optionsCreator(listsOptions, "stickSizes")}
                    {typeOfProduct === "textile" &&
                      optionsCreator(listsOptions, "textileSizes")}
                    {typeOfProduct === "general" &&
                      optionsCreator(listsOptions, "generalSizes")}
                  </Field>
                </div>
                {sizeDisabled && (
                  <Typography
                    variant="caption"
                    gutterBottom
                    className={classes.errorMessage}
                  >
                    Choisissez le type de produit (onglet informations) pour
                    pouvoir préciser la taille de l'article
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="color"
                    label="Couleur"
                    component={Select}
                    className={classes.selectField}
                    disabled={operation === "view"}
                  >
                    {optionsCreator(listsOptions, "color")}
                  </Field>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={12}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="ARCTEAN13"
                    component={TextField}
                    type="number"
                    placeholder="Code EAN"
                    label="Code EAN"
                    className={classes.textField}
                    required
                    disabled={operation === "view"}
                  />
                </div>
              </Grid>
            </Grid>
            <Typography variant="subtitle2" className={classes.category}>
              Prix
            </Typography>

            <FormSpy
              subscription={{ values: true }}
              component={AutoSave}
              onFormChange={this._updateStatePricesLinkedToProduct}
            />
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={12}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="pricesLinkedToProduct"
                    component={Switch}
                    type="checkbox"
                    disabled={operation === "view"}
                  />
                  <Typography variant="body2" className={classes.switchText}>
                    lier les prix au produit (onglet Prix)
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="ARCNTARIF9"
                    component={TextField}
                    type="number"
                    placeholder="Prix public TTC (€) (éditable dans PMI)"
                    label="Prix public TTC (€) (éditable dans PMI)"
                    disabled
                    className={classes.textField}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="ARCNTARIF8"
                    component={TextField}
                    type="number"
                    placeholder="Prix de vente conseillé actuel TTC (€)"
                    label="Prix de vente conseillé actuel TTC (€)"
                    className={classes.textField}
                    disabled={pricesLinkedToProduct || operation === "view"}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={6}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="ARCNTARIF1"
                    component={TextField}
                    type="number"
                    placeholder="Prix catalogue revendeur HT (€) (éditable dans PMI)"
                    label="Prix catalogue revendeur HT (€) (éditable dans PMI)"
                    className={classes.textField}
                    disabled
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="ARCNTARIF2"
                    component={TextField}
                    type="number"
                    placeholder="Prix cata. revendeur actuel HT (€)"
                    label="Prix cata. revendeur actuel HT (€)"
                    className={classes.textField}
                    disabled={pricesLinkedToProduct || operation === "view"}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={6}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="ARCNTARIF6"
                    component={TextField}
                    type="number"
                    placeholder="Coût de revient net HT (€)"
                    label="Coût de revient net HT (€)"
                    className={classes.textField}
                    disabled={pricesLinkedToProduct || operation === "view"}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="ARCNTARIF7"
                    component={TextField}
                    type="number"
                    placeholder="Coût de revient brut HT (€)"
                    label="Coût de revient brut HT (€)"
                    className={classes.textField}
                    disabled={pricesLinkedToProduct || operation === "view"}
                  />
                </div>
              </Grid>
            </Grid>
            <Typography variant="subtitle2" className={classes.category}>
              Dimensions produit
            </Typography>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={12}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="netWeight"
                    component={TextField}
                    type="number"
                    placeholder="Poids unitaire net (g)"
                    label="Poids unitaire net (g)"
                    className={classes.textField}
                    required
                    disabled={operation === "view"}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={6}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="minWidth"
                    component={TextField}
                    type="number"
                    placeholder="Longueur mini (cm)"
                    label="Longueur mini (cm)"
                    className={classes.textField}
                    required
                    disabled={operation === "view"}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="maxWidth"
                    component={TextField}
                    type="number"
                    placeholder="Longueur maxi (cm)"
                    label="Longueur maxi (cm)"
                    className={classes.textField}
                    required
                    disabled={operation === "view"}
                  />
                </div>
              </Grid>
            </Grid>
            <Typography variant="subtitle2" className={classes.category}>
              Dimensions expédition
            </Typography>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Poids unitaire brut (g): {articleData.ARCNPDSUNI}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  Longueur colis (cm): {articleData.ARCNLONGUE}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  Largeur colis (cm): {articleData.ARCNLARGEU}
                </Typography>
              </Grid>
            </Grid>
          </form>
        )}
      />
    );
  }
}
export default connect(
  (state) => ({
    listsOptions: getLists(state),
  }),
  {},
)(withStyles(styles)(ProductArticleForm));
