import React, { PureComponent } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Field } from "react-final-form";

class AutoComplete extends PureComponent {
  render() {
    const { name, label, options, validate, onACChange } = this.props;

    return (
      <Field name={name} validate={validate}>
        {(props) => (
          <Autocomplete
            options={options}
            getOptionLabel={(option) => option.text}
            onChange={(event, newValue) => {
              const value = newValue !== null ? newValue.value : null;
              props.input.onChange(value);
              if (onACChange !== undefined) {
                onACChange();
              }
            }}
            clearText="Effacer"
            style={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label={label} />}
          />
        )}
      </Field>
    );
  }
}
export default AutoComplete;
