import React, { useState, useEffect } from "react";
import moment from "moment";
import Parse from "parse";
import { useSelector } from "react-redux";
import { getLists } from "../../reducers/lists";
import useDebounce from "../../hooks";
import { makeStyles, Paper } from "@material-ui/core";
import DataGridView from "../reactgrid/DataGridView";
import { setNumberColumns } from "../../utils2";

const useStyles = makeStyles(() => ({
  noData: {
    textAlign: "center",
    margin: "10rem 0 0",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    marginTop: "1rem",
  },
}));

const currentYear = moment().format("YYYY");
const yearZero = parseInt(currentYear) - 3;
const yearOne = parseInt(currentYear) - 2;
const yearTwo = parseInt(currentYear) - 1;
const margin0 = "margin_" + yearZero;
const margin1 = "margin_" + yearOne;
const margin2 = "margin_" + yearTwo;
const margin3 = "margin_" + currentYear;
const turnover0 = "turnover_" + yearZero;
const turnover1 = "turnover_" + yearOne;
const turnover2 = "turnover_" + yearTwo;
const turnover3 = "turnover_" + currentYear;

const pageSizes = [5, 10, 15, 25, 50, 100, 150];

const GroupTurnoverOrMargin = (props) => {
  const classes = useStyles();
  const { shopPerimeter, commercialId, dataToShow } = props;
  const listsOptions = useSelector(getLists);
  const [numberColumns, setStateNumberColumns] = useState([]);
  const [filters, setFilters] = useState([]);
  const debouncedFilters = useDebounce(filters, 500);
  const initialSorting =
    dataToShow === "margin"
      ? [{ columnName: margin3, direction: "desc" }]
      : [{ columnName: turnover3, direction: "desc" }];
  const [sorting, setSorting] = useState(initialSorting);
  const columns =
    dataToShow === "margin"
      ? [
          { name: "groupId", title: "Réf.", type: "string" },
          { name: "name", title: "Nom", type: "string" },
          { name: margin3, title: currentYear, type: "numeric" },
          { name: margin2, title: yearTwo, type: "numeric" },
          { name: margin1, title: yearOne, type: "numeric" },
          { name: margin0, title: yearZero, type: "numeric" },
        ]
      : [
          { name: "groupId", title: "Réf.", type: "string" },
          { name: "name", title: "Nom", type: "string" },
          { name: turnover3, title: currentYear, type: "numeric" },
          { name: turnover2, title: yearTwo, type: "numeric" },
          { name: turnover1, title: yearOne, type: "numeric" },
          { name: turnover0, title: yearZero, type: "numeric" },
        ];
  const columnExtensions =
    dataToShow === "margin"
      ? [
          { columnName: "groupId", width: 85 },
          { columnName: "name", wordWrapEnabled: true },
          { columnName: margin3, width: 90, align: "right" },
          { columnName: margin2, width: 90, align: "right" },
          { columnName: margin1, width: 90, align: "right" },
          { columnName: margin0, width: 90, align: "right" },
        ]
      : [
          { columnName: "groupId", width: 85 },
          { columnName: "name", wordWrapEnabled: true },
          { columnName: turnover3, width: 90, align: "right" },
          { columnName: turnover2, width: 90, align: "right" },
          { columnName: turnover1, width: 90, align: "right" },
          { columnName: turnover0, width: 90, align: "right" },
        ];

  const [totalCount, setTotalCount] = useState(100);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [groupsData, setGroupsData] = useState(null);
  const [groupIdToDisplay, setGroupIdToDisplay] = useState(undefined);

  /********************************************************/
  /************ content info for paging panel**************/
  /********************************************************/
  const contentInfo = ({ from, to, count }) => {
    return `${from} ${from < to ? `à ${to}` : ""} sur ${count}`;
  };

  /********************************************************/
  /*********************** Navigation *********************/
  /********************************************************/
  const changeFilters = (filters) => {
    setLoading(true);
    setFilters(filters);
  };
  const changeSorting = (sorting) => {
    setLoading(true);
    setSorting(sorting);
  };
  const changeCurrentPage = (currentPage) => {
    setLoading(true);
    setCurrentPage(currentPage);
  };
  const changePageSize = (pageSize) => setPageSize(pageSize);

  /********************************************************/
  /********************** Table data **********************/
  /********************************************************/
  const getData = async () => {
    // groupIdToDisplay = undefined >>> we display all groups
    // groupIdToDisplay = null >>> we display no group (means filter by shop and shop has no group)
    // groupIdToDisplay = string (groupId) >>> we display the group of this groupId
    let groupIdToDisplay = undefined;
    if (shopPerimeter !== undefined && !!shopPerimeter.length) {
      groupIdToDisplay = null;
      // let's find out if one of groups contains one element of shopPerimeter
      const groupLists = [
        ...listsOptions.filter((lists) => lists.listName.startsWith("z_gp_")),
      ];
      groupLists.forEach((groupList) => {
        let groupIsConcerned = false;
        groupList.options.forEach((option) => {
          if (shopPerimeter.includes(option.value)) {
            groupIsConcerned = true;
          }
        });
        if (groupIsConcerned) {
          groupIdToDisplay = groupList.listName.replace("z_gp_", "");
        }
      });
    }
    if (groupIdToDisplay !== null) {
      // if we have to display a group
      setGroupIdToDisplay(groupIdToDisplay);
      const params =
        dataToShow === "margin"
          ? {
              collectionName: "StatsForGroups",
              skip: pageSize * currentPage,
              limit: pageSize,
              pipeline: [
                { match: {} },
                {
                  group: {
                    objectId: "$groupId",
                    [margin0]: { $sum: "$" + margin0 },
                    [margin1]: { $sum: "$" + margin1 },
                    [margin2]: { $sum: "$" + margin2 },
                    [margin3]: { $sum: "$" + margin3 },
                    name: { $first: "$name" },
                  },
                },
                { sort: { [margin3]: -1 } },
              ],
            }
          : {
              collectionName: "StatsForGroups",
              skip: pageSize * currentPage,
              limit: pageSize,
              pipeline: [
                { match: {} },
                {
                  group: {
                    objectId: "$groupId",
                    [turnover0]: { $sum: "$" + turnover0 },
                    [turnover1]: { $sum: "$" + turnover1 },
                    [turnover2]: { $sum: "$" + turnover2 },
                    [turnover3]: { $sum: "$" + turnover3 },
                    name: { $first: "$name" },
                  },
                },
                { sort: { [turnover3]: -1 } },
              ],
            };

      // completing pipeline according to filters on top of page...
      let paramsMatch = undefined;
      if (groupIdToDisplay !== undefined) {
        paramsMatch = {};
        paramsMatch.groupId = groupIdToDisplay;
      } else if (commercialId !== "ALL") {
        paramsMatch = {};
        paramsMatch.commercialId = commercialId;
      }
      // ... and with filter in list
      if (filters.length) {
        if (paramsMatch === undefined) {
          paramsMatch = {};
        }
        filters.forEach((filter) => {
          paramsMatch[filter.columnName] = {
            $regex: filter.value.replace(/</g, "&lt;").replace(/>/g, "&gt;"),
            $options: "i",
          };
        });
      }
      // adding filters to pipeline
      if (paramsMatch !== undefined) {
        params.pipeline[0].match = paramsMatch;
      }
      // adding sorting to pipeline
      params.pipeline[2].sort = {};
      sorting.forEach((sort) => {
        params.pipeline[2].sort[sort.columnName] =
          sort.direction === "asc" ? 1 : -1;
      });

      const request = await Parse.Cloud.run("aggregateQuery", params);

      setGroupsData(request.result);
      setTotalCount(request.count);
    } else {
      setGroupIdToDisplay(undefined);
      setGroupsData([]);
      setTotalCount(0);
    }
    setLoading(false);
  };

  useEffect(() => {
    setNumberColumns(columns, setStateNumberColumns);
    // eslint-disable-next-line
  }, []);

  // when page or sorting change
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [currentPage, sorting, pageSize, shopPerimeter]);

  // when filters change
  useEffect(() => {
    if (debouncedFilters) {
      getData();
    }
    // eslint-disable-next-line
  }, [debouncedFilters]);

  if (groupsData === null) {
    return null;
  }

  let titleExplanation = undefined;
  if (commercialId !== "ALL") {
    titleExplanation =
      "- " +
      listsOptions
        .find((listOptions) => listOptions.listName === "commercials")
        .options.find((option) => option.value === commercialId).text;
  } else if (groupIdToDisplay !== undefined) {
    titleExplanation =
      "- " +
      listsOptions
        .find((listOptions) => listOptions.listName === "groups")
        .options.find((option) => option.value === groupIdToDisplay).text;
  }

  return (
    <Paper className={classes.paper}>
      <DataGridView
        title={dataToShow === "margin" ? "Marge groupe" : "CA groupe"}
        titleExplanation={titleExplanation}
        rowsValues={groupsData}
        columnHeaders={columns}
        currentPage={currentPage}
        onCurrentPageChange={changeCurrentPage}
        pageSize={pageSize}
        pageSizes={pageSizes}
        onPageSizeChange={changePageSize}
        filters={filters}
        onFiltersChange={changeFilters}
        sorting={sorting}
        onSortingChange={changeSorting}
        totalCount={totalCount}
        loading={loading}
        numberColumns={numberColumns}
        rowsPerPage="par page:"
        contentInfoSupplier={contentInfo}
        columnExtensions={columnExtensions}
        withPagination={true}
        hasActions={false}
        noDataText="pas de donnée sur le(s) groupe(s)"
      />
    </Paper>
  );
};

export default GroupTurnoverOrMargin;
