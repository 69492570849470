import React, { useState, useEffect } from "react";
import Parse from "parse";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../reducers/currentUser";
import {
  makeStyles,
  Grid,
  Button,
  Typography,
  LinearProgress,
  Snackbar,
  Divider,
  Tooltip,
} from "@material-ui/core";
import numberFormatter from "../utils2";

const maxHeightForDisplay = 800;
const maxWidthForDisplay = 1024;
const miniatureConstraints = {
  sizeWarning: 7168, // in bytes (7ko)
  sizeLimit: 10240, // in bytes (10ko)
  width: 56,
  height: 56,
};
const imageConstraints = {
  sizeWarning: 76800, // in bytes (75ko)
  sizeLimit: 153600, // in bytes (150ko)
  maxWidth: 800,
  maxHeight: 800,
};

const useStyles = makeStyles(() => ({
  input: {
    display: "none",
  },
  grid: {
    minHeight: "175px",
  },
  imageWrapper: {
    minHeight: "56px",
    margin: "22px 0",
  },
  image: {
    maxHeight: maxHeightForDisplay,
    maxWidth: maxWidthForDisplay,
  },
  saveButtonWrapper: {
    padding: "0 0 22px",
  },
  chooseButtonWrapper: {
    padding: "11px 0",
  },
  button: {
    margin: "0 22px 0 0",
  },
  imgToUpload: {
    opacity: 0.5,
    transition: "opacity 0.3s ease",
    "&:hover": {
      opacity: 1,
    },
  },
  linearProgressWrapper: {
    minHeight: "4px",
  },
  dimensionsWrapper: {
    display: "flex",
    flex: 1,
    "& p": {
      margin: "0 24px 0 0",
    },
    margin: "0 0 8px",
  },
  errorText: {
    color: "red",
  },
  warningText: {
    color: "orange",
  },
}));

const ImageHandler = (props) => {
  const {
    imageName,
    articleDataInDb,
    productCode,
    imageDisplayName,
    onImageChange,
  } = props;
  const classes = useStyles();
  const currentUser = useSelector(getCurrentUser);
  const userRole =
    currentUser !== undefined ? currentUser.get("role") : undefined;
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarText, setSnackBarText] = useState("");
  const [articleCode, setArticleCode] = useState(null);
  const [imageUrlInDb, setImageUrlInDb] = useState(null);
  const [publicId, setPublicId] = useState(null);
  const [imageHeight, setImageHeight] = useState(0);
  const [imageWidth, setImageWidth] = useState(0);
  const [imageBytes, setImageBytes] = useState(0);
  const [imageIsValid, setImageIsValid] = useState(false);
  const [isDeletable, setIsDeletable] = useState(false);
  const [imageToSend, setImageToSend] = useState(null);
  const [sending, setSending] = useState(false);
  const [imageParams, setImageParams] = useState(null);
  const [imageError, setImageError] = useState({});
  const [imageWarning, setImageWarning] = useState({});

  const showImgInDb = imageUrlInDb !== null && imageToSend === null;
  const buttonDisabled = sending || !imageIsValid ? true : imageToSend === null;
  const inputId = imageName + articleCode + "File";
  const imageKb = imageBytes / 1024;
  const imageMb = imageBytes / 1024 / 1024;
  const imageDisplayedIsShrinked =
    imageHeight > maxHeightForDisplay || imageWidth > maxWidthForDisplay;

  const setImageData = () => {
    const imageIsInDb = articleDataInDb[imageName] !== undefined;
    setArticleCode(articleDataInDb.articleCode);
    setImageUrlInDb(imageIsInDb ? articleDataInDb[imageName].secure_url : null);
    setPublicId(imageIsInDb ? articleDataInDb[imageName].public_id : null);
    setImageHeight(imageIsInDb ? articleDataInDb[imageName].height : 0);
    setImageWidth(imageIsInDb ? articleDataInDb[imageName].width : 0);
    setImageBytes(imageIsInDb ? articleDataInDb[imageName].bytes : 0);
    setIsDeletable(imageIsInDb);
    setImageToSend(null);
    setSending(false);
    setImageParams(null);
    setImageError({});
    setImageWarning({});
  };

  const reloadImage = async () => {
    await onImageChange(); // reload all images
    setImageData();
  };
  // saving to database
  const saveImageToDb = async (data) => {
    let imageArray = [];
    let imgInDb = false;

    const product = await new Parse.Query("Products")
      .equalTo("ARKTCODART", productCode)
      .first();

    const imagesInDb = product.get("images");

    // if we already have images in db we duplicate the data
    if (imagesInDb !== undefined && !!imagesInDb.length) {
      imageArray = imagesInDb.slice();
      // checking if data exists concerning articleCode. If so we update.
      imageArray.forEach((articleData, index) => {
        if (articleData.articleCode === articleCode) {
          imageArray[index][imageName] = data;
          imgInDb = true;
        }
      });
    }

    // if no image in db we create the array
    if (!imgInDb) {
      imageArray.push({
        articleCode,
        [imageName]: data,
      });
    }

    product.set("images", imageArray);
    await product.save();
  };

  const deleteImage = async () => {
    const product = await new Parse.Query("Products")
      .equalTo("ARKTCODART", productCode)
      .first();
    const imagesInDb = product.get("images");

    if (imagesInDb !== undefined && !!imagesInDb.length) {
      // selecting articleCode
      imagesInDb.forEach((articleData) => {
        if (articleData.articleCode === articleCode) {
          // deleting image locally
          delete articleData[imageName];
        }
      });
      product.set("images", imagesInDb);
      // deleting image in db
      await product.save();

      // deleting image from host
      const params = { publicId };
      await Parse.Cloud.run("deleteProductImage", params);

      await reloadImage();
      handleSnackBarShow(
        imageDisplayName + " du produit " + productCode + " supprimée",
      );
    } else {
      // if not found
      return false;
    }
  };

  const fileSelectedHandler = (event) => {
    const reader = new FileReader();

    // image preview
    reader.onload = (event) => {
      const imageObj = new Image();
      imageObj.src = event.target.result;
      // getting img dimensions
      imageObj.onload = async () => {
        await setImageHeight(imageObj.height);
        await setImageWidth(imageObj.width);
      };
      setImageToSend(event.target.result);
    };

    // setting image data in state
    reader.onloadend = () => {
      setImageParams({
        name: imageName,
        product: productCode,
        article: articleCode,
        tag: productCode + "-" + articleCode,
        publicId,
        file: reader.result,
      });
    };
    // condition because if you select one image, validate, then reselect another and cancel you'll get an error
    const file = event.target.files[0];
    if (file !== undefined) {
      setImageBytes(file.size);
      reader.readAsDataURL(file);
    }
  };

  const fileUploadHandler = async () => {
    setSending(true);
    const query = await Parse.Cloud.run("uploadProductImage", imageParams);
    // saving images data to db
    await saveImageToDb(query.result);
    // reseting
    await reloadImage();
    handleSnackBarShow(
      imageDisplayName + " du produit " + productCode + " mise à jour",
    );
  };

  /********************************************************/
  /************************ SnackBar **********************/
  /********************************************************/

  const handleSnackBarShow = (textValue) => {
    setSnackBarOpen(true);
    setSnackBarText(textValue);
  };

  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };
  /********************************************************/

  const isEmpty = (obj) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  };

  const checkImageValidity = () => {
    //console.log('---------------------');
    let newImageError = {};
    let newImageWarning = {};
    const constraints =
      imageName === "miniature" ? miniatureConstraints : imageConstraints;

    //------------ WEIGHT ------------//
    if (imageBytes > constraints.sizeLimit) {
      newImageError.size =
        "l'image est trop lourde. Poids max: " +
        numberFormatter({ value: constraints.sizeLimit / 1024 }) +
        " Ko";
    } else if (imageBytes > constraints.sizeWarning) {
      newImageWarning.size =
        "l'image est plutôt lourde. Poids max conseillé: " +
        numberFormatter({ value: constraints.sizeWarning / 1024 }) +
        " Ko";
    }
    //------------- SIZE -------------//
    if (imageName === "miniature") {
      // miniature has fix size
      if (imageHeight !== constraints.height) {
        //console.log('imageHeight: ', imageHeight);
        newImageError.height =
          "l'image doit faire " + constraints.height + " px de haut";
      }
      if (imageWidth !== constraints.width) {
        newImageError.width =
          "l'image doit faire " + constraints.width + " px de large";
      }
    } else {
      // treating all images but miniature
      if (imageHeight > constraints.maxHeight) {
        newImageError.height =
          "l'image ne doit pas faire plus de " +
          constraints.maxHeight +
          " px de haut";
      }
      if (imageWidth > constraints.maxWidth) {
        newImageError.width =
          "l'image ne doit pas faire plus de " +
          constraints.maxWidth +
          " px de large";
      }
    }
    // console.log('imageError: ', newImageError);
    setImageError(newImageError);
    setImageWarning(newImageWarning);
    setImageIsValid(isEmpty(newImageError));
  };

  const featureDisplay = (feature, child) => {
    if (imageError !== undefined && imageError[feature] !== undefined) {
      return (
        <Tooltip title={imageError[feature]} placement="top">
          <span className={classes.errorText}>{child}</span>
        </Tooltip>
      );
    }
    if (imageWarning !== undefined && imageWarning[feature] !== undefined) {
      return (
        <Tooltip title={imageWarning[feature]} placement="top">
          <span className={classes.warningText}>{child}</span>
        </Tooltip>
      );
    }
    return <span>{child}</span>;
  };

  useEffect(() => {
    setImageData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    checkImageValidity();
    // eslint-disable-next-line
  }, [imageHeight, imageWidth, imageBytes]);

  return (
    <>
      <Grid item xs={12} className={classes.grid}>
        <Typography variant="h5" gutterBottom>
          {imageDisplayName}
        </Typography>

        {userRole !== "commercial" && (
          <div className={classes.chooseButtonWrapper}>
            <input
              accept="image/*"
              className={classes.input}
              id={inputId}
              type="file"
              onChange={fileSelectedHandler}
            />
            <label htmlFor={inputId}>
              <Button
                variant="contained"
                component="span"
                className={classes.button}
              >
                Choisir une image
              </Button>
            </label>
          </div>
        )}
        {imageName === "miniature" && (
          <Typography variant="caption" gutterBottom>
            Les miniatures doivent faire {miniatureConstraints.width}x
            {miniatureConstraints.height} et peser moins de{" "}
            {numberFormatter({ value: miniatureConstraints.sizeLimit / 1024 })}{" "}
            Ko. Le format par défaut est png 24. Les miniatures ont un fond
            transparent. Pour les bâtons, l'image est inclinée de 33°.
          </Typography>
        )}
        {imageName !== "miniature" && (
          <Typography variant="caption" gutterBottom>
            Le plus grand côté de l'image doit faire au maximum{" "}
            {imageConstraints.maxWidth} px. Pour les bâtons, le format est si
            possible de {imageConstraints.maxHeight}x200 px. L'image doit peser
            moins de{" "}
            {numberFormatter({ value: imageConstraints.sizeLimit / 1024 })} Ko.
            Le format par défaut est jpg.
          </Typography>
        )}

        <div className={classes.imageWrapper}>
          {showImgInDb && (
            <img
              id={imageName}
              src={imageUrlInDb}
              alt="en base"
              className={classes.image}
            />
          )}
          {imageToSend !== null && (
            <img
              id={imageName}
              src={imageToSend}
              alt="à envoyer"
              className={classNames(classes.imgToUpload, classes.image)}
            />
          )}
          {imageDisplayedIsShrinked && (
            <Typography variant="caption" gutterBottom>
              L'image est trop grande pour être affichée à sa taille réelle.
            </Typography>
          )}
        </div>

        <div className={classes.dimensionsWrapper}>
          {imageIsValid && imageHeight !== 0 && (
            <Typography variant="body2" gutterBottom>
              image valide
            </Typography>
          )}
          {!imageIsValid && imageHeight !== 0 && (
            <Typography
              variant="body2"
              className={classes.errorText}
              gutterBottom
            >
              image non valide
            </Typography>
          )}
          {imageHeight !== 0 && (
            <>
              <Typography variant="body2" gutterBottom>
                hauteur: {featureDisplay("height", imageHeight + " px")}
              </Typography>
              <Typography variant="body2" gutterBottom>
                largeur: {featureDisplay("width", imageWidth + " px")}
              </Typography>
              <Typography variant="body2" gutterBottom>
                poids:{" "}
                {featureDisplay(
                  "size",
                  numberFormatter({ value: imageKb }) +
                    " Ko (" +
                    numberFormatter({ value: imageMb }) +
                    "Mo)",
                )}
              </Typography>
            </>
          )}
        </div>
        {userRole !== "commercial" && (
          <div className={classes.saveButtonWrapper}>
            <Button
              variant="contained"
              component="span"
              color="primary"
              className={classes.button}
              onClick={() => fileUploadHandler()}
              disabled={buttonDisabled}
            >
              Enregistrer
            </Button>

            <Button
              variant="contained"
              component="span"
              className={classes.button}
              onClick={() => reloadImage()}
              disabled={buttonDisabled}
            >
              Annuler
            </Button>

            <Button
              variant="contained"
              component="span"
              className={classes.button}
              onClick={() => deleteImage()}
              disabled={!isDeletable}
            >
              Supprimer
            </Button>
          </div>
        )}

        <div className={classes.linearProgressWrapper}>
          {sending && <LinearProgress />}
        </div>

        <Divider />
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
        open={snackBarOpen}
        onClose={handleSnackBarClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackBarText}</span>}
      />
    </>
  );
};

export default ImageHandler;
