import React from "react";
import ReactDOM from "react-dom";
import { combineReducers, createStore, applyMiddleware } from "redux";
import reduxDevToolsComposeEnhancer from "./reduxDevTools";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import reducers from "./reducers";
import { browserHistory } from "react-router";
import { reducer as formReducer } from "redux-form";
import Parse from "parse";
import "moment/locale/fr";

//----------- express setup -----------//

const init = async () => {
  const nullMiddleware = (store) => (next) => (action) => {
    if (action == null) {
      console.group("null or undefined action");
      console.trace();
      console.groupEnd();
      return;
    }
    return next(action);
  };

  //------------------------------------//
  //------Parse server connection ------//
  const location = window.location;
  const hostName = location.hostname;

  global.env = hostName.includes("b2b.gtech-grenoble.com")
    ? "prod"
    : hostName.includes("b2b.preprod.gtech-grenoble.com")
    ? "preprod"
    : "local";

  Parse.initialize(
    "gtech",
    global.env === "local" ? "kEyGtEcHjSmail" : "kEyGtEcHjS"
  );
  const portWithColon = global.env === "local" ? ":1337" : "";
  Parse.serverURL =
    location.protocol + "//" + location.hostname + portWithColon + "/parse";

  //------------------------------------//

  const store = createStore(
    combineReducers({
      ...reducers,
      form: formReducer,
    }),
    reduxDevToolsComposeEnhancer(applyMiddleware(nullMiddleware, thunk))
  );

  //---- loading user and checking token validity ----//
  let currentUser;
  try {
    currentUser = Parse.User.current(); // it can throw (rarely)
    if (currentUser) {
      // is the session valid ? (some issues might happen)
      try {
        await Parse.Session.current();
      } catch (err) {
        console.log(
          "Error retrieving the session object => 400 but, in fact, invalid token => logout"
        );
        currentUser = null;
        throw new Error("Bad session");
      }
    }
  } catch (err) {
    try {
      await Parse.User.logOut();
    } catch (err2) {
      // might happen, but swallowed
    }
  }

  /*
  if (currentUser) {
    //we need to refresh the current user in the localStorage (in case the BO changed it)
    await currentUser.fetch();
    await loginSuccess(store.dispatch, store.getState);
    await connectLoggedUser(true)(store.dispatch, store.getState);
  }*/

  //------------------------------------------------------//
  //------------------ hot reloading ---------------------//
  //------------------------------------------------------//
  const Routes = require("./Routes").default;
  ReactDOM.render(
    <Provider store={store}>
      <Routes history={browserHistory} store={store} />
    </Provider>,
    document.getElementById("root")
  );
};

init();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
