import React, { PureComponent } from "react";
import Parse from "parse";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import { withStyles, Grid } from "@material-ui/core";

const styles = (theme) => ({
  textField: {
    width: 400,
  },
  fieldContainer: {
    display: "flex",
    flex: 1,
  },
});

class WebSecurityStockForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ARKTCODART: this.props.ARKTCODART,
      ARKTCOMART: this.props.ARKTCOMART,
      initialValues: { webSecurityStock: this.props.webSecurityStock },
    };
  }

  onSubmit = async (values) => {
    const { ARKTCODART, ARKTCOMART } = this.state;
    const { onHandleModalClose, onSubmitUpdate } = this.props;
    const webSecurityStock = parseInt(values.webSecurityStock);

    const query = await new Parse.Query("Products")
      .equalTo("ARKTCODART", ARKTCODART)
      .first();
    let articles = query.get("articles");
    if (!!articles.length) {
      // if it's a product with articles
      articles.forEach((article) => {
        if (article.ARKTCOMART === ARKTCOMART) {
          article.webSecurityStock = webSecurityStock;
        }
      });
      query.set("articles", articles);
    } else {
      query.set("webSecurityStock", webSecurityStock);
    }

    try {
      await query.save();
    } catch (error) {
      console.log(error.message);
    }
    onHandleModalClose("webSecurityStockUpdated");
    onSubmitUpdate(
      "webSecurityStock",
      ARKTCODART,
      ARKTCOMART,
      webSecurityStock,
    );
  };

  render() {
    const { initialValues } = this.state;
    const { classes } = this.props;

    return (
      <Form
        onSubmit={this.onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form id="editForm" onSubmit={handleSubmit}>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={4}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="webSecurityStock"
                    label="Stock Sécurité Web"
                    placeholder="Stock Sécurité Web"
                    type="number"
                    component={TextField}
                    className={classes.textField}
                  />
                </div>
              </Grid>
            </Grid>
          </form>
        )}
      />
    );
  }
}
export default withStyles(styles)(WebSecurityStockForm);
