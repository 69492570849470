import React, { PureComponent } from "react";
import Page from "../Page";

export default class SpareParts extends PureComponent {
  render() {
    return (
      <Page toolBarTitle="Pièces détachées" pageTitle="Pièces détachées" />
    );
  }
}
