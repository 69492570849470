import React, { useState, useEffect } from "react";
import Parse from "parse";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Brush,
  AreaChart,
  Area,
} from "recharts";
import {
  makeStyles,
  Typography,
  List,
  ListItem,
  Tooltip as MuiTooltip,
} from "@material-ui/core";
import moment from "moment";
import { generateSuppliesData } from "../../utils/generateSuppliesData/generateSuppliesData";

const useStyles = makeStyles((theme) => ({
  lineChartAndStockWrapper: {
    display: "flex",
  },
  lineChartWrapper: {
    padding: "20px 30px 25px 0",
  },
  title: {
    textAlign: "center",
    paddingTop: 20,
  },
  list: {
    maxHeight: 300,
    margin: "2rem 2rem 0 0",
    overflow: "auto",
  },
  ordersWithShippingDateInThePast: {
    textDecoration: "underline dotted",
  },
}));

const ProductStockChart = (props) => {
  const { productId, showNegativeQuantities } = props;
  const removeNegativeQuantities =
    showNegativeQuantities === undefined ? true : !showNegativeQuantities;
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [suppliesObj, setSuppliesObj] = useState(null);
  const [resuppliesObj, setResuppliesObj] = useState(null);

  useEffect(() => {
    if (data === null) {
      getData(removeNegativeQuantities);
    }
    // adding empty array of dependencies causes firing of warning. useEffect is now executed at each change, but condition inside it makes it light
  });

  const getData = async (removeNegativeQuantities) => {
    const productQuery = await new Parse.Query("Products")
      .select("supplies", "resupplies", "articles", "webSecurityStock")
      .equalTo("ARKTCODART", productId)
      .first();
    /** @type {import("../../types/Product").ProductArticle[]} */
    const articles = productQuery.get("articles");
    const hasArticles = !!articles.length;
    const articlesIds = articles.map((article) => article.ARKTCOMART);
    const suppliesObj = productQuery.get("supplies");
    const resuppliesObj = productQuery.get("resupplies");
    setSuppliesObj(suppliesObj);
    setResuppliesObj(resuppliesObj);
    const productWebSecurityStock = productQuery.get("webSecurityStock");
    const suppliesData = generateSuppliesData({
      supplies: suppliesObj,
      resupplies: resuppliesObj,
    });
    let newData = [];
    if (suppliesData.length === 1) {
      newData = suppliesData[0].supplies.map((day) => {
        return {
          date: day.formattedDay,
          stock: productWebSecurityStock
            ? Math.max(0, day.quantity - productWebSecurityStock)
            : day.quantity,
        };
      });
    } else {
      // if several lines, building date array ...
      newData = suppliesData[0].supplies.map((day) => ({
        date: day.formattedDay,
      }));
      // ... and filling
      suppliesData.forEach(({ ARKTCOMART, supplies }) => {
        if (!hasArticles || (hasArticles && articlesIds.includes(ARKTCOMART))) {
          const articleStockName = "stock " + ARKTCOMART;
          const webSecurityStock =
            (hasArticles
              ? articles.find((article) => article.ARKTCOMART === ARKTCOMART)
                  ?.webSecurityStock
              : productWebSecurityStock) || 0;
          supplies.forEach((day, index) => {
            newData[index][articleStockName] = webSecurityStock
              ? Math.max(0, day.quantity - webSecurityStock)
              : day.quantity;
          });
        }
      });
    }
    setData(newData);
  };

  const generateChartPart = (chartPartName, data) => {
    const lineColors = [
      "#0b84a5",
      "#f6c85f",
      "#6f4e7c",
      "#9dd866",
      "#ca472f",
      "#ffa056",
      "#8dddd0",
      "#a7a7a7",
      "#ca2fb7",
    ];
    const articlesName = [];
    Object.entries(data[0]).forEach(([key, value]) => {
      if (key !== "date") {
        articlesName.push(key);
      }
    });
    let chartPartToReturn = [];
    articlesName.forEach((articleName, index) => {
      if (chartPartName === "line") {
        chartPartToReturn.push(
          <Line
            key={"line_" + index}
            dataKey={articleName}
            stroke={lineColors[index]}
            dot={false}
          />
        );
      } else if (chartPartName === "area") {
        chartPartToReturn.push(
          <Area
            key={"area_" + index}
            dataKey={articleName}
            stroke={lineColors[index]}
            fillOpacity={0}
            dot={false}
          />
        );
      }
    });
    return chartPartToReturn;
  };

  if (data === null) {
    return null;
  }

  return (
    <>
      <Typography variant="h6" className={classes.title}>
        {productId} - Disponibilités
      </Typography>
      <div className={classes.lineChartAndStockWrapper}>
        <div className={classes.lineChartWrapper}>
          <LineChart width={600} height={300} data={data}>
            {/*<CartesianGrid vertical={false} />*/}
            <XAxis dataKey="date" />
            <YAxis domain={["auto", "auto"]} />
            <Tooltip
              wrapperStyle={{
                borderColor: "white",
                boxShadow: "2px 2px 3px 0px rgb(204, 204, 204)",
              }}
              contentStyle={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
              labelStyle={{ fontWeight: "bold", color: "#666666" }}
            />
            {generateChartPart("line", data)}
            <Brush dataKey="date" startIndex={0} endIndex={40}>
              <AreaChart>
                {/*<CartesianGrid />*/}
                <YAxis hide domain={["auto", "auto"]} />
                {generateChartPart("area", data)}
              </AreaChart>
            </Brush>
          </LineChart>
        </div>
        {showNegativeQuantities && (
          <List className={classes.list}>
            {Object.keys(suppliesObj).map((articleRef) => {
              const articleSupplies = suppliesObj[articleRef];

              const ordersWithShippingDateInThePast = [];
              articleSupplies.onUnshippedOrders.orders.forEach(
                (unshippedOrder) => {
                  if (
                    moment(unshippedOrder.shippingDate)
                      .startOf("day")
                      .isBefore(moment().startOf("day"))
                  ) {
                    ordersWithShippingDateInThePast.push(unshippedOrder);
                  }
                }
              );

              let resuppliesQuantity = 0;
              if (resuppliesObj !== undefined) {
                resuppliesObj.forEach((articleResupplies) => {
                  if (
                    articleSupplies.ARKTCOMART ===
                      articleResupplies.ARKTCOMART &&
                    articleResupplies.pOQuantity !== undefined
                  ) {
                    resuppliesQuantity = articleResupplies.pOQuantity;
                  }
                });
              }
              const toolTipContent = [
                <div key={articleRef}>commandes en retard concernées:</div>,
              ];
              let quantityInOrdersWithShippingDateInThePast = 0;
              ordersWithShippingDateInThePast.forEach(
                (orderWithShippingDateInThePast) => {
                  toolTipContent.push(
                    <div
                      key={
                        articleRef + orderWithShippingDateInThePast.orderNumber
                      }
                    >
                      - {orderWithShippingDateInThePast.orderNumber} (
                      {orderWithShippingDateInThePast.quantity} u. pour le{" "}
                      {moment(
                        orderWithShippingDateInThePast.shippingDate
                      ).format("DD/MM/YYYY")}
                      )
                    </div>
                  );
                  quantityInOrdersWithShippingDateInThePast +=
                    orderWithShippingDateInThePast.quantity;
                }
              );

              return (
                <ListItem key={articleSupplies.ARKTCOMART}>
                  {articleSupplies.ARKTCOMART === ""
                    ? "générique"
                    : articleSupplies.ARKTCOMART}{" "}
                  - ECTRA: {articleSupplies.ECTRA}, INTER:{" "}
                  {articleSupplies.INTER}, MAG: {articleSupplies.MAG},
                  {articleSupplies.onUnshippedOrders.quantity !== 0 ? (
                    <span>
                       
                      <MuiTooltip title={toolTipContent}>
                        <span
                          className={classes.ordersWithShippingDateInThePast}
                        >
                          dans {ordersWithShippingDateInThePast.length} com. en
                          retard: {quantityInOrdersWithShippingDateInThePast} u.
                        </span>
                      </MuiTooltip>
                       (total {articleSupplies.onUnshippedOrders.quantity} u.)
                    </span>
                  ) : (
                    " pas de commande"
                  )}
                  ,
                  {resuppliesQuantity !== 0
                    ? " OF: " + resuppliesQuantity
                    : " pas d'OF"}
                </ListItem>
              );
            })}
          </List>
        )}
      </div>
    </>
  );
};

export default ProductStockChart;
