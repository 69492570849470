import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Parse from "parse";
import {
  makeStyles,
  Button,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import { buildContactsOptions } from "../../utils2";
import { setClient, setObjectOfContactOptions } from "../../actions/main";
import { getLists } from "../../reducers/lists";
import { getCurrentUser } from "../../reducers/currentUser";
import { Form, FormSpy, Field } from "react-final-form";
import { Radio } from "final-form-material-ui";
import AutoSave from "../../components/form/AutoSave";
import AutoComplete from "../../components/form/AutoComplete";
import AutoCompleteVirtualized from "../../components/form/AutoCompleteVirtualized";

const useStyles = makeStyles(() => ({
  gridContainer: {
    width: "33%",
  },
  selectField: {
    width: "100%",
  },
  fieldContainer: {
    display: "flex",
    flex: 1,
    height: "48px",
  },
  buttonWrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    margin: "2rem 1rem 1rem 0",
  },
}));

const ClientPickerForm = (props) => {
  const { onFormSubmit } = props;
  const classes = useStyles();
  const currentUser = useSelector(getCurrentUser);
  const userRole =
    currentUser !== undefined ? currentUser.get("role") : undefined;
  const listsOptions = useSelector(getLists);
  const [clientOptions, setClientOptions] = useState(null);
  const [groupOptions, setGroupOptions] = useState(null);
  const [client, setClientState] = useState(null);
  const [group, setGroup] = useState(null);
  const [typeOfClientLinked] = useState([
    { type: "Magasin" },
    { type: "Groupement" },
  ]);
  const [typeOfClient, setTypeOfClient] = useState("Magasin");
  const [clientOrderRefAlreadyExists, setClientOrderRefAlreadyExists] =
    useState(false);
  const [clientOrderToLong, setClientOrderToLong] = useState(false);
  const clientOrderRefMaxLength = 17;
  const atLeastOneConditionIsRequired = (value, allValues) =>
    allValues !== undefined && !allValues.client && !allValues.group
      ? "Il faut préciser un magasin ou un groupement"
      : undefined;
  const dispatch = useDispatch();

  const updateState = (values) => {
    if (values.typeOfClient === "Magasin") {
      // if we didn't change the typeOfClient
      if (typeOfClient === values.typeOfClient) {
        setClientState(values.client);
      }
      setGroup(null);
    } else {
      // if we didn't change the typeOfClient
      if (typeOfClient === values.typeOfClient) {
        setGroup(values.group);
      }
      setClientState(null);
    }
    setTypeOfClient(values.typeOfClient);
  };

  const updateClientOrderRefForm = async (values) => {
    // checking if orderRef already exists
    const clientOrderRef = await new Parse.Query("Orders")
      .equalTo("ECCTCODE", currentUser.get("client"))
      .equalTo("ECCTREFCDE", values.clientOrderRef)
      .first();
    setClientOrderRefAlreadyExists(clientOrderRef !== undefined);
    setClientOrderToLong(
      values.clientOrderRef.length > clientOrderRefMaxLength,
    );
  };

  const onSubmit = async (values) => {
    let type = "";
    let id = "";
    let dbObj = "";
    if (typeOfClient === "Magasin") {
      type = "client";
      id = values.client !== null ? values.client : clientOptions[0].value;
      dbObj = await new Parse.Query("Clients").equalTo("CLKTCODE", id).first();
    } else if (values.group !== null || typeOfClient === "Groupement") {
      type = "group";
      id = values.group;
      dbObj = await new Parse.Query("Groups")
        .equalTo("CLKTCODE", id)
        .include("clients")
        .first();
    }
    const clientToSet = {
      dbObj: dbObj.attributes,
      type,
    };
    await dispatch(
      setObjectOfContactOptions(await buildContactsOptions(clientToSet)),
    );
    await buildContactsOptions(clientToSet);
    await dispatch(setClient(clientToSet));
    onFormSubmit(values);
    // when closing order we want a clean start
    setClientState(null);
    setGroup(null);
  };

  const setSelectOptions = async () => {
    const allClientsOptions = listsOptions.find(
      (lists) => lists.listName === "clients",
    ).options;
    const allGroupsOptions = listsOptions.find(
      (lists) => lists.listName === "groups",
    ).options;
    if (userRole === "commercial" || userRole === "group") {
      let clients = null;
      if (userRole === "commercial") {
        clients = await new Parse.Query("Clients")
          .select("CLKTCODE")
          .equalTo("CLCTREP1", currentUser.get("commercial"))
          .limit(1000000)
          .find();
      }
      if (userRole === "group") {
        const clientsParseObjectQuery = await new Parse.Query("Groups")
          .select("clients")
          .include("clients")
          .equalTo("CLKTCODE", currentUser.get("group"))
          .first();
        clients = clientsParseObjectQuery.get("clients");

        console.log("clientsParseObjectQuery: ", clientsParseObjectQuery);
      }

      const clientIds = clients.map((client) => client.get("CLKTCODE"));
      const clientOptions = allClientsOptions.filter((option) =>
        clientIds.includes(option.value),
      );
      setClientOptions(clientOptions);

      const groups = await new Parse.Query("Groups")
        .select("CLKTCODE")
        .equalTo("CLCTREP1", currentUser.get("commercial"))
        .limit(1000000)
        .find();
      const groupIds = groups.map((group) => group.get("CLKTCODE"));
      const groupOptions = allGroupsOptions.filter((option) =>
        groupIds.includes(option.value),
      );
      setGroupOptions(groupOptions);
    } else {
      setClientOptions(allClientsOptions);
      setGroupOptions(allGroupsOptions);
    }
  };

  useEffect(() => {
    setSelectOptions();
    // eslint-disable-next-line
  }, [currentUser]);

  if (clientOptions === null && groupOptions === null) {
    return null;
  }

  if (userRole === "client") {
    return (
      <>
        <Form
          onSubmit={onSubmit}
          initialValues={{}}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form id="clientOrderRefForm" onSubmit={handleSubmit}>
              <FormSpy
                subscription={{ values: true }}
                component={AutoSave}
                onFormChange={updateClientOrderRefForm}
              />

              <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={12}>
                  <div className={classes.buttonWrapper}>
                    <Button
                      onClick={() =>
                        onSubmit({
                          ...values,
                          ...{ client: currentUser.get("client") },
                        })
                      }
                      variant="contained"
                      color="primary"
                      disabled={
                        submitting ||
                        clientOrderRefAlreadyExists ||
                        clientOrderToLong
                      }
                    >
                      Nouvelle commande
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </>
    );
  }

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={{ client, group, typeOfClient }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form id="clientForm" onSubmit={handleSubmit}>
            <FormSpy
              subscription={{ values: true }}
              component={AutoSave}
              onFormChange={updateState}
            />

            {userRole !== "group" && (
              <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    {/* based on https://codesandbox.io/s/material-ui-radio-buttons-with-final-form-working-g39c2?file=/src/index.js:924-1001 */}
                    <FormControl component="fieldset">
                      <RadioGroup row>
                        <FormControlLabel
                          label={typeOfClientLinked[0].type}
                          control={
                            <Field
                              name="typeOfClient"
                              format={(typeOfClient) =>
                                typeOfClient !== undefined
                                  ? typeOfClient.type
                                  : ""
                              }
                              component={Radio}
                              type="radio"
                              value={typeOfClientLinked[0].type}
                            />
                          }
                        />
                        <FormControlLabel
                          label={typeOfClientLinked[1].type}
                          control={
                            <Field
                              name="typeOfClient"
                              format={(typeOfClient) =>
                                typeOfClient !== undefined
                                  ? typeOfClient.type
                                  : ""
                              }
                              component={Radio}
                              type="radio"
                              value={typeOfClientLinked[1].type}
                            />
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Grid>
              </Grid>
            )}

            {typeOfClient === "Magasin" && (
              <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    <AutoCompleteVirtualized
                      name="client"
                      label="Magasin concerné"
                      options={clientOptions}
                      validate={atLeastOneConditionIsRequired}
                    />
                  </div>
                </Grid>
              </Grid>
            )}
            {typeOfClient === "Groupement" && (
              <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    <AutoComplete
                      name="group"
                      label="Groupement concerné"
                      options={groupOptions}
                    />
                  </div>
                </Grid>
              </Grid>
            )}

            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={12}>
                <div className={classes.buttonWrapper}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={
                      submitting ||
                      typeOfClient === "" ||
                      (typeOfClient === "Magasin" && !clientOptions.length) ||
                      (typeOfClient === "Groupement" && !groupOptions.length)
                    }
                  >
                    suivant
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </>
  );
};

export default ClientPickerForm;
