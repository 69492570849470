import React from "react";
import moment from "moment";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import ForcedClientDiscountForm from "../../containers/form/ForcedClientDiscountForm";
import { getEndOfNextSeason } from "../../utils2";

const DialogForForcedDiscounts = (props) => {
  const { forcedDiscountData, onDialogClose } = props;
  const endOfNextSeason = getEndOfNextSeason();

  const handleDialogValidate = () => {
    document
      .getElementById("forcedClientDiscountForm")
      .dispatchEvent(new Event("submit", { cancelable: true }));
  };

  return (
    <Dialog
      open={true}
      onClose={() => onDialogClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Application de nouvelle(s) remise(s) client
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          La commande que vous venez de valider comprenait une ou plusieurs
          remises client différente(s) de celle(s) déclarée(s) dans le système.
          Choisissez-ci dessous si vous souhaitez appliquer cette nouvelle
          remise jusqu'à la fin de la prochaine saison (
          {moment(endOfNextSeason).format("D MMM YYYY")}).
          <br />
          <br />
          Nouvelle(s) remise(s) client détectée(s):
        </DialogContentText>
        <ForcedClientDiscountForm
          forcedDiscountData={forcedDiscountData}
          onModalClose={(action) => onDialogClose(action)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleDialogValidate()} color="primary">
          Modifier
        </Button>
        <Button onClick={() => onDialogClose()} color="primary" autoFocus>
          Annuler
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogForForcedDiscounts;
