import React, { PureComponent } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Form, Field } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";
import { withStyles } from "@material-ui/core";
import Parse from "parse";
import { getLists } from "../../reducers/lists";
import { optionsCreator, getOptions } from "../../utils2";
import AutoCompleteVirtualized from "../../components/form/AutoCompleteVirtualized";

const styles = (theme) => ({
  textField: {
    width: 400,
    marginTop: 25,
  },
  selectField: {
    width: 400,
  },
  fieldContainer: {
    display: "flex",
    flex: 1,
  },
  fieldText: {
    lineHeight: "48px",
  },
  selectContainer: {
    marginTop: 25,
  },
});
const formFields = [
  "gender",
  "firstname",
  "lastname",
  "email",
  "phone",
  "mobile",
  "fax",
  "clients",
];

class HandleContactForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      contactData: null,
    };
  }
  componentDidMount() {
    const { contactId, listsOptions, defaultClientId } = this.props;
    const clientListOptions = getOptions(listsOptions, "clients");
    this.setState({
      clientListOptions,
    });
    if (contactId !== undefined) {
      this.loadContactData();
    } else {
      this.setState({
        contactData: {
          clients:
            defaultClientId !== undefined
              ? defaultClientId
              : [clientListOptions[10]],
        },
      });
    }
  }

  // getting user from Mongo
  loadContactData = async () => {
    const { contactId } = this.props;
    const contact = await new Parse.Query("Contacts").get(contactId);
    const contactData = { ...contact.attributes };
    // pointers to array
    contactData.clients = contact
      .get("CLKTCODE")
      .map((contactObj) => contactObj.get("CLKTCODE"));

    this.setState({
      contactId,
      contactData,
    });
  };

  onSubmit = async (values) => {
    const newValue = { ...values };
    formFields.forEach((field) => {
      if (newValue[field] === undefined) {
        newValue[field] = "";
      }
    });
    delete newValue.clients;

    const { contactId, onHandleModalClose } = this.props;
    let contact;
    if (contactId !== undefined) {
      // modification
      contact = await new Parse.Query("Contacts").get(contactId);
    } else {
      // creation
      const Contacts = Parse.Object.extend("Contacts");
      contact = new Contacts();
    }

    Object.entries(newValue).forEach(([key, value]) => {
      contact.set(key, value);
    });

    const clientsQuery = await new Parse.Query("Clients")
      .containedIn("CLKTCODE", values.clients)
      .find();
    contact.set("CLKTCODE", clientsQuery);

    await contact.save();
    contactId !== undefined
      ? onHandleModalClose("contactUpdated")
      : onHandleModalClose("contactAdded");
  };

  render() {
    const { contactData, clientListOptions } = this.state;
    const { classes, listsOptions } = this.props;

    if (contactData === null || clientListOptions === undefined) {
      return null;
    }
    return (
      <Form
        onSubmit={this.onSubmit}
        initialValues={contactData}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form id="contactForm" onSubmit={handleSubmit}>
            {false && (
              <div>
                <pre>{JSON.stringify(this.state, null, 2)}</pre>
              </div>
            )}
            <div
              className={classNames(
                classes.fieldContainer,
                classes.selectContainer,
              )}
            >
              <Field
                name="gender"
                label="Genre"
                component={Select}
                className={classes.selectField}
              >
                {optionsCreator(listsOptions, "particle")}
              </Field>
            </div>
            <div className={classes.fieldContainer}>
              <Field
                name="firstname"
                component={TextField}
                type="text"
                label="Prénom"
                className={classes.textField}
              />
            </div>
            <div className={classes.fieldContainer}>
              <Field
                name="lastname"
                component={TextField}
                type="text"
                label="Nom"
                className={classes.textField}
              />
            </div>
            <div className={classes.fieldContainer}>
              <Field
                name="email"
                component={TextField}
                type="email"
                label="Email"
                className={classes.textField}
              />
            </div>
            <div className={classes.fieldContainer}>
              <Field
                name="phone"
                component={TextField}
                type="tel"
                label="Téléphone"
                className={classes.textField}
              />
            </div>
            <div className={classes.fieldContainer}>
              <Field
                name="mobile"
                component={TextField}
                type="tel"
                label="Mobile"
                className={classes.textField}
              />
            </div>
            <div className={classes.fieldContainer}>
              <Field
                name="fax"
                component={TextField}
                type="tel"
                label="Fax"
                className={classes.textField}
              />
            </div>
            <div className={classes.selectContainer}>
              <AutoCompleteVirtualized
                name="clients"
                label="Magasins"
                multiple
                initValue={clientListOptions.filter((option) =>
                  contactData.clients.includes(option.value),
                )}
                options={clientListOptions}
              />
            </div>
          </form>
        )}
      />
    );
  }
}
export default connect(
  (state) => ({
    listsOptions: getLists(state),
  }),
  {},
)(withStyles(styles)(HandleContactForm));
