import React, { PureComponent } from "react";
import Page from "../Page";
import Parse from "parse";

export default class ClockedOrder extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      result: "",
    };

    const Users = Parse.Object.extend("Users");
    const users = new Users();

    users.set("gender", "m");
    users.set("firstname", "Olivier");
    users.set("lastname", "Six");
    users.set("email", "osix@cicorio.fr");
    users.set("role", "superuser");
    users.set("enabled", false);
    users.save().then(
      (users) => {
        // Execute any logic that should take place after the object is saved.
        console.log(users);
        alert(
          "New user added: " +
            users.attributes.firstname +
            " " +
            users.attributes.lastname,
        );
      },
      (error) => {
        // Execute any logic that should take place if the save fails.
        // error is a Parse.Error with an error code and message.
        alert("Failed to create new object, with error code: " + error.message);
      },
    );
  }

  render() {
    return (
      <Page toolBarTitle="Contact" pageTitle="Contact">
        <h1>{this.state.result}</h1>
      </Page>
    );
  }
}
