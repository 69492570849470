import React, { useState } from "react";
import Parse from "parse";
import { useSelector } from "react-redux";
import { getLists } from "../../reducers/lists";
import { getCurrentUser } from "../../reducers/currentUser";
import { Form, FormSpy, Field } from "react-final-form";
import AutoSave from "../../components/form/AutoSave";
import { optionsCreator } from "../../utils2";
import AutoCompleteVirtualized from "../../components/form/AutoCompleteVirtualized";
import { makeStyles, Grid } from "@material-ui/core";
import { Select } from "final-form-material-ui";
import TurnoverPerMonthCharts from "../../components/chart/TurnoverPerMonthCharts";
import ClientTurnoverOrMargin from "../../components/list/ClientTurnoverOrMargin";
import GroupTurnoverOrMargin from "../../components/list/GroupTurnoverOrMargin";
import TradeNameTurnoverOrMargin from "../../components/list/TradeNameTurnoverOrMargin";
import ProductTurnoverOrMargin from "../../components/list/ProductTurnoverOrMargin";
import ActivityTurnoverOrMargin from "../../components/list/ActivityTurnoverOrMargin";
import ProductTypeTurnoverOrMargin from "../../components/list/ProductTypeTurnoverOrMargin";
import CommercialTurnoverOrMargin from "../../components/list/CommercialTurnoverOrMargin";

const useStyles = makeStyles(() => ({
  formContainer: {
    backgroundColor: "#ececec",
    borderRadius: "0.5rem",
    padding: "1rem 2rem",
  },
  selectField: {
    width: "100%",
  },
  fieldContainer: {
    display: "flex",
    flex: 1,
    height: "48px",
  },
  buttonWrapper: {
    display: "flex",
    flex: 1,
    margin: "1rem 0 0 1rem",
  },
}));

const HomeStats = () => {
  const classes = useStyles();
  const listsOptions = useSelector(getLists);
  const currentUser = useSelector(getCurrentUser);
  const userRole =
    currentUser !== undefined ? currentUser.get("role") : undefined;
  const allClientsOptions = [
    ...listsOptions.find((lists) => lists.listName === "clients").options,
  ];
  allClientsOptions.unshift({ value: "ALL", text: "Tous les magasins" });
  const allGroupsOptions = [
    ...listsOptions.find((lists) => lists.listName === "groups").options,
  ];
  allGroupsOptions.unshift({ value: "ALL", text: "Tous les groupes" });
  const allTradeNamesOptions = [
    ...listsOptions.find((lists) => lists.listName === "tradenames").options,
  ];
  allTradeNamesOptions.unshift({ value: "ALL", text: "Toutes les enseignes" });
  const allCommercialOptions = [
    ...listsOptions.find((lists) => lists.listName === "commercials").options,
  ];
  allCommercialOptions.unshift({
    value: "ALL",
    text: "Toutes les représentants",
  });

  const [shop, setShop] = useState("ALL");
  const [group, setGroup] = useState("ALL");
  const [tradeName, setTradeName] = useState("ALL");
  const [commercial, setCommercial] = useState(
    userRole === "commercial" ? currentUser.get("commercial") : "ALL",
  );
  const [shopPerimeter, setShopPerimeter] = useState([]);

  const [dataToShow, setDataToShow] = useState(
    userRole === "commercial" ? "turnover" : "margin",
  ); // commercial can only view the turnover, not the margin
  const turnoverOrMarginOptions = [
    { value: "margin", text: "Afficher la marge" },
    { value: "turnover", text: "Afficher le CA" },
  ];

  const updateState = async (values) => {
    let newShopValue = shop;
    let newGroupValue = group;
    let newTradeNameValue = tradeName;
    let newCommercialValue = commercial;
    if (shop !== values.shop) {
      newShopValue = values.shop !== null ? values.shop : "ALL";
      newGroupValue = "ALL";
      newTradeNameValue = "ALL";
      newCommercialValue = "ALL";
    } else if (group !== values.group) {
      newShopValue = "ALL";
      newGroupValue = values.group !== null ? values.group : "ALL";
      newTradeNameValue = "ALL";
      newCommercialValue = "ALL";
    } else if (tradeName !== values.tradeName) {
      newShopValue = "ALL";
      newGroupValue = "ALL";
      newTradeNameValue = values.tradeName !== null ? values.tradeName : "ALL";
      newCommercialValue = "ALL";
    } else if (commercial !== values.commercial) {
      newShopValue = "ALL";
      newGroupValue = "ALL";
      newTradeNameValue = "ALL";
      newCommercialValue =
        values.commercial !== null ? values.commercial : "ALL";
    }
    setShop(newShopValue);
    setGroup(newGroupValue);
    setTradeName(newTradeNameValue);
    setCommercial(
      userRole === "commercial"
        ? currentUser.get("commercial")
        : newCommercialValue,
    ); // if currentUser is a commercial we lock stats with this commercial
    setShopPerimeter(
      await getShopPerimeter(
        newShopValue,
        newGroupValue,
        newTradeNameValue,
        newCommercialValue,
      ),
    );
  };

  const getShopPerimeter = async (shop, group, tradeName, commercial) => {
    let commercialShopPerimeter = undefined;
    let shopPerimeter = [];
    if (commercial !== undefined) {
      const clients = await new Parse.Query("Clients")
        .select("CLKTCODE")
        .equalTo("CLCTREP1", commercial)
        .limit(1000000)
        .find();
      commercialShopPerimeter = clients.map((client) => client.get("CLKTCODE"));
      commercialShopPerimeter = commercialShopPerimeter.length
        ? commercialShopPerimeter
        : undefined;
    }
    if (shop !== "ALL") {
      shopPerimeter =
        commercialShopPerimeter === undefined
          ? [shop]
          : commercialShopPerimeter.filter(
              (commercialShop) => commercialShop === shop,
            );
    } else if (group !== "ALL") {
      const groupCodes = [
        ...listsOptions.find((lists) => lists.listName === "z_gp_" + group)
          .options,
      ].map((option) => option.value);
      shopPerimeter =
        commercialShopPerimeter === undefined
          ? groupCodes
          : commercialShopPerimeter.filter((commercialShop) =>
              groupCodes.includes(commercialShop),
            );
    } else if (tradeName !== "ALL") {
      const tradeNameCodes = [
        ...listsOptions.find((lists) => lists.listName === "z_tn_" + tradeName)
          .options,
      ].map((option) => option.value);
      shopPerimeter =
        commercialShopPerimeter === undefined
          ? tradeNameCodes
          : commercialShopPerimeter.filter((commercialShop) =>
              tradeNameCodes.includes(commercialShop),
            );
    } else {
      shopPerimeter = commercialShopPerimeter;
    }
    return shopPerimeter;
  };

  if (
    userRole !== "master" &&
    userRole !== "superuser" &&
    userRole !== "commercial"
  ) {
    return null;
  }

  return (
    <>
      <div className={classes.formContainer}>
        <Form
          key={shop + group + tradeName + commercial}
          onSubmit={updateState}
          initialValues={{ shop, group, tradeName, commercial }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form id="perimeterForm" onSubmit={handleSubmit}>
              <FormSpy
                subscription={{ values: true }}
                component={AutoSave}
                onFormChange={updateState}
              />

              <Grid container spacing={4} className={classes.gridContainer}>
                <Grid item xs={userRole !== "commercial" ? 3 : 6}>
                  <div className={classes.fieldContainer}>
                    <AutoCompleteVirtualized
                      name="shop"
                      label="Magasin"
                      options={allClientsOptions}
                      initValue={allClientsOptions.find(
                        (option) => option.value === shop,
                      )}
                    />
                  </div>
                </Grid>
                <Grid item xs={userRole !== "commercial" ? 3 : 6}>
                  <div className={classes.fieldContainer}>
                    <AutoCompleteVirtualized
                      name="group"
                      label="Groupe"
                      options={allGroupsOptions}
                      initValue={allGroupsOptions.find(
                        (option) => option.value === group,
                      )}
                    />
                  </div>
                </Grid>
                {userRole !== "commercial" && (
                  <>
                    <Grid item xs={3}>
                      <div className={classes.fieldContainer}>
                        <AutoCompleteVirtualized
                          name="tradeName"
                          label="Enseigne"
                          options={allTradeNamesOptions}
                          initValue={allTradeNamesOptions.find(
                            (option) => option.value === tradeName,
                          )}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className={classes.fieldContainer}>
                        <AutoCompleteVirtualized
                          name="commercial"
                          label="Représentant"
                          options={allCommercialOptions}
                          initValue={allCommercialOptions.find(
                            (option) => option.value === commercial,
                          )}
                        />
                      </div>
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
          )}
        />
      </div>
      <TurnoverPerMonthCharts
        shop={shop}
        group={group}
        tradeName={tradeName}
        commercial={commercial}
      />
      {userRole !== "commercial" && (
        <Grid container spacing={4} className={classes.gridContainer}>
          <Grid item xs={2}>
            <Form
              onSubmit={(values) => setDataToShow(values.dataToShow)}
              initialValues={{ dataToShow }}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
                invalid,
              }) => (
                <form id="dataToShowForm" onSubmit={handleSubmit}>
                  <FormSpy
                    subscription={{ values: true }}
                    component={AutoSave}
                    onFormChange={(values) => setDataToShow(values.dataToShow)}
                  />
                  <div className={classes.fieldContainer}>
                    <Field
                      name="dataToShow"
                      component={Select}
                      className={classes.selectField}
                    >
                      {optionsCreator("custom", turnoverOrMarginOptions)}
                    </Field>
                  </div>
                </form>
              )}
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={4} className={classes.gridContainer}>
        <Grid item xs={4}>
          <ClientTurnoverOrMargin
            shopPerimeter={shopPerimeter}
            commercialId={commercial}
            dataToShow={dataToShow}
            key={"ClientTurnoverOrMargin" + dataToShow}
          />
        </Grid>
        <Grid item xs={4}>
          <GroupTurnoverOrMargin
            shopPerimeter={shopPerimeter}
            commercialId={commercial}
            dataToShow={dataToShow}
            key={"GroupTurnoverOrMargin" + dataToShow}
          />
        </Grid>
        <Grid item xs={4}>
          <TradeNameTurnoverOrMargin
            shopPerimeter={shopPerimeter}
            commercialId={commercial}
            dataToShow={dataToShow}
            key={"TradeNameTurnoverOrMargin" + dataToShow}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} className={classes.gridContainer}>
        <Grid item xs={4}>
          <ProductTurnoverOrMargin
            shopPerimeter={shopPerimeter}
            commercialId={commercial}
            dataToShow={dataToShow}
            key={"ProductTurnoverOrMargin" + dataToShow}
          />
        </Grid>
        <Grid item xs={4}>
          <ActivityTurnoverOrMargin
            shopPerimeter={shopPerimeter}
            commercialId={commercial}
            dataToShow={dataToShow}
            key={"ActivityTurnoverOrMargin" + dataToShow}
          />
        </Grid>
        <Grid item xs={4}>
          <ProductTypeTurnoverOrMargin
            shopPerimeter={shopPerimeter}
            commercialId={commercial}
            dataToShow={dataToShow}
            key={"ProductTypeTurnoverOrMargin" + dataToShow}
          />
        </Grid>
      </Grid>
      {userRole !== "commercial" && (
        <Grid container spacing={4} className={classes.gridContainer}>
          <Grid item xs={4}>
            <CommercialTurnoverOrMargin
              shopPerimeter={shopPerimeter}
              commercialId={commercial}
              dataToShow={dataToShow}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default HomeStats;
