import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProducts } from "../../reducers/order";
import { setProducts } from "../../actions/main";
import { makeStyles, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles(() => ({
  tableWrapper: {
    overflow: "overlay",
    padding: "0 12px",
    maxHeight: "225px",
  },
  table: {
    width: "100%",
  },
  lineWrapper: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 12px",
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: "#eee",
    },
  },
  imageWrapper: {
    width: "32px",
    height: "32px",
    marginRight: "8px",
    paddingTop: "4px",
  },
  nameWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  name: {},
  ref: {
    fontSize: "0.75rem",
    color: "#777",
  },
}));

const ProductsSelected = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const productsOrder = useSelector(getProducts);

  const deleteProductFromStore = (productId) => {
    const newProductsOrder = [...productsOrder];
    const indexToDelete = newProductsOrder.findIndex(
      (productsOrder) => productsOrder.ARKTCODART === productId,
    );
    newProductsOrder.splice(indexToDelete, 1);
    dispatch(setProducts(newProductsOrder));
  };

  if (productsOrder === undefined) {
    return null;
  }

  return (
    <div className={classes.tableWrapper}>
      {productsOrder.map((product, index) => {
        // console.log('product: ', product);
        const imageCode =
          product.imageUrl !== undefined ? (
            <img src={product.imageUrl} width="28" height="28" alt="" />
          ) : (
            ""
          );
        return (
          <div key={index} className={classes.lineWrapper}>
            <div className={classes.imageWrapper}>{imageCode}</div>
            <div className={classes.nameWrapper}>
              <div className={classes.name}>{product.ARCTLIB01}</div>
              <div className={classes.ref}>{product.ARKTCODART}</div>
            </div>
            <div className={classes.action}>
              <IconButton
                aria-label="Delete"
                onClick={() => deleteProductFromStore(product.ARKTCODART)}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProductsSelected;

/*
class ProductsSelected extends PureComponent {

  deleteProductFromStore = productId => {

  };

  render() {

    const renderProducts = () => {
      const {classes, productsOrder} = this.props;
      
      if (productsOrder === undefined) {
        return null;
      }

      const productsToReturn = [];
      productsOrder.forEach((product, index) => {
        // console.log('product: ', product);
        const imageCode = (product.imageUrl !== undefined) ? <img src={product.imageUrl} width="28" height="28" alt=""/> : '';
        productsToReturn.push (
          <div key={index} className={classes.lineWrapper}>
            <div className={classes.imageWrapper}>{imageCode}</div>
            <div className={classes.nameWrapper}>
              <div className={classes.name}>{product.ARCTLIB01}</div>
              <div className={classes.ref}>{product.ARKTCODART}</div>
            </div>
            <div className={classes.action}>
              <IconButton aria-label="Delete" onClick={ () => this.deleteProductFromStore(product.ARKTCODART)}>
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        );
      });

      return (
        <div className={classes.tableWrapper}>
            {productsToReturn}
        </div>
      );
    };


    return (
      <>
      {renderProducts()}
      </>
    )
  }
}

export default connect(state => ({
  productsOrder: getProducts(state),
}), {})(withStyles(styles)(ProductsSelected));
*/
