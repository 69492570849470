import React from "react";
import Page from "./Page";
import HomeStats from "../stat/HomeStats";

const Home = () => {
  return (
    <Page toolBarTitle="Accueil" pageTitle="Accueil">
      <HomeStats />
    </Page>
  );
};

export default Home;
