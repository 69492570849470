import React from "react";
// import moment from 'moment';
import moment from "moment-ferie-fr";
import Parse from "parse";
import { MenuItem } from "@material-ui/core";

export default function numberFormatter({ locale, value }) {
  if (value !== "") {
    return new Intl.NumberFormat(locale).format(value);
  } else {
    return "";
  }
}

/***********************************************************/
/********************* for Data grids **********************/
/***********************************************************/

export function setNumberColumns(columns, setNumberColumns) {
  const numberColumns = [];
  let mode = "hookMode";
  if (columns === undefined && setNumberColumns === undefined) {
    mode = "classMode";
  }

  if (mode === "classMode") {
    const state = this.state;
    const columns = state.columns;
    columns.forEach((column) => {
      if (column.type !== undefined && column.type === "numeric") {
        numberColumns.push(column.name);
      }
    });
    this.setState({
      numberColumns,
    });
  } else if (mode === "hookMode") {
    columns.forEach((column) => {
      if (column.type !== undefined && column.type === "numeric") {
        numberColumns.push(column.name);
      }
    });
    setNumberColumns(numberColumns);
  }
}
export function generateAvailabilityText(
  productHasArticle,
  product,
  articleId
) {
  const suppliesPerDate = product.suppliesPerDate;
  // getting supplies for current article/product
  let supplies = null;
  if (!productHasArticle) {
    supplies = suppliesPerDate[0].supplies;
  } else {
    suppliesPerDate.forEach((suppliesPerDateForArticle) => {
      if (suppliesPerDateForArticle.ARKTCOMART === articleId) {
        supplies = suppliesPerDateForArticle.supplies;
      }
    });
  }
  // finding closest date with quantity
  if (product.productObj.ARKTCODART === "PORTVENTEFRANCE") {
    return null;
  } else if (supplies[0].quantity > 0) {
    return (
      <span
        key={product.ARKTCOMART + product.ARKTCOMART ? product.ARKTCOMART : ""}
        style={{
          color: "green",
          fontWeight: "normal",
          fontSize: "0.7rem",
          minWidth: "100px",
          paddingLeft: "5px",
        }}
      >
        dispo
      </span>
    );
  } else {
    let closestDateWithQuantity = supplies.find(
      (supply) => supply.quantity > 0
    );
    if (closestDateWithQuantity) {
      return (
        <span
          key={
            product.ARKTCOMART + product.ARKTCOMART ? product.ARKTCOMART : ""
          }
          style={{
            color: "#e99e33",
            fontWeight: "normal",
            fontSize: "0.7rem",
            minWidth: "100px",
            paddingLeft: "5px",
          }}
        >
          dispo le {closestDateWithQuantity.date.format("DD/MM/YY")}
        </span>
      );
    } else {
      return (
        <span
          key={
            product.ARKTCOMART + product.ARKTCOMART ? product.ARKTCOMART : ""
          }
          style={{
            color: "red",
            fontWeight: "normal",
            fontSize: "0.7rem",
            minWidth: "100px",
            paddingLeft: "5px",
          }}
        >
          non dispo
        </span>
      );
    }
  }
}

/***********************************************************/
/********************** for filtering **********************/
/***********************************************************/

export function listsToColumnsTranslations(
  listsToTranslate,
  lists,
  columnsTranslations
) {
  listsToTranslate.forEach((listName) => {
    // getting options in lists
    lists.forEach((list) => {
      if (list.listName === listName) {
        // putting options in columnsTranslations
        columnsTranslations.push({
          columnName: listName,
          values: list.options.map((option) => {
            return [option.value, option.text];
          }),
        });
      }
    });
  });
}

export function translateColumn(value, columnsTranslation) {
  let valueToReturn = value;

  if (columnsTranslation !== undefined) {
    let translation = "";
    // if array
    if (Array.isArray(value)) {
      valueToReturn = "";
      value.forEach((val, index) => {
        translation = columnsTranslation.values.find(
          (columnTranslation) => columnTranslation[0] === val
        );
        if (translation !== undefined) {
          valueToReturn += translation[1];
          if (index + 1 !== value.length) {
            valueToReturn += ", ";
          }
        }
      });
      // if not array
    } else {
      translation = columnsTranslation.values.find(
        (columnTranslation) => columnTranslation[0] === value
      );
      if (translation !== undefined) {
        valueToReturn = translation[1];
      }
    }
  }
  return valueToReturn;
}

export function filtersQuery(
  query,
  columnsTranslations,
  hookParams,
  arrayOfColumnsToIgnore
) {
  let filters = [];
  let sorting = [];
  let columns = [];

  if (hookParams !== undefined) {
    columns = hookParams.columns;
    filters = [...hookParams.filters];
    sorting = hookParams.sorting;
  } else {
    const state = this.state;
    columns = state.columns;
    filters = [...state.filters];
    sorting = state.sorting;
  }
  // removing filters that need specific treatment
  if (arrayOfColumnsToIgnore !== undefined) {
    let i = filters.length;
    while (i--) {
      if (arrayOfColumnsToIgnore.includes(filters[i].columnName)) {
        filters.splice(i, 1);
      }
    }
  }

  //---------- internal function -----------//
  const getColumnTranslations = (name) => {
    let translationsToReturn = [];
    if (columnsTranslations !== undefined) {
      columnsTranslations.forEach((columnTranslations) => {
        if (name === columnTranslations.columnName) {
          translationsToReturn = columnTranslations.values.map((value) => [
            value[0],
            value[1].toLowerCase(),
          ]); // toLowerCase to make it case insensitive
        }
      });
    }
    return !!translationsToReturn.length ? translationsToReturn : null;
  };
  //----------------------------------------//

  filters.forEach((filter) => {
    const name = filter.columnName;
    let value = filter.value;
    let type = undefined;
    columns.forEach((column) => {
      if (column.name === name) {
        type = column.type;
      }
    });
    const columnNameTranslations = getColumnTranslations(name);

    // treating dates
    if (type === "date") {
      // treating DDxMMxYYYY cases
      let reg = /(\d{1,2})[/|.|-](\d{1,2})[/|.|-](\d{4})/;
      const result = value.match(reg);

      if (result) {
        value = result[3] + result[2] + result[1];
        const currentDate = moment.utc(value);
        if (currentDate.isValid()) {
          if (filter.operation === "equal") {
            query.equalTo(name, currentDate.toDate());
          } else if (filter.operation === "greaterThanOrEqual") {
            query.greaterThanOrEqualTo(name, currentDate.toDate());
          } else if (filter.operation === "lessThanOrEqual") {
            query.lessThanOrEqualTo(name, currentDate.toDate());
          }
        }
      }
    } else if (type === "numeric") {
      value = value.replace(/,/g, ".");
      value = parseFloat(value);
      query.equalTo(name, value);
    } else if (type === "boolean") {
      const originalValue = value;
      if ("oui".includes(originalValue)) {
        value = true;
      } else if ("non".includes(originalValue)) {
        value = false;
      }
      if (originalValue !== "o") {
        // because 'o' is common to 'oui' and 'non'
        query.equalTo(name, value);
      }
    } else if (columnNameTranslations !== null) {
      const arrayOfValuesToSearch = [];
      columnNameTranslations.forEach((columnNameTranslation) => {
        if (columnNameTranslation[1].includes(value.toLowerCase())) {
          arrayOfValuesToSearch.push(columnNameTranslation[0]);
        }
      });
      if (!!arrayOfValuesToSearch.length) {
        if (arrayOfValuesToSearch.length > 1) {
          query.containedIn(name, arrayOfValuesToSearch);
        } else {
          query.equalTo(name, arrayOfValuesToSearch[0]);
        }
      } else {
        // if there's is a columnTranslations and we don't have to search for a value (arrayOfValuesToSearch is empty) it means there won't be any result. This line should do that.
        query.equalTo(name, value);
      }
    } else {
      query.matches(name, value, "i"); // this request can not use queries. We use it because database is very light
    }
  });
  sorting.forEach((sort, index) => {
    const nameInDb = sort.columnName;
    if (sort.direction === "asc") {
      !!index ? query.addAscending(nameInDb) : query.ascending(nameInDb);
    } else {
      !!index ? query.addDescending(nameInDb) : query.descending(nameInDb);
    }
  });
}

/***********************************************************/
/***************** for options in selects ******************/
/***********************************************************/
// for autoComplete
export function getOptions(lists, listName) {
  let options = [];
  lists.forEach((list) => {
    if (list.listName === listName) {
      options = list.options;
    }
  });
  return options;
}

// for select
export function optionsCreator(lists, listName, nullValue) {
  const optionArray = [];
  // optional null value
  if (nullValue !== undefined) {
    optionArray.push(
      <MenuItem key={-1} value="noValue">
        {nullValue}
      </MenuItem>
    );
  }

  if (lists !== "custom") {
    lists.forEach((list) => {
      if (list.listName === listName) {
        const options = list.options;
        options.forEach((option, index) => {
          optionArray.push(
            <MenuItem key={index} value={option.value}>
              {option.text}
            </MenuItem>
          );
        });
      }
    });
  } else {
    // listName is in this case an array of values
    listName.forEach((valueAndTextObj, index) => {
      optionArray.push(
        <MenuItem key={index} value={valueAndTextObj.value}>
          {valueAndTextObj.text}
        </MenuItem>
      );
    });
  }
  return optionArray;
}

/***********************************************************/
/*********************** for orders ************************/
/***********************************************************/

export async function buildContactsOptions(clientObj) {
  /* internal function */
  const getContactsOptions = async (clientId) => {
    // we have to get contacts from clients in group, not just current client
    const client = await new Parse.Query("Clients")
      .equalTo("CLKTCODE", clientId)
      .first();
    const group = await new Parse.Query("Groups")
      .select("clients")
      .equalTo("clients", client)
      .first();
    let contacts = null;
    if (group !== undefined) {
      contacts = await new Parse.Query("Contacts")
        .containedIn("CLKTCODE", group.get("clients"))
        .ascending("CLCTCONTA1")
        .find();
    } else {
      contacts = await new Parse.Query("Contacts")
        .equalTo("CLKTCODE", client)
        .ascending("CLCTCONTA1")
        .find();
    }
    return contacts.map((contact) => {
      const emailPart =
        contact.get("email") !== undefined
          ? " (" + contact.get("email") + ")"
          : "";
      const text =
        contact.get("firstname") !== undefined &&
        contact.get("lastname") !== undefined
          ? contact.get("firstname") + " " + contact.get("lastname") + emailPart
          : contact.get("CLCTCONTA1") + emailPart;
      return {
        value: contact.id,
        text,
      };
    });
  };

  const newObjectOfContactOptions = {};
  if (clientObj.type === "group") {
    await Promise.all(
      clientObj.dbObj.clients.map(async (client) => {
        const clientId = client.get("CLKTCODE");
        newObjectOfContactOptions[clientId] = await getContactsOptions(
          clientId
        );
      })
    );
  } else {
    const clientId = clientObj.dbObj.CLKTCODE;
    newObjectOfContactOptions[clientId] = await getContactsOptions(clientId);
  }
  return newObjectOfContactOptions;
}

export function validSendingDayCheck(momentValue) {
  // not valid if it's a saturday, a sunday, a ferie day or in the past
  let reason = "";
  if (momentValue.format("d") === "6") {
    reason = "le jour choisi est un samedi";
  } else if (momentValue.format("d") === "0") {
    reason = "le jour choisi est un dimanche";
  } else if (momentValue.isFerie()) {
    reason = "le jour choisi est férié";
  } else if (momentValue.isBefore(moment())) {
    reason = "le jour choisi est passé";
  }
  if (reason !== "") {
    reason =
      "La date d'expédition que vous venez de rentrer pose un problème: " +
      reason +
      ".";
  }

  return {
    validity: !(
      momentValue.format("d") === "6" ||
      momentValue.format("d") === "0" ||
      momentValue.isFerie() ||
      momentValue.isBefore(moment())
    ),
    reason,
  };
}

export async function getEarliestShippingAndDeliveryDates(clientId) {
  let orderSentInXDays = 1;
  while (
    !validSendingDayCheck(moment().add(orderSentInXDays, "days")).validity
  ) {
    orderSentInXDays++;
  }
  let daysWhereDeliveryIsOnPause = 0;
  const client = await new Parse.Query("Clients")
    .select("CLCTJOURAC")
    .equalTo("CLKTCODE", clientId)
    .first();
  const delayForDelivery = parseInt(client.get("CLCTJOURAC"));
  // if during transit we have a sunday or ferie day we need to add days
  for (let i = orderSentInXDays; i < delayForDelivery + orderSentInXDays; i++) {
    const dayTreated = moment().add(i, "days");
    if (dayTreated.format("d") === "0" || dayTreated.isFerie()) {
      daysWhereDeliveryIsOnPause++;
    }
  }
  return {
    shippingDate: moment().add(orderSentInXDays, "days").utc().startOf("day"),
    deliveryDate: moment()
      .add(
        orderSentInXDays + delayForDelivery + daysWhereDeliveryIsOnPause,
        "days"
      )
      .utc()
      .startOf("day"),
  };
}

export async function getNextDeliveryDate(shippingDate, clientId) {
  let daysWhereDeliveryIsOnPause = 0;
  const client = await new Parse.Query("Clients")
    .select("CLCTJOURAC")
    .equalTo("CLKTCODE", clientId)
    .first();
  const delayForDelivery = parseInt(client.get("CLCTJOURAC"));
  // if during transit we have a sunday or ferie day we need to add days
  for (let i = 0; i <= delayForDelivery; i++) {
    const dayTreated = moment(shippingDate).add(i, "days");
    if (dayTreated.format("d") === "0" || dayTreated.isFerie()) {
      daysWhereDeliveryIsOnPause++;
    }
  }
  //return moment(shippingDate).add(delayForDelivery + daysWhereDeliveryIsOnPause, 'days').format('YYYY-MM-DD');
  return moment(shippingDate).add(
    delayForDelivery + daysWhereDeliveryIsOnPause,
    "days"
  );
}

export async function isClientBlocked(clientId) {
  const client = await new Parse.Query("Clients")
    .equalTo("CLKTCODE", clientId)
    .first();
  return client.get("CLCTBLOCAG") === "A";
}

// used to retrieve prices (ARCNTARIFx)
export function getArticleData(article, productOrder, dataName) {
  let dataToReturn = "";
  if (article.ARKTCOMART !== "") {
    // if we are dealing with articles
    productOrder.productObj.articles.forEach((productObjArticle) => {
      if (article.ARKTCOMART === productObjArticle.ARKTCOMART) {
        if (dataName.includes("ARCNTARIF")) {
          dataToReturn = productObjArticle.pricesLinkedToProduct
            ? productOrder.productObj[dataName]
            : productObjArticle[dataName];
        } else {
          dataToReturn = productObjArticle[dataName];
        }
      }
    });
  } else {
    // if we are dealing with product directly
    dataToReturn = productOrder.productObj[dataName];
  }
  return dataToReturn;
}

export function generateEmptyQuantities(shippings, articles) {
  const createShippingsForArticle = () => {
    const shippingsForArticle = [];
    shippings.forEach((shipping) => {
      shippingsForArticle.push({
        id: shipping.id,
        quantity: 0,
        discount: 0,
        amount: 0,
      });
    });
    return shippingsForArticle;
  };

  const quantities = {
    quantity: 0,
    discount: 0,
    amount: 0,
    articles: [],
  };

  if (!!articles.length) {
    articles.forEach((article) => {
      quantities.articles.push({
        ARKTCOMART: article.ARKTCOMART,
        quantity: 0,
        discount: 0,
        amount: 0,
        shippings: createShippingsForArticle(),
      });
    });
  } else {
    quantities.articles.push({
      ARKTCOMART: "",
      quantity: 0,
      discount: 0,
      amount: 0,
      shippings: createShippingsForArticle(),
    });
  }
  return quantities;
}

export function updateProductOrderDiscountAndAmount(productsOrder, shippings) {
  /********* internal function *********/
  const getAmount = (article, productOrder, shipping, percentageDiscount) => {
    // calculating price
    const ARCNTARIF1 = getArticleData(article, productOrder, "ARCNTARIF1");
    let shippingTotalQuantityOrdered = 0;
    let shippingQuantityOrdered = 0;
    productOrder.quantities.articles.forEach((articleOrdered) => {
      articleOrdered.shippings.forEach((articleShipping) => {
        if (articleShipping.id === shipping.id) {
          shippingTotalQuantityOrdered += articleShipping.quantity;
          if (
            article !== "total" &&
            articleOrdered.ARKTCOMART === article.ARKTCOMART
          ) {
            shippingQuantityOrdered = articleShipping.quantity;
          }
        }
      });
    });
    const quantity =
      article === "total"
        ? shippingTotalQuantityOrdered
        : shippingQuantityOrdered;
    const amount =
      quantity * (ARCNTARIF1 - (ARCNTARIF1 * percentageDiscount) / 100);
    // return parseInt(amount * 100) / 100;
    return amount;
  };
  /******* END internal function *******/

  // we used to only update the touched productOrder but discount (minAmount, minQuantity) can now be base on several products quantities and amount. Total recalculation is necessary.
  productsOrder.forEach((productOrder) => {
    // updating discounts to be applied
    shippings.forEach((shipping) => {
      const forcedDiscounts = {
        clientDiscount: shipping.forcedClientDiscount,
        preSeasonDiscount: shipping.forcedPreSeasonDiscount,
      };

      // discounts must be calculated with correct quantities, that's why we calculate it after quantities update
      const productDiscounts = getProductDiscounts(
        "effective",
        shipping.clientDiscounts,
        productOrder.productObj,
        productsOrder,
        forcedDiscounts
      );
      // refreshing appliedDiscounts (discounts are applied on all shippings (quantitative, minBuyAmount...)
      if (productOrder.appliedDiscountsDetails === undefined) {
        productOrder.appliedDiscountsDetails = {};
      }
      productOrder.appliedDiscountsDetails[shipping.id] =
        getAppliedDiscountsDetails("percentage", productDiscounts, shippings);
    });

    // amount recalculation
    let productOrderAmount = 0;
    productOrder.quantities.articles.forEach((article) => {
      shippings.forEach((shipping, index) => {
        article.shippings[index].amount = getAmount(
          article,
          productOrder,
          shipping,
          productOrder.appliedDiscountsDetails[shipping.id].percentages[
            shipping.id
          ]
        );
      });
      // total amount calculation
      article.amount = 0;
      article.shippings.forEach((shipping) => {
        // recalculate article amount
        article.amount += shipping.amount;
        // recalculate product amount
        productOrderAmount += shipping.amount;
      });
    });
    productOrder.quantities.amount = productOrderAmount;
    // discount recalculation
    let productRawAmount = 0;
    productOrder.quantities.articles.forEach((article) => {
      const ARCNTARIF1 = getArticleData(article, productOrder, "ARCNTARIF1");
      // product > article discount
      const articleRawAmount = ARCNTARIF1 * article.quantity;
      article.discount =
        articleRawAmount !== 0
          ? Math.round(
              ((articleRawAmount - article.amount) / articleRawAmount) * 1000
            ) / 10
          : 0;

      // product raw amount incrementation
      productRawAmount += articleRawAmount;

      // product > article > shipping discount
      article.shippings.forEach((shipping) => {
        const shippingRawAmount = ARCNTARIF1 * shipping.quantity;
        shipping.discount =
          shippingRawAmount !== 0
            ? Math.round(
                ((shippingRawAmount - shipping.amount) / shippingRawAmount) *
                  1000
              ) / 10
            : 0;
      });
    });

    // product discount
    productOrder.quantities.discount =
      productRawAmount !== 0
        ? Math.round(
            ((productRawAmount - productOrder.quantities.amount) /
              productRawAmount) *
              1000
          ) / 10
        : 0;
  });
}

// delete order from supplies in Products
export async function deleteOrderFromOnUnshippedOrders(orderNumber) {
  //----------------- internal function -----------------//
  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }
  //----------------- end internal function -----------------//

  const query = await new Parse.Query("Orders")
    .select("lines")
    .equalTo("ECKTNUMERO", orderNumber)
    .first();
  const lines = query.get("lines");

  /************** populating productsToUpdate *************/
  const productsToUpdate = {};
  /*
  productsToUpdate: {
    'A1126': ['A1126         BLE', 'A1126         ROS']
  }
   */
  lines.forEach((line) => {
    if (productsToUpdate[line.LCCTCODART] === undefined) {
      productsToUpdate[line.LCCTCODART] = [];
    }
    if (line.articleRef === undefined) {
      console.log("line: ", line);
    }
    productsToUpdate[line.LCCTCODART].push(line.articleRef);
  });
  /************** updating Products *************/
  //console.log('productsToUpdate: ', productsToUpdate);
  await asyncForEach(
    Object.entries(productsToUpdate),
    async ([ARKTCODART, productToUpdateArticlesRefs]) => {
      const query = await new Parse.Query("Products")
        .select("supplies")
        .equalTo("ARKTCODART", ARKTCODART)
        .first();
      let newSupplies = { ...query.get("supplies") };
      // treating each articleRef to update
      //console.log('productToUpdateArticlesRefs: ', productToUpdateArticlesRefs);
      productToUpdateArticlesRefs.forEach((productToUpdateArticleRef) => {
        if (newSupplies[productToUpdateArticleRef] !== undefined) {
          // this line is necessary because we add the shippingFees line and it's seen as a product here
          newSupplies[
            productToUpdateArticleRef
          ].onUnshippedOrders.orders.forEach((orderInMongo, index) => {
            if (orderInMongo.orderNumber === orderNumber) {
              newSupplies[
                productToUpdateArticleRef
              ].onUnshippedOrders.quantity -= orderInMongo.quantity;
              newSupplies[
                productToUpdateArticleRef
              ].onUnshippedOrders.orders.splice(index, 1);
            }
          });
        }
      });
      // updating new supplies
      query.set("supplies", newSupplies);
      try {
        await query.save();
      } catch (error) {
        console.log(error.message);
      }
    }
  );
}

// updating from supplies in Products
export async function updateOnUnshippedOrders(productsOrder) {
  //----------------- internal function -----------------//
  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }
  //----------------- end internal function -----------------//

  const productsToUpdate = {};
  /*
   productsToUpdate : {
      'A1123': {
        'A1126             BLE': {
        ARKTCOMART: 'BLE',
        orders: [
          {
            orderNumber
            quantity
            shippingDate
          }
        ]
    }
  */
  /************** populating productsToUpdate *************/
  productsOrder.forEach((productOrder) => {
    productOrder.lines.forEach((line) => {
      const quantityOrdered = line.LCCNQTECDE;
      if (productsToUpdate[line.LCCTCODART] === undefined) {
        productsToUpdate[line.LCCTCODART] = {};
      }
      let articleRefExists = false;
      Object.entries(productsToUpdate[line.LCCTCODART]).forEach(
        ([articleRef, articleRefProps]) => {
          if (articleRefProps.ARKTCOMART === line.LCCTCOMART) {
            // if articleRef is already in productsToUpdate, we update
            articleRefExists = true;
            let orderExists = false;
            articleRefProps.orders.forEach((order) => {
              if (order.orderNumber === productOrder.ECKTNUMERO) {
                // if order is already in array of orders, we update
                orderExists = true;
                order.quantity += quantityOrdered;
              }
            });
            // if order is not in array of orders, we push it in
            if (!orderExists) {
              articleRefProps.orders.push({
                orderNumber: productOrder.ECKTNUMERO,
                quantity: quantityOrdered,
                shippingDate: line.LCCJDELEXP,
              });
            }
          }
        }
      );
      // if articleRef is not in productsToUpdate, we add new prop to object
      if (!articleRefExists) {
        productsToUpdate[line.LCCTCODART][line.articleRef] = {
          ARKTCOMART: line.LCCTCOMART,
          orders: [
            {
              orderNumber: productOrder.ECKTNUMERO,
              quantity: quantityOrdered,
              shippingDate: line.LCCJDELEXP,
            },
          ],
        };
      }
    });
  });
  /************** applying productsToUpdate *************/
  // next is to be placed in Parse Cloud
  // we get each product in Mongo and update supplies
  await asyncForEach(
    Object.entries(productsToUpdate),
    async ([ARKTCODART, productToUpdateArticlesRefs]) => {
      const query = await new Parse.Query("Products")
        .select("supplies")
        .equalTo("ARKTCODART", ARKTCODART)
        .first();
      let newSupplies = { ...query.get("supplies") };

      // treating each articleRef to update
      Object.entries(productToUpdateArticlesRefs).forEach(
        ([productToUpdateArticleRef, productToUpdateArticleProps]) => {
          if (newSupplies[productToUpdateArticleRef] !== undefined) {
            // this line is necessary because we add the shippingFees line and it's seen as a product here
            // treating each order in articleRef
            productToUpdateArticleProps.orders.forEach((orderToUpdate) => {
              let orderUpdated = false;
              // checking if order exists in supplies in Mongo
              newSupplies[
                productToUpdateArticleRef
              ].onUnshippedOrders.orders.forEach((orderInMongo) => {
                if (orderToUpdate.orderNumber === orderInMongo.orderNumber) {
                  // updating articleRef quantity
                  newSupplies[
                    productToUpdateArticleRef
                  ].onUnshippedOrders.quantity +=
                    orderToUpdate.quantity - orderInMongo.quantity;
                  // updating order
                  orderInMongo.quantity = orderToUpdate.quantity;
                  orderUpdated = true;
                }
              });
              // if we did not find the order, we add it
              if (!orderUpdated) {
                newSupplies[
                  productToUpdateArticleRef
                ].onUnshippedOrders.quantity += orderToUpdate.quantity;
                newSupplies[
                  productToUpdateArticleRef
                ].onUnshippedOrders.orders.push(orderToUpdate);
              }
            });
          }
        }
      );

      // updating new supplies
      query.set("supplies", newSupplies);
      try {
        await query.save();
      } catch (error) {
        console.log(error.message);
      }
    }
  );
}

/***********************************************************/
/**************** for discount calculation *****************/
/***********************************************************/

export async function getClientDiscounts(clientId) {
  /*************** internal functions ***************/
  const formatDiscounts = (sourceDiscount, destDiscount) => {
    sourceDiscount.forEach((discount) => {
      const copyDiscount = { ...discount };
      // converting strings to numbers
      if (copyDiscount.percentageDiscount !== undefined) {
        copyDiscount.percentageDiscount = parseFloat(
          copyDiscount.percentageDiscount
        );
      }
      destDiscount.push(copyDiscount);
    });

    pointersToArray(
      destDiscount,
      "minQuantityProduct",
      "minQuantityProductId",
      "ARKTCODART"
    );
    pointersToArray(destDiscount, "freeProduct", "freeProductId", "ARKTCODART");
  };

  // to create an array of strings from array of pointers for comparison purpose
  const pointersToArray = (
    sourceDiscount,
    arrayOfPointersName,
    newArrayName,
    propToPush
  ) => {
    sourceDiscount.forEach((discount) => {
      if (discount[arrayOfPointersName] !== undefined) {
        discount[newArrayName] = [];
        discount[arrayOfPointersName].forEach((client) => {
          // client PArseObject is present at first loop but not it's attribute. We have to wait (veird)
          const setPropToPush = () => {
            if (client.get(propToPush) !== undefined) {
              discount[newArrayName].push(client.get(propToPush));
            } else {
              setTimeout(setPropToPush, 50);
            }
          };
          setPropToPush();
        });
      }
    });
  };

  const cleanUpDiscounts = (discounts) => {
    discounts.forEach((discount) => {
      /*
      delete discount.allClients;
      delete discount.includedClients;
      delete discount.excludedClients;
      delete discount.includedGroups;
      delete discount.excludedGroups;
      delete discount.includedTradeNames;
      delete discount.excludedTradeNames;
      delete discount.minQuantityProduct;
      delete discount.freeProduct;
      delete discount.includedElements;
      delete discount.excludedElements;
      */
      delete discount.lastUpdater;
      delete discount.updatedAt;
      delete discount.createdAt;
      delete discount.creator;
    });
  };
  /************* end internal functions *************/

  /*
    we have to retrieve discounts that :
    ( concerns all clients (allClients=true)   AND   is not in (excludedClients, excludedGroups or excludedTradeNames) )
    OR
    ( is in (includedClients, includedGroups or includedTradeNames)   AND   is not in (excludedClients, excludedGroups or excludedTradeNames) )
  */
  const queriesForCompoundQuery = [];

  const clientQuery = await new Parse.Query("Clients")
    .equalTo("CLKTCODE", clientId)
    .first();
  const clientPointer = {
    __type: "Pointer",
    className: "Clients",
    objectId: clientQuery.id,
  };
  const groupQuery = await new Parse.Query("Groups")
    .equalTo("clients", clientPointer)
    .first();
  let groupPointer = null;
  if (groupQuery !== undefined) {
    groupPointer = {
      __type: "Pointer",
      className: "Groups",
      objectId: groupQuery.id,
    };
  }
  const tradeNameQuery = await new Parse.Query("Tradenames")
    .equalTo("clients", clientPointer)
    .first();
  let tradeNamePointer = null;
  if (tradeNameQuery !== undefined) {
    tradeNamePointer = {
      __type: "Pointer",
      className: "Tradenames",
      objectId: tradeNameQuery.id,
    };
  }

  /************************ query for all clients *************************/
  const queryIncAllClients = await new Parse.Query("Discounts")
    .limit(1000000)
    .include("minQuantityProduct")
    .include("freeProduct")
    .include("product")
    .equalTo("allClients", true)
    .notEqualTo("excludedClients", clientPointer);
  if (groupPointer !== null) {
    queryIncAllClients.notEqualTo("excludedGroups", groupPointer);
  }
  if (tradeNamePointer !== null) {
    queryIncAllClients.notEqualTo("excludedTradeNames", tradeNamePointer);
  }

  /************************** query for clients ***************************/
  const queryClients = await new Parse.Query("Discounts")
    .limit(1000000)
    .include("minQuantityProduct")
    .include("freeProduct")
    .include("product")
    .equalTo("includedClients", clientPointer)
    .notEqualTo("excludedClients", clientPointer);
  queriesForCompoundQuery.push(queryClients);

  /************************** query for groups ***************************/
  if (groupPointer !== null) {
    const queryGroups = await new Parse.Query("Discounts")
      .limit(1000000)
      .include("minQuantityProduct")
      .include("freeProduct")
      .include("product")
      .equalTo("includedGroups", groupPointer)
      .notEqualTo("excludedGroups", groupPointer)
      .notEqualTo("excludedClients", clientPointer);
    queriesForCompoundQuery.push(queryGroups);
  }

  /************************** query for tradenames ***************************/
  if (tradeNamePointer !== null) {
    const queryTradeNames = await new Parse.Query("Discounts")
      .limit(1000000)
      .include("minQuantityProduct")
      .include("freeProduct")
      .include("product")
      .equalTo("includedTradeNames", tradeNamePointer)
      .notEqualTo("excludedTradeNames", tradeNamePointer)
      .notEqualTo("excludedClients", clientPointer);
    queriesForCompoundQuery.push(queryTradeNames);
  }

  const compoundQuery = Parse.Query.or(...queriesForCompoundQuery);
  const query = await Parse.Query.or(queryIncAllClients, compoundQuery).find();

  const clientDiscounts = query.map((data) => {
    return data.attributes;
  });

  /***************************************************/
  /**************** Formatting source ****************/
  /***************************************************/

  // copying allDiscounts to discounts to be able to add properties
  let discounts = [];
  formatDiscounts(clientDiscounts, discounts);
  cleanUpDiscounts(discounts);
  //console.log('formattedDiscounts: ', discounts);

  /***************************************************/
  /******** Getting all applicable discounts *********/
  /***************************************************/

  const applicableDiscounts = [];

  discounts.forEach((discount) => {
    //console.log("discount: ", discount);
    let isApplicable = true;

    // checking discount validity dates
    const now = moment();
    if (
      moment(discount.startDate).isAfter(now) ||
      moment(discount.endDate).isBefore(now)
    ) {
      isApplicable = false;
    }

    if (isApplicable) {
      applicableDiscounts.push(discount);
    }
  });
  return applicableDiscounts;
}

const productIsInPerimeter = (discount, product) => {
  // if allElements is true ...
  return (
    (discount.allElements !== undefined && discount.allElements) ||
    // ... OR ...
    // ... if it's in one of the three EXISTING incArrays ...
    (((discount.includedProducts !== undefined &&
      discount.includedProducts.includes(product.ARKTCODART)) ||
      (discount.includedCatalogs !== undefined &&
        product.catalog !== undefined &&
        discount.includedCatalogs.includes(product.catalog)) ||
      (discount.includedBrands !== undefined &&
        product.brand !== undefined &&
        discount.includedBrands.includes(product.brand)) ||
      (discount.includedProductTypes !== undefined &&
        product.productType !== undefined &&
        discount.includedProductTypes.includes(product.productType)) ||
      (discount.includedUnivers !== undefined &&
        product.univers !== undefined &&
        discount.includedUnivers.includes(product.univers))) &&
      // ... and it's not in any of the three EXISTING excArrays
      (discount.excludedProducts === undefined ||
        (discount.excludedProducts !== undefined &&
          !discount.excludedProducts.includes(product.ARKTCODART))) &&
      (discount.excludedCatalogs === undefined ||
        (discount.excludedCatalogs !== undefined &&
          product.catalog !== undefined &&
          !discount.excludedCatalogs.includes(product.catalog))) &&
      (discount.excludedBrands === undefined ||
        (discount.excludedBrands !== undefined &&
          product.brand !== undefined &&
          !discount.excludedBrands.includes(product.brand))) &&
      (discount.excludedProductTypes === undefined ||
        (discount.excludedProductTypes !== undefined &&
          product.productType !== undefined &&
          !discount.excludedProductTypes.includes(product.productType))) &&
      (discount.excludedUnivers === undefined ||
        (discount.excludedUnivers !== undefined &&
          product.univers !== undefined &&
          !discount.excludedUnivers.includes(product.univers))))
  );
};

export function getProductDiscounts(
  type,
  discounts,
  productObj,
  productsOrder,
  forcedDiscounts
) {
  // we assume that this function gets dates-Validated discounts (with the result of getClientDiscounts). Thus we don't need to recheck dates validity.
  // type can be min, max or effective
  // forcedDiscounts are clientPermanent and preSeason discounts that can be changed during order

  // let quantityOrdered = 0;
  // let productQuantityOrdered = 0;
  // let beforeDiscountAmount = 0;
  // if (productsOrder !== undefined) {
  //   const ARKTCODART = productObj.ARKTCODART;
  //   productsOrder.forEach(productOrder => {
  //     if (productOrder.ARKTCODART === ARKTCODART) { // if we are dealing with the right product
  //       productOrder.quantities.articles.forEach(article => {
  //         const ARKTCOMART = article.ARKTCOMART;
  //         quantityOrdered = article.quantity;
  //         productQuantityOrdered += quantityOrdered;
  //         let ARCNTARIF1 = productOrder.productObj.ARCNTARIF1;
  //         productObj.articles.forEach(productObjArticle => {
  //           if (productObjArticle.ARKTCOMART === ARKTCOMART) {
  //             ARCNTARIF1 = productObjArticle.ARCNTARIF1;
  //           }
  //         });
  //         beforeDiscountAmount += ARCNTARIF1 * quantityOrdered;
  //       });
  //     }
  //   });
  // }

  /*************** internal functions ***************/
  const setDiscountGain = (sourceDiscount, destDiscount) => {
    if (sourceDiscount.discountGain[0] === "discount") {
      destDiscount.percentageDiscount = sourceDiscount.percentageDiscount; // WARNING: priceBase is ignored here!
    } else if (sourceDiscount.discountGain[0] === "franco") {
      destDiscount.francoMinAmount = sourceDiscount.francoMinAmount;
    } else if (sourceDiscount.discountGain[0] === "freeProduct") {
      destDiscount.freeProduct = [];
      sourceDiscount.freeProductId.forEach((productId) => {
        const freeProduct = {};
        freeProduct.quantity = sourceDiscount.freeProductQuantity; // this is a bit weird. Each freeProduct should have it's own freeProductQuantity.
        freeProduct.product = productId;
        destDiscount.freeProduct.push(freeProduct);
      });
    }
  };

  const addDiscount = (discountAnalyzed, discountToAdd, destDiscountArray) => {
    if (discountAnalyzed.combinable) {
      destDiscountArray.combinable.push(discountToAdd);
    } else {
      destDiscountArray.notCombinable.push(discountToAdd);
    }
  };
  /************* end internal functions *************/

  const productId = productObj.ARKTCODART;
  let clientDiscount = {
    origin: "noDiscount",
    percentageDiscount: 0,
  };
  /* preSeasonDiscount: 1 discount looks like this :
  {
    earliestOrderDate: moment(),
    percentageDiscount: 0,
  }
  */
  let preSeasonDiscount = {
    // only one will apply by combinable/notCombinable category but we have to keep them all since earliestOrderDate can be different among discounts
    combinable: [],
    notCombinable: [],
  };
  let productQuantityDiscount = {
    combinable: [],
    notCombinable: [],
  };
  /* minAmountDiscount: 1 discount looks like this : (minQuantity is always there... then percentageDiscount OR freeProduct OR francoMinAmount)
  { minQuantity: 0,
    percentageDiscount: 0,
    freeProduct: [], // 1 freeProduct looks like this : { quantity: 1, product: 'R1229' }
    francoMinAmount: 0
  }
  */
  let minAmountDiscount = {
    // same as productQuantityDiscount but minQuantity is replaced by minAmount
    combinable: [],
    notCombinable: [],
  };
  // noConditionDiscount are specialDiscount (withCondition = false)
  /* noConditionDiscount: 1 discount looks like this :
    { percentageDiscount: 0, OR
      freeProduct: [],       OR
      francoMinAmount: 0
     }
  * */
  let noConditionDiscount = {
    combinable: [],
    notCombinable: [],
  };
  let productQuantityAndMinAmountDiscount = {
    // same as productQuantityDiscount but with quantity AND minAmount
    combinable: [],
    notCombinable: [],
  };

  discounts.forEach((discount) => {
    //console.log("discount " + index + ": " + discount);
    const isClientPermanentDiscount = discount.type === "clientPermanent";
    const isPreSeasonDiscount = discount.type === "preSeason";
    const isProductQuantityDiscount =
      discount.type === "productQuantity" ||
      (discount.type === "specialDiscount" &&
        discount.withCondition &&
        discount.minAmount === undefined); // because we can also set a productQuantity discount through specialDiscount process
    const isMinAmountDiscount =
      discount.type === "minAmount" ||
      (discount.type === "specialDiscount" &&
        discount.withCondition &&
        discount.minQuantity === undefined);
    const isNoCondition = !discount.withCondition;
    const isSpecialDiscount =
      discount.type === "specialDiscount" &&
      discount.minAmount !== undefined &&
      discount.minQuantity !== undefined;

    let productsQuantityOrderedRelevantForCurrentDiscount = 0; // if a productQuantityDiscount includes several products, discount.minQuantity is to be compared to the sum of products quantities
    let productsOrderedBeforeDiscountAmountRelevantForCurrentDiscount = 0; // same as above for amount
    if (productsOrder !== undefined) {
      if (isProductQuantityDiscount) {
        productsOrder.forEach((productOrder) => {
          if (
            discount.minQuantityProductId !== undefined &&
            discount.minQuantityProductId.includes(productOrder.ARKTCODART)
          ) {
            productOrder.quantities.articles.forEach((article) => {
              productsQuantityOrderedRelevantForCurrentDiscount +=
                article.quantity;
            });
          }
        });
      }
      if (isMinAmountDiscount) {
        // console.log('discount: ', discount);
        productsOrder.forEach((productOrder) => {
          // console.log('productOrder: ', productOrder);
          if (
            discount.includedProducts !== undefined &&
            discount.includedProducts.includes(productOrder.ARKTCODART)
          ) {
            let productQuantityOrdered = 0;
            productOrder.quantities.articles.forEach((article) => {
              const ARKTCOMART = article.ARKTCOMART;
              let quantityOrdered = article.quantity;
              productQuantityOrdered += quantityOrdered;
              let ARCNTARIF1 = productOrder.productObj.ARCNTARIF1;
              productOrder.productObj.articles.forEach((productObjArticle) => {
                if (productObjArticle.ARKTCOMART === ARKTCOMART) {
                  ARCNTARIF1 = productObjArticle.ARCNTARIF1;
                }
              });
              productsOrderedBeforeDiscountAmountRelevantForCurrentDiscount +=
                ARCNTARIF1 * productQuantityOrdered;
            });
          }
        });
      }
    }

    // clientPermanent: there's only one clientDiscount per shop/group/tradename. We keep the highest
    if (isClientPermanentDiscount) {
      if (discount.percentageDiscount >= clientDiscount.percentageDiscount) {
        if (discount.includedClients !== undefined) {
          clientDiscount.origin = "shop";
        } else if (discount.includedGroups !== undefined) {
          clientDiscount.origin = "group";
        } else if (discount.includedTradeNames !== undefined) {
          clientDiscount.origin = "tradename";
        }
        if (
          forcedDiscounts.clientDiscount !== undefined &&
          forcedDiscounts.clientDiscount !== discount.percentageDiscount
        ) {
          clientDiscount.origin = "forced";
          clientDiscount.percentageDiscount = forcedDiscounts.clientDiscount;
        } else {
          clientDiscount.percentageDiscount = discount.percentageDiscount;
        }
      }

      // preSeason: there's only one preSeason discount per combinable and notCombinable category
    } else if (isPreSeasonDiscount) {
      if (productIsInPerimeter(discount, productObj)) {
        if (type === "max" || type === "effective") {
          const newDiscount = {};
          newDiscount.earliestOrderDate = discount.earliestOrderDate;
          newDiscount.percentageDiscount =
            forcedDiscounts.preSeasonDiscount !== undefined &&
            forcedDiscounts.preSeasonDiscount !== discount.percentageDiscount
              ? forcedDiscounts.preSeasonDiscount
              : discount.percentageDiscount;
          addDiscount(discount, newDiscount, preSeasonDiscount);
        }
      }

      // noConditionDiscount
    } else if (isNoCondition) {
      if (productIsInPerimeter(discount, productObj)) {
        const newDiscount = {};
        setDiscountGain(discount, newDiscount);
        addDiscount(discount, newDiscount, noConditionDiscount);
      }

      // productQuantityDiscount && minAmountDiscount
    } else if (isProductQuantityDiscount || isMinAmountDiscount) {
      let discountApplies = false;
      if (type !== "min") {
        if (
          (isProductQuantityDiscount &&
            type === "max" &&
            discount.minQuantityProductId.includes(productId)) || // if discount concerns our product
          (isProductQuantityDiscount &&
            type === "effective" &&
            ((discount.minQuantityProductId !== undefined &&
              discount.minQuantityProductId.includes(productId)) ||
              (discount.includedProducts !== undefined &&
                discount.includedProducts.includes(productId))) &&
            productsQuantityOrderedRelevantForCurrentDiscount >=
              parseInt(discount.minQuantity)) || // if discount concerns our product & we reach the minQuantity
          (isMinAmountDiscount &&
            type !== "effective" &&
            productIsInPerimeter(discount, productObj)) ||
          (isMinAmountDiscount &&
            type === "effective" &&
            productIsInPerimeter(discount, productObj) &&
            productsOrderedBeforeDiscountAmountRelevantForCurrentDiscount >=
              parseInt(discount.minAmount))
        ) {
          discountApplies = true;
        }
      }

      if (discountApplies) {
        const newDiscount = {};
        if (isProductQuantityDiscount) {
          newDiscount.minQuantity = discount.minQuantity;
        } else {
          newDiscount.minAmount = discount.minAmount;
        }

        setDiscountGain(discount, newDiscount);
        if (isProductQuantityDiscount) {
          addDiscount(discount, newDiscount, productQuantityDiscount);
        } else {
          addDiscount(discount, newDiscount, minAmountDiscount);
        }
      }

      // specialDiscount
    } else if (isSpecialDiscount) {
      if (
        type !== "min" &&
        productIsInPerimeter(discount, productObj) &&
        discount.minQuantityProductId.includes(productId)
      ) {
        const newDiscount = {};
        newDiscount.minQuantity = discount.minQuantity;
        newDiscount.minAmount = discount.minAmount;
        setDiscountGain(discount, newDiscount);
        addDiscount(discount, newDiscount, productQuantityAndMinAmountDiscount);
      }
    }
  });
  return {
    clientDiscount,
    preSeasonDiscount,
    noConditionDiscount,
    productQuantityDiscount,
    minAmountDiscount,
    productQuantityAndMinAmountDiscount,
  };
}

export function getAppliedDiscountsDetails(type, discountObj, shippings) {
  // discountObj is an object regrouping all the discounts that will be applied. Filtering the discount is already done at this point
  /*************** internal functions ***************/
  const getMultiplier = (percentage) => {
    return 1 - percentage / 100;
  };

  const getBestPercentageDiscountInArray = (array, shipping) => {
    let bestDiscount = null;

    // preSeason needs to be filtered: earliestOrderDate must be sameOrAfter shipping date
    if (shipping !== undefined) {
      // shipping means we are dealing with preSeason
      array = array.map((discount) => {
        const earliestOrderDate = moment(discount.earliestOrderDate).startOf(
          "day"
        );
        if (shipping.shippingDate.isSameOrAfter(earliestOrderDate)) {
          return discount;
        } else {
          return null;
        }
      });
    }

    array.forEach((discount) => {
      if (discount !== null && discount.percentageDiscount !== undefined) {
        const bestPercentage =
          bestDiscount !== null ? bestDiscount.percentageDiscount : 0;
        const percentage = discount.percentageDiscount;
        bestDiscount = percentage > bestPercentage ? discount : bestDiscount;
      }
    });
    return bestDiscount;
  };

  const multiplyAllMultipliersInArrayWithRefMultiplier = (
    array,
    multiplier
  ) => {
    array.forEach((discount) => {
      if (discount.percentageDiscount !== undefined) {
        multiplier *= getMultiplier(discount.percentageDiscount);
      }
    });
  };
  /************* end internal functions *************/

  // getting percentage is multiplying multipliers (coefficient multiplicateur) and then converting the result to percentage
  // this is true for all discounts but for client discount and preseason discount. In this case, percentage have to be added (example: 15%+5%=20%)
  // note: the lower a multiplier, the highest the discount

  // first we get discounts (forAllShippings or perShipping).
  // then, from this object, we generate percentages, (object with percentage for each shipping)

  /**************************************************/
  /**************** Getting discounts ***************/
  /**************************************************/

  const combinableDiscounts = {
    forAllShippings: {},
    perShipping: {},
  };
  const notCombinableDiscounts = {
    forAllShippings: {},
    perShipping: {},
  };

  shippings.forEach((shipping) => {
    combinableDiscounts.perShipping[shipping.id] = {};
    notCombinableDiscounts.perShipping[shipping.id] = {};
  });

  /****************** clientDiscount ****************/
  if (discountObj.clientDiscount.percentageDiscount > 0) {
    // we assume there's always only one clientDiscount per client
    combinableDiscounts.forAllShippings.clientDiscount =
      discountObj.clientDiscount;
    /*
    discountObj.clientDiscount.discountType = 'clientDiscount';
    notCombinableDiscounts.forAllShippings = discountObj.clientDiscount;*/
  }

  /**************** preSeasonDiscount ***************/
  const preSeasonDiscountDetailsPerShipping = {
    combinable: {},
    notCombinable: {},
  };
  let lastCombinablePreSeasonDiscount = null;
  let allShippingsHaveSameCombinablePreSeasonDiscount = true;
  let lastNotCombinablePreSeasonDiscount = {};
  let allShippingsHaveSameNotCombinablePreSeasonDiscount = true;

  // first we assign the best preSeasonDiscount (based on percentageDiscount) to each shipping...
  shippings.forEach((shipping) => {
    // preSeasonDiscount combinable (we find best discount and also determine it it's the same for all shippings (allShippingsHaveSameCombinablePreSeasonDiscount)
    const bestCombinablePreSeasonDiscountForShipping =
      getBestPercentageDiscountInArray(
        discountObj.preSeasonDiscount.combinable,
        shipping
      );
    if (bestCombinablePreSeasonDiscountForShipping !== null) {
      preSeasonDiscountDetailsPerShipping.combinable[shipping.id] =
        bestCombinablePreSeasonDiscountForShipping;
      // checking if preSeasonDiscount is the same for all shippings
      if (lastCombinablePreSeasonDiscount !== null) {
        if (
          lastCombinablePreSeasonDiscount.name !==
          bestCombinablePreSeasonDiscountForShipping.name
        ) {
          allShippingsHaveSameCombinablePreSeasonDiscount = false;
        }
      } else {
        lastCombinablePreSeasonDiscount =
          bestCombinablePreSeasonDiscountForShipping; // initiating
      }
    } else {
      // if we don't find a discount for this shipping then a discount should never be set in forAllShippings object
      allShippingsHaveSameCombinablePreSeasonDiscount = false;
    }
    // preSeasonDiscount not combinable
    const bestNotCombinablePreSeasonDiscountForShipping =
      getBestPercentageDiscountInArray(
        discountObj.preSeasonDiscount.notCombinable,
        shipping
      );
    if (bestNotCombinablePreSeasonDiscountForShipping !== null) {
      preSeasonDiscountDetailsPerShipping.notCombinable[shipping.id] =
        bestNotCombinablePreSeasonDiscountForShipping;
      // checking if preSeasonDiscount is the same for all shippings
      if (lastNotCombinablePreSeasonDiscount !== null) {
        if (
          lastNotCombinablePreSeasonDiscount.name !==
          bestNotCombinablePreSeasonDiscountForShipping.name
        ) {
          allShippingsHaveSameNotCombinablePreSeasonDiscount = false;
        }
      } else {
        lastNotCombinablePreSeasonDiscount =
          bestNotCombinablePreSeasonDiscountForShipping; // initiating
      }
    } else {
      // if we don't find a discount for this shipping then a discount should never be set in forAllShippings object
      allShippingsHaveSameNotCombinablePreSeasonDiscount = false;
    }
  });

  // ... then we see if all preSeasonDiscount are same and we assign: to forAllShippings if true, to perShipping if false
  // preSeasonDiscount combinable
  if (lastCombinablePreSeasonDiscount !== null) {
    // means we have at least one preSeasonDiscount
    if (allShippingsHaveSameCombinablePreSeasonDiscount) {
      combinableDiscounts.forAllShippings.preSeasonDiscount =
        lastCombinablePreSeasonDiscount;
    } else {
      Object.entries(preSeasonDiscountDetailsPerShipping.combinable).forEach(
        ([shippingId, discount]) => {
          combinableDiscounts.perShipping[shippingId].preSeasonDiscount =
            discount;
        }
      );
    }
  }
  // preSeasonDiscount not combinable
  if (lastNotCombinablePreSeasonDiscount !== null) {
    // means we have at least one preSeasonDiscount
    if (allShippingsHaveSameNotCombinablePreSeasonDiscount) {
      // if no discount set or if new percentageDiscount is better
      if (
        notCombinableDiscounts.forAllShippings.name === undefined ||
        lastNotCombinablePreSeasonDiscount.percentageDiscount >
          notCombinableDiscounts.forAllShippings.percentageDiscount
      ) {
        lastNotCombinablePreSeasonDiscount.discountType = "preSeasonDiscount";
        notCombinableDiscounts.forAllShippings.preSeasonDiscount =
          lastNotCombinablePreSeasonDiscount;
      }
    } else {
      Object.entries(preSeasonDiscountDetailsPerShipping.notCombinable).forEach(
        ([shippingId, discount]) => {
          // if no discount set or if new percentageDiscount is better
          if (discount !== undefined) {
            if (
              notCombinableDiscounts.forAllShippings.name === undefined ||
              discount.percentageDiscount >
                notCombinableDiscounts.forAllShippings.percentageDiscount
            ) {
              discount.discountType = "preSeasonDiscount";
              notCombinableDiscounts.perShipping[shippingId] = discount;
            }
          }
        }
      );
    }
  }

  /************** noConditionDiscount ***************/
  if (!!discountObj.noConditionDiscount.combinable.length) {
    combinableDiscounts.forAllShippings.noConditionDiscount =
      discountObj.noConditionDiscount.combinable;
  }

  if (!!discountObj.noConditionDiscount.notCombinable.length) {
    const bestNotCombinableNoConditionDiscount =
      getBestPercentageDiscountInArray(
        discountObj.noConditionDiscount.notCombinable
      );
    if (bestNotCombinableNoConditionDiscount !== null) {
      bestNotCombinableNoConditionDiscount.discountType = "noConditionDiscount";
      if (notCombinableDiscounts.forAllShippings.name === undefined) {
        // if no discount set yet
        notCombinableDiscounts.forAllShippings =
          bestNotCombinableNoConditionDiscount;
      } else if (
        bestNotCombinableNoConditionDiscount.percentageDiscount >
        notCombinableDiscounts.forAllShippings.percentageDiscount
      ) {
        notCombinableDiscounts.forAllShippings =
          bestNotCombinableNoConditionDiscount;
      }
    }
  }

  /************* productQuantityDiscount ************/
  // even if productQuantity can be set at combinable I guess they can't be combinable on the same product
  if (!!discountObj.productQuantityDiscount.combinable.length) {
    const bestCombinableProductQuantityDiscount =
      getBestPercentageDiscountInArray(
        discountObj.productQuantityDiscount.combinable
      );
    if (bestCombinableProductQuantityDiscount !== null) {
      combinableDiscounts.forAllShippings.productQuantityDiscount =
        bestCombinableProductQuantityDiscount;
    }
  }
  if (!!discountObj.productQuantityDiscount.notCombinable.length) {
    const bestNotCombinableProductQuantityDiscount =
      getBestPercentageDiscountInArray(
        discountObj.productQuantityDiscount.notCombinable
      );
    if (bestNotCombinableProductQuantityDiscount !== null) {
      bestNotCombinableProductQuantityDiscount.discountType =
        "productQuantityDiscount";
      if (notCombinableDiscounts.forAllShippings.name === undefined) {
        // if no discount set yet
        notCombinableDiscounts.forAllShippings =
          bestNotCombinableProductQuantityDiscount;
      } else if (
        bestNotCombinableProductQuantityDiscount.percentageDiscount >
        notCombinableDiscounts.forAllShippings.percentageDiscount
      ) {
        notCombinableDiscounts.forAllShippings =
          bestNotCombinableProductQuantityDiscount;
      }
    }
  }

  /*************** minAmountDiscount ****************/
  //  (same logic as productQuantity)
  if (!!discountObj.minAmountDiscount.combinable.length) {
    const bestCombinableMinAmountDiscount = getBestPercentageDiscountInArray(
      discountObj.minAmountDiscount.combinable
    );
    if (bestCombinableMinAmountDiscount !== null) {
      combinableDiscounts.forAllShippings.minAmountDiscount =
        bestCombinableMinAmountDiscount;
    }
  }
  if (!!discountObj.minAmountDiscount.notCombinable.length) {
    const bestNotCombinableMinAmountDiscount = getBestPercentageDiscountInArray(
      discountObj.minAmountDiscount.notCombinable
    );
    if (bestNotCombinableMinAmountDiscount !== null) {
      bestNotCombinableMinAmountDiscount.discountType = "minAmountDiscount";
      if (notCombinableDiscounts.forAllShippings.name === undefined) {
        // if no discount set yet
        notCombinableDiscounts.forAllShippings =
          bestNotCombinableMinAmountDiscount;
      } else if (
        bestNotCombinableMinAmountDiscount.percentageDiscount >
        notCombinableDiscounts.forAllShippings.percentageDiscount
      ) {
        notCombinableDiscounts.forAllShippings =
          bestNotCombinableMinAmountDiscount;
      }
    }
  }

  /****** productQuantityAndMinAmountDiscount *******/
  //   (same logic as productQuantity)
  if (!!discountObj.productQuantityAndMinAmountDiscount.combinable.length) {
    const bestCombinableProductQuantityAndMinAmountDiscount =
      getBestPercentageDiscountInArray(
        discountObj.productQuantityAndMinAmountDiscount.combinable
      );
    if (bestCombinableProductQuantityAndMinAmountDiscount !== null) {
      combinableDiscounts.forAllShippings.productQuantityAndMinAmountDiscount =
        bestCombinableProductQuantityAndMinAmountDiscount;
    }
  }
  if (!!discountObj.productQuantityAndMinAmountDiscount.notCombinable.length) {
    const bestNotCombinableProductQuantityAndMinAmountDiscount =
      getBestPercentageDiscountInArray(
        discountObj.productQuantityAndMinAmountDiscount.notCombinable
      );
    if (bestNotCombinableProductQuantityAndMinAmountDiscount !== null) {
      bestNotCombinableProductQuantityAndMinAmountDiscount.discountType =
        "productQuantityAndMinAmountDiscount";
      if (notCombinableDiscounts.forAllShippings.name === undefined) {
        // if no discount set yet
        notCombinableDiscounts.forAllShippings =
          bestNotCombinableProductQuantityAndMinAmountDiscount;
      } else if (
        bestNotCombinableProductQuantityAndMinAmountDiscount.percentageDiscount >
        notCombinableDiscounts.forAllShippings.percentageDiscount
      ) {
        notCombinableDiscounts.bestNotCombinableProductQuantityAndMinAmountDiscount =
          bestNotCombinableProductQuantityAndMinAmountDiscount;
      }
    }
  }

  if (type === "percentage") {
    /**************************************************/
    /*********** Getting percentages applied **********/
    /**************************************************/

    /************** combinable percentage *************/
    // working with multipliers per shipping (combinableMultiplierPerShipping)
    // initating
    const combinableMultiplierPerShipping = {};
    const combinableDiscountsApplied = {};
    const notCombinableDiscountsApplied = {};
    shippings.forEach((shipping) => {
      combinableMultiplierPerShipping[shipping.id] = 1;
      combinableDiscountsApplied[shipping.id] = {};
      notCombinableDiscountsApplied[shipping.id] = {};
    });

    const combinablePercentages = {};
    const clientDiscountPercentage =
      discountObj.clientDiscount.percentageDiscount !== undefined
        ? discountObj.clientDiscount.percentageDiscount
        : 0;
    shippings.forEach((shipping) => {
      combinableDiscountsApplied[shipping.id].clientDiscount =
        discountObj.clientDiscount;

      // clientDiscount + preSeasonDiscount
      if (combinableDiscounts.forAllShippings.preSeasonDiscount !== undefined) {
        combinableMultiplierPerShipping[shipping.id] = getMultiplier(
          combinableDiscounts.forAllShippings.preSeasonDiscount
            .percentageDiscount + clientDiscountPercentage
        );
        combinableDiscountsApplied[shipping.id].preSeasonDiscount =
          combinableDiscounts.forAllShippings.preSeasonDiscount;
      } else if (
        combinableDiscounts.perShipping[shipping.id].preSeasonDiscount !==
        undefined
      ) {
        // working with shippings
        combinableMultiplierPerShipping[shipping.id] = getMultiplier(
          clientDiscountPercentage +
            combinableDiscounts.perShipping[shipping.id].preSeasonDiscount
              .percentageDiscount
        );
        combinableDiscountsApplied[shipping.id].preSeasonDiscount =
          combinableDiscounts.perShipping[shipping.id].preSeasonDiscount;
      } else {
        // there's no preSeasonDiscount, just clientDiscount
        combinableMultiplierPerShipping[shipping.id] = getMultiplier(
          clientDiscountPercentage
        );
      }

      // there's no perShipping with next discounts. It's always forAllShippings

      // noConditionDiscount
      if (
        combinableDiscounts.forAllShippings.noConditionDiscount !== undefined
      ) {
        multiplyAllMultipliersInArrayWithRefMultiplier(
          combinableDiscounts.forAllShippings.noConditionDiscount,
          combinableMultiplierPerShipping[shipping.id]
        );
        combinableDiscountsApplied[shipping.id].noConditionDiscount =
          combinableDiscounts.forAllShippings.noConditionDiscount;
      }

      // productQuantityDiscount
      if (
        combinableDiscounts.forAllShippings.productQuantityDiscount !==
        undefined
      ) {
        combinableMultiplierPerShipping[shipping.id] *= getMultiplier(
          combinableDiscounts.forAllShippings.productQuantityDiscount
            .percentageDiscount
        );
        combinableDiscountsApplied[shipping.id].productQuantityDiscount =
          combinableDiscounts.forAllShippings.productQuantityDiscount;
      }

      // minAmountDiscount
      if (combinableDiscounts.forAllShippings.minAmountDiscount !== undefined) {
        combinableMultiplierPerShipping[shipping.id] *= getMultiplier(
          combinableDiscounts.forAllShippings.minAmountDiscount
            .percentageDiscount
        );
        combinableDiscountsApplied[shipping.id].minAmountDiscount =
          combinableDiscounts.forAllShippings.minAmountDiscount;
      }

      // productQuantityAndMinAmountDiscount
      if (
        combinableDiscounts.forAllShippings
          .productQuantityAndMinAmountDiscount !== undefined
      ) {
        combinableMultiplierPerShipping[shipping.id] *= getMultiplier(
          combinableDiscounts.forAllShippings
            .productQuantityAndMinAmountDiscount.percentageDiscount
        );
        combinableDiscountsApplied[
          shipping.id
        ].productQuantityAndMinAmountDiscount =
          combinableDiscounts.forAllShippings.productQuantityAndMinAmountDiscount;
      }

      // from multiplier to percentage
      combinablePercentages[shipping.id] =
        Math.round(
          (1 - combinableMultiplierPerShipping[shipping.id]) * 100 * 100
        ) / 100;
    });

    /************ not combinable percentage ***********/
    const notCombinableAllShippingsPercentage =
      notCombinableDiscounts.forAllShippings.percentageDiscount !== undefined
        ? notCombinableDiscounts.forAllShippings.percentageDiscount
        : 0;
    const notCombinablePercentages = {};
    shippings.forEach((shipping) => {
      const notCombinableForCurrentShippingPercentage =
        notCombinableDiscounts.perShipping[shipping.id].percentageDiscount !==
        undefined
          ? notCombinableDiscounts.perShipping[shipping.id].percentageDiscount
          : 0;
      if (
        notCombinableAllShippingsPercentage >=
        notCombinableForCurrentShippingPercentage
      ) {
        notCombinablePercentages[shipping.id] =
          notCombinableAllShippingsPercentage;
        notCombinableDiscountsApplied[shipping.id] =
          notCombinableDiscounts.forAllShippings;
      } else {
        notCombinablePercentages[shipping.id] =
          notCombinableForCurrentShippingPercentage;
        notCombinableDiscountsApplied[shipping.id] =
          notCombinableDiscounts.perShipping[shipping.id];
      }
    });

    // We now compare notCombinablePercentages to combinablePercentages to return the final percentage object: percentagesApplied
    const percentagesApplied = {};
    const discountsApplied = {};
    shippings.forEach((shipping) => {
      discountsApplied[shipping.id] = {};
    });

    shippings.forEach((shipping) => {
      if (
        combinablePercentages[shipping.id] >=
        notCombinablePercentages[shipping.id]
      ) {
        percentagesApplied[shipping.id] = combinablePercentages[shipping.id];
        discountsApplied[shipping.id] = combinableDiscountsApplied[shipping.id];
      } else {
        percentagesApplied[shipping.id] = notCombinablePercentages[shipping.id];
        discountsApplied[shipping.id] =
          notCombinableDiscountsApplied[shipping.id];
      }
    });
    /*
    console.log('------------------------------------');
    console.log('combinableDiscounts: ', combinableDiscounts);
    console.log('notCombinableDiscounts: ', notCombinableDiscounts);
    console.log('discountsApplied: ', discountsApplied);
    console.log('percentagesApplied: ', percentagesApplied);
*/
    return {
      discounts: discountsApplied,
      percentages: percentagesApplied,
    };
  }
}

export function getEndOfNextSeason(date) {
  // if > 31 dec and before 30 june ==> end of season is 31 dec n
  // if between 30 june and 31 dec ==> end of season is 30 june n+1
  const now = date !== undefined ? date : moment().startOf("day").toDate();
  const monthNumber = moment(now).get("month");
  const yearNumber = moment(now).get("year");
  return monthNumber < 6
    ? moment()
        .set({ year: yearNumber, month: 11, date: 31 })
        .startOf("day")
        .toDate()
    : moment()
        .set({ year: yearNumber + 1, month: 5, date: 30 })
        .startOf("day")
        .toDate();
}

export async function createForcedDiscountData(shippingsOrderNumber) {
  // we check that forcedDiscount is different from clientDiscount (clientPermanent). If so and if order is of "1ere commande", we ask if we want to change clientDiscount for next period
  let forcedDiscountExists = false;
  const forcedDiscountByClientId = {};
  const currentClientDiscountByClientId = {};

  // if we are dealing with an OrderNumber (History page)
  if (typeof shippingsOrderNumber === "string") {
    // getting order details
    const order = await new Parse.Query("Orders")
      .equalTo("ECKTNUMERO", shippingsOrderNumber)
      .first();
    // console.log("orderNumber: ", orderNumber);
    const orderType = order.get("ECCTDOMACT");
    // console.log("orderType: ", orderType);

    if (orderType === "1000" || orderType === "3000") {
      // 1000 is implantation, 3000 is Première implantation
      const clientId = order.get("ECCTCODE");

      forcedDiscountByClientId[clientId] = [];
      // getting client object for next query
      const client = await new Parse.Query("Clients")
        .equalTo("CLKTCODE", clientId)
        .first();

      // getting client's permanentDiscount
      const clientPermanentDiscountQuery = await new Parse.Query("Discounts")
        .equalTo("includedClients", client)
        .equalTo("type", "clientPermanent")
        .first();

      // note that if no clientPermanentDiscount exists, then we don't propose to change it
      if (clientPermanentDiscountQuery !== undefined) {
        const clientPermanentDiscount =
          clientPermanentDiscountQuery.get("percentageDiscount");

        if (clientPermanentDiscount !== order.clientPermanentDiscountApplied) {
          forcedDiscountByClientId[clientId] = [
            order.get("clientPermanentDiscountApplied"),
          ];
          currentClientDiscountByClientId[clientId] = clientPermanentDiscount;
          forcedDiscountExists = true;
        }
      }
    }

    // if we are dealing with shippings (validation during order)
  } else {
    shippingsOrderNumber.forEach((shipping) => {
      if (shipping.orderType === "1000" || shipping.orderType === "3000") {
        // 1000 is implantation, 3000 is Première implantation

        // adding client to forcedDiscountByClientId if does not exist yet
        if (forcedDiscountByClientId[shipping.client.id] === undefined) {
          forcedDiscountByClientId[shipping.client.id] = [];
        }
        let clientPermanentDiscount = 0;
        const forcedClientPermanentDiscount = shipping.forcedClientDiscount;
        // getting clientPermanentDiscount
        shipping.clientDiscounts.forEach((discount) => {
          if (discount.type === "clientPermanent") {
            clientPermanentDiscount = discount.percentageDiscount;
          }
        });
        // we add to forcedDiscountByClientId if forced discount is different from client discount and if it's not already in forcedDiscountByClientId
        if (
          clientPermanentDiscount !== forcedClientPermanentDiscount &&
          !forcedDiscountByClientId[shipping.client.id].includes(
            forcedClientPermanentDiscount
          )
        ) {
          forcedDiscountByClientId[shipping.client.id].push(
            forcedClientPermanentDiscount
          );
          currentClientDiscountByClientId[shipping.client.id] =
            clientPermanentDiscount;
          forcedDiscountExists = true;
        }
      }
    });
  }

  return {
    forcedDiscountExists,
    forcedDiscountByClientId,
    currentClientDiscountByClientId,
  };
}
