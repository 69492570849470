import Parse from "parse";
import moment from "moment";

const prefixWithChar = (value, char, finalLength) => {
  let newString = value.toString();
  for (let i = value.toString().length; i < finalLength; i++) {
    newString = char + newString;
  }
  return newString;
};

const completeWithChar = (string, char, finalLength) => {
  let newString = string.toString();
  const initialLength = newString !== undefined ? newString.length : 0;
  for (let i = initialLength; i < finalLength; i++) {
    newString += char;
  }
  return newString;
};

// Code 0 = 0% - exonéré zone EXPORT hors UE
// Code 1 = 20% - tva taux normal à 20%
// Code 2 = 10% - tva à 10% (pas utilisé à ce jour)
// Code 3 = 0% - exonéré zone UE
// Code 4 = 0% - TVA autoliquidée
// Code 5 = 5.5% - tva à 5.5%
const defaultVATCode = 1;

export async function saveOrdersToMongo(
  productsOrder,
  shippings,
  validator,
  currentUser,
) {
  // console.log('productsOrder: ', productsOrder);
  // console.log('shippings: ', shippings);
  const userRole =
    currentUser !== undefined ? currentUser.get("role") : undefined;
  const ordersSaved = [];
  const creationMoment = moment();
  const creationMomentFormatted = creationMoment.format("YYYYMMDDHHmm");
  const creationMomentFormatted2 = creationMoment.format("YYYYMMDD");
  const creationMomentDate = moment.utc(creationMomentFormatted2).toDate();
  const orderRef = !!shippings.length
    ? "MULTI_O_" + creationMomentFormatted
    : "SINGLE_O_" + creationMomentFormatted;

  // getting last invoiceNumber in base
  const query = await new Parse.Query("Orders")
    .startsWith("ECKTNUMERO", "6")
    .descending("ECKTNUMERO")
    .select("ECKTNUMERO")
    .first();
  let lastInvoiceNumber = 599999;
  if (query !== undefined) {
    lastInvoiceNumber = parseInt(query.get("ECKTNUMERO"));
  }

  // We have to save each shipping as an order.
  await Promise.all(
    shippings.map(async (shipping) => {
      const client = await new Parse.Query("Clients")
        .equalTo("CLKTCODE", shipping.client.id)
        .first();

      let priorityVATCode = undefined;
      const clientVATCode = client.get("CLCTTAXE");
      if (clientVATCode !== undefined) {
        priorityVATCode = parseInt(clientVATCode);
      }

      //---------- internal functions ----------//

      // to be applied on LCCTCODTVA
      const getAppliedVATCodeOnProduct = (productVATCode) => {
        let VATCode = undefined;
        if (priorityVATCode !== undefined) {
          VATCode = priorityVATCode;
        }
        if (
          (VATCode === undefined ||
            VATCode === 1 ||
            VATCode === 2 ||
            VATCode === 5) &&
          productVATCode !== undefined
        ) {
          VATCode = productVATCode;
        }
        return VATCode !== undefined ? VATCode : defaultVATCode;
      };

      // we treat each article with this function. It can create several lines if there are more than one shipping specified
      const createProductArticleLines = (productObj, article) => {
        // productObj is the product parse object attributes
        // article is productsOrder.quantities.articles[x]

        // getting article details (stored in productArticle)
        let productArticle = {};
        const productArticles = productObj.articles;
        productArticles.forEach((prodArticle) => {
          if (prodArticle.ARKTCOMART === article.ARKTCOMART) {
            productArticle = prodArticle;
          }
        });
        // console.log('article:', article);

        article.shippings.forEach((articleShipping) => {
          const quantity = articleShipping.quantity;
          // if it's the correct shipping and we have quantity > 0
          if (articleShipping.id === shipping.id && !!quantity) {
            // console.log("articleShipping: ", articleShipping);
            totalAmount += articleShipping.amount;
            totalRawAmount += productObj.ARCNTARIF1 * quantity;
            // console.log('productObj: ', productObj);
            // console.log('productObj.ARCNTARIF6: ', productObj.ARCNTARIF6);
            // console.log('quantity: ', quantity);

            const fullARKTCODART = completeWithChar(
              productObj.ARKTCODART,
              " ",
              18,
            );
            const ARKTCOMART =
              productArticle.ARKTCOMART !== undefined
                ? productArticle.ARKTCOMART
                : "";
            //console.log('fullARKTCODART: ', fullARKTCODART);
            //console.log('ARKTCOMART: ', ARKTCOMART);
            const articleRef = (fullARKTCODART + ARKTCOMART).trim();
            const line = {
              LCKTSOC: "100",
              LCKTNUMERO: invoiceNumber.toString(),
              LCKTLIGNE: prefixWithChar(objectToSave.lines.length + 1, "0", 3),
              LCKTPSF: "000",
              LCCTTYPE: "S",
              LCCJCRE: creationMomentDate,
              LCCTCODE: shipping.client.id,
              LCCTTYPART: productObj.ARCTTYPART,
              LCCTNATURE: productObj.ARITNATURE,
              LCCTCODART: productObj.ARKTCODART,
              LCCTCOMART:
                productArticle.ARKTCOMART !== undefined
                  ? productArticle.ARKTCOMART
                  : "",
              LCCTLIB01: productObj.ARCTLIB01,
              LCCNQTECDE: quantity,
              LCCNPDSCDE:
                productObj.ARCNPDSUNI !== undefined
                  ? productObj.ARCNPDSUNI * quantity
                  : 0,
              LCCNVOLCDE:
                productObj.ARCNVOLUNI !== undefined
                  ? productObj.ARCNVOLUNI * quantity
                  : 0,
              LCCNLONCDE:
                productObj.ARCNLONGUE !== undefined ? productObj.ARCNLONGUE : 0,
              LCCNLARCDE:
                productObj.ARCNLARGEU !== undefined ? productObj.ARCNLARGEU : 0,
              LCCNEPACDE:
                productObj.ARCNEPAISS !== undefined ? productObj.ARCNEPAISS : 0,
              LCCTUNICDE: "UN",
              LCCTUNISTO: "UN",
              LCCTUNIFAC: "UN",
              LCCNPUBRUT: productObj.ARCNTARIF1,
              LCCNRHECR: 0,
              LCCNPUNET:
                Math.round((articleShipping.amount / quantity) * 100) / 100,
              LCCNRHLIG: -1 * articleShipping.discount,
              LCCNRHBAS1: 0,
              LCCNRHBAS2: 0,
              LCCNRHBAS3: 0,
              LCCNQTEEXP: 0,
              LCCNPDSEXP: 0,
              LCCNVOLEXP: 0,
              LCCNQTECON: 0,
              LCCNPDSCON: 0,
              LCCNVOLCON: 0,
              LCCTCREP1: shipping.clientCommercial,
              LCCTCREP2: shipping.orderOrigin,
              LCCNTXREP1: shipping.clientCommercialRate,
              LCCNTXREP2: 0,
              LCCNBASCOM: 0,
              LCCJDELEXP: moment(shipping.shippingDate).toDate(),
              LCCSDELEXP: moment(shipping.shippingDate).format("YYYYww"),
              LCCTDEPOT:
                productArticle.ARCTCODEPC !== undefined
                  ? productArticle.ARCTCODEPC !== undefined
                    ? productArticle.ARCTCODEPC
                    : "MAG"
                  : productObj.ARCTCODEPC !== undefined
                    ? productObj.ARCTCODEPC
                    : "MAG",
              //LCCTNOAFFA: invoiceNumber.toString(),
              LCCNPUREVI: productObj.ARCNTARIF6,
              LCCTSECART: productObj.ARCTSECART,
              LCCTSECCDE: productObj.ARCTSECART,
              LCCTPRIORI:
                shipping.client.dbObj.CLCTPRIORI !== undefined
                  ? shipping.client.dbObj.CLCTPRIORI
                  : "",
              LCCTCODTVA: getAppliedVATCodeOnProduct(productObj.ARCTCODTVA),
              LCCTCODTAR: "0",
              LCCTDEVISE: "EUR",
              LCCTIMPUT:
                productObj.ARCTSIMPUT !== undefined
                  ? productObj.ARCTSIMPUT
                  : "",
              LCCTAFFMAC: productObj.ARCTCODFAM,
              LCCNVALCDE: articleShipping.amount,
              LCCTFATN: productObj.ARCTFATN,
              LCCNBASMAR: productObj.ARCNTARIF6 * quantity,
              LCCTLIB02:
                productArticle.ARCTLIB02 !== undefined
                  ? productArticle.ARCTLIB02
                  : productObj.ARCTLIB02 !== undefined
                    ? productObj.ARCTLIB02
                    : "",
              LCCJDELDDE: moment(shipping.deliveryDate).toDate(),
              LCCJDELARR: moment(shipping.deliveryDate).toDate(),
              LCCSDELARR: moment(shipping.deliveryDate).format("YYYYww"),
              LCCSDELDEM: moment(shipping.deliveryDate).format("YYYYww"),

              // fields added because in PMI orders these columns don't appear as NULL but as ' ' or 0
              LCCTETS: "",
              LCCTEXPU: "",
              LCCTSOLACE: "",
              LCCJDEREX: "",
              LCCTNOEXP: "",
              LCCTSOLACC: "",
              LCCJDERCO: "",
              LCCTNUMLOT: "",
              //LCCTNOLAFF: '001',
              LCCSDELFAB: "",
              LCCJDELFAB: "",
              LCCTTOPRAL: "",
              LCCTTOPBES: "",
              LCCTTOPEDI: "",
              LCCTETACDE: "",
              LCCTREVPRI: "",
              LCCTEDIPU: "",
              LCCNTXDEVI: 0,
              LCCTREFCDE: "",
              LCCTCONDIT: "",
              LCCTGAMREM: "",
              LCCTENVOIF: "",
              LCCTZONLIV: "",
              LCCTREPERE: "",
              LCCTTYPLIB: "",
              LCCTLANCEM: "",
              LCCTLIGLAN: "",
              LCCTSOLACQ: "",
              LCCNDENSIT: 0,
              LCCTANNOTA: "",
              LCCTSTATUT: "",
              LCCTREFEXT: "",
              LCCTPALLOC: "",
              LCCTDATREF: "",
              LCCTCERCON: "",
              LCCJCERCON: "",
              LCCT12DELP: "",
              LCCT12DELD: "",
              LCCNQTELAN: 0,
              LCCNCUMQRE: 0,
              LCCTTOPGE1: "",
              LCCTTOPGE2: "",
              LCCTFAMART: "",
              LCCTRUBAFF: "",
              LCCTAFFACH: "",
              LCCTLIGACH: "",
              LCCNLIBRE1: 0,
              LCCNLIBRE2: 0,
              LCCTTOP01: "",
              LCCTTOP02: "",
              LCCTTOP03: "",
              LCCTTOP04: "",
              LCCTHREXP: "",
              LCCTEDI1: "",
              LCCNPOUNI: 0,
              LCCNPREPA: 0,
              LCCTGENERE: "",
              LCCNFORFAI: 0,
              LCCNIDGMAO: 0,
              LCCNIDSAV: 0,
              LCCNNUMOP: 0,
              LCCNECOTX: 0,

              orderRef,
              articleRef: articleRef,
            };
            // console.log('-------------');
            // console.log('productObj: ', productObj);
            // console.log('productObj.ARCTCODMPL: ', productObj.ARCTCODMPL);
            // console.log('productArticle.ARCTCODMPL: ', productObj.articles.find(article => article.ARKTCOMART === line.LCCTCOMART).ARCTCODMPL);
            if (
              productArticle.ARCTCODMPL !== undefined ||
              productObj.ARCTCODMPL !== undefined
            ) {
              //console.log('here');
              line.LCCTCODEMP =
                productArticle.ARCTCODMPL !== undefined
                  ? productArticle.ARCTCODMPL
                  : productObj.ARCTCODMPL;
            }
            // LCCTCODEMP
            /*
          if (productObj.ARCTCODMPL !== undefined) {
            line.LCCTCODEMP = productObj.ARCTCODMPL;
          }*/
            objectToSave.lines.push(line);
          }
        });
      };

      const createShippingFeesLine = async () => {
        // getting fees product invoiceNumber in base
        const feesProduct = await new Parse.Query("Products")
          .equalTo("ARKTCODART", "PORTVENTEFRANCE")
          .first();
        // searching for client permanent discount with franco info
        const clientPermanentDiscount = await new Parse.Query("Discounts")
          .equalTo("type", "clientPermanent")
          .equalTo("includedClients", client)
          .first();
        // console.log('clientPemanentDiscount: ', clientPermanentDiscount);

        const francoMinAmount =
          clientPermanentDiscount !== undefined
            ? clientPermanentDiscount.get("francoMinAmount")
            : 200;
        // shipping.shippingFees is not taken into account yet
        const shippingFees =
          totalAmount >= francoMinAmount ? 0 : feesProduct.get("ARCNTARIF1");

        // only adding shipping line if shippingFees > 0
        if (shippingFees > 0) {
          totalAmount += shippingFees;
          totalRawAmount += shippingFees;

          const shippingFeesLine = {
            LCKTSOC: "100",
            LCKTNUMERO: invoiceNumber.toString(),
            LCKTLIGNE: prefixWithChar(objectToSave.lines.length + 1, "0", 3),
            LCKTPSF: "000",
            LCCTTYPE: "S",
            LCCJCRE: creationMomentDate,
            LCCTCODE: shipping.client.id,
            LCCTTYPART: feesProduct.get("ARCTTYPART"),
            LCCTNATURE: feesProduct.get("ARITNATURE"),
            LCCTCODART: feesProduct.get("ARKTCODART"),
            LCCTCOMART: "",
            LCCTLIB01: feesProduct.get("ARCTLIB01"),
            LCCNQTECDE: 1,
            // LCCNPDSCDE: (productObj.ARCNPDSUNI !== undefined) ? productObj.ARCNPDSUNI * quantity : 0,
            // LCCNVOLCDE: (productObj.ARCNVOLUNI !== undefined) ? productObj.ARCNVOLUNI * quantity : 0,
            // LCCNLONCDE: (productObj.ARCNLONGUE !== undefined) ? productObj.ARCNLONGUE : 0,
            // LCCNLARCDE: (productObj.ARCNLARGEU !== undefined) ? productObj.ARCNLARGEU : 0,
            // LCCNEPACDE: (productObj.ARCNEPAISS !== undefined) ? productObj.ARCNEPAISS : 0,
            LCCTUNICDE: "UN",
            LCCTUNISTO: "UN",
            LCCTUNIFAC: "UN",
            LCCNPUBRUT: shippingFees,
            LCCNRHECR: 0,
            LCCNPUNET: shippingFees,
            LCCNRHLIG: 0,
            // LCCNRHBAS1: 0,
            // LCCNRHBAS2: 0,
            // LCCNRHBAS3: 0,
            // LCCNQTEEXP: 0,
            // LCCNPDSEXP: 0,
            // LCCNVOLEXP: 0,
            // LCCNQTECON: 0,
            // LCCNPDSCON: 0,
            // LCCNVOLCON: 0,
            // LCCTCREP1: shipping.clientCommercial,
            // LCCTCREP2: shipping.orderOrigin,
            // LCCNTXREP1: shipping.clientCommercialRate,
            // LCCNTXREP2: 0,
            // LCCNBASCOM: 0,
            LCCJDELEXP: moment(shipping.shippingDate).toDate(),
            LCCSDELEXP: moment(shipping.shippingDate).format("YYYYww"),
            // LCCTDEPOT: (productArticle.ARCTCODEPC !== undefined) ?
            //   (productArticle.ARCTCODEPC !== undefined) ? productArticle.ARCTCODEPC : 'MAG' :
            //   (productObj.ARCTCODEPC !== undefined) ? productObj.ARCTCODEPC : 'MAG',
            // LCCTNOAFFA: invoiceNumber.toString(),
            LCCNPUREVI: feesProduct.get("ARCNTARIF6"),
            LCCTSECART: feesProduct.get("ARCTSECART"),
            LCCTSECCDE: feesProduct.get("ARCTSECART"),
            // LCCTPRIORI: (shipping.client.dbObj.CLCTPRIORI !== undefined) ? shipping.client.dbObj.CLCTPRIORI : '',
            LCCTCODTVA: getAppliedVATCodeOnProduct(
              feesProduct.get("ARCTCODTVA"),
            ),
            LCCTCODTAR: "0",
            LCCTDEVISE: "EUR",
            LCCTIMPUT:
              feesProduct.get("ARCTSIMPUT") !== undefined
                ? feesProduct.get("ARCTSIMPUT")
                : "",
            LCCTAFFMAC: feesProduct.get("ARCTCODFAM"),
            LCCNVALCDE: shippingFees,
            LCCTFATN: feesProduct.get("ARCTFATN"),
            LCCNBASMAR: shippingFees,
            // LCCTLIB02: (productArticle.ARCTLIB02 !== undefined) ?
            //   productArticle.ARCTLIB02 :
            //   (productObj.ARCTLIB02 !== undefined) ?
            //     productObj.ARCTLIB02 : ''
            // ,
            LCCJDELDDE: moment(shipping.deliveryDate).toDate(),
            LCCJDELARR: moment(shipping.deliveryDate).toDate(),
            LCCSDELARR: moment(shipping.deliveryDate).format("YYYYww"),
            // LCCSDELDEM: moment(shipping.deliveryDate).format('YYYYww'),

            orderRef,
            articleRef: feesProduct.get("ARKTCODART"),
          };
          objectToSave.lines.push(shippingFeesLine);
        }
      };
      //------------ end internal functions ------------//

      //------------ Order creation ------------//
      const invoiceNumber = ++lastInvoiceNumber;

      const objectToSave = {
        lines: [],
      };
      let totalAmount = 0;
      let totalRawAmount = 0;

      // order lines
      // console.log('productsOrder: ', productsOrder);
      productsOrder.forEach((product) => {
        product.quantities.articles.forEach((article) => {
          createProductArticleLines(product.productObj, article);
        });
      });
      let ECCNPDSNET = 0;
      let ECCNVOLUME = 0;
      objectToSave.lines.forEach((line) => {
        ECCNPDSNET += line.LCCNPDSCDE;
        ECCNVOLUME += line.LCCNVOLCDE;
      });
      // getting contact parse object
      let contact = undefined;
      if (shipping.contact !== undefined) {
        contact = await new Parse.Query("Contacts").get(shipping.contact);
      }
      // adding shipping fees
      await createShippingFeesLine();

      // order data (except lines). We do that after lines because we use the sum of data in lines
      objectToSave.ECKTSOC = "100";
      objectToSave.ECKTNUMERO = invoiceNumber.toString();
      objectToSave.ECKTINDICE = "000";
      objectToSave.ECCTTYPE1 = "C";
      objectToSave.ECCTTYPE2 = "S";
      objectToSave.ECCJCRE = creationMomentDate;
      objectToSave.ECCTCODE = shipping.client.dbObj.CLKTCODE;
      objectToSave.ECCTNOM = shipping.client.dbObj.CLCTNOM;
      objectToSave.ECCTRUE1 =
        shipping.client.dbObj.CLCTRUE1 !== undefined
          ? shipping.client.dbObj.CLCTRUE1
          : "";
      objectToSave.ECCTRUE2 = shipping.client.dbObj.CLCTRUE2;
      objectToSave.ECCTCP = shipping.client.dbObj.CLCTCP;
      objectToSave.ECCTVILLE = shipping.client.dbObj.CLCTVILLE;
      objectToSave.ECCTPAYS = shipping.client.dbObj.CLCTPAYS;
      objectToSave.ECCTCPAYS = shipping.client.dbObj.CLCTCPAYS;
      objectToSave.ECCTNOMLIV = shipping.client.dbObj.CLCTNOMLIV;
      objectToSave.ECCTRUE1LI = shipping.client.dbObj.CLCTRUE1LI;
      objectToSave.ECCTRUE2LI = shipping.client.dbObj.CLCTRUE2LI;
      objectToSave.ECCTCPLIV = shipping.client.dbObj.CLCTCPLIV;
      objectToSave.ECCTVILLIV = shipping.client.dbObj.CLCTVILLIV;
      objectToSave.ECCTPAYSLI =
        shipping.client.dbObj.CLCTPAYSLI !== undefined
          ? shipping.client.dbObj.CLCTPAYSLI
          : "";
      objectToSave.ECCTCPAYLI = shipping.client.dbObj.CLCTCPAYLI;
      objectToSave.ECCTTRANPO = shipping.carrier;
      objectToSave.ECCTREGFAC = shipping.client.dbObj.CLCTREGFAC;
      objectToSave.ECCTREGBL = shipping.client.dbObj.CLCTREGBL;
      objectToSave.ECCTCECHEA = shipping.client.dbObj.CLCTCECHEA;
      objectToSave.ECCTBECHEA = shipping.client.dbObj.CLCTBECHEA;
      objectToSave.ECCTCREG = shipping.client.dbObj.CLCTCREG;
      objectToSave.ECCTMREG = shipping.client.dbObj.CLCTMREG;
      objectToSave.ECCTREGFIS = shipping.client.dbObj.CLCTREGFIS;
      objectToSave.ECCTACTIVI = shipping.client.dbObj.CLCTACTIVI;
      objectToSave.ECCNPDSNET = ECCNPDSNET;
      objectToSave.ECCNVOLUME = ECCNVOLUME;
      objectToSave.ECCTDEVISE = "EUR";
      objectToSave.ECCTCODFAC =
        shipping.client.dbObj.CLCTCODFAC !== undefined
          ? shipping.client.dbObj.CLCTCODFAC
          : "";
      objectToSave.ECCTCENACH = shipping.client.dbObj.CLCTCOMPTA; // not a mistake
      objectToSave.ECCTUTIMOD = validator;
      objectToSave.status = validator === "" ? "confirmed" : "validated";
      objectToSave.ECCTTOP08 = "0";
      objectToSave.ECCTREFCDE = shipping.clientOrderRef;

      objectToSave.ECCTDOMACT = shipping.orderType;
      objectToSave.clientPermanentDiscountApplied =
        "" + shipping.forcedClientDiscount;
      objectToSave.preSeasonDiscountApplied = shipping.forcedPreSeasonDiscount;
      objectToSave.clientPermanentDiscountNeedsValidation =
        shipping.originalClientDiscount.toString() !==
          shipping.forcedClientDiscount.toString() && userRole === "commercial";

      objectToSave.amount = totalAmount;
      objectToSave.rawAmount = totalRawAmount;
      objectToSave.discount =
        (-1 * Math.round((totalRawAmount - totalAmount) * 100)) / 100;
      objectToSave.orderRef = orderRef; // this is the same for all shippings (orders). That way we can trace the Multi-orders.
      objectToSave.orderAuthor = currentUser;
      objectToSave.rep1 = shipping.clientCommercial;
      objectToSave.orderConfirmationSent = false;
      if (contact !== undefined) {
        objectToSave.contact = contact;
      }

      /*
    //objectToSave.ECCTETS = undefined;
    //objectToSave.ECCTPORT = '';
    //objectToSave.ECCTEMBA = '';
    //objectToSave.ECCJECHFOR = '';
    //objectToSave.ECCTRUPEDI = '';
    //objectToSave.ECCTDERBL = '';
    //objectToSave.ECCTDERFA = '';
    //objectToSave.ECCJDERBL = '';
    //objectToSave.ECCJDERFA = '';
    //objectToSave.ECCTBLOCAG = '';
    //objectToSave.ECCTFRFIXE = '';
    //objectToSave.ECCNPDSBRU = '';
    //objectToSave.ECCNBCOLIS = '';
    //objectToSave.ECCNBPAL = '';
    //objectToSave.ECCNESCAGI = '';
    //objectToSave.ECCTRUPBL = '';
    //objectToSave.ECCTRUPFAC = '';
    //objectToSave.ECCNTXDEVI = '';
    //objectToSave.ECCTREVDOC = '';
    //objectToSave.ECCTENVOIF = '';
    //objectToSave.ECCTTOP01 = '';
    //objectToSave.ECCTTOP02 = '';
    //objectToSave.ECCTTOP03 = '';
    //objectToSave.ECCTTOP04 = '';
    //objectToSave.ECCTTOP05 = '';
    //objectToSave.ECCTSTATUT = '';
    //objectToSave.ECCTVISEUR = '';
    //objectToSave.ECCTRESPON = '';
    //objectToSave.ECCTPRESCR = '';
    //objectToSave.ECCTTITRE = '';
    //objectToSave.ECCTSUIREF = '';
    //objectToSave.ECCTBP01 = '';
    //objectToSave.ECCNLIBRE1 = '';
    //objectToSave.ECCNLIBRE2 = '';
    //objectToSave.ECCTTOP06 = '';
    //objectToSave.ECCTTOP07 = '';
    //objectToSave.ECCTTOP09 = '';
    //objectToSave.ECCTLINCO = '';
    //objectToSave.ECCNIDSAV = '';
    //objectToSave.ECCTRUE3 = '';
    //objectToSave.ECCTRUE3LI = '';
    //objectToSave.ECCTREG = '';
    //objectToSave.ECCTREGLI = '';
    //objectToSave.ECCNJOURAC = '';
    //objectToSave.ECCNEXO = '';
*/

      // saving to mongo
      //console.log('objectToSave: ', objectToSave);
      const Order = Parse.Object.extend("Orders");
      const order = new Order();
      Object.entries(objectToSave).forEach(([key, value]) => {
        order.set(key, value);
      });
      //console.log('order: ', order);
      try {
        await order.save();
        ordersSaved.push(objectToSave);
      } catch (error) {
        console.log(error);
        return "error";
      }
    }),
  );

  return ordersSaved;
}
