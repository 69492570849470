import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Field } from "react-final-form-html5-validation";
import { Select } from "final-form-material-ui";
import { withStyles, Grid } from "@material-ui/core";
import { getLists } from "../../../reducers/lists";
import { optionsCreator } from "../../../utils2";

const styles = (theme) => ({
  gridContainer: {
    width: "50%",
  },
  textField: {
    width: "100%",
  },
  selectField: {
    width: "100%",
  },
  fieldContainer: {
    display: "flex",
    flex: 1,
    height: "48px",
  },
});

class StickFields extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      materialType: null,
    };
  }

  updateMaterialType = (materialType) => {
    this.setState({
      materialType: materialType.input.value,
    });
    return null;
  };

  render() {
    const { materialType } = this.state;
    const { classes, listsOptions } = this.props;

    return (
      <>
        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={6}>
            <div className={classes.fieldContainer}>
              <Field
                name="range"
                label="Gamme"
                component={Select}
                className={classes.selectField}
              >
                {optionsCreator(listsOptions, "line")}
              </Field>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.fieldContainer}>
              <Field
                name="wispCount"
                label="Nombre de brins"
                component={Select}
                className={classes.selectField}
              >
                {optionsCreator(listsOptions, "wispCount")}
              </Field>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={6}>
            <div className={classes.fieldContainer}>
              <Field
                name="material"
                label="Matériaux"
                component={Select}
                className={classes.selectField}
              >
                {optionsCreator(listsOptions, "material")}
              </Field>
              <Field name="material" subscription={{ value: true }}>
                {this.updateMaterialType}
              </Field>
            </div>
          </Grid>
          <Grid item xs={6}>
            {(materialType === "aluminium" || materialType === "aluCarbon") && (
              <div className={classes.fieldContainer}>
                <Field
                  name="aluminiumType"
                  label="Type d'aluminium"
                  component={Select}
                  className={classes.selectField}
                >
                  {optionsCreator(listsOptions, "aluminiumType")}
                </Field>
              </div>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={6}>
            <div className={classes.fieldContainer}>
              <Field
                name="handleType"
                label="Type de poignée"
                component={Select}
                className={classes.selectField}
              >
                {optionsCreator(listsOptions, "handleType")}
              </Field>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.fieldContainer}>
              <Field
                name="spikeType"
                label="Type de pointe"
                component={Select}
                className={classes.selectField}
              >
                {optionsCreator(listsOptions, "spikeType")}
              </Field>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={6}>
            <div className={classes.fieldContainer}>
              <Field
                name="clampingSystem"
                label="Système de serrage"
                component={Select}
                className={classes.selectField}
              >
                {optionsCreator(listsOptions, "clampingSystem")}
              </Field>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.fieldContainer}>
              <Field
                name="singleOrPair"
                label="Unité ou paire"
                component={Select}
                className={classes.selectField}
              >
                {optionsCreator(listsOptions, "singleOrPair")}
              </Field>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={6}>
            <div className={classes.fieldContainer}>
              <Field
                name="diameter"
                label="Diamètre"
                component={Select}
                className={classes.selectField}
              >
                {optionsCreator(listsOptions, "diameter")}
              </Field>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.fieldContainer}>
              <Field
                name="strap"
                label="Dragonne"
                component={Select}
                className={classes.selectField}
              >
                {optionsCreator(listsOptions, "strap")}
              </Field>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={6}>
            <div className={classes.fieldContainer}>
              <Field
                name="stopper"
                label="Bouchon"
                component={Select}
                className={classes.selectField}
              >
                {optionsCreator(listsOptions, "stopper")}
              </Field>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default connect(
  (state) => ({
    listsOptions: getLists(state),
  }),
  {},
)(withStyles(styles)(StickFields));
