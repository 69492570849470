import React, { PureComponent } from "react";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import { withStyles, Typography, Button } from "@material-ui/core";
import Parse from "parse";

const styles = (theme) => ({
  textField: {
    width: "100%",
    marginTop: 25,
  },

  fieldContainer: {
    display: "flex",
    flex: 1,
  },
  buttonWrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    margin: "2rem 1rem 1rem 0",
  },
  errorMessage: {
    textAlign: "center",
    color: "red",
  },
  okMessage: {
    textAlign: "center",
    color: "green",
    margin: "2rem 0 0",
  },
  link: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  messageWrapper: {
    margin: "2rem 0 0",
  },
});

class FirstLoginForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {},
      emailInDb: false,
      failedEmailTry: false,
      passwordAlreadySet: false,
      initialPasswordJustSet: false,
    };
  }

  onSubmit = async (values) => {
    const { email, emailInDb } = this.state;
    // defining password
    if (emailInDb) {
      const params = {
        email,
        password: values.password,
      };
      const query = await Parse.Cloud.run("setInitialPassword", params);
      if (query === "modified") {
        this.setState({
          emailInDb: true,
          emailNotFound: false,
          email: values.email,
          initialPasswordJustSet: true,
        });
      }
    }

    // user search
    if (!emailInDb) {
      const params = {
        email: values.email,
      };
      const user = await Parse.Cloud.run("getPristineUser", params);
      switch (user) {
        case "userIsPristine":
          this.setState({
            emailInDb: true,
            emailNotFound: false,
            email: values.email,
          });
          break;
        case "userIsNotPristine":
          this.setState({
            emailInDb: false,
            emailNotFound: false,
            passwordAlreadySet: true,
          });
          break;
        case "userNotFound":
          this.setState({
            emailNotFound: true,
          });
          break;
        default:
      }
    }
  };

  render() {
    const {
      initialValues,
      emailInDb,
      failedEmailTry,
      passwordAlreadySet,
      initialPasswordJustSet,
    } = this.state;
    const { classes, onForgottenPassword } = this.props;

    const required = (value) => (value ? undefined : "Champs obligatoire");
    const passwordRules = (value) =>
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(value)
        ? undefined
        : "Le mot de passe doit contenir au moins une minuscule, une majuscule, un chiffre et un caractère spécial.";
    const composeValidators =
      (...validators) =>
      (value) =>
        validators.reduce(
          (error, validator) => error || validator(value),
          undefined,
        );

    return (
      <>
        {initialPasswordJustSet && (
          <Typography variant="body2" className={classes.okMessage}>
            Le mot de passe a été modifié. Vous pouvez vous connecter.
          </Typography>
        )}
        {!initialPasswordJustSet && (
          <Form
            onSubmit={this.onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form id="firstLoginForm" onSubmit={handleSubmit}>
                <div className={classes.messageWrapper}>
                  {!emailInDb && (
                    <Typography variant="body2">
                      Veuillez renseigner l'email associé à votre compte
                    </Typography>
                  )}
                  {emailInDb && (
                    <Typography variant="body2">
                      Veuillez définir votre mot de passe
                    </Typography>
                  )}
                </div>
                {!emailInDb && (
                  <div className={classes.fieldContainer}>
                    <Field
                      name="email"
                      component={TextField}
                      type="email"
                      placeholder="Adresse email"
                      className={classes.textField}
                      required
                    />
                  </div>
                )}
                {emailInDb && (
                  <div className={classes.fieldContainer}>
                    <Field
                      name="password"
                      component={TextField}
                      type="password"
                      placeholder="Mot de passe"
                      className={classes.textField}
                      validate={composeValidators(required, passwordRules)}
                      required
                    />
                  </div>
                )}
                <div className={classes.buttonWrapper}>
                  <Button
                    type="submit"
                    disabled={submitting || pristine}
                    variant="contained"
                    color="primary"
                  >
                    {!emailInDb && <span>verifier l'email</span>}
                    {emailInDb && <span>définir</span>}
                  </Button>
                </div>
                {failedEmailTry && (
                  <Typography variant="body2" className={classes.errorMessage}>
                    Il n'existe aucun utilisateur associé à cet email
                  </Typography>
                )}
                {passwordAlreadySet && (
                  <Typography variant="body2" className={classes.errorMessage}>
                    Vous avez déjà personnalisé votre mot de passe.{" "}
                    <span
                      className={classes.link}
                      onClick={onForgottenPassword}
                    >
                      Cliquez ici
                    </span>{" "}
                    si vous l'avez oublié.
                  </Typography>
                )}
              </form>
            )}
          />
        )}
      </>
    );
  }
}

export default withStyles(styles)(FirstLoginForm);
