import React, { useState, useEffect } from "react";
import Parse from "parse";
import { makeStyles, Typography, Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: "26px",
  },
  title: {},
  subtitle: {
    color: "rgba(0, 0, 0, 0.55)",
  },
  columns: {
    display: "flex",
  },
  imageColumn: {},
  image: {
    maxWidth: 200,
    maxHeight: 300,
  },
  textColumn: {
    width: 400,
  },
}));

const ProductCard = (props) => {
  const { productId } = props;
  const classes = useStyles();
  const [data, setData] = useState(null);

  const getData = async () => {
    const productQuery = await new Parse.Query("Products")
      .equalTo("ARKTCODART", productId)
      .first();
    let imageUrl = null;
    let imageWidth = 0;
    let imageHeight = 0;

    if (productQuery.get("images") !== undefined) {
      const mainImage = productQuery.get("images")[0].main;
      imageUrl = mainImage.secure_url;
      imageWidth = mainImage.width;
      imageHeight = mainImage.height;
    }

    setData({
      ARKTCODART: productId,
      ARCTLIB01: productQuery.get("ARCTLIB01"),
      ARCTLIB02: productQuery.get("ARCTLIB02"),
      shortDescription: productQuery.get("shortDescription"),
      imageUrl,
      imageWidth,
      imageHeight,
    });
  };

  useEffect(() => {
    if (data === null) {
      getData();
    }
    // adding empty array of dependencies causes firing of warning. useEffect is now executed at each change, but condition inside it makes it light
  });

  if (data === null) {
    return null;
  }

  return (
    <div className={classes.card}>
      <div className={classes.columns}>
        {data.imageUrl !== null && (
          <div className={classes.imageColumn}>
            <img
              src={data.imageUrl}
              className={classes.image}
              alt={data.ARCTLIB01 + " " + data.ARCTLIB02}
            />
          </div>
        )}

        <div className={classes.textColumn}>
          <Typography variant="h6" className={classes.title}>
            {data.ARCTLIB01} {data.ARCTLIB02}
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            Réf {data.ARKTCODART}
          </Typography>
          {data.shortDescription !== undefined && (
            <>
              <div
                dangerouslySetInnerHTML={{ __html: data.shortDescription }}
              />
              <Divider />
            </>
          )}
           {/*alt0160*/}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
