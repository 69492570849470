const discounts = (state = {}, action) => {
  switch (action.type) {
    case "SET_DISCOUNTS": {
      return {
        ...state,
        allDiscounts: action.allDiscounts,
      };
    }
    case "SET_DISCOUNTS_VALIDITY": {
      return {
        ...state,
        discountsValid: action.discountsValid,
      };
    }
    default: {
      return state;
    }
  }
};

export default discounts;
//
// export function getAllDiscounts(state) {
//   return state.discounts.allDiscounts;
// }
