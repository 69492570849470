import React, { PureComponent } from "react";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import Switch from "../../components/form/Switch";
import { withStyles, Button, Grid, Typography } from "@material-ui/core";
import Parse from "parse";

const styles = (theme) => ({
  gridContainer: {
    width: "50%",
  },
  textField: {
    width: "100%",
  },
  selectField: {
    width: "100%",
  },
  fieldContainer: {
    display: "flex",
    flex: 1,
    height: "48px",
  },
  switchContainer: {
    display: "flex",
    flex: 1,
    height: "48px",
    marginTop: "16px",
  },
  buttonWrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    margin: "2rem 1rem 1rem 0",
  },
  errorMessage: {
    textAlign: "center",
    color: "red",
  },
  switchText: {
    lineHeight: "48px",
  },
});

class ClientInfoForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.loadClient();
  }

  loadClient = async () => {
    const { clientCode } = this.props;
    const client = await new Parse.Query("Clients")
      .equalTo("CLKTCODE", clientCode)
      .first();
    const clientData = client.attributes;
    this.setState({
      clientData,
    });
  };

  onSubmit = async (values) => {
    const { readOnly } = this.props;
    if (!!readOnly) {
      console.log("can not save if not master or superuser");
      return null;
    }
    const { clientData } = this.state;
    const { clientCode } = this.props;

    // saving to Mongo (Parse saves only modified fields)
    const client = await new Parse.Query("Clients")
      .equalTo("CLKTCODE", clientCode)
      .first();
    Object.entries(values).forEach(([key, value]) => {
      client.set(key, value);
    });
    await client.save();

    // saving only modified fields to PMI
    const modifiedValues = {};
    Object.entries(values).forEach(([key, value]) => {
      if (value !== clientData[key]) {
        modifiedValues[key] = value;
      }
    });
    //console.log('modifiedValues: ', modifiedValues);
    const params = {
      CLKTCODE: clientCode,
      modifiedValues,
    };
    // updating PMI
    await Parse.Cloud.run("updateClient", params);
  };

  render() {
    const { clientData } = this.state;
    const { classes, readOnly } = this.props;

    return (
      <Form
        onSubmit={this.onSubmit}
        initialValues={clientData}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form id="clientInfoForm" onSubmit={handleSubmit}>
            <Grid container spacing={0} className={classes.gridContainer}>
              <Grid item xs={12}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="CLKTCODE"
                    component={TextField}
                    placeholder="Code client"
                    label="Code client"
                    className={classes.textField}
                    disabled={true}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="CLCTNOM"
                    component={TextField}
                    placeholder="Nom"
                    label="Nom"
                    className={classes.textField}
                    disabled={readOnly}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.switchContainer}>
                  <Field
                    name="preseasonDiscountsAuthorized"
                    component={Switch}
                    type="checkbox"
                    disabled={readOnly}
                  />
                  <Typography variant="body2" className={classes.switchText}>
                    A accès aux remises présaison
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.switchContainer}>
                  <Field
                    name="productsDiscountsAuthorized"
                    component={Switch}
                    type="checkbox"
                    disabled={readOnly}
                  />
                  <Typography variant="body2" className={classes.switchText}>
                    A accès aux remises produits
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.fieldContainer}>
                  <Field
                    name="maxDiscountExclDestocking"
                    component={TextField}
                    type="number"
                    placeholder="Remise maximum applicable hors destockage"
                    label="Remise maximum applicable hors destockage"
                    className={classes.textField}
                    disabled={readOnly}
                  />
                </div>
              </Grid>
            </Grid>
            {!readOnly && (
              <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={12}>
                  <div className={classes.buttonWrapper}>
                    <Button
                      type="submit"
                      disabled={submitting || pristine}
                      variant="contained"
                      color="primary"
                    >
                      enregistrer
                    </Button>
                  </div>
                </Grid>
              </Grid>
            )}
          </form>
        )}
      />
    );
  }
}

export default withStyles(styles)(ClientInfoForm);
