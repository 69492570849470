"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateSuppliesData = void 0;
var moment_1 = __importStar(require("moment"));
var getDaysToGenerate_1 = require("./getDaysToGenerate");
function generateSuppliesData(product) {
    var start = moment_1.now();
    console.log("gsd start", start);
    var supplies = product.supplies, resupplies = product.resupplies, quantities = product.quantities, _a = product.removeNegativeQuantities, removeNegativeQuantities = _a === void 0 ? true : _a;
    var _supplies = [];
    var today = moment_1.default().startOf("day");
    var daysToGenerate = getDaysToGenerate_1.getDaysToGenerate(today, supplies, resupplies);
    var lastDayToGenerate = moment_1.default().startOf("day").add(daysToGenerate, "day");
    Object.entries(supplies).forEach(function (_a) {
        var key = _a[0], article = _a[1];
        var suppliesPerDay = [];
        // generating supplies without quantities
        for (var i = 0; i < daysToGenerate; i++) {
            var currentDate = moment_1.default().startOf("day").add(i, "day");
            var formattedDay = currentDate.format("YYYY-MM-DD");
            var dateToCompare = new Date(formattedDay);
            suppliesPerDay.push({
                date: currentDate,
                dateToCompare: dateToCompare,
                formattedDay: moment_1.default(currentDate).format("DD/MM"),
                quantity: 0,
            });
        }
        // Adding ECTRA + INTER + MAG at day 0 (today)
        suppliesPerDay[0].quantity =
            article.ECTRA /* + article.INTER */ + article.MAG;
        // taking into account unshippedOrders
        article.onUnshippedOrders.orders.forEach(function (unshippedOrder) {
            var orderShippingDateMoment = moment_1.default(unshippedOrder.shippingDate).startOf("day");
            var orderQuantity = unshippedOrder.quantity;
            if (orderShippingDateMoment.isBefore(today)) {
                // all orders with shipping dates prior to today are counted in today
                suppliesPerDay[0].quantity -= orderQuantity;
            }
            else if (orderShippingDateMoment.isBefore(lastDayToGenerate)) {
                // all orders within the segment to generate are accounted
                suppliesPerDay.forEach(function (supplyDay) {
                    if (orderShippingDateMoment.isSame(supplyDay.date)) {
                        if (supplyDay.quantity === undefined) {
                            supplyDay.quantity = -1 * orderQuantity;
                        }
                        else {
                            supplyDay.quantity -= orderQuantity;
                        }
                    }
                });
            }
        });
        // taking into account local production delay & resupplies
        if (resupplies !== undefined) {
            resupplies.forEach(function (resupplyArticle) {
                if (resupplyArticle.ARKTCOMART === article.ARKTCOMART) {
                    // Adding local production delay
                    if (resupplyArticle.localProductionTime !== undefined) {
                        var lpt = resupplyArticle.localProductionTime;
                        var days = lpt.days;
                        suppliesPerDay[days].quantity =
                            suppliesPerDay[days].quantity !== undefined
                                ? suppliesPerDay[days].quantity + lpt.quantity
                                : lpt.quantity;
                    }
                    // Adding resupplies
                    if (resupplyArticle.scheduledResupplies) {
                        suppliesPerDay.forEach(function (supplyDay) {
                            resupplyArticle.scheduledResupplies.forEach(function (_a) {
                                var date = _a.date, quantity = _a.quantity;
                                if (moment_1.default(supplyDay.dateToCompare).isSame(date)) {
                                    supplyDay.quantity =
                                        supplyDay.quantity === undefined
                                            ? typeof quantity === "number"
                                                ? quantity
                                                : parseInt(quantity)
                                            : supplyDay.quantity +
                                                (typeof quantity === "number"
                                                    ? quantity
                                                    : parseInt(quantity));
                                }
                            });
                        });
                    }
                    // Adding productionOrders
                    if (resupplyArticle.productionOrders !== undefined) {
                        resupplyArticle.productionOrders.forEach(function (resupplyDay) {
                            suppliesPerDay.forEach(function (supplyDay, index) {
                                var quantity = typeof resupplyDay.quantity === "number"
                                    ? resupplyDay.quantity
                                    : parseInt(resupplyDay.quantity);
                                // if date is passed let's put now+2days
                                if (index === 0 &&
                                    moment_1.default(supplyDay.dateToCompare).isAfter(resupplyDay.date)) {
                                    var shiftDays = 2;
                                    suppliesPerDay[shiftDays].quantity =
                                        suppliesPerDay[shiftDays].quantity === undefined
                                            ? quantity
                                            : suppliesPerDay[shiftDays].quantity + quantity;
                                }
                                else if (moment_1.default(supplyDay.dateToCompare).isSame(resupplyDay.date)) {
                                    supplyDay.quantity =
                                        supplyDay.quantity === undefined
                                            ? quantity
                                            : supplyDay.quantity + quantity;
                                }
                            });
                        });
                    }
                    // Adding supplierOrders
                    if (resupplyArticle.supplierOrders !== undefined) {
                        resupplyArticle.supplierOrders.forEach(function (resupplyDay) {
                            suppliesPerDay.forEach(function (supplyDay, index) {
                                var quantity = typeof resupplyDay.quantity === "number"
                                    ? resupplyDay.quantity
                                    : parseInt(resupplyDay.quantity);
                                // if date is passed let's put now+2days
                                if (index === 0 &&
                                    moment_1.default(supplyDay.dateToCompare).isAfter(resupplyDay.date)) {
                                    var shiftDays = 2;
                                    suppliesPerDay[shiftDays].quantity =
                                        suppliesPerDay[shiftDays].quantity === undefined
                                            ? quantity
                                            : suppliesPerDay[shiftDays].quantity + quantity;
                                }
                                else if (moment_1.default(supplyDay.dateToCompare).isSame(resupplyDay.date)) {
                                    supplyDay.quantity =
                                        supplyDay.quantity === undefined
                                            ? quantity
                                            : supplyDay.quantity + quantity;
                                }
                            });
                        });
                    }
                }
            });
        }
        // populating quantities: at that point supplies[x].quantity only shows variations. we want to change that to real quantities available per day
        var previousDayQuantity = 0;
        suppliesPerDay.forEach(function (supplyDay) {
            supplyDay.quantity =
                supplyDay.quantity === undefined
                    ? previousDayQuantity
                    : previousDayQuantity + supplyDay.quantity;
            previousDayQuantity = supplyDay.quantity;
        });
        // cautious measure for clients: if at a certain date quantity falls bellow 0 (e.g. -5):
        // - all quantities at previous dates should be deducted by this number (quantities - 5). quantities should not be negative (because calculation would be wrong and it could generate an infinite loop).
        // - all quantities at this date and after should have (quantities + 5)
        /* internal function */
        var negativeQuantitiesRemover = function () {
            var i = suppliesPerDay.length;
            var negativeQuantityFound = false;
            while (i--) {
                if (negativeQuantityFound || suppliesPerDay[i].quantity < 0) {
                    suppliesPerDay[i].quantity = 0;
                    negativeQuantityFound = true;
                }
            }
        };
        if (removeNegativeQuantities) {
            negativeQuantitiesRemover();
        }
        // taking into account quantities in current order
        if ((quantities === null || quantities === void 0 ? void 0 : quantities.constructor) === Object &&
            Object.entries(quantities).length !== 0) {
            quantities.articles.forEach(function (articleOrdered) {
                if (articleOrdered.ARKTCOMART === article.ARKTCOMART) {
                    articleOrdered.shippings.forEach(function (shipping) {
                        if (!!shipping.quantity) {
                            // we only make treatment if we have quantity > 0
                            suppliesPerDay.forEach(function (supplyDay) {
                                var newQuantity = supplyDay.quantity - shipping.quantity;
                                supplyDay.quantity =
                                    removeNegativeQuantities && newQuantity < 0 ? 0 : newQuantity;
                            });
                        }
                    });
                }
            });
        }
        _supplies.push({
            ARKTCOMART: article.ARKTCOMART,
            supplies: suppliesPerDay,
        });
    });
    /* const stop = now();
    console.log("gsd stop", stop, ":", stop - start, "ms"); */
    return _supplies;
}
exports.generateSuppliesData = generateSuppliesData;
