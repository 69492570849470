import React, { PureComponent } from "react";
import Parse from "parse";
import moment from "moment";
import classNames from "classnames";
import { connect } from "react-redux";
import { getLists } from "../../reducers/lists";
import { Form, Field, FormSpy } from "react-final-form";
import createDecorator from "final-form-focus";
import { Select, TextField } from "final-form-material-ui";
import Switch from "../../components/form/Switch";
import AutoSave from "../../components/form/AutoSave";
import AutoCompleteVirtualized from "../../components/form/AutoCompleteVirtualized";
import {
  withStyles,
  Grid,
  Typography,
  DialogActions,
  Button,
  InputAdornment,
} from "@material-ui/core";
import { optionsCreator, getOptions } from "../../utils2";
import SubdirectoryArrowRight from "@material-ui/icons/SubdirectoryArrowRight";

const styles = (theme) => ({
  fieldsContainer: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    minHeight: "430px",
    width: 500,
  },
  textField: {
    width: 400,
  },
  fieldContainer: {
    display: "flex",
    flex: 1,
  },
  fieldText: {
    lineHeight: "48px",
  },
  gridLine: {
    padding: "8px 0",
    lineHeight: 2,
  },
  category: {
    margin: "1rem 0 0 0",
  },
  buttonContainer: {
    alignSelf: "flex-end",
  },
  errorMessage: {
    color: "red",
    textAlign: "center",
    margin: "1rem 0 0",
  },
  switchText: {
    lineHeight: "48px",
  },
  arrowContainer: {
    display: "flex",
    height: "42px",
    alignItems: "flex-end",
    width: "32px",
    opacity: "0.75",
  },
  spacer: {
    marginTop: "48px",
  },
});

const focusOnErrors = createDecorator();

const authorizedProperties = [
  "withCondition",
  "allClients",
  "includedElements",
  "excludedElements",
  "discountCondition",
  "discountGain",
  "startDate",
  "endDate",
  "name",
  "description",
  "type",
  "includedClients",
  "combinable",
  "creator",
  "lastUpdater",
  "createdAt",
  "updatedAt",
];
const authorizedPropertiesPerDiscountType = {
  clientPermanent: [
    "includedGroups",
    "includedTradeNames",
    "startDate",
    "endDate",
    "percentageDiscount",
    "francoMinAmount",
    "preSeasonApplicable",
  ],
  specialDiscount: [
    "minAmount",
    "priceBase",
    "percentageDiscount",
    "includedGroups",
    "includedTradeNames",
    "excludedTradeNames",
    "excludedGroups",
    "excludedClients",
    "allElements",
    "includedCatalogs",
    "includedBrands",
    "includedProducts",
    "includedProductTypes",
    "includedUnivers",
    "excludedCatalogs",
    "excludedBrands",
    "excludedProducts",
    "excludedProductTypes",
    "excludedUnivers",
    "freeProduct",
    "freeProductQuantity",
    "productQuantity",
    "product",
    "francoMinAmount",
  ],
  preSeason: [
    "includedElements",
    "excludedElements",
    "discountCondition",
    "includedClients",
    "includedGroups",
    "includedTradeNames",
    "excludedClients",
    "excludedGroups",
    "excludedTradeNames",
    "allElements",
    "includedCatalogs",
    "includedBrands",
    "includedProducts",
    "includedProductTypes",
    "includedUnivers",
    "excludedCatalogs",
    "excludedBrands",
    "excludedProducts",
    "excludedProductTypes",
    "excludedUnivers",
    "name",
    "description",
    "type",
    "startDate",
    "endDate",
    "earliestOrderDate",
    "priceBase",
    "percentageDiscount",
    "freeProduct",
    "freeProductQuantity",
  ],
  productQuantity: [
    "discountCondition",
    "includedClients",
    "includedGroups",
    "includedTradeNames",
    "excludedClients",
    "excludedGroups",
    "excludedTradeNames",
    "name",
    "description",
    "type",
    "startDate",
    "endDate",
    "minQuantityProduct",
    "minQuantity",
    "priceBase",
    "percentageDiscount",
    "freeProduct",
    "freeProductQuantity",
    "francoMinAmount",
  ],
  minAmount: [
    "includedElements",
    "excludedElements",
    "discountCondition",
    "includedClients",
    "includedGroups",
    "includedTradeNames",
    "excludedClients",
    "excludedGroups",
    "excludedTradeNames",
    "allElements",
    "includedCatalogs",
    "includedBrands",
    "includedProducts",
    "includedProductTypes",
    "includedUnivers",
    "excludedCatalogs",
    "excludedBrands",
    "excludedProducts",
    "excludedProductTypes",
    "excludedUnivers",
    "name",
    "description",
    "type",
    "startDate",
    "endDate",
    "minAmount",
    "priceBase",
    "percentageDiscount",
    "freeProduct",
    "freeProductQuantity",
    "francoMinAmount",
  ],
};

const fieldsWithOptionalChildren = {
  includedElements: [
    [
      "catalog",
      [["includedCatalogs", "Catalogue(s) concerné(s)", "select", "catalog"]],
    ],
    [
      "brand",
      [["includedBrands", "Marque(s) concernée(s)", "select", "brand"]],
    ],
    [
      "product",
      [["includedProducts", "Produit(s) concerné(s)", "select", "products"]],
    ],
    [
      "productType",
      [
        [
          "includedProductTypes",
          "Type(s) de produit concernée(s)",
          "select",
          "productType",
        ],
      ],
    ],
    [
      "univers",
      [["includedUnivers", "Univer(s) concernée(s)", "select", "univers"]],
    ],
  ],
  excludedElements: [
    [
      "catalog",
      [["excludedCatalogs", "Catalogue(s) à exclure", "select", "catalog"]],
    ],
    ["brand", [["excludedBrands", "Marque(s) à exclure", "select", "brand"]]],
    [
      "product",
      [["excludedProducts", "Produit(s) à exclure", "select", "products"]],
    ],
    [
      "productType",
      [
        [
          "excludedProductTypes",
          "Type(s) de produit à exclure",
          "select",
          "productType",
        ],
      ],
    ],
    [
      "univers",
      [["excludedUnivers", "Univer(s) à exclure", "select", "univers"]],
    ],
  ],
  discountCondition: [
    [
      "minAmount",
      [["minAmount", "Minimum d'achat", "numberField", "minAmount", "€"]],
    ],
    [
      "productQuantity",
      [
        ["product", "Produit(s) Acheté(s)", "select", "products"],
        ["productQuantity", "Quantité", "numberField"],
      ],
    ],
  ],
  discountGain: [
    [
      "discount",
      [
        ["priceBase", "Base de prix", "select", "discountPriceBase", false],
        ["percentageDiscount", "Pourcentage de remise", "numberField", "", "%"],
      ],
    ],
    [
      "freeProduct",
      [
        ["freeProduct", "Produit offert", "select", "products"],
        ["freeProductQuantity", "Quantité", "numberField"],
      ],
    ],
    [
      "franco",
      [["francoMinAmount", "Franco à partir de", "numberField", "", "€"]],
    ],
  ],
};

const clientsFields = [
  "includedClients",
  "includedGroups",
  "includedTradeNames",
  "excludedClients",
  "excludedGroups",
  "excludedTradeNames",
];
const arrayOrStringFields = [
  "includedClients",
  "includedGroups",
  "includedTradeNames",
  "excludedClients",
  "excludedGroups",
  "excludedTradeNames",
  "discountGain",
];

class HandleDiscountForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      discountValues: {
        includedElements: [],
        excludedElements: [],
        discountCondition: [],
        discountGain: "",
        allClients: false,
        withCondition: true,
      },
    };
  }

  componentDidMount() {
    this.getDiscountData();
  }

  onSubmit = async (discountValues) => {
    const {
      onHandleNext,
      steps,
      activeStep,
      operation,
      onHandleModalClose,
      currentUser,
    } = this.props;

    // not directly touching discountValues seems mandatory as doing so will prevent from validating the form and thus going to next step
    let values = Object.assign({}, discountValues);

    // because we are submitting the form several times (for each step), we need to check that properties are valid and delete or update them if necessary
    await this._updateProperties(values);

    // updating state
    await this.setState({
      discountValues: values,
    });
    if (activeStep !== steps.length - 1) {
      // if not last step we go to next one
      onHandleNext();
    } else {
      // saving/updating discount to mongodb
      let discount;
      if (operation === "add") {
        const Discounts = Parse.Object.extend("Discounts");
        discount = new Discounts();
      } else {
        // operation === 'modify'
        const { discountId } = this.state;
        discount = await new Parse.Query("Discounts").get(discountId);
      }
      // removing all properties. If we modify, we may change the type of discount. Some old properties not compatible with new type could stay in base
      Object.entries(discount.attributes).forEach((attribute) => {
        discount.unset(attribute[0]);
      });

      // adding new properties
      await Promise.all(
        Object.entries(discountValues).map(async ([key, value]) => {
          // converting includedClients, includedGroups, includedTradeNames, excludedClients, excludedGroups, excludedTradeNames to array if necessary because select is sometimes multiple and sometimes not (when type === clientExceptional or type === clientPermanent)
          if (arrayOrStringFields.includes(key) && !Array.isArray(value)) {
            value = value.split();
          }
          // converting includedClients, includedGroups, includedTrademarks and products to pointers
          if (key === "includedClients" || key === "excludedClients") {
            const queryClients = await new Parse.Query("Clients")
              .containedIn("CLKTCODE", value)
              .find();
            discount.set(key, queryClients);
            //console.log('queryClients: ', queryClients);
          } else if (key === "includedGroups" || key === "excludedGroups") {
            const queryGroups = await new Parse.Query("Groups")
              .containedIn("CLKTCODE", value)
              .find();
            discount.set(key, queryGroups);
            //console.log('queryGroups: ', queryGroups);
          } else if (
            key === "includedTradeNames" ||
            key === "excludedTradeNames"
          ) {
            const queryTrademarks = await new Parse.Query("Tradenames")
              .containedIn("CLCTGROUPE", value)
              .find();
            discount.set(key, queryTrademarks);
            //console.log('query: ', queryTrademarks);
          } else if (
            key === "product" ||
            key === "freeProduct" ||
            key === "minQuantityProduct"
          ) {
            const queryProducts = await new Parse.Query("Products")
              .containedIn("ARKTCODART", value)
              .find();
            discount.set(key, queryProducts);
            //console.log('query: ', queryProducts);
          } else if (
            key === "startDate" ||
            key === "endDate" ||
            key === "earliestOrderDate"
          ) {
            const date = moment.utc(value).toDate();
            discount.set(key, date);
          } else {
            discount.set(key, value);
          }
        }),
      );

      if (operation === "add") {
        discount.set("creator", currentUser);
      }
      discount.set("lastUpdater", currentUser);
      //console.log('discount before save: ', discount);
      // saving
      try {
        await discount.save();
      } catch (error) {
        console.log(error.message);
      }
      const modalMessageType =
        operation === "add" ? "discountAdded" : "discountUpdated";

      onHandleModalClose(modalMessageType);
    }
  };

  // getting discount from mongo
  getDiscountData = async () => {
    const { discountId, operation } = this.props;
    if (operation === "modify") {
      const discount = await new Parse.Query("Discounts")
        .include("includedClients")
        .include("excludedClients")
        .include("includedGroups")
        .include("excludedGroups")
        .include("includedTradeNames")
        .include("excludedTradeNames")
        .include("minQuantityProduct")
        .include("product")
        .include("freeProduct")
        .get(discountId);

      let discountValues = Object.assign({}, discount.attributes);
      // converting pointers to values
      Object.entries(discount.attributes).forEach(([key, value]) => {
        // key is the property, value can be an array of pointers
        // clients
        if (clientsFields.includes(key)) {
          if (key === "includedTradeNames" || key === "excludedTradeNames") {
            const newValue = [];
            value.forEach((pointer) => {
              newValue.push(pointer.get("CLCTGROUPE"));
            });
            discountValues[key] = newValue;
          } else {
            const newValue = [];
            value.forEach((pointer) => {
              newValue.push(pointer.get("CLKTCODE"));
            });
            discountValues[key] = newValue;
          }
        }
        // products
        if (
          key === "product" ||
          key === "freeProduct" ||
          key === "minQuantityProduct"
        ) {
          const newValue = [];
          value.forEach((pointer) => {
            newValue.push(pointer.get("ARKTCODART"));
          });
          discountValues[key] = newValue;
        }
        // dates
        if (
          key === "startDate" ||
          key === "endDate" ||
          key === "earliestOrderDate"
        ) {
          discountValues[key] = moment(value).format("YYYY-MM-DD");
        }
      });

      this.setState({
        discountId,
        discountValues,
      });
    }
  };
  _updateProperties = async (newDiscountValues) => {
    const { discountValues } = this.state;
    const { activeStep } = this.props;

    // removing properties that could stay if you click the back button and change fields values (for example the includedElements)
    // we only remove the properties set by optional child fields here
    Object.entries(fieldsWithOptionalChildren).forEach(
      ([key, optionsAndTheirOptionalChildren]) => {
        optionsAndTheirOptionalChildren.forEach(
          (optionAndItsOptionalChildren) => {
            const optionValue = optionAndItsOptionalChildren[0];
            const arrayOfOptionalChildren = optionAndItsOptionalChildren[1];
            if (!newDiscountValues[key].includes(optionValue)) {
              arrayOfOptionalChildren.forEach((optionalChildren) => {
                if (newDiscountValues[optionalChildren[0]] !== undefined) {
                  console.log(
                    optionalChildren[0] +
                      " should have a parent. we delete it.",
                  );
                  delete newDiscountValues[optionalChildren[0]];
                }
              });
            }
          },
        );
      },
    );
    // discount types only allow specific properties defined in authorizedPropertiesPerDiscountType.
    // We check that they do not have other properties and delete them if necessary. (e.g. discount type "clientExceptional" can't have the property "includedProducts")
    const discountType = newDiscountValues.type;
    if (discountType !== undefined) {
      Object.entries(newDiscountValues).forEach(([name, value]) => {
        if (
          !authorizedProperties.includes(name) &&
          !authorizedPropertiesPerDiscountType[discountType].includes(name)
        ) {
          console.log(
            'property "' +
              name +
              '" is not authorized for discount type "' +
              discountType +
              '". Removing.',
          );
          delete newDiscountValues[name];
        }
      });
    }
    // some fields are only allowed it another one has the correct value
    if (activeStep === 0) {
      if (newDiscountValues.type === "clientPermanent") {
        // this is necessary because francoMinAmount needs to have franco set to be kept
        newDiscountValues.discountGain = ["franco", "discount"];
      } else if (newDiscountValues.type === "minAmount") {
        // same as above. Field minAmount has to hav discountCondition set to "minAmount"
        newDiscountValues.discountCondition = ["minAmount"];
      }
    }
    // if some switch are changed, some values should be erased or emptied
    if (newDiscountValues.allClients) {
      delete newDiscountValues.includedClients;
      delete newDiscountValues.includedTradeNames;
      delete newDiscountValues.includedGroups;
    }
    if (newDiscountValues.allElements) {
      delete newDiscountValues.includedCatalogs;
      delete newDiscountValues.includedBrands;
      delete newDiscountValues.includedProducts;
      delete newDiscountValues.includedProductTypes;
      delete newDiscountValues.includedUnivers;
    }
    if (!newDiscountValues.withCondition) {
      newDiscountValues.discountCondition = [];
      delete newDiscountValues.minAmount;
      delete newDiscountValues.minQuantityProduct;
      delete newDiscountValues.minQuantity;
    }
    // if discount type is clientPermanent then there should be either includedClients, includedGroups or includedTradeNames
    if (newDiscountValues.type === "clientPermanent") {
      if (
        discountValues.includedClients !== newDiscountValues.includedClients
      ) {
        delete newDiscountValues.includedGroups;
        delete newDiscountValues.includedTradeNames;
      } else if (
        discountValues.includedGroups !== newDiscountValues.includedGroups
      ) {
        delete newDiscountValues.includedClients;
        delete newDiscountValues.includedTradeNames;
      } else if (
        discountValues.includedTradeNames !==
        newDiscountValues.includedTradeNames
      ) {
        delete newDiscountValues.includedClients;
        delete newDiscountValues.includedGroups;
      }
    }
  };

  // used by FormSpy
  _updateState = async (values) => {
    const { discountValues } = this.state;
    const { onChosenDiscountType } = this.props;

    // setting a more specific dialogTitle (with discount type)
    if (values.type !== undefined) {
      if (
        discountValues.type === undefined ||
        (discountValues.type !== undefined &&
          values.type !== discountValues.type)
      ) {
        switch (values.type) {
          case "clientPermanent":
            onChosenDiscountType("Création d'une remise client");
            break;
          case "minAmount":
            onChosenDiscountType("Création d'une remise avec montant minimum");
            break;
          case "preSeason":
            onChosenDiscountType("Création d'une remise de présaison");
            break;
          case "productQuantity":
            onChosenDiscountType("Création d'une remise quantitative produit");
            break;
          case "specialDiscount":
            onChosenDiscountType("Création d'une remise spécifique");
            break;
          default:
        }
      }
    }

    await this._updateProperties(values);

    this.setState({
      discountValues: values,
    });
  };

  isStepOptional = (step) => {
    const { optionalSteps } = this.props;
    return optionalSteps === undefined ? false : optionalSteps.includes(step);
  };

  render() {
    const { discountValues } = this.state;
    const {
      classes,
      operation,
      listsOptions,
      steps,
      activeStep,
      onHandleBack,
      onHandleSkip,
    } = this.props;
    //const startDateLabel = (discountValues.type !== 'preSeason') ? "Date de début" : "Date de livraison au plus tôt (commande)";
    //const endDateLabel = (discountValues.type !== 'preSeason') ? "Date de fin" : "Date butoire de commande";
    const dateTitle =
      discountValues.type !== "preSeason"
        ? "Période d'application"
        : "Période de présaison";
    const validationButtonText = operation === "add" ? "Ajouter" : "Modifier";
    const nextButtonText =
      activeStep === steps.length - 1 ? validationButtonText : "Suivant";
    const isClientDiscount = discountValues.type === "clientPermanent";

    /**********************************************************/
    /*********************** validators ***********************/
    /**********************************************************/

    const required = (value) => (value ? undefined : "Champs obligatoire");
    const onlyCharsNbrs = (value) =>
      /^[a-zA-Z0-9]+$/.test(value)
        ? undefined
        : "Ne doit contenir que des lettres ou chiffres";
    const atLeastOneClientConditionIsRequired = (value, allValues) =>
      !allValues.allClients &&
      !allValues.includedClients &&
      !allValues.includedTradeNames &&
      !allValues.includedGroups
        ? "Il faut soit préciser tous les clients ou au moins un magasin, un groupement ou une enseigne à inclure ou exclure"
        : undefined;
    const atLeastOneElementConditionIsRequired = (value, allValues) =>
      !allValues.allElements &&
      !allValues.includedElements.length &&
      !allValues.includedCatalogs &&
      !allValues.includedBrands &&
      !allValues.includedProducts &&
      !allValues.includedProductTypes &&
      !allValues.includedUnivers
        ? "Il faut soit préciser tous les produits ou au moins un produit"
        : undefined;
    const composeValidators =
      (...validators) =>
      (value) =>
        validators.reduce(
          (error, validator) => error || validator(value),
          undefined,
        );

    /**********************************************************/
    /********************** option fields *********************/
    /**********************************************************/

    const manageOptionalFields = (
      fieldsWithOptionalChildren,
      parentField,
      validationCondition,
    ) => {
      const { discountValues } = this.state;
      if (discountValues === undefined) {
        return null;
      }
      //const validate = (validationCondition !== undefined) ? validationCondition : required;

      let dataToReturn = [];
      Object.entries(fieldsWithOptionalChildren).forEach(
        ([key, optionsAndTheirOptionalChildren]) => {
          const parentFieldValues = discountValues[parentField];
          optionsAndTheirOptionalChildren.forEach(
            (optionAndItsOptionalChildren) => {
              const optionValue = optionAndItsOptionalChildren[0];
              const arrayOfOptionalChildren = optionAndItsOptionalChildren[1];

              if (
                key === parentField &&
                parentFieldValues.includes(optionValue)
              ) {
                arrayOfOptionalChildren.forEach((optionalChildren) => {
                  const fieldName = optionalChildren[0];
                  const fieldLabel = optionalChildren[1];
                  const fieldType = optionalChildren[2];
                  const fieldListName = optionalChildren[3];
                  const inputAdornment = optionalChildren[4];
                  const inputProps =
                    inputAdornment !== undefined
                      ? {
                          endAdornment: (
                            <InputAdornment position="end">
                              {inputAdornment}
                            </InputAdornment>
                          ),
                        }
                      : {};

                  if (fieldType === "select") {
                    // console.log('discountValues: ', discountValues);
                    // console.log('fieldName: ', fieldName);
                    const isMultiple =
                      optionalChildren[4] !== undefined
                        ? optionalChildren[4]
                        : true;
                    const options = getOptions(listsOptions, fieldListName);
                    const listOptions = listsOptions.find(
                      (listOptions) => listOptions.listName === fieldListName,
                    ).options;
                    let initValue = "";
                    if (discountValues[fieldName] !== undefined) {
                      initValue = isMultiple
                        ? listOptions.filter((option) =>
                            discountValues[fieldName].includes(option.value),
                          )
                        : listOptions.find(
                            (option) =>
                              option.value === discountValues[fieldName],
                          );
                    } else {
                      initValue = isMultiple ? [options[0]] : options[0];
                    }
                    // console.log('listOptions: ', listOptions);
                    // console.log('fieldName: ', fieldName);
                    // console.log('discountValues[fieldName]: ', discountValues[fieldName]);
                    // console.log('isMultiple: ', isMultiple);
                    // console.log('initValue: ', initValue);
                    dataToReturn.push(
                      <Grid
                        key={optionValue + fieldListName}
                        container
                        spacing={0}
                        className={classes.gridContainer}
                      >
                        <Grid item xs={12}>
                          <div className={classes.fieldContainer}>
                            <div className={classes.arrowContainer}>
                              <SubdirectoryArrowRight />
                            </div>
                            <AutoCompleteVirtualized
                              name={fieldName}
                              label={fieldLabel}
                              multiple={isMultiple}
                              options={getOptions(listsOptions, fieldListName)}
                              initValue={initValue}
                            />
                            {/*
                      <Field
                        name={fieldName}
                        label={fieldLabel}
                        component={Select}
                        fullWidth
                        multiple={isMultiple}
                        format={value => value || []}
                        className={classes.selectField}
                        validate={required}
                      >
                        {optionsCreator(listsOptions, fieldListName)}
                      </Field>
                      */}
                          </div>
                        </Grid>
                      </Grid>,
                    );
                  }

                  if (fieldType === "numberField") {
                    dataToReturn.push(
                      <Grid
                        key={optionValue}
                        container
                        spacing={0}
                        className={classes.gridContainer}
                      >
                        <Grid item xs={6}>
                          <div className={classes.fieldContainer}>
                            <div className={classes.arrowContainer}>
                              <SubdirectoryArrowRight />
                            </div>
                            <Field
                              name={fieldName}
                              component={TextField}
                              label={fieldLabel}
                              type="number"
                              fullWidth
                              validate={required}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={inputProps}
                            />
                          </div>
                        </Grid>
                      </Grid>,
                    );
                  }
                });
              }
            },
          );
        },
      );
      return <>{dataToReturn}</>;
    };

    return (
      <Form
        onSubmit={this.onSubmit}
        decorators={[focusOnErrors]}
        initialValues={discountValues}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
          invalid,
        }) => (
          <form id="editForm" onSubmit={handleSubmit}>
            {false && (
              <div>
                <pre>{JSON.stringify(this.state, null, 2)}</pre>
              </div>
            )}
            <div className={classes.fieldsContainer}>
              {/* Hiding or showing a field reloads the form values from state. FormSpy is there to update the state everytime there's a change to keep this change */}
              <FormSpy
                subscription={{ values: true }}
                component={AutoSave}
                onFormChange={this._updateState}
              />

              {/****************************************************************/
              /************************* activeStep 0 *************************/
              /****************************************************************/
              /******************* Nom, description et type *******************/
              /****************************************************************/}
              {activeStep === 0 && (
                <>
                  <Typography variant="body1" className={classes.category}>
                    Informations générales :
                  </Typography>
                  <Grid container spacing={0} className={classes.gridContainer}>
                    <Grid item xs={12}>
                      <div className={classes.fieldContainer}>
                        <Field
                          name="name"
                          label="Nom de l'opération"
                          placeholder="Nom de l'opération"
                          component={TextField}
                          fullWidth
                          validate={composeValidators(required, onlyCharsNbrs)}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0} className={classes.gridContainer}>
                    <Grid item xs={12}>
                      <div className={classes.fieldContainer}>
                        <Field
                          name="description"
                          label="Description"
                          placeholder="Description"
                          component={TextField}
                          fullWidth
                          validate={required}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0} className={classes.gridContainer}>
                    <Grid item xs={12}>
                      <div className={classes.fieldContainer}>
                        <Field
                          name="type"
                          label="Type de remise"
                          component={Select}
                          fullWidth
                          className={classes.selectField}
                          validate={required}
                        >
                          {optionsCreator(listsOptions, "discountType")}
                        </Field>
                      </div>
                    </Grid>
                  </Grid>
                </>
              )}

              {/****************************************************************/
              /************************* activeStep 1 *************************/
              /****************************************************************/
              /************************** Périmètre ***************************/
              /****************************************************************/}
              {activeStep === 1 && (
                <>
                  <Typography variant="body1" className={classes.category}>
                    Périmètre clients :
                  </Typography>

                  {!isClientDiscount && (
                    <>
                      <Grid
                        container
                        spacing={0}
                        className={classes.gridContainer}
                      >
                        <Grid item xs={12}>
                          <div className={classes.fieldContainer}>
                            <Field
                              name="allClients"
                              component={Switch}
                              type="checkbox"
                            />
                            <Typography
                              variant="body2"
                              className={classes.switchText}
                            >
                              Tous les clients
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        spacing={0}
                        className={classes.gridContainer}
                      >
                        <Grid item xs={12}>
                          <div className={classes.fieldContainer}>
                            <Field
                              name="includedClients"
                              label="Magasin(s) concerné(s)"
                              component={Select}
                              fullWidth
                              multiple
                              format={(value) => value || []}
                              className={classes.selectField}
                              validate={atLeastOneClientConditionIsRequired}
                              disabled={discountValues.allClients}
                            >
                              {optionsCreator(listsOptions, "clients")}
                            </Field>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={0}
                        className={classes.gridContainer}
                      >
                        <Grid item xs={12}>
                          <div className={classes.fieldContainer}>
                            <Field
                              name="includedGroups"
                              label="Groupement(s) concerné(s)"
                              component={Select}
                              fullWidth
                              multiple
                              format={(value) => value || []}
                              className={classes.selectField}
                              validate={atLeastOneClientConditionIsRequired}
                              disabled={discountValues.allClients}
                            >
                              {optionsCreator(listsOptions, "groups")}
                            </Field>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={0}
                        className={classes.gridContainer}
                      >
                        <Grid item xs={12}>
                          <div className={classes.fieldContainer}>
                            <Field
                              name="includedTradeNames"
                              label="Enseigne(s) concernée(s)"
                              component={Select}
                              fullWidth
                              multiple
                              format={(value) => value || []}
                              className={classes.selectField}
                              validate={atLeastOneClientConditionIsRequired}
                              disabled={discountValues.allClients}
                            >
                              {optionsCreator(listsOptions, "tradenames")}
                            </Field>
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {/* for client discount you can only pick on client, or one group or one tradename */}
                  {isClientDiscount && (
                    <>
                      <Grid
                        container
                        spacing={0}
                        className={classes.gridContainer}
                      >
                        <Grid item xs={12}>
                          <div className={classes.fieldContainer}>
                            <Field
                              key={discountValues.includedClients}
                              name="includedClients"
                              label="Magasin concerné"
                              component={Select}
                              fullWidth
                              multiple={false}
                              format={(value) => value || []}
                              className={classes.selectField}
                              validate={atLeastOneClientConditionIsRequired}
                            >
                              {optionsCreator(listsOptions, "clients")}
                            </Field>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={0}
                        className={classes.gridContainer}
                      >
                        <Grid item xs={12}>
                          <div className={classes.fieldContainer}>
                            <Field
                              key={discountValues.includedGroups}
                              name="includedGroups"
                              label="Groupement concerné"
                              component={Select}
                              fullWidth
                              multiple={false}
                              format={(value) => value || []}
                              className={classes.selectField}
                              validate={atLeastOneClientConditionIsRequired}
                            >
                              {optionsCreator(listsOptions, "groups")}
                            </Field>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={0}
                        className={classes.gridContainer}
                      >
                        <Grid item xs={12}>
                          <div className={classes.fieldContainer}>
                            <Field
                              key={discountValues.includedTradeNames}
                              name="includedTradeNames"
                              label="Enseigne concernée"
                              component={Select}
                              fullWidth
                              multiple={false}
                              format={(value) => value || []}
                              className={classes.selectField}
                              validate={atLeastOneClientConditionIsRequired}
                            >
                              {optionsCreator(listsOptions, "tradenames")}
                            </Field>
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {!isClientDiscount && (
                    <>
                      <Grid
                        container
                        spacing={0}
                        className={classes.gridContainer}
                      >
                        <Grid item xs={12}>
                          <div className={classes.fieldContainer}>
                            <Field
                              name="excludedClients"
                              label="Magasin(s) à exclure"
                              component={Select}
                              fullWidth
                              multiple
                              format={(value) => value || []}
                              className={classes.selectField}
                            >
                              {optionsCreator(listsOptions, "clients")}
                            </Field>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={0}
                        className={classes.gridContainer}
                      >
                        <Grid item xs={12}>
                          <div className={classes.fieldContainer}>
                            <Field
                              name="excludedGroups"
                              label="Groupement(s) à exclure"
                              component={Select}
                              fullWidth
                              multiple
                              format={(value) => value || []}
                              className={classes.selectField}
                            >
                              {optionsCreator(listsOptions, "groups")}
                            </Field>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={0}
                        className={classes.gridContainer}
                      >
                        <Grid item xs={12}>
                          <div className={classes.fieldContainer}>
                            <Field
                              name="excludedTradeNames"
                              label="Enseigne(s) à exclure"
                              component={Select}
                              fullWidth
                              multiple
                              format={(value) => value || []}
                              className={classes.selectField}
                            >
                              {optionsCreator(listsOptions, "tradenames")}
                            </Field>
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {(discountValues.type === "preSeason" ||
                    discountValues.type === "minAmount" ||
                    discountValues.type === "specialDiscount") && (
                    <>
                      <Typography
                        variant="body1"
                        className={classNames(classes.category, classes.spacer)}
                      >
                        Périmètre produits :
                      </Typography>

                      {discountValues.type === "specialDiscount" && (
                        <Typography variant="caption">
                          Note: Dans le cas d'une remise spécifique, le
                          périmètre produits est utile uniquement si cette
                          dernière sera composée d'une remise sur minimum
                          d'achat.
                          <br />
                          Si ça n'est pas le cas, vous pouvez cocher "Tous les
                          produits"
                        </Typography>
                      )}

                      <Grid
                        container
                        spacing={0}
                        className={classes.gridContainer}
                      >
                        <Grid item xs={12}>
                          <div className={classes.fieldContainer}>
                            <Field
                              name="allElements"
                              component={Switch}
                              type="checkbox"
                            />
                            <Typography
                              variant="body2"
                              className={classes.switchText}
                            >
                              Tous les produits
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        spacing={0}
                        className={classes.gridContainer}
                      >
                        <Grid item xs={12}>
                          <div className={classes.fieldContainer}>
                            <Field
                              name="includedElements"
                              label="Element(s) concerné(s)"
                              component={Select}
                              fullWidth
                              multiple
                              format={(value) => value || []}
                              className={classes.selectField}
                              validate={atLeastOneElementConditionIsRequired}
                              disabled={discountValues.allElements}
                            >
                              {optionsCreator(listsOptions, "discountBase")}
                            </Field>
                          </div>
                        </Grid>
                      </Grid>
                      {manageOptionalFields(
                        fieldsWithOptionalChildren,
                        "includedElements",
                        atLeastOneElementConditionIsRequired,
                      )}

                      <Grid
                        container
                        spacing={0}
                        className={classes.gridContainer}
                      >
                        <Grid item xs={12}>
                          <div className={classes.fieldContainer}>
                            <Field
                              name="excludedElements"
                              label="Element(s) à exclure"
                              component={Select}
                              fullWidth
                              multiple
                              format={(value) => value || []}
                              className={classes.selectField}
                              validate={required}
                              disabled={discountValues.allElements}
                            >
                              {optionsCreator(listsOptions, "discountBase")}
                            </Field>
                          </div>
                        </Grid>
                      </Grid>
                      {manageOptionalFields(
                        fieldsWithOptionalChildren,
                        "excludedElements",
                      )}
                    </>
                  )}
                </>
              )}

              {/****************************************************************/
              /************************* activeStep 2 *************************/
              /****************************************************************/
              /**************************** Période ***************************/
              /****************************************************************/}
              {activeStep === 2 && (
                <>
                  <Typography variant="body1" className={classes.category}>
                    {dateTitle} :
                  </Typography>
                  <Grid container spacing={0} className={classes.gridContainer}>
                    <Grid item xs={12}>
                      <div className={classes.fieldContainer}>
                        <Field
                          name="startDate"
                          component={TextField}
                          type="date"
                          label="Date de début"
                          fullWidth
                          validate={required}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0} className={classes.gridContainer}>
                    <Grid item xs={12}>
                      <div className={classes.fieldContainer}>
                        <Field
                          name="endDate"
                          component={TextField}
                          type="date"
                          label="Date de fin"
                          fullWidth
                          validate={required}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </>
              )}

              {/****************************************************************/
              /************************* activeStep 3 *************************/
              /****************************************************************/
              /********************* Conditions et remises ********************/
              /****************************************************************/}
              {activeStep === 3 && (
                <>
                  <FormSpy
                    subscription={{ values: true }}
                    component={AutoSave}
                    onFormChange={this._updateState}
                  />

                  {discountValues.type !== "clientPermanent" && (
                    <>
                      <Typography variant="body1" className={classes.category}>
                        Conditions de remise :
                      </Typography>

                      {discountValues.type === "specialDiscount" && (
                        <>
                          <Grid
                            container
                            spacing={0}
                            className={classes.gridContainer}
                          >
                            <Grid item xs={12}>
                              <div className={classes.fieldContainer}>
                                <Field
                                  name="withCondition"
                                  component={Switch}
                                  type="checkbox"
                                />
                                <Typography
                                  variant="body2"
                                  className={classes.switchText}
                                >
                                  Avec condition(s)
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            spacing={0}
                            className={classes.gridContainer}
                          >
                            <Grid item xs={12}>
                              <div className={classes.fieldContainer}>
                                <Field
                                  name="discountCondition"
                                  label="Types de remise"
                                  component={Select}
                                  fullWidth
                                  multiple
                                  format={(value) => value || []}
                                  className={classes.selectField}
                                  validate={required}
                                  disabled={!discountValues.withCondition}
                                >
                                  {optionsCreator(
                                    listsOptions,
                                    "discountCondition",
                                  )}
                                </Field>
                              </div>
                            </Grid>
                          </Grid>
                          {manageOptionalFields(
                            fieldsWithOptionalChildren,
                            "discountCondition",
                          )}
                        </>
                      )}

                      {discountValues.type === "productQuantity" && (
                        <>
                          <Grid
                            container
                            spacing={0}
                            className={classes.gridContainer}
                          >
                            <Grid item xs={12}>
                              <div className={classes.fieldContainer}>
                                <Field
                                  name="minQuantityProduct"
                                  label="Produit"
                                  component={Select}
                                  fullWidth
                                  multiple
                                  format={(value) => value || []}
                                  className={classes.selectField}
                                  validate={required}
                                  disabled={!discountValues.withCondition}
                                >
                                  {optionsCreator(listsOptions, "products")}
                                </Field>
                              </div>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            spacing={0}
                            className={classes.gridContainer}
                          >
                            <Grid item xs={12}>
                              <div className={classes.fieldContainer}>
                                <Field
                                  name="minQuantity"
                                  component={TextField}
                                  label="Quantité"
                                  type="number"
                                  fullWidth
                                  validate={required}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </>
                      )}

                      {discountValues.type === "minAmount" && (
                        <>
                          <Grid
                            container
                            spacing={0}
                            className={classes.gridContainer}
                          >
                            <Grid item xs={12}>
                              <div className={classes.fieldContainer}>
                                <Field
                                  name="minAmount"
                                  component={TextField}
                                  label="Minimum d'achat"
                                  type="number"
                                  fullWidth
                                  validate={required}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        €
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </>
                      )}

                      {discountValues.type === "preSeason" && (
                        <>
                          <Grid
                            container
                            spacing={0}
                            className={classes.gridContainer}
                          >
                            <Grid item xs={12}>
                              <div className={classes.fieldContainer}>
                                <Field
                                  name="earliestOrderDate"
                                  component={TextField}
                                  type="date"
                                  label="Date de livraison au plus tôt (commande)"
                                  fullWidth
                                  validate={required}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                  <Typography variant="body1" className={classes.category}>
                    Gains :
                  </Typography>

                  {discountValues.type !== "clientPermanent" && (
                    <>
                      <Grid
                        container
                        spacing={0}
                        className={classes.gridContainer}
                      >
                        <Grid item xs={12}>
                          <div className={classes.fieldContainer}>
                            <Field
                              name="discountGain"
                              label="Types de gain"
                              component={Select}
                              fullWidth
                              format={(value) => value || []}
                              className={classes.selectField}
                              validate={required}
                            >
                              {optionsCreator(listsOptions, "discountGain")}
                            </Field>
                          </div>
                        </Grid>
                      </Grid>
                      {manageOptionalFields(
                        fieldsWithOptionalChildren,
                        "discountGain",
                      )}
                      {/*
                  <Grid container spacing={0} className={classes.gridContainer}>
                    <Grid item xs={6}>
                      <div className={classes.fieldContainer}>
                        <Field
                          name="priceBase"
                          label="Base de prix"
                          component={Select}
                          fullWidth
                          className={classes.selectField}
                          validate={required}
                        >
                          {optionsCreator(listsOptions, 'discountPriceBase')}
                        </Field>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0} className={classes.gridContainer}>
                    <Grid item xs={6}>
                      <div className={classes.fieldContainer}>
                        <Field
                          name="percentage"
                          component={TextField}
                          label="Pourcentage de remise"
                          type="number"
                          fullWidth
                          validate={required}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>*/}
                    </>
                  )}

                  {discountValues.type === "clientPermanent" && (
                    <>
                      <Grid
                        container
                        spacing={0}
                        className={classes.gridContainer}
                      >
                        <Grid item xs={12}>
                          <div className={classes.fieldContainer}>
                            <Field
                              name="percentageDiscount"
                              component={TextField}
                              label="Pourcentage de remise"
                              type="number"
                              fullWidth
                              validate={required}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={0}
                        className={classes.gridContainer}
                      >
                        <Grid item xs={12}>
                          <div className={classes.fieldContainer}>
                            <Field
                              name="francoMinAmount"
                              component={TextField}
                              label="Franco à partir de"
                              type="number"
                              fullWidth
                              validate={required}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    €
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      {discountValues.type !== "preSeason" && (
                        <Grid
                          container
                          spacing={0}
                          className={classes.gridContainer}
                        >
                          <Grid item xs={12}>
                            <div className={classes.fieldContainer}>
                              <Field
                                name="preSeasonApplicable"
                                component={Switch}
                                type="checkbox"
                              />
                              <Typography
                                variant="body2"
                                className={classes.switchText}
                              >
                                Remise de présaison applicable
                              </Typography>
                            </div>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}
                  <Grid container spacing={0} className={classes.gridContainer}>
                    <Grid item xs={12}>
                      <div className={classes.fieldContainer}>
                        <Field
                          name="combinable"
                          component={Switch}
                          type="checkbox"
                        />
                        <Typography
                          variant="body2"
                          className={classes.switchText}
                        >
                          Cumulable avec d'autres remises
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </>
              )}
            </div>
            <DialogActions>
              <Button
                disabled={activeStep === 0}
                onClick={onHandleBack}
                className={classes.button}
              >
                Retour
              </Button>
              {this.isStepOptional(activeStep) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onHandleSkip}
                  className={classes.button}
                >
                  Sauter
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitting}
                className={classes.button}
              >
                {nextButtonText}
              </Button>
            </DialogActions>
          </form>
        )}
      />
    );
  }
}
export default connect(
  (state) => ({
    listsOptions: getLists(state),
  }),
  {},
)(withStyles(styles)(HandleDiscountForm));
