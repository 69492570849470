const lists = (state = {}, action) => {
  switch (action.type) {
    case "SET_LISTS": {
      return {
        ...state,
        lists: action.lists,
      };
    }
    case "SET_LISTS_VALIDITY": {
      return {
        ...state,
        listsValid: action.listsValid,
      };
    }
    default: {
      return state;
    }
  }
};

export default lists;

export function getLists(state) {
  return state.lists.lists;
}
