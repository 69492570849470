import React, { useState, useEffect, useRef } from "react";
import Parse from "parse";
import { useSelector } from "react-redux";
import { getLists } from "../../reducers/lists";
import { LineChart, Line, XAxis, YAxis, Tooltip } from "recharts";
import { makeStyles, Typography, Grid, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    marginTop: "1rem",
  },
  lineChartAndtoWrapper: {
    display: "flex",
  },
  lineChartWrapper: {
    display: "flex",
    justifyContent: "center",
    height: 250,
  },
  title: {
    textAlign: "center",
    padding: "8px 0",
  },
  list: {
    maxHeight: 300,
    margin: "2rem 2rem 0 0",
    overflow: "auto",
  },
  tooltip: {
    margin: "8px",
  },
  tooltipLabel: {
    fontWeight: "bold",
    color: "#666666",
    margin: "3px 0",
  },
  tooltipValue: {
    display: "flex",
    color: "#666666",
    margin: "3px 0",
  },
  tooltipValueTo: {
    width: 85,
    textAlign: "right",
  },
}));
const colors = [
  "#9dd866",
  "#0b84a5",
  "#ca472f",
  "#f6c85f",
  "#6f4e7c",
  "#ffa056",
  "#8dddd0",
  "#a7a7a7",
  "#ca2fb7",
];

const TurnoverPerMonthChart = (props) => {
  const classes = useStyles();
  const { shop, group, tradeName, commercial } = props;
  const listsOptions = useSelector(getLists);

  const [turnoverPerMonthData, setTurnoverPerMonthData] = useState(null);
  const [cumulativeTurnoverPerMonthData, setCumulativeTurnoverPerMonthData] =
    useState(null);
  const [
    cumulativeTurnoverPerMonthForShippingNextYearData,
    setCumulativeTurnoverPerMonthForShippingNextYearData,
  ] = useState(null);
  const [preordersTurnoverPerMonthData, setPreordersTurnoverPerMonthData] =
    useState(null);
  const [
    cumulativePreordersTurnoverPerMonthData,
    setCumulativePreordersTurnoverPerMonthData,
  ] = useState(null);
  const gridWidth = useRef(999);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [shop, group, tradeName, commercial, gridWidth]);

  const getData = async () => {
    let filterName = "";
    if (shop !== "ALL") {
      filterName = "cl_" + shop;
    } else if (group !== "ALL") {
      filterName = "gr_" + group;
    } else if (tradeName !== "ALL") {
      filterName = "tn_" + tradeName;
    } else if (commercial !== "ALL") {
      filterName = "com_" + commercial;
    } else {
      filterName = "all";
    }

    /*------------------ turnover per month -----------------*/
    const turnoverPerMonth = await new Parse.Query("StatsProfiles")
      .equalTo("name", "turnoverPerMonthData_" + filterName)
      .first();

    if (turnoverPerMonth !== undefined) {
      setTurnoverPerMonthData(turnoverPerMonth.get("data"));
    }

    /*------------ cumulative turnover per month ------------*/
    const cumulativeTurnoverPerMonth = await new Parse.Query("StatsProfiles")
      .equalTo("name", "cumulativeTurnoverPerMonthData_" + filterName)
      .first();

    if (cumulativeTurnoverPerMonth !== undefined) {
      setCumulativeTurnoverPerMonthData(cumulativeTurnoverPerMonth.get("data"));
    }

    /*------------ cumulative turnover per month for shipping next year ------------*/
    const cumulativeTurnoverPerMonthForShippingNextYear = await new Parse.Query(
      "StatsProfiles",
    )
      .equalTo(
        "name",
        "cumulativeTurnoverPerMonthForShippingNextYearData_" + filterName,
      )
      .first();

    if (cumulativeTurnoverPerMonthForShippingNextYear !== undefined) {
      setCumulativeTurnoverPerMonthForShippingNextYearData(
        cumulativeTurnoverPerMonthForShippingNextYear.get("data"),
      );
    }

    /*----------- turnover for preorders per month -----------*/
    const preorderTurnoverPerMonth = await new Parse.Query("StatsProfiles")
      .equalTo("name", "preOrdersTurnoverPerMonthData_" + filterName)
      .first();

    if (preorderTurnoverPerMonth !== undefined) {
      setPreordersTurnoverPerMonthData(preorderTurnoverPerMonth.get("data"));
    }

    /*------ cumulative turnover for preorder per month ------*/
    const cumulativePreorderTurnoverPerMonth = await new Parse.Query(
      "StatsProfiles",
    )
      .equalTo("name", "cumulativePreOrdersTurnoverPerMonthData_" + filterName)
      .first();

    if (cumulativePreorderTurnoverPerMonth !== undefined) {
      setCumulativePreordersTurnoverPerMonthData(
        cumulativePreorderTurnoverPerMonth.get("data"),
      );
    }
  };

  /********************************************************/
  /********************** stat **********************/
  /********************************************************/

  const generateChartPart = (data) => {
    const dataPerYear = [];
    Object.entries(data[0]).forEach(([key, value]) => {
      if (key !== "date") {
        dataPerYear.push(key);
      }
    });
    let chartPartToReturn = [];
    dataPerYear.forEach((year, index) => {
      chartPartToReturn.push(
        <Line
          key={"line_" + index}
          dataKey={year}
          stroke={colors[index]}
          dot={false}
          type="monotone"
        />,
      );
    });
    return chartPartToReturn;
  };

  const CustomTooltip = ({ active, payload, label, type }) => {
    if (active) {
      return (
        <div className={classes.tooltip}>
          <p className={classes.tooltipLabel}>{label}</p>
          {payload.map((pl) => {
            return (
              <div className={classes.tooltipValue} key={pl.color}>
                <div style={{ color: pl.color }}>
                  {type === "perMonth" && <>{pl.dataKey.slice(-4)}</>}
                  {type === "cumulative" && <>{pl.dataKey}</>}
                </div>
                <div className={classes.tooltipValueTo}>
                  {" "}
                  {new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                    minimumFractionDigits: 0,
                  }).format(pl.value)}
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  if (
    turnoverPerMonthData === null ||
    cumulativeTurnoverPerMonthData === null ||
    preordersTurnoverPerMonthData === null ||
    cumulativePreordersTurnoverPerMonthData === null
  ) {
    return null;
  }
  let titleExplanation = "";
  //shop, group, tradeName, commercial
  if (shop !== "ALL") {
    titleExplanation = (
      <span>
        {" "}
        -{" "}
        {
          listsOptions
            .find((listOptions) => listOptions.listName === "clients")
            .options.find((option) => option.value === shop).text
        }
      </span>
    );
  } else if (group !== "ALL") {
    titleExplanation = (
      <span>
        {" "}
        -{" "}
        {
          listsOptions
            .find((listOptions) => listOptions.listName === "groups")
            .options.find((option) => option.value === group).text
        }
      </span>
    );
  } else if (tradeName !== "ALL") {
    titleExplanation = (
      <span>
        {" "}
        -{" "}
        {
          listsOptions
            .find((listOptions) => listOptions.listName === "tradenames")
            .options.find((option) => option.value === tradeName).text
        }
      </span>
    );
  } else if (commercial !== "ALL") {
    titleExplanation = (
      <span>
        {" "}
        -{" "}
        {
          listsOptions
            .find((listOptions) => listOptions.listName === "commercials")
            .options.find((option) => option.value === commercial).text
        }
      </span>
    );
  }

  return (
    <>
      <Grid container spacing={3} ref={gridWidth}>
        <Grid item xs={4}>
          <Paper className={classes.paper}>
            <Typography variant="subtitle2" className={classes.title}>
              CA par mois{titleExplanation}
            </Typography>
            <div className={classes.lineChartWrapper}>
              {!!turnoverPerMonthData.length && (
                <LineChart
                  width={gridWidth.current / 2}
                  height={250}
                  data={turnoverPerMonthData}
                >
                  <XAxis dataKey="date" />
                  <YAxis domain={["auto", "auto"]} />
                  <Tooltip
                    wrapperStyle={{
                      borderColor: "white",
                      boxShadow: "2px 2px 3px 0px rgb(204, 204, 204)",
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                    }}
                    content={<CustomTooltip type="perMonth" />}
                  />
                  {generateChartPart(turnoverPerMonthData)}
                </LineChart>
              )}
              {!turnoverPerMonthData.length && <span>Pas de CA</span>}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>
            <Typography variant="subtitle2" className={classes.title}>
              CA par mois cumulé{titleExplanation}
            </Typography>
            <div className={classes.lineChartWrapper}>
              {!!cumulativeTurnoverPerMonthData.length && (
                <LineChart
                  width={gridWidth.current / 2}
                  height={250}
                  data={cumulativeTurnoverPerMonthData}
                >
                  <XAxis dataKey="date" />
                  <YAxis domain={["auto", "auto"]} />
                  <Tooltip
                    wrapperStyle={{
                      borderColor: "white",
                      boxShadow: "2px 2px 3px 0px rgb(204, 204, 204)",
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                    }}
                    content={<CustomTooltip type="cumulative" />}
                  />
                  {generateChartPart(cumulativeTurnoverPerMonthData)}
                </LineChart>
              )}
              {!cumulativeTurnoverPerMonthData.length && <span>Pas de CA</span>}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}>
            <Typography variant="subtitle2" className={classes.title}>
              CA par mois cumulé pour N+1{titleExplanation}
            </Typography>
            <div className={classes.lineChartWrapper}>
              {!!cumulativeTurnoverPerMonthForShippingNextYearData.length && (
                <LineChart
                  width={gridWidth.current / 2}
                  height={250}
                  data={cumulativeTurnoverPerMonthForShippingNextYearData}
                >
                  <XAxis dataKey="date" />
                  <YAxis domain={["auto", "auto"]} />
                  <Tooltip
                    wrapperStyle={{
                      borderColor: "white",
                      boxShadow: "2px 2px 3px 0px rgb(204, 204, 204)",
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                    }}
                    content={<CustomTooltip type="perMonth" />}
                  />
                  {generateChartPart(
                    cumulativeTurnoverPerMonthForShippingNextYearData,
                  )}
                </LineChart>
              )}
              {!cumulativeTurnoverPerMonthForShippingNextYearData.length && (
                <span>Pas de CA</span>
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Typography variant="subtitle2" className={classes.title}>
              CA précommandes par mois{titleExplanation}
            </Typography>
            <div className={classes.lineChartWrapper}>
              {!!preordersTurnoverPerMonthData.length && (
                <LineChart
                  width={gridWidth.current / 1.33}
                  height={250}
                  data={preordersTurnoverPerMonthData}
                >
                  <XAxis dataKey="date" />
                  <YAxis domain={["auto", "auto"]} />
                  <Tooltip
                    wrapperStyle={{
                      borderColor: "white",
                      boxShadow: "2px 2px 3px 0px rgb(204, 204, 204)",
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                    }}
                    content={<CustomTooltip type="perMonth" />}
                  />
                  {generateChartPart(preordersTurnoverPerMonthData)}
                </LineChart>
              )}
              {!preordersTurnoverPerMonthData.length && <span>Pas de CA</span>}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Typography variant="subtitle2" className={classes.title}>
              CA précommandes par mois cumulé{titleExplanation}
            </Typography>
            <div className={classes.lineChartWrapper}>
              {!!cumulativePreordersTurnoverPerMonthData.length && (
                <LineChart
                  width={gridWidth.current / 1.33}
                  height={250}
                  data={cumulativePreordersTurnoverPerMonthData}
                >
                  <XAxis dataKey="date" />
                  <YAxis domain={["auto", "auto"]} />
                  <Tooltip
                    wrapperStyle={{
                      borderColor: "white",
                      boxShadow: "2px 2px 3px 0px rgb(204, 204, 204)",
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                    }}
                    content={<CustomTooltip type="cumulative" />}
                  />
                  {generateChartPart(cumulativePreordersTurnoverPerMonthData)}
                </LineChart>
              )}
              {!cumulativePreordersTurnoverPerMonthData.length && (
                <span>Pas de CA</span>
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default TurnoverPerMonthChart;
