import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Field } from "react-final-form-html5-validation";
import { Select } from "final-form-material-ui";
import { withStyles, Grid } from "@material-ui/core";
import { getLists } from "../../../reducers/lists";
import { optionsCreator } from "../../../utils2";

const styles = (theme) => ({
  gridContainer: {
    width: "50%",
  },
  textField: {
    width: "100%",
  },
  selectField: {
    width: "100%",
  },
  fieldContainer: {
    display: "flex",
    flex: 1,
    height: "48px",
  },
});

class SubTitleField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      productData: {},
      values: [],
    };
  }

  render() {
    const { classes, productType, listsOptions, disabled } = this.props;

    return (
      <>
        {productType !== "" && (
          <Grid item xs={6}>
            <div className={classes.fieldContainer}>
              <Field
                name="subtype"
                label="Sous-Type"
                component={Select}
                className={classes.selectField}
                disabled={disabled}
              >
                {productType === "stickAccessories" &&
                  optionsCreator(listsOptions, "stickAccessories")}
                {productType === "headWear" &&
                  optionsCreator(listsOptions, "headWear")}
                {productType === "socks" &&
                  optionsCreator(listsOptions, "socks")}
                {productType === "hydration" &&
                  optionsCreator(listsOptions, "hydration")}
                {productType === "wearable" &&
                  optionsCreator(listsOptions, "wearable")}
                {productType === "textile" &&
                  optionsCreator(listsOptions, "textile")}
              </Field>
            </div>
          </Grid>
        )}
      </>
    );
  }
}

export default connect(
  (state) => ({
    listsOptions: getLists(state),
  }),
  {},
)(withStyles(styles)(SubTitleField));
