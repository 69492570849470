import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ListSubheader from "@material-ui/core/ListSubheader";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { VariableSizeList } from "react-window";
import { Field } from "react-final-form";

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
const ListboxComponent = React.forwardRef(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
      if (React.isValidElement(child) && child.type === ListSubheader) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 12) {
        return 12 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            key={itemCount}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  },
);

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const useStyles = makeStyles({
  listbox: {
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

const AutoCompleteVirtualized = (props) => {
  const classes = useStyles();
  const { limitTags } = props;
  const [name] = useState(props.name);
  const [label] = useState(props.label);
  const [options] = useState(props.options);
  const [validate] = useState(props.validate);
  const [multiple] = useState(
    props.multiple !== undefined ? props.multiple : false,
  );
  // we always pass as initValue looking like {value: '', text: ''} or in an array for multiple
  const [value, setValue] = useState(multiple ? [options[0]] : options[0]);

  useEffect(() => {
    if (props.initValue !== undefined) {
      setValue(props.initValue);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Field name={name} validate={validate}>
      {(props) => (
        <Autocomplete
          options={options}
          multiple={multiple}
          getOptionLabel={(option) => option.text || ""}
          value={value}
          limitTags={limitTags}
          onChange={(event, newValue) => {
            setValue(newValue);

            const value = multiple
              ? newValue !== null
                ? newValue.map((val) => val.value)
                : null
              : newValue !== null
                ? newValue.value
                : null;
            props.input.onChange(value);
          }}
          clearText="Effacer"
          style={{ width: "100%" }}
          classes={classes}
          disableListWrap
          ListboxComponent={ListboxComponent}
          renderInput={(params) => <TextField {...params} label={label} />}
        />
      )}
    </Field>
  );
};
export default AutoCompleteVirtualized;
