const initialState = [
  { id: 0, open: false, selected: true },
  { id: 1, open: false, selected: false },
  { id: 2, open: false, selected: false },
  { id: 3, open: false, selected: false },
  { id: 4, open: false, selected: false },
  { id: 5, open: false, selected: false },
  { id: 6, open: false, selected: false },
  { id: 7, open: false, selected: false },
  { id: 8, open: false, selected: false },
  { id: 9, open: false, selected: false },
  { id: 10, open: false, selected: false },
  { id: 11, open: false, selected: false },
  { id: 12, open: false, selected: false },
  { id: 13, open: false, selected: false },
  { id: 14, open: false, selected: false },
  { id: 15, open: false, selected: false },
  { id: 16, open: false, selected: false },
  { id: 17, open: false, selected: false },
  { id: 18, open: false, selected: false },
  { id: 19, open: false, selected: false },
  { id: 20, open: false, selected: false },
];

const navigation = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_LIST_ITEM": {
      return [
        ...state.slice(0, action.id),
        { id: action.id, open: !state[action.id].open },
        ...state.slice(action.id + 1),
      ];
    }
    case "SELECT_LINK": {
      return state.map((entry) => ({
        ...entry,
        selected: entry.id === action.id,
      }));
    }
    default: {
      return state;
    }
  }
};

export default navigation;

export function getNavigation(state) {
  return state.navigation;
}
