import discounts from "./discounts";
import lists from "./lists";
import navigation from "./navigation";
import order from "./order";
import currentUser from "./currentUser";

const reducers = {
  discounts,
  lists,
  navigation,
  order,
  currentUser,
};

export default reducers;
