import React, { PureComponent } from "react";
import Page from "../Page";

export default class ClockedOrder extends PureComponent {
  render() {
    return (
      <Page
        toolBarTitle="Demande de ticket SAV"
        pageTitle="Demande de ticket SAV"
      />
    );
  }
}
